// This slice is used to for shwing project modal

import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  showModal: false,
  message: "",
  showDissociationModal: false,
  dissociatedInfo: {},
};

const ShowProjectModal = createSlice({
  name: "showProjectModal",
  initialState: initialVal,
  reducers: {
    updateModalState(state, action) {
      state.showModal = action.payload.showModal;
      state.message = action.payload.message;
    },
    updateDissociationModal(state, action) {
      state.showDissociationModal = action.payload.showDissociationModal;
      state.dissociatedInfo = action.payload.dissociationInfo;
    },
    clearData(state) {
      state.showModal = false;
      state.message = "";
      state.showDissociationModal = false;
      state.dissociatedInfo = {};
    },
  },
});

export const ShowProjectModalActions = ShowProjectModal.actions;
export default ShowProjectModal.reducer;
