import { createSlice } from "@reduxjs/toolkit";

const initialvaue = {
  wavesurferObjSFX: {},
};
const wavesurferobjSFXslice = createSlice({
  name: "wavesurferObjSFX",
  initialState: initialvaue,
  reducers: {
    updateWavesurferObjSFX(state, action) {
      state.wavesurferObjSFX = action.payload.wavesurferObjSFX;
    },
    clearWavesurferObjSFX(state) {
      state.wavesurferObjSFX = {};
    },
  },
});
export const wavesurferObjSFXActions = wavesurferobjSFXslice.actions;
export default wavesurferobjSFXslice.reducer;
