import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  runEditFeaturesTour: false,
};

const editFeaturesTourSlice = createSlice({
  name: "editFeaturesTour",
  initialState,
  reducers: {
    startEditFeaturesTour: (state) => {
      state.runEditFeaturesTour = true;
    },
    stopEditFeaturesTour: (state) => {
      state.runEditFeaturesTour = false;
    },
  },
});

export const editFeaturesTourActions = editFeaturesTourSlice.actions;
export default editFeaturesTourSlice.reducer;
