import { createSlice } from "@reduxjs/toolkit";

// Make sure the initial state is properly set up
const initialState = {
  workSpaceTour: false, // Ensure this is false initially
};

// Example slice
const workSpaceTourSlice = createSlice({
  name: "workSpaceTour",
  initialState,
  reducers: {
    startWorkSpaceTour: (state) => {
      state.workSpaceTour = true;
    },
    stopWorkSpaceTour: (state) => {
      state.workSpaceTour = false;
    },
  },
});

export const workSpaceTourActions = workSpaceTourSlice.actions;
export default workSpaceTourSlice.reducer;
