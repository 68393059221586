import { createSlice } from "@reduxjs/toolkit";
import { selectedApiProvider } from "../../utils/utils";

const initialVal = {
  selectedApiProvider: "",
  isNativeExport: true,
};

const SelectedApiProviderSlice = createSlice({
  name: "selectedApiProvider",
  initialState: initialVal,
  reducers: {
    updateApiProvider(state, action) {
      state.selectedApiProvider = action.payload.selectedApiProvider;
    },
    updateIsNativeExport(state, action) {
      state.isNativeExport = action.payload.isNativeExport;
    },
    clearApiProvider(state) {
      state.selectedApiProvider = "";
      state.isNativeExport = true;
    },
  },
});

export const SelectedApiProviderSliceActions = SelectedApiProviderSlice.actions;

export default SelectedApiProviderSlice.reducer;
