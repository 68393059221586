import { createSlice } from "@reduxjs/toolkit";

const multiSelectWordList = {
  wordsObject: [],
  toDragSFX: {},
};
const multiSelectWordSlice = createSlice({
  name: "multiSelectwordObject",
  initialState: multiSelectWordList,
  reducers: {
    updateMultiSelectWordList(state, action) {
      state.wordsObject = action.payload.wordsObject;
    },
    updateDragSFX(state, action) {
      console.log(action.payload);
      state.toDragSFX = action.payload.toDragSFX;
    },
    clearWordList(state) {
      state.wordsObject = [];
    },
  },
});

export const multiSelectWordActions = multiSelectWordSlice.actions; // for list of words that need to be operated on
export default multiSelectWordSlice.reducer;
