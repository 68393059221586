import React from "react";
import { hideMessage } from "../../utils/koolio-workspace";

const PopUpMessage = () => {
  return (
    // pop-up-messages
    <div
      className="pop-up-message"
      id="pop-up-message"
      displayOn="workspace"
      style={{ display: "none !important" }}
      onClick={hideMessage}
    >
      <div className="columnProgress">
        <div className="circle-small">
          {/* <svg
            id="pop-icon"
            style={{
              width: "21px",
              position: "absolute",
              left: "29.5px",
              fill: "#c4c4c4",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path d="M 4 4 L 4 22 L 10 22 L 10 28 L 28 28 L 28 10 L 22 10 L 22 4 Z M 6 6 L 20 6 L 20 10.5625 L 10.5625 20 L 6 20 Z M 11 8 L 11 9 L 8 9 L 8 11 L 12.9375 11 C 12.808594 12.148438 12.457031 13.054688 11.875 13.6875 C 11.53125 13.574219 11.222656 13.433594 10.96875 13.28125 C 10.265625 12.863281 10 12.417969 10 12 L 8 12 C 8 13.191406 8.734375 14.183594 9.71875 14.84375 C 9.226563 14.949219 8.65625 15 8 15 L 8 17 C 9.773438 17 11.25 16.59375 12.375 15.84375 C 12.898438 15.933594 13.429688 16 14 16 L 14 14.125 C 14.542969 13.214844 14.832031 12.152344 14.9375 11 L 16 11 L 16 9 L 13 9 L 13 8 Z M 21.4375 12 L 26 12 L 26 26 L 12 26 L 12 21.4375 Z M 20 13.84375 L 19.0625 16.6875 L 17.0625 22.6875 L 17 22.84375 L 17 24 L 19 24 L 19 23.125 L 19.03125 23 L 20.96875 23 L 21 23.125 L 21 24 L 23 24 L 23 22.84375 L 22.9375 22.6875 L 20.9375 16.6875 Z M 20 20.125 L 20.28125 21 L 19.71875 21 Z" />
          </svg> */}
          <div id="pop-icon-cont">
            <img
              src="/image/workspace/transcribe.png"
              id="pop-icon"
              style={{ position: "absolute" }}
              alt=""
            />
          </div>
          <svg>
            <circle
              id="bg-progress"
              className="bg"
              cx="40"
              cy="40"
              r="20"
            ></circle>
            <circle
              id="active-progress"
              className="progress one"
              cx="40"
              cy="40"
              r="20"
            ></circle>
          </svg>
        </div>
      </div>

      <div className="columnMessage">
        <div className="pop-up-message-title">
          <span id="pop-up-title"></span>
        </div>
        <div className="pop-up-message-sub-title">
          <span id="pop-up-sub-title"></span>
        </div>
      </div>
    </div>
  );
};

export default PopUpMessage;
