import { showMessage } from "../koolio-workspace";
import { exportHighQualityAudio, showStatText } from "../utils";
import {  spreakerSettings } from "./ApiIntegrationAuth";
import axios from "axios";

const spreakerGetUserUrl = "https://api.spreaker.com/v2/me";
const spreakerGetUserShows = "https://api.spreaker.com/v2/users/";
export let noSpreakerShow = false;

export function getUserInfoFromSpreaker() {
  return axios
    .get(spreakerGetUserUrl, {
      headers: { Authorization: `Bearer ${spreakerSettings.accessToken}` },
    })
    .then((res) => {
      const user = res.data.response.user;
      return Promise.resolve(user.user_id);
    })
    .catch((e) => {
      // console.error(e)
      showStatText("Sorry, couldn't find the user. Please try again later.");
      return Promise.resolve(0);
    });
}

export function getUserShowsFromSpreaker(userID) {
  return axios
    .get(spreakerGetUserShows + userID + "/shows?")
    .then((res) => {
      const shows = res.data.response.items;
      return shows.map((show) => {
        const showObj = {
          id: show.show_id,
          title: show.title,
        };
        return showObj;
      });
    })
    .then((userShows) => {
      spreakerSettings.shows = userShows;
      return Promise.resolve(userShows);
    })
    .catch((e) => {
      // console.error(e)
      showMessage(
        "Wrong show title!",
        "Sorry, couldn't find the shows for user. Please try again later.",
        3000,
        "error",
        "more-options",
      );
      return Promise.resolve([]);
    });
}

export async function uploadAudioToShowInSpreaker(
  showID,
  episodeTitle,
  fileToUpload,
  options,
) {
  const publishInfo = {
    titleOrPodcastId: showID.toString(),
    episodeTitle,
    apiProvider: "spreaker",
  };
  return exportHighQualityAudio(options, false, true, publishInfo)
    .then((res) => Promise.resolve())
    .catch((error) => {
      // Handle the error here
      // console.error("An error occurred:", error);
      // You can choose to rethrow the error or handle it gracefully
      // throw error; // Rethrowing the error
    });
}

export async function publishAudioToSpreaker(
  showTitle,
  episodeTitle,
  fileToUpload,
  options,
) {
  const userID = await getUserInfoFromSpreaker();
  if (userID !== 0) {
    const shows = await getUserShowsFromSpreaker(userID);
    if (shows.length > 0) {
      let showID = 0;
      shows.forEach((show) => {
        if (show.title === showTitle) {
          showID = show.id;
        }
      });
      if (showID !== 0) {
        await uploadAudioToShowInSpreaker(
          showID,
          episodeTitle,
          fileToUpload,
          options,
        );
      }
    }
  }
}

export async function getSpreakerShowList() {
  let shows = [];
  const userID = await getUserInfoFromSpreaker();
  if (userID !== 0) {
    shows = await getUserShowsFromSpreaker(userID);
  }
  if (!shows.length) {
    noSpreakerShow = true;
  } else noSpreakerShow = false;

  spreakerSettings.shows = shows;
  return shows;
}

export function changeNoSpreakerVal(val){
  noSpreakerShow = val
}