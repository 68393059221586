import { createSlice } from "@reduxjs/toolkit";

const initatialSolo = {
  soloSpkList: [],
  soloSpk: false,
  soloSfxList: [],
  soloSfx: false,
};
const soloSlice = createSlice({
  name: "solo",
  initialState: initatialSolo,
  reducers: {
    updateSoloSpkList(state, action) {
      state.soloSpkList = action.payload;
    },
    updateSoloSpkCheck(state, action) {
      state.soloSpk = action.payload;
    },
    updateSoloSfxList(state, action) {
      state.soloSfxList = action.payload;
    },
    updateSoloSfxCheck(state, action) {
      state.soloSfx = action.payload;
    },
  },
});
export const soloListActions = soloSlice.actions;
export default soloSlice.reducer;
