import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  runTranscriptDrivenTour: false,
};

const transcriptDrivenTourSlice = createSlice({
  name: "transcriptDrivenTour",
  initialState,
  reducers: {
    startTranscriptDrivenTour: (state) => {
      state.runTranscriptDrivenTour = true;
    },
    stopTranscriptDrivenTour: (state) => {
      state.runTranscriptDrivenTour = false;
    },
  },
});

export const transcriptDrivenTourActions = transcriptDrivenTourSlice.actions;
export default transcriptDrivenTourSlice.reducer;
