import { createSlice } from "@reduxjs/toolkit";

const SfxDragAndDropSlice = createSlice({
  name: "SfxDragAndDrop",
  initialState: {
    drag: false,
    drop: false,
    startTime: null,
    cutRegionStartTime: null,
    cutRegionEndTime: null,
    pateRegionStartTime: null,
    pasteRegionEndTime: null,
    sfxDuration: null,
  },
  reducers: {
    setDrag: (state, action) => {
      state.drag = action.payload;
      console.log(state.drag);
    },
    setDrop: (state, action) => {
      state.drop = action.payload;
      console.log(state.drop);
    },
    setStartTime: (state, action) => {
      state.startTime = action.payload;
      console.log(state.startTime);
    },
    setCutRegionStartTime: (state, action) => {
      state.cutRegionStartTime = action.payload;
      console.log(state.cutRegionStartTime);
    },
    setCutRegionEndTime: (state, action) => {
      state.cutRegionEndTime = action.payload;
      console.log(state.cutRegionEndTime);
    },
    setPateRegionStartTime: (state, action) => {
      state.pateRegionStartTime = action.payload;
      console.log(state.pateRegionStartTime);
    },
    setPasteRegionEndTime: (state, action) => {
      state.pasteRegionEndTime = action.payload;
      console.log(state.pasteRegionEndTime);
    },
    setSfxDuration: (state, action) => {
      state.sfxDuration = action.payload;
      console.log(state.sfxDuration);
    },
  },
});

export const setSfxDragAndDropActions = SfxDragAndDropSlice.actions;
export default SfxDragAndDropSlice.reducer;
