import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  copiedAnnotation: [],
  copiedFrom: "wav",
  toSendAnnot: [],
};
const copiedAnnotData = createSlice({
  name: "copiedAnnotation",
  initialState: initialVal,
  reducers: {
    updAnnotclk(state, action) {
      state.copiedAnnotation = action.payload.copiedAnnotations;
    },
    updSendAnnot(state, action) {
      state.toSendAnnot = action.payload.toSendAnnot;
    },
    updCopiedFrom(state, action) {
      state.copiedFrom = action.payload;
    },
    clearcopydata(state) {
      state.copiedAnnotation = [];
    },
  },
});
export default copiedAnnotData.reducer;
export const copiedAnnotActions = copiedAnnotData.actions;
