// This will have apis related to kooliospace
import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";
import { showStatText } from "../utils/utils";
import {
  getKSFromLocal,
  saveKooliospace,
} from "../components/pages/kooliospace/kooliospaceUtils";

export function getKooliospace() {
  return getKSFromLocal()
    .catch(() =>
      Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
        .then((tokens) =>
          axios.get(_config.api + "/kooliospace", {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          }),
        )
        .then(
          (response) =>
            new Promise((resolve) => {
              let kooliospace = response.data;
              // console.log(response);
              saveKooliospace(kooliospace);
              resolve(kooliospace);
            }),
        ),
    )
    .then((res) => {
      // console.log(res);
      return res;
    })
    .catch((_err) => {
      // console.log('Unable to fetch kooliospace. Check your network connection.')
      // console.error(_err)
      return Promise.resolve([]);
    });
}
