import store from "../redux/Store";
import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";

export async function initiateJobForUploadedFile(kooliojob) {
  try {
    let transcriptionRequired = JSON.parse(
      sessionStorage.getItem("transcriptUploadOption"),
    );
    kooliojob.transcription_required = transcriptionRequired;
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.get(_config.api + "/upload-file", {
          params: kooliojob,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then((response) => {
        //console.log(response)
        const status = response.data.result_stat;
        return Promise.resolve(status);
      });
  } catch (error) {
    //console.error(error)
  }
}

export async function initiateUploadedFileInBackend(kooliojob) {
  try {
    let transcriptionRequired = JSON.parse(
      sessionStorage.getItem("transcriptUploadOption"),
    );

    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.post(_config.api + "/upload-file", kooliojob, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then((response) => {
        //console.log(response)
        const status = response.data.result_stat;
        return Promise.resolve(status);
      });
  } catch (error) {
    console.error(error);
  }
}

export async function startTranscription(jobname, diarization, tran) {
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.post(
        _config.api + "/transcription-new-architecture",
        {
          jobname: jobname, // Pass jobname as data, not in the params object
          diarization_required: diarization,
          transcription_required: tran,
        },
        {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        },
      ),
    )
    .then((response) => {
      const status = response;
      //console.log(status)
      return Promise.resolve(status);
    })
    .catch((error) => {
      //console.log(error)
    });
}

export async function initiateTranscription(params) {
  let token = await Koolioai.authToken();
  // let transcription_required = transcriptionRequired // 3015 manually sending transcription is required or not
  let transcription_required = JSON.parse(
    sessionStorage.getItem("NestedtranscriptUploadOption"),
  );
  sessionStorage.setItem(
    "NestedtranscriptUploadOption",
    JSON.stringify(!transcription_required),
  );
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  // resetTranscriptionRequiredValue()
  return axios.get(_config.api + "/transcribe-clipboard", {
    params: {
      transcription_required,
      refid: params.refid,
      filename: params.filename,
      jobname,
      position: params.inputs.startTime,
      customclass: params.customClass,
      trackid: params.trackid,
      username: Koolioai.username,
    },
    headers: {
      Authorization: `${token}`,
    },
  });
}

export async function initiateJobForRecordedFile(kooliojob) {
  try {
    let transcriptionRequired = JSON.parse(
      sessionStorage.getItem("transcriptUploadOption"),
    );
    kooliojob.transcription_required = transcriptionRequired;
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.get(_config.api + "/record-file", {
          params: kooliojob,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then((response) => {
        //console.log(response)
        const status = response.data.result_stat;
        return Promise.resolve(status);
      });
  } catch (error) {
    //console.error(error)
  }
}

export async function initiateRecordedFileInBackend(kooliojob) {
  try {
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.post(_config.api + "/record-file", kooliojob, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then((response) => {
        const status = response.data.result_stat;
        return Promise.resolve(status);
      });
  } catch (error) {
    console.error(error);
  }
}
