import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./components/pages/loginPage/LoginPage";
import FileUploader from "./components/pages/kooliospace/projectUpload/FileUploader";
// import MorePage from './components/pages/moreoptions/MorePage';
import GenAiPage from "./components/pages/moreoptions/GenAi";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import Recordingspace from "./components/pages/kooliospace/recordingspace/Recordingspace";
import Kooliospace from "./components/pages/kooliospace/Kooliospace";
import Workspace from "./components/pages/workspace/Workspace";
import "bootstrap/dist/css/bootstrap.min.css";
import topbar from "topbar";
import PrivateRoute from "./route/PrivateRoute";
import PopUpMessage from "./components/modals/PopUpMessage";
import MobileUnsupportedModal from "./components/modals/MobileUnsupportedModal";
import LoaderModal from "./components/modals/LoaderModal";
import { useSelector } from "react-redux";
import IdleTimerComponent from "./route/IdleTimerContainer";
import AuthCallback from "./components/authComponent/AuthComponent";
import useBootstrapTooltips from "./hooks/useBootstrapTooltips";

const App = () => {
  topbar.config({
    autoRun: false,
    barThickness: 5,
    barColors: {
      0: "rgb(226, 82, 43, .7)",
      ".5": "rgb(138, 43, 226, .9)",
      "1.0": "rgba(226, 82,  43,  1)",
    },
    shadowBlur: 5,
    shadowColor: "rgba(0, 0, 0, .5)",
    className: "topbar",
  });

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const loaderValue = useSelector((state) => state.playlistLoader.showLoader);
  useEffect(() => {
    if (loaderValue) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loaderValue]);

  useBootstrapTooltips();

  return (
    // <Suspense fallback="...is loading">

    //   <div>
    //   <h2>{t('Welcome.view')}</h2>
    //   <p>{t('interpolation_pluralization.text', { count: 5 })}</p>
    // </div>
    // </Suspense>

    // <Router>
    //   <Routes>
    //     <Route path="/" element={<LoginPage />} />
    //     <Route path="/kooliospace" element={<Kooliospace></Kooliospace>} />
    //     <Route path="/workspace/:jobname" element={<Workspace></Workspace>} />
    //     <Route path="/fileUpload" element={<FileUploader />} />
    //     {/* <Route path="/More" element={<MorePage />} /> */}
    //     <Route path="/record_space" element={<Recordingspace />} />
    //     {/* <Route path="/genai" element={<GenAiPage />} /> */}
    //     <Route path="/genai" element={<GenAiPage />} />
    //   </Routes>
    // </Router>

    <>
      {/* <MobileUnsupportedModal /> */}
      <PopUpMessage />
      {isLoading && <LoaderModal />}
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />

          <Route
            path="/kooliospace"
            element={
              <PrivateRoute>
                <IdleTimerComponent>
                  <Kooliospace />
                </IdleTimerComponent>
              </PrivateRoute>
            }
          />
          <Route
            path="/workspace/:jobname"
            element={
              <PrivateRoute>
                <IdleTimerComponent>
                  <Workspace isLoading={isLoading} />
                </IdleTimerComponent>
              </PrivateRoute>
            }
          />
          <Route
            path="/fileUpload"
            element={
              <PrivateRoute>
                <IdleTimerComponent>
                  <FileUploader />
                </IdleTimerComponent>
              </PrivateRoute>
            }
          />
          {/* <Route path="/More" element={<MorePage />} /> */}
          <Route
            path="/record_space"
            element={
              <PrivateRoute>
                <IdleTimerComponent>
                  <Recordingspace />
                </IdleTimerComponent>
              </PrivateRoute>
            }
          />
          {/* <Route path="/genai" element={<GenAiPage />} /> */}
          <Route
            path="/genai"
            element={
              <PrivateRoute>
                <IdleTimerComponent>
                  <GenAiPage />
                </IdleTimerComponent>
              </PrivateRoute>
            }
          />
          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
