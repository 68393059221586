import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "../../css/dark/RecordingName.css";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import store from "../../redux/Store";
import { projectTitleSliceActions } from "../../redux/slice/ProjectTitleSlice";
import TranscriptionModal from "./TranscriptionModal";

function RecordingFileName({ closeModal }) {
  const [recordFileName, setRecordFileName] = useState("");
  const [transcriptionRequired, setTranscriptionRequired] = useState("");

  const [showTranscriptionModal, setShowTranscriptionModal] = useState(false);

  const navigate = useNavigate();

  const handleInput = (e) => {
    setRecordFileName(e.target.value);
  };

  const initiateRecord = () => {
    store.dispatch(
      projectTitleSliceActions.updateProjectTitle({
        env: "Recordspace",
        projectTitle: recordFileName,
      }),
    );
    navigate("/record_space", {
      state: {
        fileName: recordFileName,
        transcriptionOption: transcriptionRequired,
      },
      replace: true,
    });
  };

  const handleTranscriptionMOdal = async () => {
    setShowTranscriptionModal(true);
  };

  async function handleTranscriptionYes() {
    let transcriptionSelected = true;
    sessionStorage.setItem(
      "transcriptUploadOption",

      JSON.stringify(transcriptionSelected),
    );
    setTranscriptionRequired(true);
    setShowTranscriptionModal(false);
  }
  async function handleTranscriptionNo() {
    let transcriptionSelected = false;
    sessionStorage.setItem(
      "transcriptUploadOption",

      JSON.stringify(transcriptionSelected),
    );
    setTranscriptionRequired(false);
    setShowTranscriptionModal(false);
  }

  function handleTranscirptionClose() {
    setShowTranscriptionModal(false);
  }
 
  useEffect(() => {
 
    if (transcriptionRequired !== "") {
      initiateRecord();
    }
  }, [transcriptionRequired]);

  return createPortal(
    <>
      <div className="recordingName-wrapper" onClick={closeModal}>
        <span
          className="col-12 "
          id="annotCloseModal"
          style={{
            cursor: "pointer",
            padding: "0px",
            textAlign: "end",
            position: "absolute",
            right: "22px",
            top: "10px",
            zIndex: "1010",
          }}
        >
          <img
            className="img-fluid"
            id="close-modal-window"
            src="./image/exit-workspace.svg"
            onClick={closeModal}
            alt=""
          />
        </span>
      </div>
      <div className="recordingName-modal">
        {!showTranscriptionModal && (
          <>
            <input
              placeholder="Enter Project Title"
              className="recordModalInput"
              type="text"
              required
              onChange={handleInput}
            />
            <br />
            <div className="">
              <button
                className="record-btn-options me-4"
                onClick={handleTranscriptionMOdal}
              >
                Record
              </button>
              <button className="record-btn-options" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </>
        )}
        {showTranscriptionModal && (
          <TranscriptionModal
            onClose={handleTranscirptionClose}
            onTranscriptYes={handleTranscriptionYes}
            onTranscriptNo={handleTranscriptionNo}
          ></TranscriptionModal>
        )}
        {/* {showDiarizationModal && (
          <DiarizationModal
            onClose={handleDiarizationClose}
            onDiarizationYes={handleDiarizationYes}
            onDiarizationNo={handleDiarizationNo}
          ></DiarizationModal>
        )} */}
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default RecordingFileName;
