import { createSlice } from "@reduxjs/toolkit";

/*************************GenAI selected sfx and music data*****************************/

const initialGenAIValue = {
  result: "",
};

const customGenaiSlice = createSlice({
  name: "customGenaiSlice",
  initialState: initialGenAIValue,
  reducers: {
    updateGenAIsfxMusic(state, action) {
      state.result = action.payload.value;
    },
  },
});

export const customGenaiSliceActions = customGenaiSlice.actions;
export default customGenaiSlice.reducer;
