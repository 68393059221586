// In this slice we will be using global variable for share and collab

import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  isEditFeatureEnabled: false,
  editFeatureLockedBy: "",
  isTheProjectShared: false,
};

const shareAndCollabSlice = createSlice({
  name: "shareAndCollab",
  initialState: initialVal,
  reducers: {
    updateEditFeatureEnable(state, action) {
      state.isEditFeatureEnabled = action.payload.isEditFeatureEnabled;
    },
    updateEditFeatureLockedBy(state, action) {
      state.editFeatureLockedBy = action.payload.editFeatureLockedBy;
    },
    updateISProjectShared(state, action) {
      state.isTheProjectShared = action.payload.isTheProjectShared;
    },
  },
});

export const shareAndCollabActions = shareAndCollabSlice.actions;
export default shareAndCollabSlice.reducer;
