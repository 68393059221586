import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import LeaveWorkspaceModal from "./LeaveWorkspaceModal";
import { moreOptionsTourActions } from "../../redux/slice/MoreOptionsTourSlice";
import { transcriptDrivenTourActions } from "../../redux/slice/TranscriptDrivenTourSlice";
import { editFeaturesTourActions } from "../../redux/slice/EditFeaturesTourSlice";
import { workSpaceTourActions } from "../../redux/slice/WorkSpaceTourSlice";
import store from "../../redux/Store";
import { showStatText } from "../../utils/utils";

import "../../css/dark/TourGuideModal.css";
import { useDispatch } from "react-redux";

export default function TourGuideModal({
  showTourGuideModal,
  closeTourGuideModal,
  props,
  openMoreOptions,
  openTranscriptDrivenMode,
  openWorkspaceTour,
}) {
  const dispatch = useDispatch();
  const [showLeaveModal, setShowLeaveModal] = useState(false);

  // useEffect to handle showing LeaveWorkspaceModal after closing TourGuideModal
  useEffect(() => {
    if (!showTourGuideModal && showLeaveModal) {
      setShowLeaveModal(true);
    }
  }, [showTourGuideModal, showLeaveModal]);

  const handleKooliospaceClick = () => {
    closeTourGuideModal(); // Close the current modal
    setShowLeaveModal(true); // Open the LeaveWorkspaceModal
  };

  const handleMoreOptionsClicked = () => {
    dispatch(moreOptionsTourActions.startMoreOptionsTour());
    closeTourGuideModal();
    openMoreOptions();
  };

  const handleTranscriptDrivenClicked = () => {
    const isTranscribed = store.getState().projectData?.resultStat?.transcribed;

    if (isTranscribed) {
      dispatch(transcriptDrivenTourActions.startTranscriptDrivenTour());
      closeTourGuideModal();
      openTranscriptDrivenMode();
    } else {
      closeTourGuideModal();
      showStatText("transcription is not available for this project");
    }
  };

  const handleEditFeaturesClicked = () => {
    dispatch(editFeaturesTourActions.startEditFeaturesTour());
    closeTourGuideModal();
  };

  const handleWorkSpaceClicked = () => {
    dispatch(workSpaceTourActions.startWorkSpaceTour());
    closeTourGuideModal();
    openWorkspaceTour();
  };

  return (
    <>
      {showTourGuideModal &&
        createPortal(
          <div id="tourGuideModalWrapper">
            <div
              className="half-modals-content"
              id="half-modals-content"
              onClick={(e) => e.stopPropagation()} // Prevents the modal from closing when clicked
            >
              <div className="half-modals-header">
                <p className="half-modals-title">Tour Guides</p>
                <img
                  title="Close"
                  onClick={closeTourGuideModal}
                  id="closeWorkspaceModalIcon"
                  src="/image/exit-workspace.svg"
                  alt=""
                />
              </div>
              <div className="half-modals-sub-header"></div>
              <div className="half-modals-action">
                <div className="half-modals-actions text-center">
                  <div>
                    <button
                      type="button"
                      className="half-modals-action-button"
                      onClick={handleKooliospaceClick}
                    >
                      <img
                        className="img-fluid"
                        src="/image/workspace/podcast-icon.png"
                        alt="Podcast Icon"
                      />
                      Kooliospace
                    </button>
                    <button
                      type="button"
                      className="half-modals-action-button"
                      onClick={handleWorkSpaceClicked}
                    >
                      <img
                        className="img-fluid"
                        src="/image/workspace/podcast-icon.png"
                        alt="Podcast Icon"
                      />
                      Workspace
                    </button>
                    <button
                      type="button"
                      className="half-modals-action-button"
                      onClick={handleEditFeaturesClicked}
                    >
                      <img
                        className="img-fluid"
                        src="/image/workspace/podcast-icon.png"
                        alt="Podcast Icon"
                      />
                      Edit Features
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="half-modals-action-button"
                      onClick={handleTranscriptDrivenClicked}
                    >
                      <img
                        className="img-fluid"
                        src="/image/workspace/podcast-icon.png"
                        alt="Podcast Icon"
                      />
                      Transcript Driven Edit Mode
                    </button>
                    <button
                      type="button"
                      className="half-modals-action-button"
                      onClick={handleMoreOptionsClicked}
                    >
                      <img
                        className="img-fluid"
                        src="/image/workspace/podcast-icon.png"
                        alt="Podcast Icon"
                      />
                      More Options
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>,
          document.querySelector("body"),
        )}
      <LeaveWorkspaceModal
        showModal={showLeaveModal}
        closeModal={() => setShowLeaveModal(false)}
        props
      />
    </>
  );
}
