import React from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { projectDataSliceActions } from "../../redux/slice/ProjectDataSlice";
import { projectTitleSliceActions } from "../../redux/slice/ProjectTitleSlice";
import { recordSpaceBtnActions } from "../../redux/slice/RecordspaceBtnTriggerSlice";
import { multiSelectActions } from "../../redux/slice/MultiselectSlice";
import { multiSelectWordActions } from "../../redux/slice/MultiSelectWordList";
import { hideplaylistloader } from "../pages/kooliospace/kooliospaceUtils";
import { applyOperationsInBackend } from "../../services/globalServices";
import { unlockFeatureForOtherCollaborators } from "../../services/ShareAndCollabeServices";
import { frontendApplySliceActions } from "../../redux/slice/FrontendApplySlice";
import { shareAndCollabActions } from "../../redux/slice/ShareAndCollableSlice";
import { updateIndexDBoperationsData } from "../../utils/indexedDButils";
import { updateResultstatByJobname } from "../../utils/indexedDButils";
import { editFeatureTitle } from "../../utils/utils";
import { getKSFromLocal } from "../pages/kooliospace/kooliospaceUtils";
import { updateKooliospace } from "../pages/kooliospace/kooliospaceUtils";
import { clearBackendApplyScheduler } from "../../utils/utils";
import { clearOperationCountHandlerScheduler } from "../../utils/utils";
import { showplaylistloader } from "../pages/kooliospace/kooliospaceUtils";
import store from "../../redux/Store";
import { koolioSpaceTourActions } from "../../redux/slice/KoolioSpaceTourSlice";

import "../../css/dark/LeaveWorkspaceModal.css";

export default function LeaveWorkspaceModal({ showModal, closeModal, props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectTitle = useSelector((state) => state.projectTitle.projectTitle);
  const closeKoolioWorkspace = async () => {
    if (document.getElementById("pop-up-message")) {
      //this if handling nested recordspace close btn
      document.getElementById("pop-up-message").style.display = "none";
      dispatch(
        projectTitleSliceActions.updateProjectTitle({
          env: "workspace",
          projectTitle: projectTitle,
        }),
      );
    }
    if (props.env === "workspace") {
      dispatch(
        recordSpaceBtnActions.storeBtnClickedEnv({
          env: "recordspaceToWorkspace",
        }),
      );
      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({
          wordsObject: [],
        }),
      );
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
      props.onClose();
    } else {
      if (document.getElementById("playlist-transcript")) {
        document.getElementById("playlist-transcript").style.display = "none";
      }
      showplaylistloader(true);
      if (props.env !== "recordspace") {
        const resultStat = store.getState().projectData.resultStat;
        const jobname = resultStat.jobname;
        const refid = store.getState().projectData.resultStat.refid;
        getKSFromLocal()
          .then(async (koolioJobs) => {
            let job = koolioJobs.filter((job) => job.jobname === jobname)[0];
            if (job) {
              job.duration = resultStat.duration;
              job.updated_at = new Date();
              await updateKooliospace(job);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        // job.duration = playlist.duration
        // job.updated_at = new Date()
        clearBackendApplyScheduler();
        clearOperationCountHandlerScheduler();
        await applyOperationsInBackend(jobname);
        await updateResultstatByJobname(jobname, refid);
        await unlockFeatureForOtherCollaborators(editFeatureTitle);
        await updateIndexDBoperationsData(
          "jobname, frontend_apply_status, deleted",
          [jobname, "pending", "false"],
          "frontend_apply_status",
          "completed",
        );
        store.dispatch(
          shareAndCollabActions.updateEditFeatureLockedBy({
            editFeatureLockedBy: "",
          }),
        );
        store.dispatch(
          shareAndCollabActions.updateEditFeatureEnable({
            isEditFeatureEnabled: true,
          }),
        );
        store.dispatch(
          shareAndCollabActions.updateISProjectShared({
            isTheProjectShared: false,
          }),
        );
        store.dispatch(projectDataSliceActions.clearProjectReducerData());
        store.dispatch(projectTitleSliceActions.clearProjectData());
        sessionStorage.removeItem("aiShowNotes");
        sessionStorage.setItem("prevstate", "workspace");
      }
      hideplaylistloader(true);
      store.dispatch(frontendApplySliceActions.frontendApplyReducerData(false));
      dispatch(koolioSpaceTourActions.startKoolioSpaceTour());
      navigate("/kooliospace", { replace: true });
    }
  };
  return createPortal(
    <>
      {showModal && (
        <div id="modalWrapper" onClick={closeModal}>
          <div
            className="half-modals-content"
            id="half-modals-content"
            onClick={(e) => e.stopPropagation()} // Prevents the modal from closing when clicked
          >
            <div className="half-modals-header">
              <p className="half-modals-title">
                Opening the kooliospace tour will close the workspace. Are you
                sure you want to continue?
              </p>
              <img
                title="Close"
                onClick={closeModal}
                id="closeWorkspaceModalIcon"
                src="/image/exit-workspace.svg"
                alt=""
              />
            </div>
            <div className="half-modals-sub-header"></div>
            <div className="half-modals-action">
              <div className="half-modals-actions text-center">
                <div>
                  <button
                    type="button"
                    className="half-modals-action-button"
                    onClick={() => {
                      closeKoolioWorkspace();
                      closeModal();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="half-modals-action-button"
                    onClick={closeModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>,
    document.querySelector("body"),
  );
}
