// let editableFlag = undefined
// let username_for_flag

import store from "../redux/Store";
import { flagListActions } from "../redux/slice/Flagslice";
import $ from "jquery";
import { create_UUID } from "./utils";
import { Koolioai } from "./cognitoAuth";
// function addFlag() {
//     reInitializeTooltips('#cm-flag')
//     //if (!ACM.checkPermission('flag')) return showStatText('please upgrade your account')
//     const permResponse = ACM.checkPermission('flag')
//     if (!permResponse.success) return showStatText(permResponse.message)

//     let flag = {
//         id:'',
//         jobname:'',
//         shouldDelete: false,
//         isEditable:true,
//         trackFlagMapping:{
//             id: '',
//             startTime: 0,
//             endTime:0,
//             customClass:'',
//             trackId:'',
//             operationId: '',
//         },
//         flagContent: {
//             id:'',
//             userName:'',
//             comment:'',
//             timestamp:0
//         }
//     }

//     let startTime = playlist.getTimeSelection().start
//     let endTime   = playlist.getTimeSelection().end
//     if(startTime === 0 || endTime === 0) {
//         showStatText("Please select a point to add flag!")
//     }
//     else {
//         if(startTime !== endTime) {
//             //check if startTime is not in activeTrack
//             if(startTime < playlist.activeTrack.startTime ||  playlist.activeTrack.endTime < startTime) {
//                 // need to find correct track to which startTime belongs to
//                 const track = playlist.tracks.find(track =>
//                     track.src && track.customClass.split('-')[0] === playlist.activeTrack.customClass.split('-')[0]
//                     && track.startTime <= startTime && startTime <= track.endTime)
//                 if(track)
//                     playlist.activeTrack = track
//             }
//         }
//         flag.id = create_UUID()
//         flag.jobname = playlist.jobname
//         flag.trackFlagMapping.id = create_UUID()
//         flag.trackFlagMapping.startTime      = startTime
//         flag.trackFlagMapping.endTime        = startTime // flags always at a particular point, so start == end.
//         flag.trackFlagMapping.customClass    = playlist.activeTrack.customClass
//         flag.trackFlagMapping.operationId    = ''
//         flag.flagContent.id        = create_UUID()
//         flag.flagContent.userName  = Koolioai.username
//         flag.flagContent.comment   = ''
//         flag.flagContent.timestamp = Date.now()

//         let trackIndex = -1
//         if(flag.trackFlagMapping.customClass.includes('sfx')){
//             trackIndex = playlist.tracks.findIndex(track => (track.src && track.customClass === flag.trackFlagMapping.customClass))
//             if(trackIndex > -1)flag.trackFlagMapping.trackId = playlist.tracks[trackIndex].id
//             else flag.trackFlagMapping.trackId = playlist.activeTrack.id
//         }else{
//             flag.trackFlagMapping.trackId        = playlist.activeTrack.id
//             trackIndex = playlist.tracks.findIndex(track => track.id === flag.trackFlagMapping.trackId)
//         }

//         if(trackIndex > -1){
//             if(playlist.tracks[trackIndex].flags === undefined) playlist.tracks[trackIndex].flags=[]
//             playlist.tracks[trackIndex].flags.push(flag)

//             playlist.draw(playlist.render())
//             drawGlobalView()
//             addTooltipStyle()

//             const params = {
//                 task: 'add',
//                 inputs: flag
//             }
//             if(!playlist.flags) playlist.flags = []
//             playlist.flags.push(flag)
//             sendFlagToBackend(params)
//         }
//     }
// }

// function editFlag(flag){
//     reInitializeTooltips('flag-'+flag.id)
//     flag.flagContent.id = create_UUID()
//     flag.flagContent.comment = $('#comment').val()
//     flag.flagContent.userName = Koolioai.username
//     flag.flagContent.timestamp = Date.now()
//     updateFlagContentInUI(flag)
//     const index = playlist.flags.findIndex(playlistFlag=> flag.id === playlistFlag.id)
//     if(index > -1){
//         playlist.flags[index] = flag
//     }
//     const params = {
//         task: 'edit',
//         inputs: flag
//     }
//     sendFlagToBackend(params)
//     indicateTranscriptSpecials()
// }
// function deleteFlag(flag){
//     reInitializeTooltips('flag-'+flag.id)
//     let deletedFlag = undefined
//     for(let track of  playlist.tracks){
//         if(track.src
//             && ((track.startTime <= flag.trackFlagMapping.startTime && flag.trackFlagMapping.startTime <= track.endTime) || flag.trackFlagMapping.customClass.includes('sfx'))
//             && flag.trackFlagMapping.customClass == track.customClass){
//             const flagIndex = track.flags.findIndex(trackFlag => trackFlag.id === flag.id)
//             deletedFlag = track.flags.splice(flagIndex,1)
//             if(deletedFlag.length>0) removeFlagFromUI(deletedFlag[0].id)
//             break
//         }
//     }
//     globalPlaylist.tracks.forEach(t=>{
//         if(t.flags){
//             let flagIndex = t.flags.findIndex(trackFlag => trackFlag.id === flag.id)
//             t.flags.splice(flagIndex,1)
//         }
//     })
//     if(deletedFlag !== undefined && deletedFlag.length>0){
//         const index = playlist.flags.findIndex(playlistFlag=> flag.id === playlistFlag.id)
//         if(index > -1){
//             playlist.flags.splice(index,1)
//         }
//     }

//     if(deletedFlag !== undefined && deletedFlag.length>0){
//         editableFlag = undefined
//         const params = {
//             task: 'delete',
//             inputs: deletedFlag[0]
//         }
//         deleteFlagToBackend(params)
//     }
//     indicateTranscriptSpecials()
// }

// function sendFlagToBackend (params) {
//     showStatText('saving...', false)
//     return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
//         .then(tokens => {
//             return axios
//                 .post(_config.api + '/api-flag', params, {
//                     headers: {
//                         Authorization: `${tokens[0]}`,
//                         AccessToken: `${tokens[1]}`

//                     }
//                 })
//                 .then(response => {
//                     showStatText('synchronized', false)
//                     return Promise.resolve(response.data)
//                 })
//                 .catch(e=>{
//                     showStatText('Sorry! could not update the flag. please try again later' , false)
//                     return Promise.reject(e)
//                 })
//         })
// }
// function deleteFlagToBackend (params) {
//     showStatText('saving...', false)
//     return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
//         .then(tokens => {
//             return axios
//                 .delete(_config.api + '/api-flag',  {
//                     data: {
//                         jobname: params.inputs.jobname,
//                         flagId: params.inputs.id,
//                         deletedBy: params.inputs.flagContent.userName
//                     },
//                     headers: {
//                         Authorization: `${tokens[0]}`,
//                         AccessToken: `${tokens[1]}`
//                     }
//                 })
//                 .then(response => {
//                     showStatText('synchronized', false)
//                     return Promise.resolve(response.data)
//                 })
//                 .catch(e=>{
//                     showStatText('Sorry! could not delete the flag. please try again later' , false)
//                     return Promise.reject(e)
//                 })
//         })
// }
// function getFlagsFromBackend(jobname){
//     showStatText('getting flags...', false)
//     return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
//         .then(tokens => {
//             return axios
//                 .get(_config.api + '/api-flag', {
//                     params: {
//                         jobname
//                     },
//                     headers: {
//                         Authorization: `${tokens[0]}`,
//                         AccessToken: `${tokens[1]}`

//                     }
//                 })
//                 .then(response => {
//                     getUsernameforFlagsforBackend(response.data)
//                     return Promise.resolve(response.data)
//                 })
//                 .catch(e =>{
//                     return Promise.reject(e)
//                 })
//         })
// }
// function sendFlagAdjustmentDataToBackend(flagListToUpdateInBackend, task){
//     if(flagListToUpdateInBackend.length>0){
//         const params = {
//             task: task,
//             inputs: flagListToUpdateInBackend
//         }
//         sendFlagToBackend(params)
//     }
// }

// async function loadFlags(jobname, isEditable= true, isApplying = false){
//     clearFlags()
//     try{
//         let response = await getFlagsFromBackend(jobname)
//         playlist.flags = response.flags
//         if(!isApplying) loadFlagsInPlaylist(response.flags, isEditable)
//         else loadFlagsInPlaylistAfterApplyingOperation(response.flags, isEditable)
//         return Promise.resolve()
//     }catch(e) {
//         return Promise.reject(e)
//     }
// }
// function loadFlagsInPlaylist(flags, isEditable){
//     flags.forEach(flag=>{
//         let ifFlagLoaded = false
//         playlist.tracks.forEach(track=>{
//             if(track.flags === undefined) track.flags = []
//             if(track.src
//                 && track.startTime <= flag.trackFlagMapping.startTime && flag.trackFlagMapping.startTime  <= track.endTime
//                 && flag.trackFlagMapping.customClass === track.customClass){

//                 flag.isEditable = isEditable
//                 flag.trackFlagMapping.trackId = track.id
//                 flag.trackFlagMapping.customClass = track.customClass
//                 track.flags.push(flag)
//                 ifFlagLoaded = true
//             }
//         })
//         if(!ifFlagLoaded && flag.trackFlagMapping.customClass.includes('sfx')){
//             playlist.tracks.forEach(track=>{
//                 if(track.flags === undefined) track.flags = []
//                 if(track.src && flag.trackFlagMapping.customClass === track.customClass){

//                     flag.isEditable = isEditable
//                     flag.trackFlagMapping.trackId = track.id
//                     flag.trackFlagMapping.customClass = track.customClass
//                     track.flags.push(flag)
//                     ifFlagLoaded = true
//                 }
//             })
//         }
//     })
// }
// function loadFlagsInPlaylistAfterApplyingOperation(flags, isEditable){
//     flags.forEach(flag=>{
//         let ifFlagLoaded = false
//         let flagElement = document.getElementById('flag-' + flag.id)
//         if(!flagElement){
//             playlist.tracks.forEach(track=>{
//                 if(track.flags === undefined) track.flags = []
//                 if(track.src
//                     && track.startTime <= flag.trackFlagMapping.startTime && flag.trackFlagMapping.startTime  <= track.endTime
//                     && flag.trackFlagMapping.customClass === track.customClass){
//                     flag.isEditable = isEditable
//                     flag.trackFlagMapping.trackId = track.id
//                     flag.trackFlagMapping.customClass = track.customClass
//                     track.flags.push(flag)
//                     ifFlagLoaded = true
//                 }
//             })
//             if(!ifFlagLoaded && flag.trackFlagMapping.customClass.includes('sfx')){
//                 playlist.tracks.forEach(track=>{
//                     if(track.flags === undefined) track.flags = []
//                     if(track.src && flag.trackFlagMapping.customClass === track.customClass){

//                         flag.isEditable = isEditable
//                         flag.trackFlagMapping.trackId = track.id
//                         flag.trackFlagMapping.customClass = track.customClass
//                         track.flags.push(flag)
//                         ifFlagLoaded = true
//                     }
//                 })
//             }
//         }
//     })
//     showStatText('', false)
// }

// To handle real-time sync
export function addFlagInPlaylist(flag) {
  let tracks = store.getState().projectData.resultStat.speakers_segments;
  // for(let track of playlist.tracks){
  // if(track.src
  //     && ((track.startTime <= flag.trackFlagMapping.startTime && flag.trackFlagMapping.startTime <= track.endTime) || flag.trackFlagMapping.customClass.includes('sfx'))
  //     && flag.trackFlagMapping.customClass === track.customClass){
  //     if(!track.flags) track.flags = []
  //     const flagIndex = track.flags.findIndex(trackFlag => trackFlag.id === flag.id)
  //     if(flagIndex < 0) track.flags.push(flag)
  // playlist.draw(playlist.render())
  // drawGlobalView()
  // break
  // }
  // }
}
// function editFlagInPlaylist(flag){
//     for(let track of playlist.tracks){
//         if(track.src
//             && ((track.startTime <= flag.trackFlagMapping.startTime && flag.trackFlagMapping.startTime <= track.endTime) || flag.trackFlagMapping.customClass.includes('sfx'))
//             && flag.trackFlagMapping.customClass === track.customClass){
//             if(!track.flags) track.flags = []
//             const flagIndex = track.flags.findIndex(trackFlag => trackFlag.id === flag.id)
//             if(flagIndex<0)track.flags.push(flag)
//             else track.flags[flagIndex] = flag
//             playlist.draw(playlist.render())
//             drawGlobalView()
//             break
//         }
//     }
// }
// function deleteFlagInPlaylist(flagId){
//     for(let track of playlist.tracks){
//         if(!track.flags) track.flags = []
//         const flagIndex = track.flags.findIndex(trackFlag => trackFlag.id === flagId)
//         if(flagIndex > -1){
//             let deletedFlag = track.flags.splice(flagIndex,1)
//             removeFlagFromUI(flagId)
//             break
//         }
//     }
//     globalPlaylist.tracks.forEach(t=>{
//         if(t.flags){
//             let flagIndex = t.flags.findIndex(trackFlag => trackFlag.id === flagId)
//             t.flags.splice(flagIndex,1)
//         }
//     })
// }
// function adjustFlagsInPlaylist(flags){
//     flags.forEach(flag=>{
//         for(let track of playlist.tracks){
//             if(!track.flags)track.flags = []
//             const flagIndex = track.flags.findIndex(trackFlag => trackFlag.id === flag.id)
//             if(flagIndex > -1) track.flags[flagIndex] = flag
//             break
//         }
//     })
// }

// function addTooltipStyle(){
//     const tooltips = document.querySelectorAll('.tt')
//     tooltips.forEach(t => {
//         new bootstrap.Tooltip(t)
//     })
// }
// function showFlagContentAddModal (modal) {
//     $('#half-modals').html(modal)
//     $('#half-modals').css('visibility', 'visible')
//     $('.tt').tooltip('hide')
//     $('.tooltip').tooltip('hide')
//     koolioModals()
// }
// function prepareModal(flag){
//     //if(playlist.mode === 'edit'){
//     let modal = ''
//     if(flag.flagContent.comment === '') modal= comment
//     else modal= editcomment

//     updateFlagContent(flag, modal)
//     //}
// }
// function updateFlagContentInUI(flag){
//     showStatText('updating flag...', false)
//     let flagComment = ''
//     getUsernameforFlags(flag.flagContent.userName)
//     if(flag.flagContent.comment !== '') flagComment = username_for_flag + ': ' +  flag.flagContent.comment

//     let element = document.getElementById('flag-'+ flag.id)
//     if(element) element.setAttribute('title', flagComment)

//     let globalElement = document.getElementById('global-flag-'+ flag.id)
//     if(globalElement) globalElement.setAttribute('title', flagComment)

//     addTooltipStyle()
//     showStatText('', false)
// }
// function updateFlagContent(flag, modal){
//     showFlagContentAddModal(modal)
//     $('#comment').val(flag.flagContent.comment)
//     editableFlag = flag

//     $container.on('click', '#add-comment', function (){
//         if($('#comment').val() !== undefined && $('#comment').val()!== '' && editableFlag!== undefined){
//             editFlag(editableFlag)
//             closeHalfModal()
//             editableFlag = undefined
//         }
//     })
//     $container.on('click', '#update', function (){
//         if($('#comment').val() !== undefined && $('#comment').val()!== '' && editableFlag!== undefined){
//             editFlag(editableFlag)
//             closeHalfModal()
//             editableFlag = undefined
//         }
//     })
//     $container.on('click', '#delete', function () {
//         closeHalfModal()
//         if(editableFlag !== undefined) deleteFlag(editableFlag)
//     })
// }
// function removeFlagFromUI(flagId){
//     let element = document.getElementById('flag-'+ flagId)
//     if(element)element.remove()

//     let globalElement = document.getElementById('global-flag-'+ flagId)
//     if(globalElement)globalElement.remove()
// }

// function clearFlags(){
//     playlist.flagUndoList = []
//     playlist.flagRedoList =[]
//     editableFlag = undefined
// }

// async function getUsernameforFlags(flagUsername) {
//     if (flagUsername.startsWith('google_')){
//         const url = _config.api + '/get-email'
//         const params = {
//             username: flagUsername
//         }
//         const res = await axios.get(url, {
//             params,
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         if (res.status === 200) {
//             username_for_flag = res.data.email.split('@')[0]
//         }
//     } else {
//         username_for_flag = flagUsername
//     }
// }

// async function getUsernameforFlagsforBackend(flagData) {
//     let flags = flagData.flags
//     for (i=0; i<flags.length; i++) {
//         if (flags[i].flagContent.userName.startsWith('google_')){
//             const url = _config.api + '/get-email'
//             const params = {
//                 username: flags[i].flagContent.userName
//             }
//             const res = await axios.get(url, {
//                 params,
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }
//             })
//             if (res.status === 200) {
//                 flagData.flags[i].flagContent.userName = res.data.email.split('@')[0]
//             }
//         }
//     }
// }
export function deleteFlag(ws_socket = false, flagInfo) {
  let flagID;

  if (!ws_socket) {
    flagID = store.getState().flag.currFlagId;
  } else {
    flagID = flagInfo;
  }

  let flagsList = store.getState().flag.flagsList;
  let todeleteflag;
  flagsList.forEach((flag) => {
    if (flag.id == flagID) {
      todeleteflag = flag;
    }
  });
  flagsList = flagsList.filter((flag) => flag.id !== flagID);
  if (todeleteflag) {
    const params = {
      task: "delete",
      inputs: todeleteflag,
    };
    if (document.getElementById("flag-" + flagID)) {
      document.getElementById("flag-" + flagID).remove();
      document.getElementById("global-flag-" + flagID).remove();
    }

    store.dispatch(flagListActions.uploadFlagList(flagsList));
  } else {
    console.error("invalid flag data");
  }
}

export function editFlag(data) {
  let flagsList = store.getState().flag.flagsList;
  let updateFlag;
  let flagID = data.id;
  flagsList.forEach((flag) => {
    if (flag.id == flagID) {
      updateFlag = JSON.parse(JSON.stringify(flag));
    }
  });
  flagsList = flagsList.filter((flag) => flag.id !== flagID);

  updateFlag.flagContent.id = data.flagContent.id;
  updateFlag.flagContent.comment = data.flagContent.comment;
  updateFlag.flagContent.userName = data.flagContent.userName;
  updateFlag.flagContent.timestamp = Date.now();
  flagsList.push(updateFlag);
  const params = {
    task: "edit",
    inputs: updateFlag,
  };
  store.dispatch(flagListActions.uploadFlagList(flagsList));

  document.getElementById("flag-" + flagID).title =
    `${data.flagContent.userName}: ${updateFlag.flagContent.comment}`;

  document.getElementById("global-flag-" + flagID).title =
    `${Koolioai.username}: ${updateFlag.flagContent.comment}`;
  // indicateTranscriptSpecials()
}
