import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";
import store from "../redux/Store";
import { create_UUID, renderFlag, showStatText } from "../utils/utils";
import { flagListActions } from "../redux/slice/Flagslice";
import { data } from "../components/operations/operations";

export async function sendFlagToBackend(params) {
  showStatText("saving...", false);
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .post(_config.api + "/api-flag", params, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          showStatText("synchronized", false);
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          showStatText(
            "Sorry! could not update the flag. please try again later",
            false,
          );
          return Promise.reject(e);
        });
    },
  );
}

export async function getFlagsFromBackend(jobname) {
  showStatText("getting flags...", false);
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/api-flag", {
          params: {
            jobname,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          getUsernameforFlagsforBackend(response.data);
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

export async function deleteFlagToBackend(params) {
  showStatText("saving...", false);
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .delete(_config.api + "/api-flag", {
          data: {
            jobname: params.inputs.jobname,
            flagId: params.inputs.id,
            deletedBy: params.inputs.flagContent.userName,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          showStatText("synchronized", false);
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          showStatText(
            "Sorry! could not delete the flag. please try again later",
            false,
          );
          return Promise.reject(e);
        });
    },
  );
}
async function getUsernameforFlagsforBackend(flagData) {
  let flags = flagData.flags;
  for (let i = 0; i < flags.length; i++) {
    if (flags[i].flagContent.userName.startsWith("google_")) {
      const url = _config.api + "/get-email";
      const params = {
        username: flags[i].flagContent.userName,
      };
      const res = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        flagData.flags[i].flagContent.userName = res.data.email.split("@")[0];
      }
    }
  }
}
export async function loadFlags(
  jobname,
  isEditable = true,
  isApplying = false,
  ws_socket = false,
  data,
) {
  //clearFlags()
  let resultstat = store.getState().projectData.resultStat;
  let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
  try {
    let flagsList = [];
    let response;
    if (ws_socket) {
      let flags_List = store.getState().flag.flagsList;
      const exists = flags_List.some((item) => item.id === data.id);
      if (exists) return;
      if (flags_List.length > 0) {
        for (let flag of flags_List) {
          if (flag.id === data.id) {
            return; // Exit the function immediately
          }
        }
      }
      response = {
        flags: [
          {
            flagContent: {
              id: data.flagContent.id,
              comment: data.flagContent.comment,
              timestamp: data.flagContent.timestamp,
              userName: data.flagContent.userName,
            },
            id: data.id,
            jobname: data.jobname,
            shouldDelete: data.shouldDelete,
            trackFlagMapping: {
              customClass: data.trackFlagMapping.customClass,
              endTime: data.trackFlagMapping.endTime,
              id: data.trackFlagMapping.id,
              operationId: data.trackFlagMapping.operationId,
              startTime: data.trackFlagMapping.startTime,
              trackId: data.trackFlagMapping.trackId,
            },
          },
        ],
      };
    } else {
      response = await getFlagsFromBackend(jobname);
    }

    response.flags.forEach((flag) => {
      flagsList.push(flag);
      var flags = document.createElement("img");
      var globalflags = document.createElement("img");
      let globalWaveformContainer = document.querySelector("#globalview");
      let waveformContainer;
      if (flag.trackFlagMapping.customClass == "speaker") {
        waveformContainer = document.querySelector("#waveform");
        flags.style.top = "0vh";
      } else {
        waveformContainer = document.querySelector("#sfxarea");
        flags.style.top = "21vh";
      }
      var width = wavesurferObj.params.minPxPerSec * resultstat.duration;
      let widthG = document.getElementById("globalview").clientWidth;
      let positionX =
        (flag.trackFlagMapping.startTime / resultstat.duration) * width;
      let positionXG =
        (flag.trackFlagMapping.startTime / resultstat.duration) *
        parseInt(widthG);

      flags.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
      flags.style.position = "absolute";
      flags.style.left = positionX - 3 + "px"; // Adjust based on your needs
      flags.style.zIndex = 999;
      flags.id = "flag-" + flag.id;
      globalflags.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
      globalflags.style.position = "absolute";
      globalflags.style.left = positionXG - 3 + "px"; // Adjust based on your needs
      globalflags.style.zIndex = 99;
      globalflags.id = "global-flag-" + flag.id;
      globalflags.style.width = "0.8463541666666666vw";
      globalflags.style.top = "-10px";
      if (flag.flagContent.comment) {
        flags.title = `${flag.flagContent.userName}: ${flag.flagContent.comment}`;
        globalflags.title = `${flag.flagContent.userName}: ${flag.flagContent.comment}`;
      }
      flags.style.cursor = "pointer";
      globalflags.style.cursor = "pointer";

      flags.addEventListener("click", function () {
        let currFlagId = flag.id;
        store.dispatch(flagListActions.updateFlagId(currFlagId));
        store.dispatch(flagListActions.updateFlagShowModal(true));
      });
      globalflags.addEventListener("click", function () {
        globalFlagTriggerFunction(flag.id);
      });
      waveformContainer.appendChild(flags);
      globalWaveformContainer.appendChild(globalflags);
    });
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
export function adjustFlagForInsert(start, end) {
  let flagsList = JSON.parse(JSON.stringify(store.getState().flag.flagsList));
  let flagListToUpdateInBackend = [];
  let undstack = [];
  flagsList.forEach((flag) => {
    if (flag.trackFlagMapping.startTime > start) {
      undstack.push(JSON.parse(JSON.stringify(flag)));
      flag.trackFlagMapping.startTime += end - start;
      flag.trackFlagMapping.endTime += end - start;
      flag.trackFlagMapping.id = create_UUID();
      flagListToUpdateInBackend.push(flag);
    }
  });
  const params = {
    task: "adjustment",
    inputs: flagListToUpdateInBackend,
  };
  if (flagListToUpdateInBackend.length > 0) {
    data.flagUndo.push(undstack);
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    sendFlagToBackend(params);
    renderFlag();
    return "insertFlag";
  }
  return "insert";
}
export function adjustFlagForCut(regionList, tracktype) {
  let newReg = JSON.parse(JSON.stringify(regionList));
  tracktype = tracktype == "speakers_segments" ? "speaker" : " sfx";
  let flagsList = JSON.parse(JSON.stringify(store.getState().flag.flagsList));
  let initialFlags = JSON.parse(JSON.stringify(flagsList));
  let flagListToUpdateInBackend = [];
  let undstack = [];
  newReg.sort((a, b) => b.start < a.start);
  flagsList.sort(
    (a, b) => b.trackFlagMapping.startTime > a.trackFlagMapping.startTime,
  );
  newReg.forEach((reg) => {
    flagsList.forEach((flag) => {
      if (
        flag.trackFlagMapping.startTime > reg.start &&
        flag.trackFlagMapping.startTime < reg.end &&
        flag.trackFlagMapping.customClass == tracktype
      ) {
        flag.shouldDelete = true;
        document.getElementById("flag-" + flag.id).remove();
        document.getElementById("global-flag-" + flag.id).remove();
      } else if (
        flag.trackFlagMapping.startTime > reg.start &&
        flag.trackFlagMapping.customClass == tracktype
      ) {
        flag.trackFlagMapping.startTime -= reg.end - reg.start;
        flag.trackFlagMapping.endTime -= reg.end - reg.start;
        flag.trackFlagMapping.id = create_UUID();
      }
    });
  });
  flagsList = flagsList.filter((flag) => !flag.shouldDelete);
  // Compare flagsList with initialFlags and update undstack and flagListToUpdateInBackend
  initialFlags.forEach((initialFlag) => {
    const currentFlag = flagsList.find((flag) => flag.id === initialFlag.id);
    if (!currentFlag) {
      // Flag was deleted
      initialFlag.shouldDelete = true;
      undstack.push(initialFlag);
      flagListToUpdateInBackend.push(initialFlag);
    } else if (JSON.stringify(initialFlag) !== JSON.stringify(currentFlag)) {
      // Flag was modified
      undstack.push(initialFlag);
      flagListToUpdateInBackend.push(currentFlag);
    }
  });

  const params = {
    task: "adjustment",
    inputs: flagListToUpdateInBackend,
  };
  if (flagListToUpdateInBackend.length > 0) {
    data.flagUndo.push(undstack);
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    sendFlagToBackend(params);
    renderFlag();
    return "cutFlag";
  }
  return "cut";
}

export async function restoreFlags(
  response, // new flags to be shown
  currflagsList,
  resultstat,
  wavesurferObj,
  isFinalStepOfRestoreOperation = false,
) {
  let flagsList = [];
  console.log(response, currflagsList);
  hideFlagsFromUi(currflagsList);

  response.forEach((flag) => {
    // create a copy of restore flag and show it in ui
    // comments are not coming in restore the backend flow needs to be changed for this
    let flagDup = {
      id: flag.id,
      jobname: flag.jobname,
      shouldDelete: flag.shouldDelete,
      isEditable: true,
      trackFlagMapping: {
        id: flag.trackFlagMapping.id,
        startTime: flag.trackFlagMapping.startTime,
        endTime: flag.trackFlagMapping.endTime,
        customClass: flag.trackFlagMapping.customClass,
        trackId: flag.trackFlagMapping.trackId,
        operationId: flag.trackFlagMapping.operationId,
      },
      flagContent: {
        id: "",
        userName: "",
        comment: "",
        timestamp: 0,
      },
    };
    flagsList.push(flagDup);
    var flags = document.createElement("img");
    var globalflags = document.createElement("img");
    let globalWaveformContainer = document.querySelector("#globalview");
    let waveformContainer;
    if (flag.trackFlagMapping.customClass == "speaker") {
      waveformContainer = document.querySelector("#waveform");
      flags.style.top = "0vh";
    } else {
      waveformContainer = document.querySelector("#sfxarea");
      flags.style.top = "21vh";
    }
    var width = wavesurferObj.params.minPxPerSec * resultstat.duration;
    let widthG = document.getElementById("globalview").clientWidth;
    let positionX =
      (flag.trackFlagMapping.startTime / resultstat.duration) * width;
    let positionXG =
      (flag.trackFlagMapping.startTime / resultstat.duration) *
      parseInt(widthG);

    flags.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
    flags.style.position = "absolute";
    flags.style.left = positionX - 3 + "px"; // Adjust based on your needs
    flags.style.zIndex = 999;
    flags.id = "flag-" + flag.id;
    globalflags.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
    globalflags.style.position = "absolute";
    globalflags.style.left = positionXG - 3 + "px"; // Adjust based on your needs
    globalflags.style.zIndex = 99;
    globalflags.id = "global-flag-" + flag.id;
    globalflags.style.width = "0.8463541666666666vw";
    globalflags.style.top = "-10px";
    if (flag.flagContent?.comment) {
      flags.title = `${flag.flagContent.userName}: ${flag.flagContent.comment}`;
      globalflags.title = `${flag.flagContent.userName}: ${flag.flagContent.comment}`;
    }
    flags.style.cursor = "pointer";
    globalflags.style.cursor = "pointer";

    if (isFinalStepOfRestoreOperation) {
      flags.addEventListener("click", function () {
        let currFlagId = flag.id;
        store.dispatch(flagListActions.updateFlagId(currFlagId));
        store.dispatch(flagListActions.updateFlagShowModal(true));
      });
    }
    waveformContainer.appendChild(flags);
    globalWaveformContainer.appendChild(globalflags);
  });
  store.dispatch(flagListActions.uploadRestoreFlagList(flagsList));
  // console.log(flagsList);
}

export function hideFlagsFromUi(flagsList) {
  flagsList.forEach((flag) => {
    let flagId = document.getElementById("flag-" + flag.id);
    let flagIdGlobal = document.getElementById("global-flag-" + flag.id);
    if (flagId) {
      document.getElementById("flag-" + flag.id).remove();
    }
    if (flagIdGlobal) {
      document.getElementById("global-flag-" + flag.id).remove();
    }
  });
}

export function enableFlagsClickFunctionality(flagsList) {
  if (flagsList.length > 0) {
    flagsList.forEach((flag) => {
      let flagContainer = document.getElementById("flag-" + flag.id);
      flagContainer.addEventListener("click", () => {
        let currFlagId = flag.id;
        store.dispatch(flagListActions.updateFlagId(currFlagId));
        store.dispatch(flagListActions.updateFlagShowModal(true));
      });
    });
  }
}
// this function will take cursor to particular flag location in speaker and sfx segment
export function globalFlagTriggerFunction(flag) {
  let flagsList = store.getState().flag.flagsList;
  let ele = flagsList.filter((ele) => ele.id === flag);
  ele = ele[0];
  let props = store.getState().propsSlice.props;
  props.wavesurferObj.seekTo(
    ele.trackFlagMapping.startTime / props.wavesurferObj.getDuration(),
  );
  props.wavesurferObjSFX.seekTo(
    ele.trackFlagMapping.startTime / props.wavesurferObj.getDuration(),
  );
  props.wavesurferObjGlobal.seekTo(
    ele.trackFlagMapping.startTime / props.wavesurferObjGlobal.getDuration(),
  );
}
