import React, { useEffect } from "react";
// import "../../css/dark/ShowNotesModal.css"; // Import the CSS file
import "../../css/dark/AutoLevelModal.css";
import { koolioModals } from "../../utils/Res";
import { createPortal } from "react-dom";
import store from "../../redux/Store";
import { wavesurferObjActions } from "../../redux/slice/wavesurferobj";
import {
  create_UUID,
  getSelectedCC,
  restThings,
  saveState,
  sendOperation,
} from "../../utils/utils";
import { addOperationsToDB } from "../../utils/indexedDButils";
import { operationListSliceActions } from "../../redux/slice/OperationListSlice";
import { projectDataSliceActions } from "../../redux/slice/ProjectDataSlice";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

const AutoLevelModal = (props) => {
  useEffect(() => {
    koolioModals();
  }, []);
  const closeHalfModal = () => {
    props.onClose();
  };
  const initateAutoLevel = async () => {
    let resultsStat = store.getState().projectData.resultStat;
    let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    saveState(resultsStat, "autoLevel");
    // Function to calculate peak level
    const calculatePeakLevel = (wavesurfer) => {
      const peaks = wavesurfer.backend.getPeaks(512);
      return Math.max(...peaks);
    };
    const originalPeakValue = calculatePeakLevel(wavesurferObj);
    // Normalize the waveform
    wavesurferObj.params.normalize = true;
    // Get the new peak value after normalization
    let value = 1 / originalPeakValue;
    wavesurferObj.drawBuffer();
    store.dispatch(
      wavesurferObjActions.updateWavesurferObj({
        wavesurferObj: wavesurferObj,
      }),
    );
    let operationID = create_UUID();
    const items = [
      {
        customClass: "speakers-0",
        value,
      },
    ];
    const params = {
      jobname: resultsStat.jobname,
      refid: operationID,
      parentRefid: resultsStat.refid,
      worker: "multiVolumeChange",
      inputs: {
        items,
        selectedCustomClasses: getSelectedCC(resultsStat),
      },
    };
    let operationList = store.getState().operationListData.operationList;
    const newResultStat = { ...resultsStat };
    newResultStat.refid = operationID;
    await restThings(params, newResultStat)
    closeHalfModal();
  };
  return createPortal(
    <>
      <div
        id="half-modals-container"
        className="autoLevel-wrapper"
        onClick={closeHalfModal}
      >
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p style={{ margin: 0 }} id="auto-filter-confirmation-modal-header">
              Auto level confirmation
            </p>
            <img
              title="close"
              className="img-fluid"
              src="/image/exit-workspace.svg"
              alt="close icon"
              onClick={closeHalfModal}
              id="closeWorkspaceModalIcon"
            />
          </div>
          <div
            className="half-modals-sub-header"
            id="auto-filter-confirmation-modal-sub-header"
          >
            Are you sure you want to auto level the whole track sound?
          </div>
          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                id="btn-auto-filter-okay"
                className="half-modals-action-button"
                onClick={initateAutoLevel}
              >
                Yes
              </button>
              <button
                type="button"
                className="half-modals-action-button"
                onClick={closeHalfModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};
export default AutoLevelModal;
