import { createSlice } from "@reduxjs/toolkit";

const initailclick = {
  clickedregion: "",
  // regionStartTime: 0,
  // regionEndTime: 0,
};
const clickedRegion = createSlice({
  name: "selectedRegion",
  initialState: initailclick,
  reducers: {
    uploadSelectedRegion(state, action) {
      state.clickedregion = action.payload.payload.type;
    },
    // updateRegionStartTime(state, action) {
    //   state.regionStartTime = action.payload;
    //   console.log(state.regionStartTime);
    // },
    // updateRegionEndTime(state, action) {
    //   state.regionEndTime = action.payload;
    //   console.log(state.regionEndTime);
    // },
    clearSelectedRegion(state) {
      state.clickedregion = "";
    },
  },
});

export const clickedRegionActions = clickedRegion.actions;
export default clickedRegion.reducer;
