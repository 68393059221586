import { createSlice } from "@reduxjs/toolkit";

const intialVal = {
  speakerBtnClicked: false,
  speakerType: "",
};

const speakerBtnClickedSlice = createSlice({
  name: "speakerBtnClickedSlice",
  initialState: intialVal,
  reducers: {
    updateSpeakerRightClick(state, action) {
      state.speakerBtnClicked = action.payload.value;
    },
    updateSpeakerType(state, action) {
      state.speakerType = action.payload.tracktype;
    },
  },
});

export const speakerBtnClickedSliceActions = speakerBtnClickedSlice.actions;
export default speakerBtnClickedSlice.reducer;
