import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "../../css/dark/TranscriptionModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";
// import "../../css/dark/ShowNotesModal.css";
// import { koolioModals } from "../../utils/Res";

function TranscriptionModal({ onClose, onTranscriptYes, onTranscriptNo }) {
  // useEffect(() => {
  //   koolioModals();
  // }, []);

  return createPortal(
    <>
      <div className="transcriptionModal-wrapper">
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p style={{ margin: "0" }} id="annotation-modal-header">
              Do you want transcription for your project?
            </p>
            <img
              title="close"
              onClick={onClose}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
            />
          </div>
          <div className="half-modals-action">
            <div
              className="half-modals-actions text-center"
              style={{ width: "15%" }}
            >
              <button
                id="transyes"
                type="button"
                onClick={() => onTranscriptYes(true)}
                className="half-modals-action-button"
              >
                Yes
              </button>
            </div>
            <div
              className="half-modals-actions text-center"
              style={{ width: "15%" }}
            >
              <button
                id="transno"
                type="button"
                onClick={() => onTranscriptNo(false)}
                className="half-modals-action-button"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default TranscriptionModal;
