import React, { useEffect } from "react";
import "./../../css/dark/AuthCallback.css";
import { API_ENV } from "../../utils/publish-api/ApiIntegrationAuth";

const AuthCallback = () => {
  useEffect(() => {
    const handleAuth = () => {
      const url = window.location.href;
      let apiProvider = "";

      // Determine the API provider based on the window name
      if (window.name.includes("spreaker-auth")) apiProvider = "spreaker";
      else if (window.name.includes("blubrry-auth")) apiProvider = "blubrry";
      else if (window.name.includes("podbean-auth")) apiProvider = "podbean";

      // Function to parse URL and retrieve the token
      const getToken = (url, apiProvider) => {
        const parseParams = (str) => {
          const parts = str.split("?");
          if (parts.length === 2) str = parts[1];
          const pieces = str.split("&");
          const data = {};
          pieces.forEach((piece) => {
            const [key, value = ""] = piece.split("=");
            data[decodeURIComponent(key)] = decodeURIComponent(value);
          });
          return data;
        };

        const hash = parseParams(url);
        let token = "";

        switch (apiProvider.toLowerCase()) {
          case "spreaker":
          case "blubrry":
          case "podbean":
            if (hash.code !== undefined) token = hash.code;
            break;
          default:
            console.error("Unsupported provider or missing token.");
        }

        return token;
      };

      const code = getToken(url, apiProvider);
      if (code && apiProvider) {
        const dataToSend = { status: "success", data: { code, apiProvider } }; // we are using this to send message to parent componenet to trigger sendTokenToBAckend
        window.opener.postMessage(dataToSend, API_ENV);
      }

      // Close the current window after handling authentication
      window.close();
    };

    // Call the authentication handling logic
    handleAuth();
  }, []); // Dependency array ensures this runs only once

  return (
    <div
      id="redirect-page"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {/* Koolio Logo */}
      <div
        id="koolio-logo-redirect"
        style={{ margin: "auto" }}
        className="header-logo-h"
      >
        <img
          id="koolio-icon-header-redirect"
          className="img-fluid"
          src="/image/header/koolio-header-icon.svg"
          alt="Koolio Logo"
        />
      </div>

      {/* Status Display */}
      <center>
        <div className="status-display">
          <h4>
            <span className="status"></span>
          </h4>
          <div
            id="status-bar"
            className="ldBar text-white"
            data-stroke="#8A2BE2"
            data-value="0"
          >
            <span id="publisher-name">Placeholder</span> authentication
            successful.
          </div>
        </div>
      </center>
    </div>
  );
};

export default AuthCallback;
