import React from "react";
import MoreOption from "./MoreOption";
import { createPortal } from "react-dom";
import "../../../css/dark/MoreOptions.css";
import GenAi from "./GenAi";

const GenAIPortal = (props) => {
  return createPortal(
    <>
      <div className="moreOptionContainer">
        <GenAi
          isWhat={props.isWhat}
          onClose={props.onClose}
          onMoreFunctionClose={props.onCloseMoreOptions}
        />
      </div>
      ,
    </>,
    document.querySelector("body"),
  );
};

export default GenAIPortal;
