import React, { useEffect } from "react";
import "../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { koolioModals } from "../../utils/Res";
import { createPortal } from "react-dom";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

export default function DeleteRecordingModal({
  onRecord,
  onClose,
  onDeleteYes,
  msg,
}) {
  useEffect(() => {
    koolioModals();
  }, []);

  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="full-modals-content" id="full-modals-content">
          <div className="close-btn-container">
            <img
              title="close"
              className="img-fluid top-right-button"
              onClick={onClose}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="18px"
            />
          </div>

          <div className="full-modals-actions">
            <p className="full-modal-title">
              {msg == true
                ? "Close recording workspace?"
                : "Delete current recording?"}
            </p>
            <div className="full-modals-button-section">
              <button
                type="button"
                onClick={onDeleteYes}
                className="full-modal-btn"
              >
                {msg == true ? "Close" : "Delete"}
              </button>
              <button
                type="button"
                onClick={onRecord}
                className="full-modal-btn"
              >
                Record
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}
