import { createSlice } from "@reduxjs/toolkit";

const initialRecordingFile = {
  file: {
    file: "",
    name: "",
    duration: 0,
    jobname: "",
  },
};

const recordSlice = createSlice({
  name: "file",
  initialState: initialRecordingFile,
  reducers: {
    addRecordFileToKooliospace(state, action) {
      //adding new file in store reducer so that we can use it in kooliospace
      // console.log("new file added", action.payload.file)
      console.log(action.payload.file);
      state.file = action.payload.file;
      // console.log(state)
    },
    clearRecordedFileFromStore(state) {
      //for clearing record file from store after the file is added in kooliospace
      // console.log(state.file)
      state.file = {
        file: "",
        name: "",
        duration: 0,
        jobname: "",
      };
      // console.log(state.file)
    },
  },
});

export const recordActions = recordSlice.actions;
export default recordSlice.reducer;
