import React from "react";
import { createPortal } from "react-dom";
import "../../../css/dark/GenAIModals.css";

function GenAImodals(props) {
  function closeImage() {
    props.closeGenAiImage();
  }

  function downloadImage() {
    // Get the image URL from the props
    var imageUrl = props.selectedImage.id;
    //var dataURL = canvas.toDataURL("image/png");
    var fileExtension = imageUrl.split(".").pop();
    // Create a temporary link element
    var downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;

    // Set the download attribute with a filename
    downloadLink.download = "image." + fileExtension;

    // Append the link to the document body
    document.body.appendChild(downloadLink);

    // Trigger a click on the link to initiate the download
    downloadLink.click();

    // Remove the link from the document
    document.body.removeChild(downloadLink);
  }
  return createPortal(
    <>
      <div id="genAi-image">
        <div id="modalgenimage">
          <div className="image-modal-container">
            <img
              title="close"
              id="closeImageModalIcon"
              onClick={closeImage}
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt="close-image"
            />
            <img
              title="download"
              id="downloadImageIcon"
              onClick={downloadImage}
              style={{ cursor: "pointer" }}
              src="/image/transcript/export_transcript.png"
              alt="download-image"
            />
            <div className="image-wrapper">
              <img
                src={props.selectedImage.id}
                className="hoverable-image"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default GenAImodals;
