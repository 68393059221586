import { createSlice } from "@reduxjs/toolkit";

const initialFrontendApplyStatus = {
  isfrontendApplyActive: false,
};

const frontendApplySlice = createSlice({
  name: "frontendApplyData",
  initialState: initialFrontendApplyStatus,
  reducers: {
    frontendApplyReducerData(state, action) {
      state.isfrontendApplyActive = action.payload;
    },
    clearFrontendApplyReducerData(state) {
      state.isfrontendApplyActive = false;
    },
  },
});
export const frontendApplySliceActions = frontendApplySlice.actions;
export default frontendApplySlice.reducer;
