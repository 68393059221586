import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  runKoolioSpaceTour: false,
};

const koolioSpaceTourSlice = createSlice({
  name: "koolioSpaceTour",
  initialState,
  reducers: {
    startKoolioSpaceTour: (state) => {
      state.runKoolioSpaceTour = true;
    },
    stopKoolioSpaceTour: (state) => {
      state.runKoolioSpaceTour = false;
    },
  },
});

export const koolioSpaceTourActions = koolioSpaceTourSlice.actions;
export default koolioSpaceTourSlice.reducer;
