/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adjustFlagForCut,
  adjustFlagForInsert,
  enableFlagsClickFunctionality,
  hideFlagsFromUi,
  restoreFlags,
  globalFlagTriggerFunction,
  sendFlagToBackend,
} from "../../services/FlagServices";
import { _config, Koolioai } from "../../utils/cognitoAuth";
//import Buffer from "buffer";
import "../../css/dark/editcontrolls.css";
import "../../css/dark/Operations.css";
import "../../css/dark/gapremovalmodal.css";
import _ from "lodash";
import FileUploader from "../pages/kooliospace/projectUpload/FileUploader";
import WaveSurfer from "wavesurfer.js";
import {
  clearCurrentTimeScheduler,
  showStatText,
  create_UUID,
  enableHistoryOptions,
  getDuration,
  handleHorizontalScroll,
  initiateGetCurrentTimeScheduler,
  sendOperation,
  checkAccess,
  sendLog,
  editFeatureTitle,
  pasteOperation,
  insertOperation,
  loadSlickSliders,
  updateCurrentTime,
  convertTimeToVw,
  renderFade,
  adjustAnnot,
  processAnnotations,
  renderFlag,
  annotUIshiftSub,
  ajustAnnotandFadeAfterZoom,
  findClosestAnnotationIndex,
  resetAnnotation,
  disableIconSpacingButtons,
  enableIconSpacingButtons,
  insertAnnotations,
  restThings,
  saveState,
  disableOperationsForNestedImport,
  hideZoomControls,
  adjustFade,
  deleteannotation,
  handleAnnotationShift,
  fadeOperation,
  removeFade,
  ACM,
  getCurrentFeatureWiseCount,
  updateTrialFeatureUsageCount,
  activeAnnotationColor,
  mergeSegments,
  getSelectedCC,
} from "../../utils/utils";
import Modal from "react-modal";
import $, { param } from "jquery";

import "../../css/dark/modals.css";
// import '../../css/dark/ShowNotesModal.css'
import Recordingspace from "../pages/kooliospace/recordingspace/Recordingspace";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import store from "../../redux/Store";
import { CopyBufferActions } from "../../redux/slice/copyBuffer";
import { recordSpaceBtnActions } from "../../redux/slice/RecordspaceBtnTriggerSlice";
import { projectDataSliceActions } from "../../redux/slice/ProjectDataSlice";
import { multiSelectWordActions } from "../../redux/slice/MultiSelectWordList";
import { multiSelectActions } from "../../redux/slice/MultiselectSlice";
import wavesurferobj, {
  wavesurferObjActions,
} from "../../redux/slice/wavesurferobj";
import { wavesurferObjSFXActions } from "../../redux/slice/wavesurferObjSFX";
import {
  addOperationsToDB,
  convertAudioBufferToBlob,
  getArrayBufferFromSrcURL,
  restoreOperationsInIndexedDB,
  updateIndexDBoperationsData,
  updateIndexedDBoperation,
  updateResultstatByJobname,
} from "../../utils/indexedDButils";
import "bootstrap/dist/css/bootstrap.css";
import { operationListSliceActions } from "../../redux/slice/OperationListSlice";
import { viewEditSliceActions } from "../../redux/slice/ViewEditModeSlice";
import { projectTitleSliceActions } from "../../redux/slice/ProjectTitleSlice";
import TranscriptionModal from "../modals/TranscriptionModal";
import { convertTimeToPixel } from "../../utils/utils";
import { wordMultiSelect } from "../pages/workspace/Workspace";
import { copiedAnnotActions } from "../../redux/slice/copiedAnnotation";
import { restoreDataSliceActions } from "../../redux/slice/restoreSlice";
import {
  checkTimeoutOfFeatureLockData,
  lockFeatureForOtherCollaborators,
  unlockFeatureForOtherCollaborators,
} from "../../services/ShareAndCollabeServices";
import {
  hideplaylistloader,
  showplaylistloader,
} from "../pages/kooliospace/kooliospaceUtils";
import { getKooliospace } from "../../services/KooliospaceServices";
import FlagModal from "../pages/workspace/modals/flagModal";
import AutoLevelModal from "../modals/AutoLevelModal";
import { flagListActions } from "../../redux/slice/Flagslice";
import { AdjustAnnotationActions } from "../../redux/slice/RestoreAnnotationofCutSlice";
import SfxMusicLengthModal from "../modals/SfxMusicLengthModal";
import axios from "axios";
import { shareAndCollabActions } from "../../redux/slice/ShareAndCollableSlice";
import { updateOperationSpace } from "../../utils/ProjectTitleUtils";
import {
  showMessage,
  updateWorkspaceWithOrigin,
} from "../../utils/koolio-workspace";
import { clickedRegionActions } from "../../redux/slice/ClickedRegionSlice";
import { setSfxDragAndDropActions } from "../../redux/slice/SfxDragAndDropSlice";
import { annotationListActions } from "../../redux/slice/AnnotationListSlice";
import { nestedFileSliceActions } from "../../redux/slice/NestedImportSlice";
import { setSfxDurationSliceActions } from "../../redux/slice/SfxDurationSlice";
import { ToggleSliceActions } from "../../redux/slice/ToggleButtonForEnablingOperationsSlice";
import { customGenaiSliceActions } from "../../redux/slice/GenaiSelectedSfxMusicSlice";
import { restoreProjectHistory } from "../../services/globalServices";
import UpdateSpeakerModal from "../modals/UpdateSpeakerModal";
import { speakerBtnClickedSliceActions } from "../../redux/slice/RightCLickOnSpeakerSlice";
import { ensureBuffersReady } from "./frontendApplyOperations";
Modal.setAppElement("#root");
export let data = {
  flagUndo: [],
  flagRedo: [],
  undoStack: [],
  redoStack: [],
  canvasUndoStack: [],
  canvasRedoStack: [],
  annottrackstack: [],
};

export const iconsData = {
  annotations: {
    inactive: "/image/workspace/controls/annotations.png",
    active: "/image/workspace/active-icons/annotations.png",
  },
  copy: {
    inactive: "/image/workspace/controls/copy.png",
    active: "/image/workspace/active-icons/copy.png",
  },
  cut: {
    inactive: "/image/workspace/controls/cut.png",
    active: "/image/workspace/active-icons/cut.png",
  },
  fades: {
    inactive: "/image/workspace/controls/fades.png",
    active: "/image/workspace/active-icons/fades.png",
  },
  flag: {
    inactive: "/image/workspace/controls/flag.png",
    active: "/image/workspace/active-icons/flag.png",
  },
  import_track: {
    inactive: "/image/workspace/controls/import_track.png",
    active: "/image/workspace/active-icons/import_track.png",
  },
  insert: {
    inactive: "/image/workspace/controls/insert.png",
    active: "/image/workspace/active-icons/insert.png",
  },
  loop: {
    inactive: "/image/workspace/controls/loop.png",
    active: "/image/workspace/active-icons/loop.png",
  },
  magic: {
    inactive: "/image/magic.png",
    active: "/image/workspace/active-icons/magic.png",
  },
  "match-word": {
    inactive: "/image/workspace/controls/match-word.png",
    active: "/image/workspace/active-icons/match-word.png",
  },
  "notify-active": {
    inactive: "/image/workspace/controls/notify-active.png",
    active: "/image/workspace/active-icons/notify-active.png",
  },
  paste: {
    inactive: "/image/workspace/controls/paste.png",
    active: "/image/workspace/active-icons/paste.png",
  },
  play: {
    inactive: "/image/workspace/controls/play.png",
    active: "/image/workspace/active-icons/play.png",
  },
  push_to_end: {
    inactive: "/image/workspace/controls/push_to_end.png",
    active: "/image/workspace/active-icons/push_to_end.png",
  },
  push_to_start: {
    inactive: "/image/workspace/controls/push_to_start.png",
    active: "/image/workspace/active-icons/push_to_start.png",
  },
  "rec-play": {
    inactive: "/image/workspace/controls/rec-play.png",
    active: "/image/workspace/active-icons/rec-play.png",
  },
  record: {
    inactive: "/image/workspace/controls/record.png",
    active: "/image/workspace/active-icons/record.png",
  },
  replace: {
    inactive: "/image/workspace/controls/replace.png",
    active: "/image/workspace/active-icons/replace.png",
  },
  silence: {
    inactive: "/image/workspace/controls/silence.png",
    active: "/image/workspace/active-icons/silence.png",
  },
  speakers: {
    inactive: "/image/workspace/controls/speakers.png",
    active: "/image/workspace/active-icons/speakers.png",
  },
  "view-password": {
    inactive: "/image/workspace/controls/view-password.png",
    active: "/image/workspace/active-icons/view-password.png",
  },
};

const EditControls = (props) => {
  // for modal
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [value, setValue] = useState(1);
  const [dropdownContent, setDropdownContent] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [playing, setPlaying] = useState(true); // to keep track whether audio is currently playing or not
  const [copyBuffer, setCopyBuffer] = useState(null);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [showAutoLEvelModal, setShowAutoLEvelModal] = useState(false);
  const lastclicked = useSelector((state) => state.selectedRegion);
  const runEditFeaturesTour = useSelector(
    (state) => state.editFeaturesTour.runEditFeaturesTour,
  );

  const [currentFlagId, setCurrentFlagId] = useState("");
  const [fadeInOut, setFadeInOut] = useState("");
  //loop
  const [isLooping, setIsLooping] = useState(false);
  const audioContextRef = useRef(null);
  const [nestedRecordBtnClicked, setNestedRecordBtnClicked] = useState(false);
  // for modal
  const [modalIsOpenForNestedRecord, setModalIsOpenForNestedRecord] =
    useState(false);
  const [valueForNestedRecording, setValueForNestedRecording] = useState(1.0);
  const [durationNestedRecord, setDurationNestedRecord] = useState(0);
  const [nestedRecordFile, setNestedRecordFile] = useState("");
  const [disableModalForNestedRec, setdisableModalForNestedRec] =
    useState(false); // this we will use to disable modal for recording in sfx
  const handleAnnotafterUndo = useSelector((state) => state.adjustAnnotation);
  const [disableBtn, setDisableBtn] = useState("false");
  const [disableBtnNested, setDisableBtnNested] = useState(false); // for nested import and nested record
  const [isModalOpenModal, setIsModalOpenModal] = useState(false);
  const [pastePosition, setPastePosition] = useState(null);
  const [optype, setOptype] = useState(null);
  const [isFileImported, setIsFileImported] = useState(false);
  const [openUpdateSpeakerModal, setopenUpdateSpeakerModal] = useState(false);
  const enableoperationBtn = useSelector((state) => state.toggleSlice.value);
  const customeGenaiSfxMusic = useSelector(
    (state) => state.customGenaiSFXMusicSlice.result,
  );
  const speakerIconCLicked = useSelector(
    (state) => state.speakerBtnClicked.speakerBtnClicked,
  );
  //for opening length selection Modal
  function closeUpdateSpeakerModal() {
    setopenUpdateSpeakerModal(false);
    dispatch(
      speakerBtnClickedSliceActions.updateSpeakerRightClick({ value: false }),
    );
  }
  const sfxopenModal = (position, op) => {
    setOptype(op);
    setPastePosition(position);
    setIsModalOpenModal(true);
  };

  const sfxcloseModal = () => {
    setIsModalOpenModal(false);
  };

  const handleSetLength = (length, position) => {
    if (optype == "paste") handlePaste(position, length);
    else handleInsert(position, length);
    sfxcloseModal();
  };

  useEffect(() => {
    setShowFlagModal(store.getState().flag.flagShowModal);
    setCurrentFlagId(store.getState().flag.currFlagId);
  }, [
    useSelector((state) => state.flag.currFlagId),
    useSelector((state) => state.flag.flagShowModal),
  ]);
  useEffect(() => {
    if (
      handleAnnotafterUndo.adjustAnnot != undefined &&
      handleAnnotafterUndo.adjustAnnot
    ) {
      let resultStat = store.getState().projectData.resultStat;
      let props = store.getState().propsSlice.props;
      annotUIshiftSub(resultStat, props);
      restoreCursorPositions(0);
      store.dispatch(
        AdjustAnnotationActions.updateAdjustAnnotation({ adjustAnnot: false }),
      );
    }
  }, [handleAnnotafterUndo]);
  useEffect(() => {
    setDropdownContent(document.querySelector(".dropdown-content"));
  }, []);

  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (enableoperationBtn) {
      enableButtonAsync();
      store.dispatch(ToggleSliceActions.updateToggleVal({ value: "false" }));
    } else {
    }
    if (
      customeGenaiSfxMusic != undefined &&
      customeGenaiSfxMusic.signedUrlCompressed
    ) {
      addGenaiSfxMusic(customeGenaiSfxMusic);
    }
  }, [enableoperationBtn, customeGenaiSfxMusic]);
  useEffect(() => {
    if (speakerIconCLicked) {
      setopenUpdateSpeakerModal(true);
    }
  }, [speakerIconCLicked]);
  function openModal() {
    const permResponse = ACM.checkPermission("auto-gap-removal");
    if (!permResponse.success) return showStatText(permResponse.message);
    setModalIsOpen(true);
  }
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  function closeModal() {
    setModalIsOpen(false);
  }
  function cleanRegion() {
    const regionaudio = props.wavesurferObj.regions.list;
    const regionSFX = props.wavesurferObjSFX.regions.list;
    const regionGlobal = props.wavesurferObjGlobal.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    for (const regionKey in regionGlobal) {
      regionGlobal[regionKey].remove();
    }
  }

  const handleToggleLoop = () => {
    hideZoomControls();

    if (isLooping) {
      document.getElementById("btn-loop-icon").src = iconsData.loop.inactive;
      stopLoop();
    } else {
      document.getElementById("btn-loop-icon").src = iconsData.loop.active;
      startLoop();
    }
  };
  document.addEventListener("DOMContentLoaded", (event) => {});
  //undoredo for flag
  function flagUndos() {
    let task = "adjustment-undo";
    let flagsList = JSON.parse(JSON.stringify(store.getState().flag.flagsList));
    let flagsToRestore = JSON.parse(JSON.stringify(data.flagUndo.pop()));
    let flagsForRedoStack = [];
    let flagsToBacked = [];

    flagsToRestore.forEach((restoreFlag) => {
      let existingFlagIndex = flagsList.findIndex(
        (flag) => flag.id === restoreFlag.id,
      );

      if (existingFlagIndex !== -1) {
        // Flag exists, update it
        let existingFlag = flagsList[existingFlagIndex];
        flagsForRedoStack.push(JSON.parse(JSON.stringify(existingFlag)));
        flagsList[existingFlagIndex] = restoreFlag;
        flagsToBacked.push(restoreFlag);
      } else {
        // Flag doesn't exist (was deleted), so add it back
        flagsForRedoStack.push(JSON.parse(JSON.stringify(restoreFlag))); // Mark as deleted in redo stack
        restoreFlag.shouldDelete = false;
        flagsList.push(restoreFlag);
        flagsToBacked.push(restoreFlag);
      }
    });

    if (!data.flagRedo) {
      data.flagRedo = [];
    }

    const params = {
      task,
      inputs: flagsToBacked,
    };

    data.flagRedo.push(flagsForRedoStack);
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    sendFlagToBackend(params);
    renderFlag();
  }

  function flagRedos() {
    let task = "adjustment-redo";
    let flagsList = JSON.parse(JSON.stringify(store.getState().flag.flagsList));
    let flagsToRestore = JSON.parse(JSON.stringify(data.flagRedo.pop()));
    let flagsForUndoStack = [];
    let flagsToBacked = [];
    flagsToRestore.forEach((restoreFlag) => {
      if (restoreFlag.shouldDelete == true) {
        // This flag was deleted in the original action, so remove it now
        document.getElementById("flag-" + restoreFlag.id).remove();
        document.getElementById("global-flag-" + restoreFlag.id).remove();
        flagsForUndoStack.push(JSON.parse(JSON.stringify(restoreFlag)));
        flagsList = flagsList.filter((flag) => flag.id !== restoreFlag.id);
        flagsToBacked.push(restoreFlag);
      } else {
        let existingFlagIndex = flagsList.findIndex(
          (flag) => flag.id === restoreFlag.id,
        );

        if (existingFlagIndex !== -1) {
          // Flag exists, update it
          let existingFlag = flagsList[existingFlagIndex];
          flagsForUndoStack.push(JSON.parse(JSON.stringify(existingFlag)));
          flagsList[existingFlagIndex] = restoreFlag;
          flagsToBacked.push(restoreFlag);
        } else {
          // Flag doesn't exist, add it back
          flagsForUndoStack.push(JSON.parse(JSON.stringify(restoreFlag)));
          restoreFlag.shouldDelete = false;
          flagsList.push(restoreFlag);
          flagsToBacked.push(restoreFlag);
        }
      }
    });

    if (!data.flagUndo) {
      data.flagUndo = [];
    }

    const params = {
      task,
      inputs: flagsToBacked,
    };

    data.flagUndo.push(flagsForUndoStack);
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    sendFlagToBackend(params);
    renderFlag();
  }
  /***************************canva management for undo and redo  */
  function updateCanvas(type) {
    if (type == "undo" && data.canvasUndoStack.length > 0) {
      let previousState = data.canvasUndoStack.pop();
      data.canvasRedoStack.push(previousState);
      updateOperationSpace();
      const { canvasContext, initialCanvasData } = previousState;
      canvasContext.putImageData(initialCanvasData, 0, 0);
    } else if (type == "redo" && data.canvasRedoStack.length > 0) {
      let previousState = data.canvasRedoStack.pop();
      data.canvasUndoStack.push(previousState);
      updateOperationSpace();

      const { canvasContext, finalCanvasData } = previousState;
      canvasContext.putImageData(finalCanvasData, 0, 0);
    }
  }
  /************** undo redo implementation */
  const triggerSaveState = useSelector((state) => state.undoRedoSlice);

  useEffect(() => {
    if (triggerSaveState.type != "") {
      saveState(triggerSaveState.currentResultStat, triggerSaveState.type);
    }
  }, [triggerSaveState]);

  async function undo() {
    if (data.undoStack.length > 0) {
      cleanRegion();
      await disableButtonAsync();
      let currentresultStat = store.getState().projectData.resultStat;
      // Save the current state into the redo stack before undoing
      let currentState = cloneWaveSurfer(props.wavesurferObj);
      let currentSFXState = cloneWaveSurfer(props.wavesurferObjSFX);
      currentState.buffer = props.wavesurferObj.backend.buffer;
      currentSFXState.buffer = props.wavesurferObjSFX.backend.buffer;
      let previousState = data.undoStack.pop();
      let type = previousState.type;
      let createdFrom = previousState.createdFrom;
      let delannot;
      if (type == "cutFlag" || type == "insertFlag") {
        flagUndos("undo");
      }
      if (
        type == "paste" ||
        type == "insert" ||
        type == "insertFlag" ||
        type == "replaceAnnot"
      ) {
        delannot = data.annottrackstack.pop();
      } else if (type == "autoLevel") {
        props.wavesurferObj.params.normalize = false;
        props.wavesurferObj.drawBuffer();
      } else if (type == "fade") {
        updateCanvas("undo");
      } else if (type == "multiselect-replace") {
        let params = previousState.currentresultStat.params;
        let items = params.inputs.items;
        const searchWord = params.inputs.searchWord;
        const replaceText = params.inputs.replaceWord;
        items.map((word, ind) => {
          if (document.getElementById(word.wordid + "_div")) {
            document.getElementById(word.wordid + "_div").textContent =
              searchWord;
          }
          if (document.getElementById(word.wordid + "_span")) {
            document.getElementById(word.wordid + "_span").textContent =
              searchWord;
          }
          if (document.getElementById(word.wordid + "_tspan")) {
            document.getElementById(word.wordid + "_tspan").textContent =
              searchWord;
          }
        });
        let newresultStat = JSON.parse(JSON.stringify(currentresultStat));
        newresultStat.params = params;
        currentresultStat = [];
        currentresultStat = JSON.parse(JSON.stringify(newresultStat));
      }
      if (createdFrom === "sfx") {
        if (ACM.checkAllowedSFXCount() !== -1) {
          const currentCount = await getCurrentFeatureWiseCount(
            "allowed_number_of_sfx",
          );
          await updateTrialFeatureUsageCount(
            "allowed_number_of_sfx",
            currentCount - 1,
          );
        }
      }

      data.redoStack.push({
        currentState,
        currentSFXState,
        currentresultStat,
        type,
        createdFrom,
      });
      updateOperationSpace();
      const resultStat = previousState.currentresultStat;
      let params = {
        jobname: resultStat.jobname,
        refid: resultStat.refid,
        parentRefid: currentresultStat.refid,
        worker: "undo",
        inputs: {},
      };

      dispatch(
        projectTitleSliceActions.updateProjectTitle({
          env: "workspace",
          projectTitle: resultStat.project_title,
        }),
      );
      renderFade(resultStat);
      deleteannotation(delannot);
      restoreState(previousState);
      annotUIshift(resultStat);
      await restThings(params, resultStat);
      enableButtonAsync();
      loadSlickSliders(resultStat);

      if (type == "replaceAnnot") {
        await undo();
      }
    } else {
      showStatText("nothing to undo");
    }
  }

  async function redo() {
    if (data.redoStack.length > 0) {
      disableButtonAsync();
      let currentresultStat = store.getState().projectData.resultStat;
      let currentState = cloneWaveSurfer(props.wavesurferObj);
      let currentSFXState = cloneWaveSurfer(props.wavesurferObjSFX);
      currentState.buffer = props.wavesurferObj.backend.buffer;
      currentSFXState.buffer = props.wavesurferObjSFX.backend.buffer;
      let nextState = data.redoStack.pop();
      let type = nextState.type;
      let createdFrom = nextState.createdFrom;
      let delannot;
      if (type == "cutFlag" || type == "insertFlag") {
        flagRedos();
      }
      if (
        type == "cut" ||
        type == "paste" ||
        type == "cutFlag" ||
        type == "replaceAnnot"
      ) {
        delannot = data.annottrackstack.pop();
      } else if (type == "fade") {
        updateCanvas("redo");
      } else if (type == "autoLevel") {
        props.wavesurferObj.params.normalize = true;
        props.wavesurferObj.drawBuffer();
      } else if (type == "multiselect-replace") {
        let params = nextState.currentresultStat.params;
        let items = params.inputs.items;
        const searchWord = params.inputs.searchWord;
        const replaceText = params.inputs.replaceWord;
        items.map((word, ind) => {
          if (document.getElementById(word.wordid + "_div")) {
            document.getElementById(word.wordid + "_div").textContent =
              replaceText;
          }
          if (document.getElementById(word.wordid + "_span")) {
            document.getElementById(word.wordid + "_span").textContent =
              replaceText;
          }
          if (document.getElementById(word.wordid + "_tspan")) {
            document.getElementById(word.wordid + "_tspan").textContent =
              replaceText;
          }
        });
        let newresultStat = JSON.parse(JSON.stringify(currentresultStat));
        newresultStat.params = params;
        currentresultStat = [];
        currentresultStat = JSON.parse(JSON.stringify(newresultStat));
      }
      if (createdFrom === "sfx") {
        if (ACM.checkAllowedSFXCount() !== -1) {
          const currentCount = await getCurrentFeatureWiseCount(
            "allowed_number_of_sfx",
          );
          await updateTrialFeatureUsageCount(
            "allowed_number_of_sfx",
            currentCount + 1,
          );
        }
      }
      data.undoStack.push({
        currentState,
        currentSFXState,
        currentresultStat,
        type,
        createdFrom,
      });
      updateOperationSpace();
      const resultStat = nextState.currentresultStat;

      let params = {
        jobname: resultStat.jobname,
        refid: resultStat.refid,
        parentRefid: currentresultStat.refid,
        worker: "redo",
        inputs: {},
      };

      deleteannotation(delannot);
      dispatch(
        projectTitleSliceActions.updateProjectTitle({
          env: "workspace",
          projectTitle: resultStat.project_title,
        }),
      );
      loadSlickSliders(resultStat);
      renderFade(resultStat);
      restoreState(nextState);
      annotUIshift(resultStat);
      await restThings(params, resultStat);
      if (type == "insert" || type == "paste") {
        let nextOperation = data.redoStack[data.redoStack.length - 1];
        if (
          nextOperation !== undefined &&
          nextOperation.type !== undefined &&
          nextOperation.type === "replaceAnnot"
        ) {
          await redo();
        }
      }
      enableButtonAsync();
    } else {
      showStatText("Nothing to redo.");
    }
  }

  function restoreState(state) {
    // Restore the state of the WaveSurfer object
    props.wavesurferObj.loadDecodedBuffer(state.currentState.buffer);
    props.wavesurferObjSFX.loadDecodedBuffer(state.currentSFXState.buffer);
    props.wavesurferObjGlobal.loadDecodedBuffer(state.currentState.buffer);
    //update redux for wavesurfer obj
    dispatch(
      wavesurferObjActions.updateWavesurferObj({
        wavesurferObj: props.wavesurferObj,
      }),
    );
  }
  function cloneWaveSurfer(waveSurferObj) {
    // Create a new WaveSurfer instance and clone its state
    let clonedWaveSurfer = _.cloneDeep(waveSurferObj);
    return clonedWaveSurfer;
  }

  async function applyFade(fadeType) {
    const resultStat = store.getState().projectData.resultStat;
    let currentsegment, trackType, container;
    let topoffset = 0;
    if (lastclicked.clickedregion == "SFX") {
      currentsegment = props.wavesurferObjSFX;
      trackType = "sfx_segments";
      container = document.querySelector("#sfxarea");
      topoffset = 20;
    } else {
      currentsegment = props.wavesurferObj;
      trackType = "speakers-0";
      container = document.querySelector("#waveform");
    }
    await disableButtonAsync();
    const region = Object.values(currentsegment.regions.list)[0];
    const { start: startTime, end: endTime } = region;
    let shouldStop = false;

    resultStat.fades?.forEach((fade) => {
      if (
        ((fade.start < startTime && fade.end > startTime) ||
          (fade.start < endTime && fade.end > endTime)) &&
        fade.trackType == trackType
      ) {
        showStatText("cross fades disabled");
        shouldStop = true;
        return;
      }
    });
    if (shouldStop) {
      await enableButtonAsync();
      return;
    }
    const operationId = create_UUID();
    const fadeId = create_UUID();
    let duration = endTime - startTime;
    const canvas = document.createElement("canvas");
    canvas.classList.add("fade-canvas");
    const ctx = canvas.getContext("2d");
    const minPxPerSec = currentsegment.params.minPxPerSec;
    const leftOffset = startTime * minPxPerSec;
    canvas.style.cssText = `position: absolute; top: ${topoffset}vh; left: ${leftOffset}px; pointer-events: none; transition: 0.5s;`;
    container.appendChild(canvas);
    const totalWidth = duration * minPxPerSec;
    const containerRect = container.getBoundingClientRect();
    canvas.id = "canva_" + fadeId;
    canvas.width = totalWidth;
    canvas.height = containerRect.height;
    //undo restricted after imlementation of delete fade
    //saveState(resultStat, "fade");
    // const initialCanvasData = ctx.getImageData(
    //   0,
    //   0,
    //   canvas.width,
    //   canvas.height,
    // );

    const originalBuffer = currentsegment.backend.buffer;
    const sampleRate = originalBuffer.sampleRate;
    const startSample = Math.floor(startTime * sampleRate);
    const endSample = Math.floor(endTime * sampleRate);
    const regionLength = endSample - startSample;
    const regionBuffer = currentsegment.backend.ac.createBuffer(
      originalBuffer.numberOfChannels,
      regionLength,
      sampleRate,
    );
    const copiedBuffer = new Float32Array(
      (endTime - startTime) * originalBuffer.sampleRate,
    );
    originalBuffer.copyFromChannel(
      copiedBuffer,
      0,
      Math.floor(startTime * originalBuffer.sampleRate),
    );

    for (
      let channel = 0;
      channel < originalBuffer.numberOfChannels;
      channel++
    ) {
      const originalData = originalBuffer.getChannelData(channel);
      const regionData = regionBuffer.getChannelData(channel);
      for (let i = 0; i < regionLength; i++) {
        regionData[i] = originalData[startSample + i];
      }
    }

    const offlineContext = new OfflineAudioContext(
      regionBuffer.numberOfChannels,
      regionLength,
      sampleRate,
    );
    const source = offlineContext.createBufferSource();
    source.buffer = regionBuffer;
    const gainNode = offlineContext.createGain();
    source.connect(gainNode);
    gainNode.connect(offlineContext.destination);
    const gain = gainNode.gain;
    gain.setValueAtTime(1, 0);
    const startValue = fadeInOut === "fadein" ? 0.1 : 1;
    const endValue = fadeInOut === "fadein" ? 1 : 0.1;
    switch (fadeType) {
      case "linear":
        gain.setValueAtTime(startValue, 0);
        gain.linearRampToValueAtTime(endValue, duration);
        break;
      case "exponential":
        gain.setValueAtTime(startValue, 0);
        gain.exponentialRampToValueAtTime(endValue, duration);
        break;
      case "logarithmic":
        gain.setValueAtTime(startValue, 0);
        gain.exponentialRampToValueAtTime(endValue, duration);
        break;
      case "sCurve":
        for (let i = 0; i <= 1; i += 0.01) {
          const t = i * duration;
          let volume;
          if (fadeInOut === "fadein") {
            volume = 0.5 * (1 - Math.cos(Math.PI * i));
          } else {
            volume = 0.5 * (1 + Math.cos(Math.PI * i));
          }
          gain.setValueAtTime(volume, t);
        }
        break;
      default:
        console.error("Invalid fade type");
        return;
    }

    source.start(0);
    offlineContext.startRendering().then(async function (renderedBuffer) {
      const modifiedBuffer = originalBuffer.getChannelData(0).slice();
      modifiedBuffer.set(renderedBuffer.getChannelData(0), startSample);
      const newBuffer = currentsegment.backend.ac.createBuffer(
        1,
        modifiedBuffer.length,
        originalBuffer.sampleRate,
      );

      newBuffer.copyToChannel(modifiedBuffer, 0);
      let params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "fades",
        inputs: {
          selectedCustomClasses: getSelectedCC(resultStat),
          customClass: trackType,
          fade: [
            {
              segementId: resultStat.speakers_segments[0].id,
              segmentFade: {
                id: fadeId,
                segmentFadeEnd: endTime,
                segmentFadeStart: startTime,
                shape: fadeType,
                start: startTime,
                end: endTime,
                type: fadeInOut,
                trackType,
                value: {
                  start: 0,
                  end: 1,
                },
              },
            },
          ],
          fadeEndTime: endTime,
          fadeStartTime: startTime,
          fadeid: fadeId,
        },
      };
      const newResultStat = { ...resultStat };
      newResultStat.refid = operationId;
      if (lastclicked.clickedregion == "SFX") {
        newResultStat.sfx_src = newBuffer;
        store.dispatch(
          wavesurferObjSFXActions.updateWavesurferObjSFX({
            wavesurferObjSFX: props.wavesurferObjSFX,
          }),
        );
      } else {
        props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
        newResultStat.speakers_src = newBuffer;
        store.dispatch(
          wavesurferObjActions.updateWavesurferObj({
            wavesurferObj: props.wavesurferObj,
          }),
        );
      }
      let fadeSeg = {
        end_time: endTime,
        id: fadeId,
        shape: fadeType,
        src: copiedBuffer,
        start_time: startTime,
        trackType,
        type: fadeInOut,
      };
      let fades = [...(newResultStat.fades ?? [])];
      if (fades.length >= 1) fades.push({ ...fadeSeg });
      else fades = [{ ...fadeSeg }];
      newResultStat.fades = [...fades];
      restThings(params, newResultStat);
      currentsegment.loadDecodedBuffer(newBuffer);
    });
    //update redux for wavesurfer obj
    dispatch(
      wavesurferObjActions.updateWavesurferObj({
        wavesurferObj: props.wavesurferObj,
      }),
    );
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();

    switch (fadeType) {
      case "linear":
        if (fadeInOut === "fadein") {
          ctx.moveTo(0, canvas.height);
          ctx.lineTo(canvas.width, 0);
        } else {
          ctx.moveTo(0, 0);
          ctx.lineTo(canvas.width, canvas.height);
        }
        break;
      case "exponential":
        for (let i = 0; i <= 1; i += 0.01) {
          const x = i * canvas.width;
          let y;
          if (fadeInOut === "fadein") {
            y = canvas.height - Math.pow(i, 2) * canvas.height;
          } else {
            y = Math.pow(i, 2) * canvas.height;
          }
          ctx.lineTo(x, y);
        }
        break;
      case "logarithmic":
        for (let i = 0; i <= 1; i += 0.01) {
          const x = i * canvas.width;
          let y;
          if (fadeInOut === "fadein") {
            y =
              canvas.height -
              (Math.log(i * 10 + 1) / Math.log(11)) * canvas.height;
          } else {
            y = (Math.log(i * 10 + 1) / Math.log(11)) * canvas.height;
          }
          ctx.lineTo(x, y);
        }
        break;
      case "sCurve":
        for (let i = 0; i <= 1; i += 0.01) {
          const x = i * canvas.width;
          let y;
          if (fadeInOut === "fadein") {
            y =
              canvas.height - 0.5 * (1 - Math.cos(Math.PI * i)) * canvas.height;
          } else {
            y = 0.5 * (1 - Math.cos(Math.PI * i)) * canvas.height;
          }
          ctx.lineTo(x, y);
        }
        break;
      default:
        console.error("Invalid fade type");
        return;
    }

    ctx.strokeStyle = "#E2522B";
    ctx.stroke();
    // const finalCanvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    // commented after delete fade implementation
    // data.canvasUndoStack.push({
    //   canvasContext: ctx,
    //   initialCanvasData,
    //   finalCanvasData,
    // });

    const deleteButton = document.createElement("div");
    // deleteButton.innerHTML = "&#10006;"; // Unicode for "heavy multiplication X"
    deleteButton.innerHTML = "Delete";
    deleteButton.id = "fade_btn_" + fadeId;
    deleteButton.style.cssText = `
            top: ${topoffset}vh;
            left: ${leftOffset + (totalWidth - (window.innerWidth * 3.25) / 100) / 2}px;
        `;
    container.appendChild(deleteButton);

    deleteButton.addEventListener("mouseenter", (e) => {
      e.preventDefault();

      canvas.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
    });

    deleteButton.addEventListener("mouseleave", (e) => {
      e.preventDefault();

      canvas.style.backgroundColor = "transparent";
    });

    // Add click event listener to the delete button
    deleteButton.addEventListener("click", async (e) => {
      e.stopPropagation();

      if (store.getState().viewEditMode.mode === "view") {
        showStatText("operation resticted in view mode");
        return;
      }
      await removeFade(fadeId);
      container.removeChild(deleteButton);
      container.removeChild(canvas);
    });

    updateOperationSpace();
    fadeOperation();
    await enableButtonAsync();

    document.getElementById("waveforms-selector").src =
      iconsData.fades.inactive;
  }

  //loop function
  const sourceRef = useRef(null);
  const animationFrameRef = useRef(null);

  const startLoop = () => {
    // Update the loop state
    setIsLooping(true);

    const region =
      props.wavesurferObj.regions.list[
        Object.keys(props.wavesurferObj.regions.list)[0]
      ];
    const annotationList = store.getState().annotationList.annotationList;

    if (region) {
      const start = region.start;
      const end = region.end;
      const originalBuffer = props.wavesurferObj.backend.buffer;
      const sampleRate = originalBuffer.sampleRate;

      // Calculate start and end indices for the selected region
      const startIndex = Math.floor(start * sampleRate);
      const endIndex = Math.min(
        Math.floor(end * sampleRate),
        originalBuffer.length,
      );
      // Calculate the duration of the selected region
      const duration = (endIndex - startIndex) / sampleRate;

      // Create a new buffer for the selected region
      const selectedBuffer = props.wavesurferObj.backend.ac.createBuffer(
        originalBuffer.numberOfChannels,
        endIndex - startIndex,
        sampleRate,
      );

      // Copy data from the original buffer to the selected buffer
      for (
        let channel = 0;
        channel < originalBuffer.numberOfChannels;
        channel++
      ) {
        const channelData = originalBuffer
          .getChannelData(channel)
          .subarray(startIndex, endIndex);
        selectedBuffer.copyToChannel(channelData, channel);
      }

      // Create an AudioBufferSourceNode
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContext.createBufferSource();
      source.buffer = selectedBuffer;

      // Set the loop points
      source.loopStart = 0;
      source.loopEnd = duration;

      // Enable looping
      source.loop = true;

      // Connect the source to the destination (speakers)
      source.connect(audioContext.destination);

      // Start playing the audio in a loop
      const startTime = audioContext.currentTime;
      source.start();
      // Function to update cursor position
      const updateCursor = () => {
        const elapsedTime = (audioContext.currentTime - startTime) % duration;
        const cursorPosition = start + elapsedTime;
        props.wavesurferObj.seekTo(
          cursorPosition / props.wavesurferObj.getDuration(),
        );
        props.wavesurferObjSFX.seekTo(
          cursorPosition / props.wavesurferObj.getDuration(),
        );
        props.wavesurferObjGlobal.seekTo(
          cursorPosition / props.wavesurferObj.getDuration(),
        );

        // Schedule the next update
        animationFrameRef.current = requestAnimationFrame(updateCursor);

        //highlight annotation
        if (annotationList.length > 0) {
          const closestStartIndex = findClosestAnnotationIndex(
            start,
            annotationList,
          );
          const closestEndIndex = findClosestAnnotationIndex(
            end,
            annotationList,
          );
          // reset highlight after clicking the loop btn
          for (let i = closestStartIndex; i <= closestEndIndex; i++) {
            const currAnnot = annotationList[i];
            if (currAnnot) {
              const currAnnotElem = document.getElementById(
                `${currAnnot.id}_div`,
              );
              currAnnotElem.classList.remove("clicked-annot", "inactive-annot");
              currAnnotElem.classList.add("active-annot");
            }
          }
          // add hightlight while playing
          for (let i = closestStartIndex; i <= closestEndIndex; i++) {
            if (cursorPosition >= annotationList[i].start) {
              const currAnnot = annotationList[i];

              if (currAnnot) {
                const currAnnotElem = document.getElementById(
                  `${currAnnot.id}_div`,
                );
                currAnnotElem.classList.add("clicked-annot");
                if (i > 0 && cursorPosition >= annotationList[i - 1].start) {
                  let prevAnnot = annotationList[i - 1];
                  if (prevAnnot) {
                    const prevAnnotElem = document.getElementById(
                      `${prevAnnot.id}_div`,
                    );
                    prevAnnotElem.classList.remove("clicked-annot");
                  }
                }
                if (i === closestStartIndex) {
                  let lastAnnot = annotationList[closestEndIndex];
                  if (lastAnnot) {
                    const lastAnnotElem = document.getElementById(
                      `${lastAnnot.id}_div`,
                    );
                    lastAnnotElem.classList.remove("clicked-annot");
                  }
                }
              }
            }
          }
        }
      };

      // Start updating the cursor
      updateCursor();

      // Save the AudioContext reference to stop the loop later
      audioContextRef.current = audioContext;
      sourceRef.current = source;
    }
  };

  const stopLoop = () => {
    setIsLooping(false);

    if (audioContextRef.current && sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
      audioContextRef.current.close();

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }

      sourceRef.current = null;
      audioContextRef.current = null;
      const region =
        props.wavesurferObj.regions.list[
          Object.keys(props.wavesurferObj.regions.list)[0]
        ];
      // props.wavesurferObj.seekTo(region.start / props.wavesurferObj.getDuration());
    }
  };

  useEffect(() => {
    // Cleanup the AudioContext when the component unmounts
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);
  const handlePlayPause = (stop = false) => {
    hideZoomControls();
    let time = store.getState().utilsData.currentTime;
    let waveTime = props.wavesurferObj.getDuration();
    if (parseFloat(time.toFixed(1)) === parseFloat(waveTime.toFixed(1))) {
      props.wavesurferObj.seekTo(0);
      props.wavesurferObjSFX.seekTo(0);
      props.wavesurferObjGlobal.seekTo(0);

      props.wavesurferObj.playPause();
      props.wavesurferObjGlobal.playPause();
      props.wavesurferObjSFX.playPause();

      updateCurrentTime();
    } else {
      props.wavesurferObj.playPause();
      props.wavesurferObjGlobal.playPause();
      props.wavesurferObjSFX.playPause();
    }
    let playing = store.getState().viewEditMode.playPauseMode;
    if (isLooping) {
      stopLoop();
      return;
    }

    if (stop) {
      const region =
        props.wavesurferObj.regions.list[
          Object.keys(props.wavesurferObj.regions.list)
        ];
      if (region) {
        props.wavesurferObj.seekTo(
          region.start / props.wavesurferObj.getDuration(),
        );
        props.wavesurferObjSFX.seekTo(
          region.start / props.wavesurferObj.getDuration(),
        );
        props.wavesurferObjGlobal.seekTo(
          region.start / props.wavesurferObj.getDuration(),
        );
      }
      let regionplaying = setInterval(() => {
        if (region) {
          // Check if the region end time is very close to the current time
          if (
            Math.abs(region.end - props.wavesurferObj.getCurrentTime()) < 0.01
          ) {
            // Small threshold to account for precision issues
            props.wavesurferObj.playPause();
            props.wavesurferObjGlobal.playPause();
            props.wavesurferObjSFX.playPause();
            setPlaying(false);
            clearInterval(regionplaying);
          } else {
          }
        }
      }, 50);
    }
    if (playing) {
      activeAnnotationColor();
      initiateGetCurrentTimeScheduler();
    } else {
      // document.getElementById("btn-play-icon").src =
      //   "/image/workspace/controls/play.png";
      clearCurrentTimeScheduler();
    }
    store.dispatch(
      viewEditSliceActions.updatePlayPauseMode({ value: !playing }),
    );
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        !(
          document.getElementById("more-options-menu") ||
          document.getElementById("modal_1") ||
          document.getElementById("half-modals-content") ||
          document.getElementsByClassName("EditAnnot").length
        ) &&
        event.code === "Space"
      ) {
        event.preventDefault();
        handlePlayPause(); // Call play/pause on spacebar press
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePlayPause]);
  const handleCopy = async (resultStat) => {
    let currentsegment;
    hideZoomControls();
    setIsFileImported(false);
    let curr_word_list = store.getState().multiSelectwordObject.wordsObject;
    store.dispatch(
      CopyBufferActions.updatedCreatedFrom({ createdFrom: "null" }),
    );
    store.dispatch(copiedAnnotActions.updCopiedFrom(lastclicked.clickedregion));
    if (lastclicked.clickedregion == "SFX") {
      currentsegment = props.wavesurferObjSFX;
    } else {
      currentsegment = props.wavesurferObj;
    }
    if (!resultStat) resultStat = store.getState().projectData.resultStat;
    const region =
      currentsegment.regions.list[Object.keys(currentsegment.regions.list)[0]];
    if (curr_word_list.length > 1) {
      let allCopiedBuffers = [];
      let copiedAnnot = [];
      let lasttime = 0;
      curr_word_list.forEach((annotation) => {
        let currannot = { ...annotation };
        currannot.start_time = new Number(lasttime).toFixed(2);
        currannot.end_time = Number(
          lasttime + (annotation.end_time - annotation.start_time),
        ).toFixed(2);
        lasttime = currannot.end_time;
        currannot.id = `spk_0_${create_UUID()}`;
        const originalBuffer = currentsegment.backend.buffer;
        const copiedBuffer = new Float32Array(
          (annotation.end_time - annotation.start_time) *
            originalBuffer.sampleRate,
        );
        originalBuffer.copyFromChannel(
          copiedBuffer,
          0,
          Math.floor(annotation.start_time * originalBuffer.sampleRate),
        );
        allCopiedBuffers.push(copiedBuffer);
        const newAnnotations = [...copiedAnnot, currannot];
        copiedAnnot = newAnnotations;
        //copiedAnnot.push(currannot);
      });
      dispatch(
        copiedAnnotActions.updAnnotclk({ copiedAnnotations: copiedAnnot }),
      );
      // Calculate the total length for the final buffer
      let totalLength = 0;
      for (let buffer of allCopiedBuffers) {
        totalLength += buffer.length;
      }
      // Create the final buffer
      let finalBuffer = new Float32Array(totalLength);
      // Fill the final buffer with data from each copied buffer
      let offset = 0;
      for (let buffer of allCopiedBuffers) {
        finalBuffer.set(buffer, offset);
        offset += buffer.length;
      }

      dispatch(
        CopyBufferActions.updateCopyBuffer({ copiedBuffer: finalBuffer }),
      );
    } else if (region) {
      document.getElementById("btn-copy").src = iconsData.copy.active;
      const start = region.start;
      const end = region.end;
      if (lastclicked.clickedregion != "SFX")
        await copyAnnotations(start, end, resultStat);
      const originalBuffer = currentsegment.backend.buffer;
      const copiedBuffer = new Float32Array(
        (end - start) * originalBuffer.sampleRate,
      );
      originalBuffer.copyFromChannel(
        copiedBuffer,
        0,
        Math.floor(start * originalBuffer.sampleRate),
      );
      dispatch(CopyBufferActions.updateCopyBuffer({ copiedBuffer }));
      setTimeout(() => {
        document.getElementById("btn-copy").src = iconsData.copy.inactive;
      }, 100);
    }
  };
  /******** paste/insert annotation */
  function pasteAnnotations(startTime, endTime, trackType, refid = false) {
    let newAnnotations = [];
    let deleteannot = [];
    const resultStat = store.getState().projectData.resultStat;
    let copiedAnnotations = store.getState().copiedAnnotation.copiedAnnotation;
    if (trackType == "sfx") {
      let newCopyAnnotationsList = []; //  we will pass the copied annotations data to global so that correct copied anotations id would be send to backend
      copiedAnnotations.forEach((annot) => {
        let newAnnot = JSON.parse(JSON.stringify(annot));
        newAnnot.start_time = startTime.toFixed(2).toString();
        newAnnot.end_time =
          typeof endTime === "number"
            ? endTime.toFixed(2).toString()
            : endTime.toString();
        newAnnot.annotations[0].start_time = newAnnot.start_time;
        newAnnot.annotations[0].end_time = newAnnot.end_time;
        newAnnot.annotations[0].id = `sfx_0_${create_UUID()}`;

        newAnnotations.push(newAnnot);
        newCopyAnnotationsList.push(newAnnot);
      });
      dispatch(
        copiedAnnotActions.updSendAnnot({
          toSendAnnot: newCopyAnnotationsList,
        }),
      );
    } else {
      resultStat.speakers_segments.forEach((track) => {
        let trackAnnotation = [];
        track.annotations.forEach((annotation) => {
          const annotationDuration =
            annotation.end_time - annotation.start_time;
          const annotationOverlapDuration =
            Math.min(annotation.end_time, endTime) -
            Math.max(annotation.start_time, startTime);

          if (!(annotationOverlapDuration >= 0.55 * annotationDuration)) {
            trackAnnotation.push(annotation);
          } else {
            deleteannot.push(annotation);
          }
        });
        if (track.start_time <= startTime && track.end_time >= startTime) {
          let newCopyAnnotationsList = []; //  we will pass the copied annotations data to global so that correct copied anotations id would be send to backend
          copiedAnnotations.forEach((annot) => {
            let newAnnot = { ...annot };
            newAnnot.start_time = (startTime + parseFloat(annot.start_time))
              .toFixed(2)
              .toString();
            newAnnot.end_time = (startTime + parseFloat(annot.end_time))
              .toFixed(2)
              .toString();
            newAnnot.speaker_label =
              "spk_" + trackType.charAt(trackType.length - 1);
            newAnnot.id =
              "spk_" +
              trackType.charAt(trackType.length - 1) +
              "_" +
              create_UUID();
            trackAnnotation.push(newAnnot);
            newCopyAnnotationsList.push(newAnnot);
            if (annot.transcribed) {
              let file = {
                id: newAnnot.id,
                start_time: newAnnot.start_time,
                end_time: newAnnot.end_time,
                refid,
              };
              store.dispatch(nestedFileSliceActions.addNestedFiles({ file }));
            }
          });
          dispatch(
            copiedAnnotActions.updSendAnnot({
              toSendAnnot: newCopyAnnotationsList,
            }),
          );
        }
        newAnnotations.push(trackAnnotation);
      });
    }
    if (deleteannot) {
      //kjsdfhk
      deleteannotation(deleteannot);
      data.annottrackstack.push(deleteannot);
    }
    return newAnnotations;
  }

  /******* annotation copy */

  async function copyAnnotations(startTime, endTime, resultStat) {
    let copiedAnnotations = [];
    // Traverse all the speaker segments
    for (let segment of resultStat.speakers_segments) {
      // Traverse all the annotations inside each segment
      for (let annotation of segment.annotations) {
        // Calculate the duration of the annotation and the overlap with the provided time range
        let currannot = { ...annotation };
        let annotationDuration = annotation.end_time - annotation.start_time;
        let overlapStart = Math.max(annotation.start_time, startTime);
        let overlapEnd = Math.min(annotation.end_time, endTime);
        let overlapDuration = overlapEnd - overlapStart;

        // Check if the annotation's start and end time falls inside the provided start and end time
        // or if the overlap duration is at least 50% of the annotation's duration
        if (
          (annotation.start_time >= startTime &&
            annotation.end_time <= endTime) ||
          overlapDuration >= 0.5 * annotationDuration
        ) {
          currannot.start_time = (annotation.start_time - startTime).toFixed(2);
          currannot.end_time = (annotation.end_time - startTime).toFixed(2);
          currannot.id = `spk_0_${create_UUID()}`;
          // If it does, store the annotation in the array
          copiedAnnotations.push(currannot);
        }
      }
    }

    dispatch(copiedAnnotActions.updAnnotclk({ copiedAnnotations }));
  }

  async function annotUIshift(resultStats) {
    store.dispatch(
      AdjustAnnotationActions.updateAdjustAnnotation({ adjustAnnot: false }),
    );
    let deleteannot = annotUIshiftSub(resultStats, props);
    if (deleteannot) {
      //kjsdfhk
      data.annottrackstack.push(deleteannot);
    }
  }

  /***************** */
  useEffect(() => {
    if (disableBtn === "true") {
      // Call the function (you can trigger this on some event or directly on window load)
      disableIconSpacingButtons();
    } else if (disableBtn === "nestedTranscription") {
      disableOperationsForNestedImport();
    } else {
      enableIconSpacingButtons();
    }
  }, [disableBtn]);
  const disableButtonAsync = () => {
    return new Promise((resolve) => {
      setDisableBtn("true");
      setTimeout(() => {
        resolve(); // Resolve the promise so the next task can continue
      }, 0);
    });
  };
  const enableButtonAsync = () => {
    return new Promise((resolve) => {
      setDisableBtn("false");

      setTimeout(() => {
        resolve(); // Resolve the promise so the next task can continue
      }, 0);
    });
  };
  const disableFunctionForNestedtranscription = () => {
    return new Promise((resolve) => {
      setDisableBtn("nestedTranscription");
      setTimeout(() => {
        resolve(); // Resolve the promise so the next task can continue
      }, 0);
    });
  };
  const handleCut = async (
    word_list = null,
    gapremoval = null,
    threshold = null,
    delTrack = null,
  ) => {
    const permResponse = ACM.checkPermission("cut");
    if (!permResponse.success) return showStatText(permResponse.message);

    document.getElementById("btn-cut-icon").src = iconsData.cut.active;

    hideZoomControls();
    let currentsegment, otherSegment, globalSegment;
    let trackType;
    let customClass;
    let params;
    let curr_word_list =
      word_list != null
        ? word_list
        : store.getState().multiSelectwordObject.wordsObject;
    let resultStat = store.getState().projectData.resultStat;
    const operationId = create_UUID();
    globalSegment = props.wavesurferObjGlobal;

    if (lastclicked.clickedregion == "SFX") {
      currentsegment = props.wavesurferObjSFX;
      otherSegment = props.wavesurferObj;
      trackType = "sfx_segments";
    } else {
      currentsegment = props.wavesurferObj;
      otherSegment = props.wavesurferObjSFX;
      trackType = "speakers_segments";
    }
    let cursorPosition = getCurrentPosition(currentsegment);
    const originalBuffer = currentsegment.backend.buffer;
    const secondaryBuffer = otherSegment.backend.buffer;

    let timeList = [];
    let updatedAnnotation;
    if (word_list || curr_word_list.length > 1) {
      await disableButtonAsync();
      curr_word_list.forEach((word) => {
        let timeObject = {
          wordid: word.id,
          start: parseFloat(word.start_time),
          end: parseFloat(word.end_time),
          word: word.content,
          customClass:
            trackType === "speakers_segments" ? "speakers-0" : "sfx-0",
        };
        timeList.push(timeObject);
      });
      updatedAnnotation = handleAnnotationShift(
        trackType,
        timeList,
        resultStat,
      );
      params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "multiselect-cut",
        inputs: {
          items: timeList,
          trackType,
          selectedCustomClasses: getSelectedCC(resultStat),
        },
      };
      await handleCopy(resultStat);
      multiSelectOperation();
    } else if (gapremoval) {
      await disableButtonAsync();
      gapremoval.forEach((word) => {
        let timeObject = {
          wordid: "",
          start: parseFloat(word.start),
          end: parseFloat(word.end),
          word: "",
          customClass: "speakers-0",
        };
        timeList.push(timeObject);
      });
      updatedAnnotation = handleAnnotationShift(
        trackType,
        null,
        resultStat,
        false,
        timeList,
      );
      params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "long-gap-removal",
        inputs: {
          items: timeList,
          threshold,
          trackType: "speakers_segments",
          selectedCustomClasses: getSelectedCC(resultStat),
        },
      };
    } else if (delTrack) {
      await disableButtonAsync();
      let deleteannot = [];
      resultStat.speakers_segments.forEach((track) => {
        if (track.speaker_label == delTrack) {
          let timeObject = {
            wordid: "",
            start: parseFloat(track.start_time),
            end: parseFloat(track.end_time),
            word: "",
            customClass: delTrack,
          };
          timeList.push(timeObject);
          track.annotations.forEach((annot) => deleteannot.push(annot));
        }
      });
      params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "deleteCustomClass",
        inputs: {
          customClass: delTrack,
          trackType,
        },
      };

      deleteannotation(deleteannot);
    } else {
      const region =
        currentsegment.regions.list[
          Object.keys(currentsegment.regions.list)[0]
        ];

      if (region) {
        await disableButtonAsync();
        await new Promise((resolve) => setTimeout(resolve, 0));
        updatedAnnotation = handleAnnotationShift(
          trackType,
          false,
          resultStat,
          region,
        );
        let timeObject = {
          start: region.start,
          end: region.end,
        };
        timeList.push(timeObject);
        await handleCopy(resultStat);
        resultStat[trackType].forEach((segment) => {
          if (
            region.start >= segment.start_time &&
            region.end <= segment.end_time
          ) {
            customClass = segment.speaker_label;
          }
        });
        params = {
          jobname: resultStat.jobname,
          refid: operationId,
          parentRefid: resultStat.refid,
          worker: "cut",
          inputs: {
            startTime: region.start,
            endTime: region.end,
            selectedCustomClasses: getSelectedCC(resultStat),
            trackType,
            customClass,
            adjustFade: false,
          },
        };
      } else {
        showStatText("select a region for cut");
        return;
      }
    }
    let typ = adjustFlagForCut(timeList, trackType);
    saveState(resultStat, typ);
    let oldfade = resultStat.fades ? resultStat.fades : [];
    resultStat = JSON.parse(JSON.stringify(resultStat));
    resultStat.fades = oldfade;
    if (delTrack) {
      delete resultStat.speakers[delTrack];
      resultStat.speakers_segments = resultStat.speakers_segments.filter(
        (track) => track.speaker_label !== delTrack,
      );
      updatedAnnotation = handleAnnotationShift(
        trackType,
        null,
        resultStat,
        false,
        timeList,
      );
    }
    timeList.sort((a, b) => a.start - b.start);
    const sampleRate = originalBuffer.sampleRate;
    const secondarySampleRate = secondaryBuffer.sampleRate;
    const numberOfChannels = originalBuffer.numberOfChannels;
    let totalCutLength = 0;
    timeList.forEach((time) => {
      totalCutLength += Math.floor((time.end - time.start) * sampleRate);
    });
    if (lastclicked.clickedregion == "SFX") {
      totalCutLength = 0;
    } else {
      let endtime = 0;
      resultStat.sfx_segments.forEach((element) => {
        element.annotations?.forEach((annot) => {
          endtime = Math.max(
            endtime,
            annot.end_time * originalBuffer.sampleRate,
          );
        });
      });
      if (
        resultStat.duration * originalBuffer.sampleRate - totalCutLength <
        endtime
      ) {
        totalCutLength =
          resultStat.duration * originalBuffer.sampleRate - endtime;
      }
    }
    const newBufferLength = originalBuffer.length - totalCutLength;
    const newBuffer = currentsegment.backend.ac.createBuffer(
      numberOfChannels,
      newBufferLength,
      sampleRate,
    );

    const newSecondaryBufferLength = secondaryBuffer.length - totalCutLength;
    const newSecondaryBuffer = otherSegment.backend.ac.createBuffer(
      secondaryBuffer.numberOfChannels,
      newSecondaryBufferLength,
      secondarySampleRate,
    );

    let sourceOffset = 0;
    let targetOffset = 0;
    let secondarySourceOffset = secondaryBuffer.length;
    let secondaryTargetOffset = newSecondaryBufferLength;

    timeList.forEach((time, index) => {
      const cutStartIndex = Math.floor(time.start * sampleRate);
      const cutEndIndex = Math.floor(time.end * sampleRate);

      // Primary buffer processing
      const copyLength = Math.max(
        0,
        Math.min(cutStartIndex - sourceOffset, newBufferLength - targetOffset),
      );
      if (copyLength > 0) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
          newBuffer
            .getChannelData(channel)
            .set(
              originalBuffer
                .getChannelData(channel)
                .subarray(sourceOffset, sourceOffset + copyLength),
              targetOffset,
            );
        }
        targetOffset += copyLength;
      }
      sourceOffset = Math.min(cutEndIndex, originalBuffer.length);

      // Secondary buffer processing (cutting from the end)
      const secondaryCutStartIndex = Math.floor(
        otherSegment.getDuration() * secondarySampleRate - totalCutLength,
      );
      const secondaryCutEndIndex = Math.floor(
        otherSegment.getDuration() * secondarySampleRate,
      );

      const secondaryCopyLength = Math.max(
        0,
        Math.min(
          secondarySourceOffset - secondaryCutEndIndex,
          secondaryTargetOffset,
        ),
      );

      if (secondaryCopyLength > 0) {
        const secondarySourceStart =
          secondarySourceOffset - secondaryCopyLength;
        const secondaryTargetStart =
          secondaryTargetOffset - secondaryCopyLength;

        for (
          let channel = 0;
          channel < secondaryBuffer.numberOfChannels;
          channel++
        ) {
          newSecondaryBuffer
            .getChannelData(channel)
            .set(
              secondaryBuffer
                .getChannelData(channel)
                .subarray(secondarySourceStart, secondarySourceOffset),
              secondaryTargetStart,
            );
        }
        secondaryTargetOffset -= secondaryCopyLength;
        secondarySourceOffset = secondarySourceStart;
      }
    });

    // Copy any remaining audio after the last cut for primary buffer
    if (sourceOffset < originalBuffer.length) {
      const remainingLength = Math.min(
        originalBuffer.length - sourceOffset,
        newBufferLength - targetOffset,
      );
      for (let channel = 0; channel < numberOfChannels; channel++) {
        newBuffer
          .getChannelData(channel)
          .set(
            originalBuffer
              .getChannelData(channel)
              .subarray(sourceOffset, sourceOffset + remainingLength),
            targetOffset,
          );
      }
    }

    // Copy any remaining audio for secondary buffer (from the beginning)
    if (secondarySourceOffset > 0) {
      const remainingLength = Math.min(
        secondarySourceOffset,
        secondaryTargetOffset,
      );
      for (
        let channel = 0;
        channel < secondaryBuffer.numberOfChannels;
        channel++
      ) {
        newSecondaryBuffer
          .getChannelData(channel)
          .set(
            secondaryBuffer
              .getChannelData(channel)
              .subarray(0, remainingLength),
            0,
          );
      }
    }

    const newDuration = resultStat.duration - totalCutLength / sampleRate;
    let newResultStat = resultStat;
    if (trackType == "sfx_segments") {
      //const channelData = newBuffer.getChannelData(0);
      newResultStat.sfx_src = newBuffer;
      newResultStat.speakers_src = newSecondaryBuffer;
      newResultStat.sfx_segments = updatedAnnotation;
    } else {
      //const channelData = newSecondaryBuffer.getChannelData(0);
      newResultStat.sfx_src = newSecondaryBuffer;
      newResultStat.speakers_src = newBuffer;
      newResultStat.speakers_segments.forEach((track, index) => {
        track.annotations = [];
        track.annotations = updatedAnnotation[index];
      });
    }
    newResultStat.refid = operationId;
    newResultStat.duration = newDuration;

    currentsegment.loadDecodedBuffer(newBuffer);
    otherSegment.loadDecodedBuffer(newSecondaryBuffer);
    if (currentsegment == props.wavesurferObj) {
      globalSegment.loadDecodedBuffer(newBuffer);
    } else {
      globalSegment.loadDecodedBuffer(newSecondaryBuffer);
    }
    globalSegment.drawBuffer();

    const durationInSeconds = newBuffer.length / newBuffer.sampleRate;
    const newWidth = currentsegment.params.minPxPerSec * durationInSeconds;
    // if (document.getElementById("transcriptions_div"))
    //   document.getElementById("transcriptions_div").style.width =
    //     `${newWidth}px`;
    // if (document.getElementById("transcriptions_div_sfx")) {
    //   document.getElementById("transcriptions_div_sfx").style.width =
    //     `${newWidth}px`;
    // }
    resetAnnotation();
    cleanRegion();
    if (newResultStat.fades)
      newResultStat = adjustFade(newResultStat, timeList, "cut", trackType);
    annotUIshift(newResultStat);
    await restThings(params, newResultStat);

    //update redux for wavesurfer obj
    dispatch(
      wavesurferObjActions.updateWavesurferObj({
        wavesurferObj: props.wavesurferObj,
      }),
    );
    dispatch(
      wavesurferObjSFXActions.updateWavesurferObjSFX({
        wavesurferObjSFX: props.wavesurferObjSFX,
      }),
    );
    dispatch(CopyBufferActions.updatedCreatedFrom({ createdFrom: "null" }));
    restoreCursorPositions(cursorPosition);
    //revert back redux value of multiselect words
    dispatch(
      multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
    );
    store.dispatch(annotationListActions.clearAnnotationList());
    await enableButtonAsync();

    document.getElementById("btn-cut-icon").src = iconsData.cut.inactive;
  };

  // Function which handles silencing a selected segment
  const handleInsertSilence = async () => {
    const permResponse = ACM.checkPermission("silence");
    if (!permResponse.success) return showStatText(permResponse.message);

    document.getElementById("btn-silence").src = iconsData.silence.active;

    dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
    hideZoomControls();
    let trackType;
    let customClass;
    let timeList = [];
    const resultStat = store.getState().projectData.resultStat;
    let curr_word_list = store.getState().multiSelectwordObject.wordsObject;
    let currentsegment;
    if (lastclicked.clickedregion == "SFX") {
      currentsegment = props.wavesurferObjSFX;
      trackType = "sfx_segments";
    } else {
      currentsegment = props.wavesurferObj;
      trackType = "speakers_segments";
    }
    const region =
      currentsegment.regions.list[Object.keys(currentsegment.regions.list)[0]];

    const operationId = create_UUID();
    const originalBuffer = currentsegment.backend.buffer;
    let insertedBuffer = originalBuffer.getChannelData(0).slice();
    let params;

    if (curr_word_list.length > 1) {
      await disableButtonAsync();
      curr_word_list.forEach((word) => {
        let timeObject = {
          wordid: word.id,
          start: parseFloat(word.start_time),
          end: parseFloat(word.end_time),
          word: word.content,
          customClass:
            trackType === "speakers_segments" ? "speakers-0" : "sfx-0",
        };
        timeList.push(timeObject);
        params = {
          jobname: resultStat.jobname,
          refid: operationId,
          parentRefid: resultStat.refid,
          worker: "multiselect-silence",
          inputs: {
            items: timeList,
            trackType,
            selectedCustomClasses: getSelectedCC(resultStat),
          },
        };
      });
      multiSelectOperation();
    } else if (region) {
      await disableButtonAsync();
      let timeObject = {
        start: region.start,
        end: region.end,
      };
      timeList.push(timeObject);

      resultStat[trackType].forEach((segment) => {
        if (
          region.start >= segment.start_time &&
          region.end <= segment.end_time
        ) {
          customClass = segment.speaker_label;
        }
      });

      params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "silence",
        inputs: {
          startTime: region.start,
          endTime: region.end,
          selectedCustomClasses: getSelectedCC(resultStat),
          customClass,
          adjustFade: false,
        },
      };
    } else {
      showStatText("no selected region to silence");
      return;
    }
    saveState(resultStat, "silence");

    timeList.forEach((word) => {
      const start = word.start;
      const end = word.end;

      // Calculate the start index and length for inserting silence
      const insertStartIndex = Math.floor(start * originalBuffer.sampleRate);
      const insertSilenceLength = Math.floor(
        (end - start) * originalBuffer.sampleRate,
      );

      // Insert silence into the buffer at the specified index
      insertedBuffer.fill(
        0,
        insertStartIndex,
        insertStartIndex + insertSilenceLength,
      );
    });

    const newResultStat = JSON.parse(JSON.stringify(resultStat));
    newResultStat.refid = operationId;
    resultStat.speakers_segments.map((segment, indexSeg) => {
      segment.annotations.map((annot, indexAnnot) => {
        let newAnnot = { ...annot };
        if (annot.start_time >= region.start && annot.end_time <= region.end) {
          newAnnot.is_silenced = true;
        }
        newResultStat.speakers_segments[indexSeg].annotations[indexAnnot] =
          newAnnot;
      });
    });

    const newBuffer = currentsegment.backend.ac.createBuffer(
      1,
      insertedBuffer.length,
      originalBuffer.sampleRate,
    );

    newBuffer.copyToChannel(insertedBuffer, 0);

    // Update the waveform display with the inserted buffer
    currentsegment.loadDecodedBuffer(newBuffer);
    if (lastclicked.clickedregion != "SFX")
      props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
    if ((currentsegment = props.wavesurferObj)) {
      newResultStat.speakers_src = newBuffer;
      newResultStat.sfx_src = resultStat.sfx_src;
    } else {
      newResultStat.speakers_src = resultStat.speakers_src;
      newResultStat.sfx_src = newBuffer;
    }

    restThings(params, newResultStat);
    // reset redux value for multiselect words
    dispatch(
      multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
    );
    const highlightAnnotationList =
      store.getState().annotationList.highlightAnnotationList;
    if (highlightAnnotationList.length > 0) {
      highlightAnnotationList.map((annot) => {
        const currAnnotElem = document.getElementById(`${annot.id}_div`);
        if (currAnnotElem) {
          currAnnotElem.classList.remove("clicked-annot");
          currAnnotElem.classList.remove("box-shadow");
          currAnnotElem.style.color = "#818181";
          // currAnnotElem.classList.add("silenced-annot");
        }
      });
      store.dispatch(annotationListActions.clearAnnotationList());
    }
    resetAnnotation();
    await enableButtonAsync();
    setTimeout(() => {
      document.getElementById("btn-silence").src = iconsData.silence.inactive;
    }, 100);
  };

  // Function to add a flag at current time
  async function addFlag() {
    const permResponse = ACM.checkPermission("flag");
    if (!permResponse.success) return showStatText(permResponse.message);

    document.getElementById("btn-flag").src = iconsData.flag.active;

    hideZoomControls();
    let flagsList = [...store.getState().flag.flagsList];
    let startTime = props.wavesurferObj.getCurrentTime();
    let endTime = props.wavesurferObj.getDuration();
    if (startTime === 0 || endTime === 0) {
      showStatText("Please select a point to add flag!");
      return;
    }
    await disableButtonAsync();
    let resultStat = store.getState().projectData.resultStat;
    let currentTime = props.wavesurferObj.getCurrentTime();
    let currentsegment, trackType, waveformContainer;
    var flags = document.createElement("img");
    var globalflags = document.createElement("img");
    let globalWaveformContainer = document.querySelector("#globalview");

    if (lastclicked.clickedregion == "SFX") {
      currentsegment = props.wavesurferObjSFX;
      trackType = "sfx";
      waveformContainer = document.querySelector("#sfxarea");
      flags.style.top = "21vh"; // Adjust based on your needs
    } else {
      currentsegment = props.wavesurferObj;
      trackType = "speaker";
      waveformContainer = document.querySelector("#waveform");
      flags.style.top = "0px"; // Adjust based on your needs
    }

    let flag = {
      id: "",
      jobname: "",
      shouldDelete: false,
      isEditable: true,
      trackFlagMapping: {
        id: "",
        startTime: 0,
        endTime: 0,
        customClass: "",
        trackId: "",
        operationId: "",
      },
      flagContent: {
        id: "",
        userName: "",
        comment: "",
        timestamp: 0,
      },
    };
    flag.id = create_UUID();
    flag.jobname = resultStat.jobname;
    flag.trackFlagMapping.id = create_UUID();
    flag.trackFlagMapping.startTime = startTime;
    flag.trackFlagMapping.endTime = startTime;
    flag.trackFlagMapping.customClass = trackType;
    flag.trackFlagMapping.operationId = "";
    flag.flagContent.id = create_UUID();
    flag.flagContent.userName = Koolioai.username;
    flag.flagContent.comment = "";
    flag.flagContent.timestamp = Date.now();
    const params = {
      task: "add",
      inputs: flag,
    };
    flagsList.push(flag);
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    sendFlagToBackend(params);
    var duration = currentsegment.getDuration();
    var width = currentsegment.params.minPxPerSec * duration;
    let widthG = document.getElementById("globalview").clientWidth;
    let positionX = (currentTime / duration) * width;
    let positionXG = (currentTime / duration) * parseInt(widthG);

    // Create a new flag element
    flags.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
    flags.style.position = "absolute";
    flags.style.left = positionX - 3 + "px"; // Adjust based on your needs
    flags.id = "flag-" + flag.id;
    flags.style.zIndex = 999;
    flags.addEventListener("click", function () {
      store.dispatch(flagListActions.updateFlagId(flag.id));
      store.dispatch(flagListActions.updateFlagShowModal(true));
    });

    globalflags.addEventListener("click", function () {
      globalFlagTriggerFunction(flag.id);
    });
    flags.style.cursor = "pointer";
    waveformContainer.appendChild(flags);
    globalflags.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
    globalflags.style.position = "absolute";
    globalflags.style.left = positionXG - 3 + "px"; // Adjust based on your needs
    globalflags.style.width = "0.8463541666666666vw";
    globalflags.style.top = "-10px";
    globalflags.id = "global-flag-" + flag.id;
    globalflags.style.zIndex = 99;
    globalflags.style.cursor = "pointer";
    globalWaveformContainer.appendChild(globalflags);
    await enableButtonAsync();

    setTimeout(() => {
      document.getElementById("btn-flag").src = iconsData.flag.inactive;
    }, 100);
  }

  const handlePasteandInsertOperation = (position, op) => {
    // setPastePosition(position);
    hideZoomControls();
    let tocopy = store.getState().copiedAnnotation.copiedFrom;
    if (tocopy !== lastclicked.clickedregion) {
      showStatText("cross paste/insert is  disabled");
      return;
    }
    if (lastclicked.clickedregion === "SFX" && !disableModalForNestedRec) {
      sfxopenModal(position, op);
    } else {
      if (op == "paste") handlePaste(position, null);
      else handleInsert(position, null);
    }
  };

  const handlePaste = async (position, length) => {
    const permResponse = ACM.checkPermission("insert");
    if (!permResponse.success) return showStatText(permResponse.message);

    let copyBuffer = store.getState().copyBuffer.copyBuffer;
    let createdFrom = store.getState().copyBuffer.createdFrom;
    if (createdFrom === "sfx") {
      if (ACM.checkAllowedSFXCount() !== -1) {
        const currentCount = await getCurrentFeatureWiseCount(
          "allowed_number_of_sfx",
        );
        await updateTrialFeatureUsageCount(
          "allowed_number_of_sfx",
          currentCount + 1,
        );
      }
    }
    if (copyBuffer) {
      setdisableModalForNestedRec(false);
      await disableButtonAsync();
      setNestedRecord(false);
      let currentSegment;
      let trackType;
      let customClass;
      const resultStat = store.getState().projectData.resultStat;
      if (lastclicked.clickedregion === "SFX") {
        currentSegment = props.wavesurferObjSFX;
        trackType = "sfx_segments";
      } else {
        currentSegment = props.wavesurferObj;
        trackType = "speakers_segments";
      }
      let currentTime = currentSegment.getCurrentTime();
      let shouldStop = false;
      let fadeappliedon =
        trackType == "sfx_segments" ? "sfx_segments" : "speakers-0";
      resultStat.fades?.forEach((fade) => {
        if (
          fade.start < currentTime &&
          fade.end > currentTime &&
          fadeappliedon == fade.trackType
        ) {
          shouldStop = true;
          showMessage(
            "Operation restricted!",
            "Cannot perform operation of faded region.",
            2000,
            "error",
            "workspace",
          );
          return;
        }
      });
      if (shouldStop) {
        await enableButtonAsync();
        return;
      }
      await disableButtonAsync();
      let cursorPostion = getCurrentPosition(currentSegment);
      const originalBuffer = currentSegment.backend.buffer;

      let bufferDuration =
        length !== null
          ? length
          : copyBuffer.length / originalBuffer.sampleRate;
      let copyBufferSlice =
        length !== null
          ? copyBuffer.slice(0, length * originalBuffer.sampleRate)
          : copyBuffer;

      let start, end;
      let sfxDropStartTime = SfxDragAndDrop?.startTime;

      dispatch(
        setSfxDragAndDropActions.setPateRegionStartTime(sfxDropStartTime),
      );
      dispatch(
        setSfxDragAndDropActions.setPasteRegionEndTime(
          sfxDropStartTime + bufferDuration,
        ),
      );

      let startInputParam, endInputParam;
      const region =
        currentSegment.regions.list[
          Object.keys(currentSegment.regions.list)[0]
        ];
      switch (position) {
        case "before":
          end = currentTime;
          start = parseFloat(end) - parseFloat(bufferDuration);

          if (region) {
            startInputParam = region.start;
            endInputParam = region.end;
            cursorPostion = start;
          } else {
            startInputParam = currentTime;
            endInputParam = currentTime;
          }
          break;
        case "after":
          if (region) {
            start = region.end;
            startInputParam = region.start;
            endInputParam = region.end;
            cursorPostion = endInputParam;
          } else {
            start = currentTime;
            startInputParam = currentTime;
            endInputParam = currentTime;
          }
          end = parseFloat(start) + parseFloat(bufferDuration);
          break;
        case "sfxDropPosition":
          start = sfxDropStartTime;
          end = start + bufferDuration;
          startInputParam = sfxDropStartTime;
          endInputParam = start + bufferDuration;
          break;
        case "center":
        default:
          start = currentTime;
          end = parseFloat(start) + parseFloat(bufferDuration);
          if (region) {
            startInputParam = region.start;
            endInputParam = region.end;
            cursorPostion = startInputParam;
          } else {
            startInputParam = currentTime;
            endInputParam = currentTime;
          }
          break;
      }
      if (start < 0) {
        showStatText("cannot paste before at start");
        await enableButtonAsync();
        return;
      }
      // remove region and annotation color after paste
      const regionaudio = props.wavesurferObj.regions.list;
      const regionSFX = props.wavesurferObjSFX.regions.list;
      const regionGlobal = props.wavesurferObjGlobal.regions.list;
      for (const regionKey in regionaudio) {
        regionaudio[regionKey].remove();
      }
      for (const regionKey in regionSFX) {
        regionSFX[regionKey].remove();
      }
      for (const regionKey in regionGlobal) {
        regionGlobal[regionKey].remove();
      }
      resetAnnotation();
      //
      const operationId = create_UUID();
      if (position !== "sfxDropPosition") saveState(resultStat, "paste");

      if (resultStat[trackType]) {
        resultStat[trackType].forEach((segment) => {
          customClass = segment.speaker_label;
        });
      }

      const newResultStat = JSON.parse(JSON.stringify(resultStat));
      newResultStat.fades = resultStat.fades ? resultStat.fades : [];
      newResultStat.speakers_src = resultStat.speakers_src;
      newResultStat.sfx_src = resultStat.sfx_src;
      newResultStat.refid = operationId;
      let newannot = [],
        toInsertSegment;

      if (resultStat.speakers_segments) {
        newResultStat.speakers_segments = resultStat.speakers_segments.map(
          (track) => ({ ...track }),
        );
        newResultStat.speakers_segments.forEach((track) => {
          if (track.start_time <= start && track.end_time >= start) {
            toInsertSegment = track.speaker_label;
          } else if (track.start_time > start) {
            //here why are we increasing this segment
            // track.start_time += end - start;
            // track.end_time += end - start;
          }
        });
      }

      if (trackType === "speakers_segments") {
        newannot = pasteAnnotations(start, end, toInsertSegment, operationId);
        newResultStat.speakers_segments.forEach((track, index) => {
          track.annotations = newannot[index];
        });
      } else if (trackType === "sfx_segments") {
        if (position !== "sfxDropPosition") {
          newannot = pasteAnnotations(start, end, "sfx");
          newResultStat.sfx_segments.push(newannot[0]);
        }
      }

      const pasteStartIndex = Math.floor(start * originalBuffer.sampleRate);
      let pastedBuffer = originalBuffer.getChannelData(0).slice();

      if (pasteStartIndex + copyBufferSlice.length > pastedBuffer.length) {
        pastedBuffer = new Float32Array(
          pasteStartIndex + copyBufferSlice.length,
        );
        pastedBuffer.set(originalBuffer.getChannelData(0));
      }

      pastedBuffer.set(copyBufferSlice, pasteStartIndex);

      const newBuffer = currentSegment.backend.ac.createBuffer(
        1,
        pastedBuffer.length,
        originalBuffer.sampleRate,
      );
      newBuffer.copyToChannel(pastedBuffer, 0);
      let copiedAnnotations =
        store.getState().copiedAnnotation.toSendAnnot || [];
      if (copiedAnnotations.length > 0) {
        let newcopiedAnnotations = [];
        for (let i = 0; i < copiedAnnotations.length; i++) {
          let newAnnot = {
            ...copiedAnnotations[i],
            start_time:
              position === "after"
                ? parseFloat(copiedAnnotations[i].start_time - endInputParam)
                : parseFloat(copiedAnnotations[i].start_time - start),
            end_time:
              position === "after"
                ? parseFloat(copiedAnnotations[i].end_time - endInputParam)
                : parseFloat(copiedAnnotations[i].end_time - start),
          };
          newcopiedAnnotations.push(newAnnot);
        }
        copiedAnnotations = [];
        copiedAnnotations = [...newcopiedAnnotations];
        if (trackType === "sfx_segments") {
          copiedAnnotations = copiedAnnotations[0].annotations;
        }
      }
      // send params to backend and store it in indexDb
      const track = {
        annotCueIn: 0,
        annotCueOut: parseFloat(bufferDuration),
        annotations: [...copiedAnnotations],
        cueIn: 0,
        cueOut: parseFloat(bufferDuration),
        customClass: customClass,
        displayCursorTime: true,
        duration: parseFloat(bufferDuration),
        enabledStates: {
          cursor: true,
          fadein: true,
          fadeout: true,
          select: true,
          shift: true,
        },
        endTime: parseFloat(bufferDuration),
        fades: [],
        gain: 0.5,
        id: operationId,
        src: copyBufferSlice,
        startTime: 0,
      };

      const params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "paste",
        inputs: {
          startTime: startInputParam,
          endTime: endInputParam,
          selectedCustomClasses: getSelectedCC(resultStat),
          clipboardDuration: parseFloat(bufferDuration),
          fileFormat: "wav",
          isImport: false,
          isTranscriptInitiated: false,
          pasteKey: position,
          customClass,
          adjustFade: false,
          tracks: [track],
        },
      };
      const newEndTime = pastedBuffer.length / originalBuffer.sampleRate;

      if (
        newEndTime > props.wavesurferObj.getDuration() ||
        newEndTime > props.wavesurferObjSFX.getDuration()
      ) {
        newResultStat.duration = newEndTime;
        newResultStat.sfx_duration = newEndTime;

        if (lastclicked.clickedregion !== "SFX") {
          let newTracks = [];
          let deleteannot = [];
          newResultStat.speakers_segments.forEach((track) => {
            if (
              track.start_time <= currentTime &&
              track.end_time >= currentTime
            ) {
              track.end_time = newEndTime; // we will move the endtime at the endtime of new buffer
              newTracks.push(track);
            } else if (track.start_time > currentTime) {
              // now we want to overwrite the track audio with pasted audio

              track.annotations.forEach((annot) => {
                deleteannot.push(annot);
              });
            } else {
              newTracks.push(track);
            }
          });
          deleteannotation(deleteannot);
          newResultStat.speakers_segments = [];
          newResultStat.speakers_segments = [...newTracks];
          newResultStat.speakers_src = newBuffer;
          props.wavesurferObj.loadDecodedBuffer(newBuffer);
          props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
        } else {
          // If pasting on SFX, create a new buffer for speakers with silence
          const speakerBuffer = props.wavesurferObj.backend.ac.createBuffer(
            1,
            pastedBuffer.length,
            originalBuffer.sampleRate,
          );
          speakerBuffer.copyToChannel(
            props.wavesurferObj.backend.buffer.getChannelData(0),
            0,
          );
          newResultStat.speakers_src = speakerBuffer;
          props.wavesurferObj.loadDecodedBuffer(speakerBuffer);
          props.wavesurferObjGlobal.loadDecodedBuffer(speakerBuffer);
        }

        // Extend SFX track
        if (lastclicked.clickedregion === "SFX") {
          newResultStat.sfx_src = newBuffer;
          props.wavesurferObjSFX.loadDecodedBuffer(newBuffer);
        } else {
          // If pasting on speakers, create a new buffer for SFX with silence
          const sfxBuffer = props.wavesurferObjSFX.backend.ac.createBuffer(
            1,
            pastedBuffer.length,
            originalBuffer.sampleRate,
          );
          sfxBuffer.copyToChannel(
            props.wavesurferObjSFX.backend.buffer.getChannelData(0),
            0,
          );
          newResultStat.sfx_src = sfxBuffer;
          props.wavesurferObjSFX.loadDecodedBuffer(sfxBuffer);
        }
      } else {
        //length of resultstat/project is not increased
        let copiedDuration = copyBufferSlice.length / originalBuffer.sampleRate;
        let changeIntrackLength = 0;
        let newtracks = [];

        // Original behavior if no extension is needed
        if (lastclicked.clickedregion === "SFX") {
          newResultStat.sfx_src = newBuffer;
          props.wavesurferObjSFX.loadDecodedBuffer(newBuffer);
        } else {
          newResultStat.speakers_segments.forEach((track, index) => {
            if (
              track.start_time <= currentTime &&
              track.end_time > currentTime &&
              track.end_time < currentTime + copiedDuration &&
              position !== "before"
            ) {
              changeIntrackLength =
                currentTime + copiedDuration - track.end_time;
              track.end_time += changeIntrackLength; // this is calculating the extra time we are adding to particular segment for multispeaker file
              newtracks.push(track);
              // now we have to iterate over next tracks to keep track of which track we want to keep and which one we have to discard
              let duration = 0;
              let trackInfo;
              for (
                let i = index + 1;
                i < newResultStat.speakers_segments.length;
                i++
              ) {
                duration +=
                  newResultStat.speakers_segments[i].end_time -
                  newResultStat.speakers_segments[i].start_time;
                if (duration > changeIntrackLength) {
                  trackInfo = i;
                  break;
                }
              }
              //remove the tracks from index to trackInfo-1
              let deleteannot = [];
              let gap = 0;
              for (let i = index + 1; i < trackInfo; i++) {
                gap +=
                  newResultStat.speakers_segments[i].end_time -
                  newResultStat.speakers_segments[i].start_time;
                // newResultStat.speakers_segments[i].annotations.forEach(
                //   (annot) => {
                //     deleteannot.push(annot);
                //   },
                // );
              }

              let pendingGapInLastSeg = changeIntrackLength - gap; // this will take care of the pending gap in the segement where we have to only reduce a portion of track
              let pendingGapInLastSegAnnotsum = 0; // this is to store the sum of annot duration in the last track where the pasted buffer is overlapping
              let trackannot = [];
              let annotReached = false;
              let copytrack = {
                ...newResultStat.speakers_segments[trackInfo],
                start_time:
                  newResultStat.speakers_segments[trackInfo].start_time +
                  pendingGapInLastSeg,
              };
              newtracks.push(copytrack);

              deleteannotation(deleteannot);
            } else if (
              track.start_time <= currentTime &&
              track.end_time < currentTime
            ) {
              newtracks.push(track);
            } else if (track.start_time > currentTime + copiedDuration) {
              newtracks.push(track);
            } else {
              newtracks.push(track);
            }
          });
          newResultStat.speakers_segments = [];
          newResultStat.speakers_segments = [...newtracks];
          newResultStat.speakers_src = newBuffer;
          props.wavesurferObj.loadDecodedBuffer(newBuffer);
          props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
        }
      }
      setIsFileImported(false);
      dispatch(CopyBufferActions.updatedCreatedFrom({ createdFrom: "null" }));
      dispatch(
        wavesurferObjActions.updateWavesurferObj({
          wavesurferObj: props.wavesurferObj,
        }),
      );
      dispatch(
        wavesurferObjSFXActions.updateWavesurferObjSFX({
          wavesurferObjSFX: props.wavesurferObjSFX,
        }),
      );

      restoreCursorPositions(cursorPostion);

      if (position === "sfxDropPosition") {
        dispatch(setSfxDragAndDropActions.setDrop(false));
        dispatch(
          projectDataSliceActions.projectReducerData({
            resultStat: newResultStat,
          }),
        );
        await enableButtonAsync();
        return params.inputs;
      }
      await annotUIshift(newResultStat);
      await restThings(params, newResultStat, true, isFileImported);
    } else {
      showStatText("nothing to paste", false);
    }
    let transcriptionRequired = JSON.parse(
      sessionStorage.getItem("NestedtranscriptUploadOption"),
    );

    await enableButtonAsync();
    if (transcriptionRequired) {
      await disableFunctionForNestedtranscription();
      showMessage(
        "Please wait!",
        "Transcription is in progress.",
        2000,
        "success",
        "workspace",
      );
      showStatText(
        "Operations are restricted until transcriptions is completed.",
      );
    }
    if (position !== "sfxDropPosition") {
      pasteOperation();
    }
  };

  // sfx drag and drop
  const SfxDragAndDrop = useSelector((state) => state.SfxDragAndDrop);
  const DeleteSFX = async () => {
    let currentsegment = props.wavesurferObjSFX;
    const region =
      currentsegment.regions.list[Object.keys(currentsegment.regions.list)[0]];
    const originalBuffer = currentsegment.backend.buffer;
    let insertedBuffer = originalBuffer.getChannelData(0).slice();
    let resultStat = store.getState().projectData.resultStat;
    if (region) {
      let start = region.start;
      let end = region.end;
      let sfxDuration = end - start;
      dispatch(setSfxDragAndDropActions.setSfxDuration(sfxDuration));
      dispatch(setSfxDragAndDropActions.setCutRegionStartTime(region.start));
      dispatch(setSfxDragAndDropActions.setCutRegionEndTime(region.end));

      const regionSFX = currentsegment.regions.list;

      for (const regionKey in regionSFX) {
        regionSFX[regionKey].remove();
      }

      // Calculate the start index and length for inserting silence
      const insertStartIndex = Math.floor(start * originalBuffer.sampleRate);
      const insertSilenceLength = Math.floor(
        (end - start) * originalBuffer.sampleRate,
      );

      // Insert silence into the buffer at the specified index
      insertedBuffer.fill(
        0,
        insertStartIndex,
        insertStartIndex + insertSilenceLength,
      );
      // Create a new AudioBuffer with the inserted silence
      const newBuffer = currentsegment.backend.ac.createBuffer(
        1,
        insertedBuffer.length,
        originalBuffer.sampleRate,
      );

      newBuffer.copyToChannel(insertedBuffer, 0);
      // Update the waveform display with the inserted buffer
      currentsegment.loadDecodedBuffer(newBuffer);
      const newResultStat = JSON.parse(JSON.stringify(resultStat));
      newResultStat.fades = resultStat.fades ? resultStat.fades : [];
      newResultStat.speakers_src = resultStat.speakers_src;
      newResultStat.sfx_src = newBuffer;
      dispatch(
        projectDataSliceActions.projectReducerData({
          resultStat: newResultStat,
        }),
      );
      return { start, end };
    } else {
      return;
    }
  };
  const handleSfxDragAndDrop = async () => {
    let region =
      props.wavesurferObjSFX.regions.list[
        Object.keys(props.wavesurferObjSFX.regions.list)[0]
      ];
    if (!region) return;
    let resultStat = store.getState().projectData.resultStat;
    saveState(resultStat, "dragAndDropSFX");
    const operationId = create_UUID();

    await handleCopy(resultStat);
    dispatch(setSfxDragAndDropActions.setDrop(false));
    showplaylistloader();
    let dragWord = store.getState().multiSelectwordObject.toDragSFX;
    const start = dragWord.start;
    const end = dragWord.end;
    const duration = end - start;
    region = await DeleteSFX();
    const pasteParam = await handlePaste("sfxDropPosition", duration); // here need to give the sfx length
    if (pasteParam) {
      hideplaylistloader();
      const {
        startTime,
        endTime,
        clipboardDuration,
        fileFormat,
        isImport,
        isTranscriptInitiated,
        pasteKey,
        customClass,
        adjustFade,
        tracks,
      } = pasteParam;

      let cc = dragWord.data.speaker_label;
      const params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "dragAndDropSFX",
        inputs: {
          items: [
            {
              startTime: region.start,
              endTime: region.end,
              customClass: "sfx-0",
              worker: "cutDeleteCustomClass",
            },
            {
              wordID: dragWord.data.id,
              startTime,
              endTime,
              clipboardDuration,
              fileFormat,
              isImport,
              isTranscriptInitiated,
              pasteKey,
              customClass,
              selectedCustomClasses: [cc],
              adjustFade,
              trackType: "sfx",
              tracks,
              worker: "paste",
            },
          ],
          customClass,
        },
      };
      resultStat = store.getState().projectData.resultStat;
      const newResultStat = JSON.parse(JSON.stringify(resultStat));
      newResultStat.speakers_src = resultStat.speakers_src;
      newResultStat.sfx_src = resultStat.sfx_src;
      newResultStat.refid = operationId;
      newResultStat.sfx_segments.forEach((track) => {
        if (track.annotations[0]?.id == dragWord.data.id) {
          track.start_time = startTime;
          track.end_time = endTime;
          track.annotations[0].start_time = startTime;
          track.annotations[0].end_time = endTime;
        }
      });
      await annotUIshift(newResultStat);
      await restThings(params, newResultStat);
    } else {
      hideplaylistloader();
      showStatText("undefined paste param");
    }
  };
  if (SfxDragAndDrop.drop) {
    dispatch(setSfxDragAndDropActions.setDrop(false));
    handleSfxDragAndDrop();
  }

  const handleInsert = async (position, length) => {
    const permResponse = ACM.checkPermission("insert");
    if (!permResponse.success) return showStatText(permResponse.message);
    let createdFrom = store.getState().copyBuffer.createdFrom;
    if (createdFrom === "sfx") {
      if (ACM.checkAllowedSFXCount() !== -1) {
        const currentCount = await getCurrentFeatureWiseCount(
          "allowed_number_of_sfx",
        );
        await updateTrialFeatureUsageCount(
          "allowed_number_of_sfx",
          currentCount + 1,
        );
      }
    }
    setNestedRecord(false);
    setdisableModalForNestedRec(false);
    let resultStat = store.getState().projectData.resultStat;
    let copyBuffer = store.getState().copyBuffer.copyBuffer;

    if (lastclicked && copyBuffer) {
      await disableButtonAsync();
      let currentsegment, trackType, customClass, otherSegment;
      if (lastclicked.clickedregion == "SFX") {
        otherSegment = props.wavesurferObj;
        currentsegment = props.wavesurferObjSFX;
        trackType = "sfx_segments";
      } else {
        otherSegment = props.wavesurferObjSFX;
        currentsegment = props.wavesurferObj;
        trackType = "speakers_segments";
      }
      const currentTime = currentsegment.getCurrentTime();
      let shouldStop = false;
      let fadeappliedon =
        trackType == "sfx_segments" ? "sfx_segments" : "speakers-0";
      resultStat.fades?.forEach((fade) => {
        if (
          fade.start < currentTime &&
          fade.end > currentTime &&
          fadeappliedon == fade.trackType
        ) {
          shouldStop = true;
          showMessage(
            "Operation restricted!",
            "Cannot perform operation of faded region.",
            2000,
            "error",
            "workspace",
          );
          return;
        }
      });
      if (shouldStop) {
        await enableButtonAsync();
        return;
      }
      let cursorPosition = getCurrentPosition(currentsegment);
      const originalBuffer = currentsegment.backend.buffer;
      let bufferDuration =
        length !== null
          ? length
          : copyBuffer.length / originalBuffer.sampleRate;
      copyBuffer =
        length !== null
          ? copyBuffer.slice(0, length * originalBuffer.sampleRate)
          : copyBuffer;
      let start, end;
      let startInputParam, endInputParam;
      const region =
        currentsegment.regions.list[
          Object.keys(currentsegment.regions.list)[0]
        ];
      switch (position) {
        case "after":
          if (region) {
            start = region.end;
            startInputParam = region.start;
            endInputParam = region.end;
            cursorPosition = endInputParam;
          } else {
            start = currentTime;
            startInputParam = currentTime;
            endInputParam = currentTime;
          }
          end = parseFloat(start) + parseFloat(bufferDuration);
          break;
        case "before":
          if (region) {
            start = currentTime;
            startInputParam = region.start;
            endInputParam = region.end;
            cursorPosition =
              parseFloat(endInputParam) - parseFloat(bufferDuration);
          } else {
            start = currentTime;
            startInputParam = currentTime;
            endInputParam = currentTime;
          }
          end = parseFloat(start) + parseFloat(bufferDuration);
          break;
        default:
          start = currentTime;
          end = parseFloat(start) + parseFloat(bufferDuration);
          break;
      }
      let typ = adjustFlagForInsert(start, end);
      saveState(resultStat, typ, createdFrom);
      // Calculate the insertion point in terms of sample index
      const insertStartIndex = Math.floor(start * originalBuffer.sampleRate);

      // Create a new buffer to store the modified audio
      const newBuffer = currentsegment.backend.ac.createBuffer(
        1,
        originalBuffer.length + copyBuffer.length,
        originalBuffer.sampleRate,
      );

      // Copy the audio before the insertion point
      newBuffer
        .getChannelData(0)
        .set(originalBuffer.getChannelData(0).slice(0, insertStartIndex));

      // Insert the copied data at the specified position
      newBuffer.getChannelData(0).set(copyBuffer, insertStartIndex);

      // Copy the audio after the insertion point
      newBuffer
        .getChannelData(0)
        .set(
          originalBuffer.getChannelData(0).slice(insertStartIndex),
          insertStartIndex + copyBuffer.length,
        );

      // Update the waveform display with the new buffer
      currentsegment.loadDecodedBuffer(newBuffer);
      currentsegment.drawer.updateSize();

      const otherBuffer = otherSegment.backend.buffer;
      const silenceBuffer = otherSegment.backend.ac.createBuffer(
        1,
        copyBuffer.length,
        otherBuffer.sampleRate,
      );
      const combinedBuffer = otherSegment.backend.ac.createBuffer(
        1,
        otherBuffer.length + silenceBuffer.length,
        otherBuffer.sampleRate,
      );
      combinedBuffer
        .getChannelData(0)
        .set(otherBuffer.getChannelData(0).slice(0, insertStartIndex));
      combinedBuffer
        .getChannelData(0)
        .set(silenceBuffer.getChannelData(0), insertStartIndex);
      combinedBuffer
        .getChannelData(0)
        .set(
          otherBuffer.getChannelData(0).slice(insertStartIndex),
          insertStartIndex + silenceBuffer.length,
        );
      otherSegment.loadDecodedBuffer(combinedBuffer);
      otherSegment.drawer.updateSize();

      if (lastclicked.clickedregion == "wav") {
        props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
      } else {
        props.wavesurferObjGlobal.loadDecodedBuffer(combinedBuffer);
      }

      // Update the timeline to reflect the new duration

      const operationId = create_UUID();
      resultStat = store.getState().projectData.resultStat;
      resultStat[trackType].forEach((segment) => {
        // && end <= segment.end_time // this has to be added below
        if (start >= segment.start_time) {
          customClass = segment.speaker_label;
        }
      });

      let newResultStat = JSON.parse(JSON.stringify(resultStat));
      newResultStat.fades = resultStat.fades ? resultStat.fades : [];
      let newannot, toInsertSegment;
      if (newResultStat.speakers_segments) {
        newResultStat.speakers_segments.forEach((track) => {
          if (track.start_time <= start && track.end_time >= start) {
            toInsertSegment = track.speaker_label;
          } else if (track.start_time > start) {
            track.start_time += end - start;
            track.end_time += end - start;
          }
        });
      }

      newannot = insertAnnotations(
        trackType,
        start,
        copyBuffer.length / originalBuffer.sampleRate,
        toInsertSegment,
        operationId,
      );
      if (newResultStat.speakers_segments) {
        newResultStat.speakers_segments.forEach((track, index) => {
          track.annotations = newannot[index];
        });
      }

      newannot = insertAnnotations(
        trackType,
        start,
        copyBuffer.length / originalBuffer.sampleRate,
        "sfx_segments",
      );
      newResultStat.sfx_segments = newannot;
      newResultStat.refid = operationId;
      newResultStat.duration += copyBuffer.length / originalBuffer.sampleRate;
      let newEndTime = props.wavesurferObjGlobal.getDuration();
      newResultStat.duration = newEndTime;
      newResultStat.sfx_duration = newEndTime;
      newResultStat.speakers_segments.forEach((track) => {
        if (track.start_time < currentTime && track.end_time >= currentTime) {
          track.end_time += newEndTime - resultStat.duration;
        } else if (track.start_time > currentTime) {
          track.end_time += end - start;
          track.start_time += end - start;
        }
      });

      if (lastclicked.clickedregion == "SFX") {
        // const channelData = newBuffer.getChannelData(0); // Assuming mono audio, change index for stereo
        // const float32Array = new Float32Array(channelData);
        newResultStat.sfx_src = newBuffer;
        newResultStat.speakers_src = combinedBuffer;
      } else {
        // const channelData = combinedBuffer.getChannelData(0); // Assuming mono audio, change index for stereo
        // const float32Array = new Float32Array(channelData);
        newResultStat.sfx_src = combinedBuffer;
        newResultStat.speakers_src = newBuffer;
      }

      if (newResultStat.fades)
        newResultStat = adjustFade(
          newResultStat,
          [{ start, end }],
          "insert",
          trackType === "speakers_segments" ? "speakers-0" : trackType,
        );

      //condition to check if we are pasting a sfx we need to add startime and end time to annotation
      let copiedAnnotations =
        store.getState().copiedAnnotation.toSendAnnot || [];
      if (copiedAnnotations.length > 0) {
        let newcopiedAnnotations = [];
        for (let i = 0; i < copiedAnnotations.length; i++) {
          let newAnnot = {
            ...copiedAnnotations[i],
            start_time:
              position === "after"
                ? parseFloat(copiedAnnotations[i].start_time - endInputParam)
                : parseFloat(copiedAnnotations[i].start_time - startInputParam),
            end_time:
              position === "after"
                ? parseFloat(copiedAnnotations[i].end_time - endInputParam)
                : parseFloat(copiedAnnotations[i].end_time - startInputParam),
          };
          newcopiedAnnotations.push(newAnnot);
        }
        copiedAnnotations = [];
        copiedAnnotations = [...newcopiedAnnotations];
        if (trackType === "sfx_segments") {
          copiedAnnotations = copiedAnnotations[0].annotations;
        }
      }
      const track = {
        annotCueIn: 0,
        annotCueOut: end - start,
        annotations: [...copiedAnnotations],
        cueIn: 0,
        cueOut: end - start,
        customClass: customClass,
        displayCursorTime: true,
        duration: end - start,
        enabledStates: {
          cursor: true,
          fadein: true,
          fadeout: true,
          select: true,
          shift: true,
        },
        endTime: end - start,
        fades: [],
        gain: 0.5,
        id: operationId,
        src: copyBuffer,
        startTime: 0,
      };
      const params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "insert",
        inputs: {
          trackType: trackType.split("_")[0],
          startTime: startInputParam,
          endTime: endInputParam,
          clipboardDuration: end - start,
          fileFormat: "wav",
          isImport: false,
          isTranscriptInitiated: false,
          insertKey: position,
          customClass,
          adjustFade: false,
          tracks: [track],
          selectedCustomClasses: getSelectedCC(resultStat),
        },
      };
      restThings(params, newResultStat, true, isFileImported);

      setIsFileImported(false);
      annotUIshift(newResultStat);

      let isOperationsTransActive =
        store.getState().operationListData.isOperationsTransActive;
      if (!isOperationsTransActive) sendOperation();
      restoreCursorPositions(cursorPosition);
      // remove region and annotation color after paste
      const regionaudio = props.wavesurferObj.regions.list;
      const regionSFX = props.wavesurferObjSFX.regions.list;
      const regionGlobal = props.wavesurferObjGlobal.regions.list;
      for (const regionKey in regionaudio) {
        regionaudio[regionKey].remove();
      }
      for (const regionKey in regionSFX) {
        regionSFX[regionKey].remove();
      }
      for (const regionKey in regionGlobal) {
        regionGlobal[regionKey].remove();
      }
      resetAnnotation();
      //
    }
    dispatch(CopyBufferActions.updatedCreatedFrom({ createdFrom: "null" }));
    //update redux for wavesurfer obj
    dispatch(
      wavesurferObjActions.updateWavesurferObj({
        wavesurferObj: props.wavesurferObj,
      }),
    );
    dispatch(
      wavesurferObjSFXActions.updateWavesurferObjSFX({
        wavesurferObjSFX: props.wavesurferObjSFX,
      }),
    );
    let transcriptionRequired = JSON.parse(
      sessionStorage.getItem("NestedtranscriptUploadOption"),
    );

    await enableButtonAsync();
    if (transcriptionRequired) {
      await disableFunctionForNestedtranscription();
      showMessage(
        "Please wait!",
        "Transcription is in progress.",
        2000,
        "success",
        "workspace",
      );
      showStatText(
        "Operations are restricted until transcriptions is completed.",
      );
    }
    insertOperation();
  };

  const autoFilterWords = () => {
    const permResponse = ACM.checkPermission("auto-filter-word");
    if (!permResponse.success) return showStatText(permResponse.message);
    if (sessionStorage.getItem("transcriptUploadOption") == "false") {
      showStatText(
        "operation disabled as transcription is not available for this project",
      );
      return;
    }
    const resultStat = store.getState().projectData.resultStat;
    let tracks = resultStat.speakers_segments;
    let wordList =
      "ah,uh,um,umm,er,hmm,actually,basically,basically.,actually.,hmm.,umm.,ah.,er.,uh.,um.,basically!,actually!,hmm!,umm!,ah!,er!,uh!,um!,basically$,actually$,hmm$,umm$,ah$,er$,uh$,um$,basically#,actually#,hmm#,umm#,ah#,er#,uh#,um#,basically;,actually;,hmm;,umm;,ah;,er;,uh;,um;,GAP-THRESHOLD-1-SECOND,mm.,mm,mm!,mm#,mm;,mm$";
    wordList = wordList.split(",");
    let matchedItems = [];

    tracks.forEach((segment) => {
      segment.annotations.forEach((annot) => {
        const lowerContent = annot.content.toLowerCase();
        const matchedWord = wordList.find(
          (word) => lowerContent === word || lowerContent === word + ",",
        );

        if (matchedWord) {
          matchedItems.push(annot);
        }
      });
    });
    if (!matchedItems.length) {
      console.log(matchedItems.length);
      showStatText("no words to autofilter");
      return;
    }
    handleCut(matchedItems);
  };

  //long gaps
  async function removeLongGaps(threshold) {
    const resultStat = store.getState().projectData.resultStat;
    var audioBuffer = props.wavesurferObj.backend.buffer;
    var audioData = audioBuffer.getChannelData(0);
    var sampleRate = audioBuffer.sampleRate;
    var gapSamples = threshold * sampleRate;
    const operationId = create_UUID();

    var startGap = -1;
    var gaps = [];
    saveState(resultStat, "remove gaps");
    for (var i = 0; i < audioData.length; i++) {
      if (Math.abs(audioData[i]) < 0.009) {
        if (startGap === -1) {
          startGap = i;
        }
      } else {
        if (startGap !== -1) {
          var gapLength = i - startGap;
          if (gapLength > gapSamples) {
            gaps.push({
              start: (startGap / sampleRate).toFixed(2),
              end: (i / sampleRate).toFixed(2),
            });
          }
          startGap = -1;
        }
      }
    }
    handleCut(null, gaps, threshold);
    closeModal();
    return;
  }

  const magicbutton = () => {
    document.getElementById("btn-magic-icon").src = document
      .getElementById("btn-magic-icon")
      .src.endsWith(iconsData.magic.active)
      ? iconsData.magic.inactive
      : iconsData.magic.active;

    hideZoomControls();
    if (dropdownContent) {
      dropdownContent.style.display =
        dropdownContent.style.display === "block" ? "none" : "block";
    }
  };

  function processingUIForEditOption() {
    const viewModeText = document.getElementById("control-opt-preview");
    const editModeText = document.getElementById("control-opt-edit");
    if (viewModeText && editModeText) {
      viewModeText.style.color = "#494949";
      editModeText.style.color = "#e2522b";
    }
    const shareAndCollab = store.getState().shareAndCollab;
    const featureLockedByIcon = document.getElementById(
      shareAndCollab.editFeatureLockedBy + "-circle",
    );
    if (featureLockedByIcon) {
      featureLockedByIcon.style.backgroundColor = "#E2522B";
      featureLockedByIcon.src =
        "https://ui-avatars.com/api/?name=" +
        shareAndCollab.editFeatureLockedBy +
        "&background=E2522B&color=fff&rounded=true&size=28";
    }

    const jobname = store.getState().projectData.resultStat.jobname;
    sendLog({
      activity: "click",
      target: "edit button click",
      timeStamp: new Date().toISOString(),
      jobname,
      status: "success",
      statusMsg: "success",
    });
    // playlist.mode = 'edit'
    //this is call for workspace tour
    // destroytour()
    // startTour('workspace_edit')
    // Enabled icons
    document.getElementById("add-spk-sfx-tracks").style.cssText =
      "opacity: 1; cursor:pointer";
    document.getElementById("menu-vol-ctrl-spk").style.cssText =
      "opacity: 1; cursor:pointer";
    document.getElementById("menu-vol-ctrl-sfx").style.cssText =
      "opacity: 1; cursor:pointer";
    // updateOperationSpace()
    const projectName = document.getElementById("projectname");
    hideplaylistloader();
  }

  const editControls = async () => {
    if (document.getElementById("control-opt-edit").innerText === "Reject") {
      //! reject
      showplaylistloader();
      let resultStat = store.getState().restoreData.currentResultStat;
      let props = store.getState().propsSlice.props;
      const { wavesurferObj, wavesurferObjSFX, wavesurferObjGlobal } = {
        ...props,
      };
      let currflagsList = store.getState().flag.restoreFlagsList;
      let originalflagsList = store.getState().flag.flagsList;
      hideFlagsFromUi(currflagsList);
      restoreFlags(
        originalflagsList,
        currflagsList,
        resultStat,
        wavesurferObj,
        true,
      );
      // const audioBlob = new Blob([resultStat.speakers_src]);

      await ensureBuffersReady(wavesurferObj, wavesurferObjSFX);
      let promises = [
        getArrayBufferFromSrcURL(resultStat.speakers_src),
        getArrayBufferFromSrcURL(resultStat.sfx_src),
      ];
      Promise.all(promises).then((audioBuffersArray) => {
        let audioBlob = new Blob([audioBuffersArray[0]]);
        let blobURL = URL.createObjectURL(audioBlob);
        wavesurferObj.load(blobURL);
        wavesurferObjGlobal.load(blobURL);
        audioBlob = new Blob([audioBuffersArray[1]]);
        blobURL = URL.createObjectURL(audioBlob);
        wavesurferObjSFX.load(blobURL);
        wavesurferObjGlobal.setVolume(0);
        Promise.all([
          new Promise((resolve) => wavesurferObj.on("ready", resolve)),

          new Promise((resolve) => wavesurferObjSFX.on("ready", resolve)),
        ]).then(() => {
          dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
          document.getElementById("transcriptions_div").innerHTML = "";
          document.getElementById("transcriptions_div_sfx").innerHTML = "";
          store.dispatch(
            wavesurferObjActions.updateWavesurferObj({
              wavesurferObj: wavesurferObj,
            }),
          );

          store.dispatch(
            wavesurferObjSFXActions.updateWavesurferObjSFX({
              wavesurferObjSFX: wavesurferObjSFX,
            }),
          );
          annotUIshiftSub(resultStat, props);
          dispatch(restoreDataSliceActions.clearRestoreData());
          enableHistoryOptions(false);
          hideplaylistloader();
          return;
        });
      });
    } else {
      let checkAccessVar = true,
        isProjectSharedVar = true,
        isEditableVar = true;
      if (!(await checkAccess())) {
        checkAccessVar = false;
        const jobname = store.getState().projectData.resultStat.jobname;
        sendLog({
          activity: "click",
          target: "edit button click",
          timeStamp: new Date().toISOString(),
          jobname,
          status: "failed",
          statusMsg: "do not have permission to edit",
        });
        showStatText("you do not have permission to edit");
        return false;
      }

      if (!store.getState().shareAndCollab.isTheProjectShared) {
        isProjectSharedVar = false;
        processingUIForEditOption();
        setIsDisabled(false);
        store.dispatch(
          viewEditSliceActions.updateViewEditMode({ mode: "edit" }),
        );
        return;
      }
      if (!store.getState().shareAndCollab.isEditFeatureEnabled) {
        if (
          store
            .getState()
            .shareAndCollab.editFeatureLockedBy.startsWith("google_")
        ) {
          const url = _config.api + "/get-email";
          const params = {
            username: store.getState().shareAndCollab.editFeatureLockedBy,
          };
          const response = await axios.get(url, {
            params,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.status === 200) {
            store.dispatch(
              shareAndCollabActions.updateEditFeatureLockedBy({
                editFeatureLockedBy: response.data.email.split("@")[0],
              }),
            );
          }
        }
        isEditableVar = false;
        const message =
          "Another collaborator, " +
          store.getState().shareAndCollab.editFeatureLockedBy +
          ", is currently working on the project. Please wait or request access from them to edit";
        showMessage("Edit mode disabled", message, 8000, "error", "workspace");
        const jobname = store.getState().projectData.resultStat.jobname;
        sendLog({
          activity: "click",
          target: "play button click",
          timeStamp: new Date().toISOString(),
          jobname,
          status: "failed",
          statusMsg: "Transcription in progress. edit mode restricted",
        });
        showStatText("edit mode restricted");
        checkTimeoutOfFeatureLockData(editFeatureTitle);
        return false;
      }

      if (checkAccessVar && isProjectSharedVar && isEditableVar) {
        showStatText("enabling edit mode, please wait...");
        showplaylistloader();
        lockFeatureForOtherCollaborators(editFeatureTitle).then(
          async (response) => {
            if (
              response == "Feature locked" ||
              Koolioai.username === response.lockedBy
            ) {
              store.dispatch(
                viewEditSliceActions.updateViewEditMode({ mode: "edit" }),
              );
              processingUIForEditOption();
              setIsDisabled(false); // this will be used to show edit icons
            } else if (
              response.lockStatus === "locked" &&
              Koolioai.username !== response.lockedBy
            ) {
              let shareAndCollab = {};
              hideplaylistloader();
              store.dispatch(
                shareAndCollabActions.updateEditFeatureLockedBy({
                  editFeatureLockedBy: response.lockedBy,
                }),
              );
              shareAndCollab.editFeatureLockedBy = response.lockedBy;
              store.dispatch(
                shareAndCollabActions.updateEditFeatureEnable({
                  isEditFeatureEnabled: false,
                }),
              );
              shareAndCollab.isEditFeatureEnabled = false;
              if (shareAndCollab.editFeatureLockedBy.startsWith("google_")) {
                const url = _config.api + "/get-email";
                const params = {
                  username: shareAndCollab.editFeatureLockedBy,
                };
                const response = await axios.get(url, {
                  params,
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                if (response.status === 200) {
                  store.dispatch(
                    shareAndCollabActions.updateEditFeatureLockedBy({
                      editFeatureLockedBy: response.data.email.split("@")[0],
                    }),
                  );
                  shareAndCollab.editFeatureLockedBy =
                    response.data.email.split("@")[0];
                }
              }
              const message =
                "Another collaborator, " +
                shareAndCollab.editFeatureLockedBy +
                ", is currently working on the project. Please wait or request access from them to edit";
              showMessage(
                "Edit mode disabled",
                message,
                8000,
                "error",
                "workspace",
              );
            }
          },
        );
      }
    }
  };
  const callViewCOntrol = useSelector((state) => state.viewEditMode.viewMode);
  const restoreStatus = useSelector((state) => state.restoreData.isRestore);
  const mode = useSelector((state) => state.viewEditMode.mode);

  useEffect(() => {
    if (mode === "view") {
      setIsDisabled(true);
    }
    if (callViewCOntrol) {
      viewControls(true);
      store.dispatch(viewEditSliceActions.updateViewMode({ value: false }));
      //      if (restoreStatus) {
      //   setIsDisabled(true);
      // }
    }
  }, [callViewCOntrol, restoreStatus, mode]);

  const viewControls = async (isUserInitiated = false) => {
    if (document.getElementById("control-opt-preview").innerText === "Accept") {
      enableHistoryOptions(false);
      document.getElementById("track-controls").style.visibility = "visible";
      document.getElementById("control-mode").style.visibility = "visible";
      let resultStat = store.getState().restoreData.restoreResultStat;
      // dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
      const restoredRefid = resultStat.jobname;
      const fromRefId = store.getState().restoreData.fromRefId;
      const toRefId = store.getState().restoreData.toRefId;
      let res = await restoreProjectHistory(fromRefId, toRefId, false);

      if (res.status === 200) {
        let currflagsList = store.getState().flag.restoreFlagsList; // fetch the restore flags on accept
        enableFlagsClickFunctionality(currflagsList);
        store.dispatch(flagListActions.uploadFlagList(currflagsList)); // set the flaglist according to restore flags to that particular point
        let currResultStat = store.getState().projectData.resultStat;
        let newResultStat = JSON.parse(JSON.stringify(currResultStat));
        newResultStat.speakers_src = currResultStat.speakers_src;
        newResultStat.sfx_src = currResultStat.sfx_src;
        newResultStat.refid = res.jobhead;
        store.dispatch(
          projectDataSliceActions.projectReducerData({
            resultStat: newResultStat,
          }),
        ); // change the refid of resulstat with new Jobhead from backend

        showStatText("workspace restored.");
        restoreOperationsInIndexedDB(restoredRefid, "deleted", "true");
        data = {
          flagUndo: [],
          flagRedo: [],
          undoStack: [],
          redoStack: [],
          canvasUndoStack: [],
          canvasRedoStack: [],
          annottrackstack: [],
        };
        updateOperationSpace();
        updateResultstatByJobname(resultStat.jobname, res.jobhead);
        await updateIndexDBoperationsData(
          "jobname, frontend_apply_status, deleted",
          [resultStat.jobname, "pending", "false"],
          "frontend_apply_status",
          "completed",
        ); // update indexDb till this operation
        currflagsList = [];
        store.dispatch(flagListActions.uploadRestoreFlagList(currflagsList)); // clean the restore Flags from redux
      } else if (res.status === 201) {
        showStatText("oriign ahead of local");
      } else if (res.status === 404) {
        // hideplaylistloader();
        showStatText("conflict");
      }

      dispatch(restoreDataSliceActions.clearRestoreData());
    } else {
      let operationList = store.getState().operationListData.operationList;
      if (
        store.getState().operationListData.isOperationsTransActive ||
        operationList.length > 0
      ) {
        showMessage(
          "View mode is disabled",
          "Saving your operations, please wait...",
          8000,
          "success",
          "workspace",
        );
        return;
      }
      let shouldUnlock = false;
      if (isUserInitiated) {
        shouldUnlock = true;
        let jobname = store.getState().projectData.resultStat.jobname;
        sendLog({
          activity: "click",
          target: "view button click",
          timeStamp: new Date().toISOString(),
          jobname,
          status: "success",
          statusMsg: "success",
        });
      }

      if (!store.getState().shareAndCollab.isTheProjectShared) {
        processingUIForViewOption();
        store.dispatch(
          viewEditSliceActions.updateViewEditMode({ mode: "view" }),
        );

        setIsDisabled(true);
        return;
      }
      if (shouldUnlock) {
        showStatText("enabling view mode, please wait...");
        showplaylistloader();
        unlockFeatureForOtherCollaborators(editFeatureTitle).then(() => {
          hideplaylistloader();
          processingUIForViewOption();
          store.dispatch(
            viewEditSliceActions.updateViewEditMode({ mode: "view" }),
          );
          setIsDisabled(true);
        });
      }
    }
  };

  function processingUIForViewOption() {
    const viewModeText = document.getElementById("control-opt-preview");
    const editModeText = document.getElementById("control-opt-edit");
    if (viewModeText && editModeText) {
      viewModeText.style.color = "blueviolet";
      editModeText.style.color = "#494949";
    }
    hideplaylistloader();
  }
  async function changeSpeakerOperation(tracktype) {
    const permResponse = ACM.checkPermission("changeSpeaker");
    if (!permResponse.success) return showStatText(permResponse.message);
    hideZoomControls();
    await disableButtonAsync();
    const region =
      props.wavesurferObj.regions.list[
        Object.keys(props.wavesurferObj.regions.list)[0]
      ];
    let res = store.getState().projectData.resultStat;
    let resultStat = JSON.parse(JSON.stringify(res));
    resultStat.fades = res.fades ? res.fades : [];
    resultStat.speakers_src = res.speakers_src;
    resultStat.sfx_src = res.sfx_src;

    let topushindex = parseInt(tracktype.match(/\d+$/));
    // resultStat.speakers_segments.forEach((segment, index) => {
    //   if (segment.speaker_label == tracktype) {
    //     topushindex = index;
    //   }
    // });
    let changedAnnot = [];
    let selectedCustomClasses = [];
    saveState(resultStat, "change-speaker");
    resultStat.speakers_segments.forEach((segment) => {
      selectedCustomClasses.push(segment.speaker_label);
      segment.annotations = segment.annotations.filter((annot, i) => {
        let currannot = { ...annot };
        let annotationDuration = annot.end_time - annot.start_time;
        let overlapStart = Math.max(annot.start_time, region.start);
        let overlapEnd = Math.min(annot.end_time, region.end);
        let overlapDuration = overlapEnd - overlapStart;

        // Check if the annotation's start and end time falls inside the provided start and end time
        // or if the overlap duration is at least 50% of the annotation's duration
        if (
          (annot.start_time >= region.start && annot.end_time <= region.end) ||
          overlapDuration >= 0.5 * annotationDuration
        ) {
          changedAnnot.push(currannot);
          // Return false to remove this annotation from the original array
          return false;
        }
        // Return true to keep this annotation in the original array
        return true;
      });
    });

    let newSegment;
    let matchingSegment = {
      annotations: [],
    };
    let testResultstat = JSON.parse(JSON.stringify(res));
    testResultstat.speakers_segments = []; // empty the segments then rearrange it
    resultStat.speakers_segments.forEach((segment, index) => {
      if (segment.speaker_label === tracktype) {
        matchingSegment = segment;
      }
    });
    /***************************This code is for pushing the segments into resulstat and creating new segments based on change speaker region ********************/
    resultStat.speakers_segments.forEach((segment, index) => {
      // push segment into the testResulstat, whose start time and end time is not in the region, which are not in the after region
      if (
        (segment.start_time < region.start &&
          segment.end_time <= region.start) ||
        (segment.start_time >= region.end && segment.end_time > region.end)
      ) {
        testResultstat.speakers_segments.push(segment);
      } else {
        if (
          segment.start_time > region.start &&
          segment.start_time <= region.end
        ) {
          // this is the case where first half of segment is in region
          let duplicateSegment = { ...segment, start_time: region.end };
          let duplicateAnnotations = [];
          segment.annotations.forEach((annot) => {
            let newAnnot = { ...annot };
            if (annot.start_time >= region.end) {
              duplicateAnnotations.push(newAnnot);
            }
          });
          duplicateSegment.annotations = [];
          duplicateSegment.annotations = [...duplicateAnnotations];
          testResultstat.speakers_segments.push(duplicateSegment);
        } else if (
          segment.start_time < region.start &&
          segment.end_time >= region.start &&
          segment.end_time < region.end
        ) {
          // this is the case where later half of segment is in region
          let duplicateSegment = { ...segment, end_time: region.start };
          let duplicateAnnotations = [];
          segment.annotations.forEach((annot) => {
            let newAnnot = { ...annot };
            if (annot.end_time <= region.start) {
              duplicateAnnotations.push(newAnnot);
            }
          });
          duplicateSegment.annotations = [];
          duplicateSegment.annotations = [...duplicateAnnotations];
          testResultstat.speakers_segments.push(duplicateSegment);
        } else if (
          segment.start_time < region.start &&
          segment.end_time >= region.end
        ) {
          // in this case we have to break segment into 3 segments
          let firstDuplicateSegment = { ...segment, end_time: region.start }; // first half track
          let secondDuplicateSegment = { ...segment, start_time: region.end }; // third half track, second one is the region track itself
          // we need to divide annotations as well
          let firstDuplicateSegmentAnnotations = [];
          let secondDuplicateSegmentAnnotaions = [];
          segment.annotations.forEach((annot) => {
            let newAnnot = { ...annot };
            if (annot.end_time <= region.start) {
              firstDuplicateSegmentAnnotations.push(newAnnot);
            } else if (annot.start_time >= region.end) {
              secondDuplicateSegmentAnnotaions.push(newAnnot);
            }
          });
          firstDuplicateSegment.annotations = [];
          firstDuplicateSegment.annotations = [
            ...firstDuplicateSegmentAnnotations,
          ];
          secondDuplicateSegment.annotations = [];
          secondDuplicateSegment.annotations = [
            ...secondDuplicateSegmentAnnotaions,
          ];
          testResultstat.speakers_segments.push(firstDuplicateSegment);
          testResultstat.speakers_segments.push(secondDuplicateSegment);
        }
      }
    });
    /***************************This code is for pushing the segments into resulstat and creating new segments based on change speaker region ********************/

    /********************change id of changed annotations according to new speaker id***********************************************/
    let newChangedAnnots = [];
    changedAnnot.forEach((annot) => {
      let newID = annot.id.split("_");
      let speakerNumber = tracktype.split("-")[1];
      let newId = newID[0] + "_" + speakerNumber + "_" + newID[2];
      let newSpeakerLabel = newID[0] + "_" + speakerNumber;
      let newAnnot = { ...annot, id: newId, speaker_label: newSpeakerLabel };
      newChangedAnnots.push(newAnnot);
      //change annot color after change speaker
      let annotdata = document.getElementById(annot.id + "_div");
      if (annotdata) {
        annotdata.classList.remove("clicked-annot", "active-annot");
        annotdata.classList.add("inactive-annot");
      }
    });
    /********************change id of changed annotations according to new speaker id***********************************************/

    //create new segment for change speaker track and push it in resultstat
    newSegment = {
      ...matchingSegment,
      start_time: region.start,
      end_time: region.end,
      id: create_UUID(),
    };
    newSegment.annotations = [];
    newSegment.annotations = [...newChangedAnnots];
    testResultstat.speakers_segments.push(newSegment);
    testResultstat.speakers_segments.sort((a, b) => {
      return a.start_time - b.start_time;
    });

    /******************This code will merge continuous same speakers segments****************************/
    let newSegments = [];
    newSegments = await mergeSegments(testResultstat);
    deleteannotation(changedAnnot);

    /******************This code will merge continuous same speakers segments****************************/

    // change the annotations in the drag and add to new changed track
    resultStat.speakers_segments = []; // empty the new resultstat segments
    resultStat.speakers_segments = [...newSegments];
    let operationId = create_UUID();
    let params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "changeSpeaker",
      inputs: {
        startTime: region.start,
        endTime: region.end,
        customClass: tracktype,
        selectedCustomClasses,
        trackId: undefined,
      },
    };
    resultStat.refid = operationId;
    annotUIshift(resultStat);
    await enableButtonAsync();
    restThings(params, resultStat);
  }

  const changeSpeaker = () => {
    let resultStat = store.getState().projectData.resultStat;
    if (!resultStat.transcribed) {
      showStatText("This operation require transcription");
      return;
    }

    const region =
      props.wavesurferObj.regions.list[
        Object.keys(props.wavesurferObj.regions.list)[0]
      ];

    if (!region) {
      if (
        document.getElementById("change-speakers").style.visibility == "visible"
      ) {
        document.getElementById("change-speakers").style.visibility = "hidden";
        document.getElementById("control-mode").classList.remove("none");
        document.getElementById("control-mode").style.display = "flex";
        document.getElementById("btn-spk-icon").src =
          "/image/workspace/controls/speakers.png";
      } else {
        showStatText("select segments to change speakers");
      }
      return false;
    }
    document.getElementById("btn-insert-icon").src = iconsData.paste.inactive;
    document.getElementById("waveforms-selector").src =
      iconsData.fades.inactive;
    document.getElementById("btn-insert-alt-icon").src =
      iconsData.insert.inactive;

    document.getElementById("change-speakers").innerHTML = "";

    // change classes of other buttons except speakers

    //fades
    if (
      document.getElementById("change-speakers").style.visibility == "visible"
    ) {
      document.getElementById("change-speakers").style.visibility = "hidden";
      document.getElementById("control-mode").classList.remove("none");
      document.getElementById("control-mode").style.display = "flex";
      document.getElementById("btn-spk-icon").src =
        "/image/workspace/controls/speakers.png";
      document.getElementById("change-speakers").style.display = "none";
      return;
    } else {
      document.getElementById("change-speakers").style.visibility = "visible";
      document.getElementById("control-mode").classList.remove("d-flex");
      document.getElementById("control-mode").style.display = "none";
      document.getElementById("btn-spk-icon").src =
        "/image/workspace/active-icons/speakers.png";
      document.getElementById("change-speakers").style.display = "flex";
    }
    if (document.getElementById("sub-menus").style.visibility === "visible") {
      document.getElementById("sub-menus").style.visibility = "hidden";
    }
    if (
      document.getElementById("sub-menus-fade").style.visibility === "visible"
    ) {
      document.getElementById("sub-menus-fade").style.visibility = "hidden";
    }

    if (
      document.getElementById("sub-menus-insert").style.visibility === "visible"
    ) {
      document.getElementById("sub-menus-insert").style.visibility = "hidden";
    }
    document
      .getElementById("btn-waveform-selector")
      .classList.replace("waveform-enable", "waveform-disable");
    // insert
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document
      .getElementById("btn-insert")
      .classList.replace("insert-menu-open", "insert-menu-closed");
    document
      .getElementById("btn-insert-alt")
      .classList.replace("insert-alt-menu-open", "insert-alt-menu-closed");
    document
      .getElementById("btn-record")
      .classList.replace("record-enabled", "record-disabled");
    document
      .getElementById("btn-import")
      .classList.replace("import-enabled", "import-disabled");
    //controlMode()
    //resetFades()

    let changeSpeakerIconSize = (24 / 1440) * window.innerWidth + "px";
    $("#btn-spk-icon").css({ width: changeSpeakerIconSize });
    Object.keys(resultStat.speakers).forEach((cc, index) => {
      const className = "active-track-speaker";
      let name = resultStat.speakers[cc].name;
      const image =
        "https://ui-avatars.com/api/?name=" +
        name +
        "&background=BB417C&rounded=true&size=31&color=fff";
      const div =
        '<div class="speaker-icon-spacing ' +
        className +
        '" style="padding: 0px 1.5rem;"><a href="#" id="cm-change-spk-' +
        index +
        '"> ' +
        '<img title="' +
        name +
        '" src="' +
        image +
        '" class="spk-slid">' +
        "</a></div>";
      // 'speakers' === 'speakers'
      //     ? '<div title="' +'speaker '+ (index+1) + '" class="speaker-icon-spacing ' + className + '" style="padding: 0px 1.5rem;"><a href="#" id="cm-change-spk-' + index + '"> ' + '<img title="' + 'speaker '+ (index+1)  + '" src="' + image + '" class="spk-slid">' + '</a></div>'
      //     : '<div title="' + 'speaker '+ (index+1) + '" class="speaker-icon-spacing ' + className + '" style="padding: 0px 1.5rem;"><a href="" id="cm-change-spk-' + index + '"> ' + '<span class="sfx-text"> SFX' + '<sup>' + (index + 1) + '</sup>' + '</span>' + '</a></div>'
      const activeTrack = document.getElementById(`speakers-${index}-slider`);
      setTimeout(() => {
        if (activeTrack.classList.contains("slick-current")) {
          const activeSpk = document.getElementById(`cm-change-spk-${index}`);
          if (activeSpk) {
            activeSpk.classList.add("disabled-spk");
          }
        }
      });

      $("#change-speakers").append(div);
      $("#cm-change-spk-" + index).on("click", function (event) {
        event.preventDefault();
        changeSpeakerOperation(`speakers-${index}`);
        document.getElementById("change-speakers").style.visibility = "hidden";
        document.getElementById("control-mode").classList.remove("none");
        document.getElementById("control-mode").style.display = "flex";
        document.getElementById("btn-spk-icon").src =
          iconsData.speakers.inactive;
        document.getElementById("change-speakers").style.display = "none";
      });
    });
  };
  // const multiselect = useSelector(state =>state.multiSelectedWords.isMultiselect)
  const multiSelectOperation = () => {
    const multiselect = store.getState().multiSelectedWords.isMultiselect;
    hideZoomControls();
    if (sessionStorage.getItem("transcriptUploadOption") == "false") {
      showStatText(
        "operation disabled as transcription is not available for this project",
      );
      return;
    }
    if (!multiselect) {
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: true }));
    } else {
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
    }
  };

  const [nestedTransriptionModal, setNestedTranscriptionModal] =
    useState(false);
  const [file, setFile] = useState(null);

  function closeTranscriptionModal() {
    setNestedTranscriptionModal(false);
    setActiveImportRecord("");
  }
  function handleFileImportFunction(file, selectedRangeForSfx = 0) {
    if (file) {
      hideZoomControls();
      setActiveImportRecord("import");
      if (lastclicked.clickedregion == "SFX") {
        handleFileImport(file, selectedRangeForSfx);
      } else if (lastclicked.clickedregion == "wav") {
        setNestedTranscriptionModal(true);
        setFile(file);
      }
    }
  }
  function handleTranscriptionYes() {
    let transcriptionSelected = true;
    sessionStorage.setItem(
      "NestedtranscriptUploadOption",
      JSON.stringify(transcriptionSelected),
    );

    activeImportRecord == "import"
      ? handleFileImport(file, 0, true)
      : handleRecordImport(nestedRecordFile, 0, true);
    closeTranscriptionModal();
  }

  function handleTranscriptionNo() {
    let transcriptionSelected = false;
    sessionStorage.setItem(
      "NestedtranscriptUploadOption",
      JSON.stringify(transcriptionSelected),
    );
    activeImportRecord == "import"
      ? handleFileImport(file, 0, false)
      : handleRecordImport(nestedRecordFile, 0, false);
    closeTranscriptionModal();
  }
  const [nestedRecord, setNestedRecord] = useState(false);
  async function handleFileImport(file, selectedRangeForSfx = 0, transcribed) {
    let copiedAnnotations = [];
    setIsFileImported(true);
    setNestedRecord(true);
    setFile(null);
    store.dispatch(copiedAnnotActions.updCopiedFrom(lastclicked.clickedregion));
    const wavesurferObjForNestedImport = WaveSurfer.create({
      container: "#nested-file-import",
      waveColor: "#8A2BE2",
    });

    // Load the file into the WaveSurfer object
    await new Promise((resolve) => {
      wavesurferObjForNestedImport.load(URL.createObjectURL(file));
      wavesurferObjForNestedImport.on("ready", resolve);
    });

    // Get the duration of the audio file
    let duration =
      selectedRangeForSfx != 0
        ? selectedRangeForSfx
        : wavesurferObjForNestedImport.getDuration();
    const originalBuffer = wavesurferObjForNestedImport.backend.buffer;
    const copiedBuffer = new Float32Array(duration * originalBuffer.sampleRate);
    dispatch(setSfxDurationSliceActions.setSfxDuration(duration));
    // Copy the audio data into the new buffer
    originalBuffer.copyFromChannel(
      copiedBuffer,
      0,
      Math.floor(0 * originalBuffer.sampleRate),
    );
    // Save the copied data in the component state or wherever appropriate
    // For example, you could use state or a global variable
    // Assuming you have a state variable copyBuffer and setCopyBuffer function
    let annot = {
      confidence: 1,
      content: transcribed ? "Transcribing " + file.name : file.name,
      end_time: duration,
      is_silenced: false,
      speaker_label: "speakers_0",
      start_time: 0,
      transcribed,
    };
    copiedAnnotations.push(annot);
    dispatch(copiedAnnotActions.updAnnotclk({ copiedAnnotations }));
    dispatch(CopyBufferActions.updateCopyBuffer({ copiedBuffer }));
    insertOperation();
    //setCopyBuffer(copiedBuffer);
  }

  // handle nested Recording
  function handleNestedRecord() {
    store.dispatch(
      projectTitleSliceActions.updateProjectTitleEnv({
        env: "NestedRecording",
      }),
    );

    // document.getElementById('wrapper').style.opacity ='0.2'
    setNestedRecordBtnClicked(true);
    dispatch(
      recordSpaceBtnActions.storeBtnClickedEnv({
        env: "workspaceToRecordspace",
      }),
    );
  }
  function closeFlagModal() {
    setShowFlagModal(false);
  }
  function openAutolevelModal() {
    const permResponse = ACM.checkPermission("auto-level");
    if (!permResponse.success) return showStatText(permResponse.message);
    setShowAutoLEvelModal(true);
  }
  function closeAutoLevelModal() {
    setShowAutoLEvelModal(false);
  }
  function openModalForNestedRecord() {
    setModalIsOpenForNestedRecord(true);
  }

  const handleChange1 = (event) => {
    setValueForNestedRecording(event.target.value);
  };

  function closeModal1() {
    setModalIsOpenForNestedRecord(false);
  }
  const [activeImportRecord, setActiveImportRecord] = useState("");
  async function handleRecordFileFromRecordspace(file, audioDuration) {
    // let duration = await getDuration(URL.createObjectURL(file))
    setCopyBuffer(null);
    setDurationNestedRecord(audioDuration);
    setNestedRecordBtnClicked(false);
    setNestedRecordFile(file);
    setActiveImportRecord("record");
    store.dispatch(copiedAnnotActions.updCopiedFrom(lastclicked.clickedregion));
    if (lastclicked.clickedregion == "SFX") {
      dispatch(setSfxDurationSliceActions.setSfxDuration(audioDuration));
      handleRecordImport(file, 0, false);
    } else {
      setNestedTranscriptionModal(true);
    }
  }

  function handleNestedRecordSfxValue(value) {
    setModalIsOpenForNestedRecord(false);
    handleRecordImport(nestedRecordFile, value);
  }

  function openFade(fadeType) {
    setFadeInOut(fadeType);
    let currentsegment;
    if (lastclicked.clickedregion == "SFX") {
      currentsegment = props.wavesurferObjSFX;
    } else {
      currentsegment = props.wavesurferObj;
    }
    const region =
      currentsegment.regions.list[Object.keys(currentsegment.regions.list)[0]];
    if (!region) {
      showStatText("select a region for fade", false);
      return;
    }
    fadeOperation();
    if (
      document.getElementById("sub-menus-section").style.visibility == "visible"
    ) {
      document.getElementById("sub-menus-section").style.visibility = "hidden";
      document.getElementById("control-mode").classList.remove("none");
      document.getElementById("control-mode").style.display = "flex";
    } else {
      document.getElementById("sub-menus-section").style.visibility = "visible";
      document.getElementById("control-mode").classList.remove("d-flex");
      document.getElementById("control-mode").style.display = "none";
    }
  }

  async function handleRecordImport(
    file,
    selectedRangeForSfx = 0,
    transcribed,
  ) {
    hideZoomControls();
    let copiedAnnotations = [];
    setIsFileImported(true);
    setdisableModalForNestedRec(true);
    setNestedRecordFile(null);
    const nestedRecordTitle = store.getState().projectTitle.nestedRecordTitle;

    const wavesurferObjForNestedRecord = WaveSurfer.create({
      container: "#nested-file-import",
      waveColor: "#8A2BE2",
    });

    // Load the file into the WaveSurfer object
    await new Promise((resolve) => {
      wavesurferObjForNestedRecord.load(URL.createObjectURL(file));
      wavesurferObjForNestedRecord.on("ready", resolve);
    });

    // Get the duration of the audio file
    let duration =
      selectedRangeForSfx != 0
        ? selectedRangeForSfx
        : wavesurferObjForNestedRecord.getDuration();

    const originalBuffer = wavesurferObjForNestedRecord.backend.buffer;
    const copiedBuffer = new Float32Array(duration * originalBuffer.sampleRate);

    // Copy the audio data into the new buffer
    originalBuffer.copyFromChannel(
      copiedBuffer,
      0,
      Math.floor(0 * originalBuffer.sampleRate),
    );
    //setCopyBuffer(copiedBuffer);

    let annot = {
      confidence: 1,
      // content: transcribed
      //   ? "Transcribing " + "New Recording"
      //   : "New Recording",
      content: transcribed
        ? `Transcribing  ${nestedRecordTitle} `
        : `${nestedRecordTitle} `,
      end_time: duration,
      is_silenced: false,
      speaker_label: "speakers_0",
      start_time: 0,
      transcribed,
    };
    copiedAnnotations.push(annot);
    dispatch(copiedAnnotActions.updAnnotclk({ copiedAnnotations }));
    dispatch(CopyBufferActions.updateCopyBuffer({ copiedBuffer }));
    pasteOperation();
  }

  function closeNestedRecording() {
    setNestedRecordBtnClicked(false);
  }
  function removeRegion() {
    const regionaudio = props.wavesurferObj.regions.list;
    const regionSFX = props.wavesurferObjSFX.regions.list;
    const regionGlobal = props.wavesurferObjGlobal.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    for (const regionKey in regionGlobal) {
      regionGlobal[regionKey].remove();
    }
  }
  function pushToStart() {
    document.getElementById("btn-rewind").src = iconsData.push_to_start.active;
    hideZoomControls();
    handleHorizontalScroll(0);
    props.wavesurferObj.seekTo(0);
    updateCurrentTime();
    props.wavesurferObjSFX.seekTo(0);
    props.wavesurferObjGlobal.seekTo(0);
    resetAnnotation();
    removeRegion();
    props.wavesurferObj.pause();
    props.wavesurferObjGlobal.pause();
    props.wavesurferObjSFX.pause();
    store.dispatch(viewEditSliceActions.updatePlayPauseMode({ value: true }));
    setTimeout(() => {
      document.getElementById("btn-rewind").src =
        iconsData.push_to_start.inactive;
    }, 100);
  }
  function pushToEnd() {
    document.getElementById("btn-forward").src = iconsData.push_to_end.active;
    hideZoomControls();
    handleHorizontalScroll(
      convertTimeToPixel(props.wavesurferObj.getDuration()),
    );
    props.wavesurferObj.seekTo(1);
    updateCurrentTime();
    props.wavesurferObjSFX.seekTo(1);
    props.wavesurferObjGlobal.seekTo(1);
    resetAnnotation();
    removeRegion();
    props.wavesurferObj.pause();
    props.wavesurferObjGlobal.pause();
    props.wavesurferObjSFX.pause();
    store.dispatch(viewEditSliceActions.updatePlayPauseMode({ value: true }));
    setTimeout(() => {
      document.getElementById("btn-forward").src =
        iconsData.push_to_end.inactive;
    }, 100);
  }
  function getCurrentPosition(wavesurferObj) {
    return wavesurferObj.getCurrentTime();
  }

  function restoreCursorPositions(cursorPosition) {
    props.wavesurferObj.seekTo(
      cursorPosition / props.wavesurferObj.getDuration(),
    );
    props.wavesurferObjSFX.seekTo(
      cursorPosition / props.wavesurferObj.getDuration(),
    );
    props.wavesurferObjGlobal.seekTo(
      cursorPosition / props.wavesurferObj.getDuration(),
    );
  }
  async function addGenaiSfxMusic(result) {
    showplaylistloader();
    let copiedAnnotations = [];
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    let context = new AudioContext();
    let sampleRate = context.sampleRate;
    let samplesPerPixel = parseInt((256 * 1440) / window.innerWidth);
    let pixPerSecond = sampleRate / samplesPerPixel;
    const wavesurferObjGenai = WaveSurfer.create({
      container: "#nested-file-import",
      waveColor: "#8A2BE2",
    });
    // closeModal();
    // stopSfxOnLeave();
    let arrayBuffer;
    try {
      arrayBuffer = await getArrayBufferFromSrcURL(result.signedUrlCompressed);
    } catch {
      hideplaylistloader();
      // return;
    }

    const audioBlob = new Blob([arrayBuffer]);
    const blobURL = URL.createObjectURL(audioBlob);
    wavesurferObjGenai.load(blobURL);

    wavesurferObjGenai.on("ready", function () {
      // Get the duration of the audio file
      let duration = wavesurferObjGenai.getDuration();
      dispatch(setSfxDurationSliceActions.setSfxDuration(duration));
      // Update the span with the actual duration

      const originalBuffer = wavesurferObjGenai.backend.buffer;
      const copiedBuffer = new Float32Array(
        duration * originalBuffer.sampleRate,
      );

      // Copy the audio data into the new buffer
      originalBuffer.copyFromChannel(
        copiedBuffer,
        0,
        Math.floor(0 * originalBuffer.sampleRate),
      );

      dispatch(CopyBufferActions.updateCopyBuffer({ copiedBuffer }));
      let annot = {
        confidence: 1,
        content: result.description,
        end_time: "",
        id: `sfx_0_${create_UUID()}`,
        is_silenced: false,
        speaker_label: "sfx_0",
        start_time: "",
      };
      let sfxseg = {
        annotations: [annot],
        backend_filename: "",
        cueIn: 0,
        cueOut: 3,
        end_time: "",
        fades: [],
        filename: "",
        id: create_UUID(),
        speaker_label: "sfx-0",
        src: "",
        start_time: "",
        sub_pannings: [],
        sub_volumes: [],
        to_upload: true,
      };
      copiedAnnotations.push(sfxseg);
      dispatch(copiedAnnotActions.updAnnotclk({ copiedAnnotations }));
      dispatch(customGenaiSliceActions.updateGenAIsfxMusic({ value: "" }));
      dispatch(copiedAnnotActions.updCopiedFrom("SFX"));
      hideplaylistloader();
      pasteOperation(true);
    });
  }
  return (
    <>
      <div className="controls" id="controls" style={{ visibility: "hidden" }}>
        <center>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                id="control-mode"
                className="control-options d-flex justify-content-center"
              >
                <div
                  className={`control-undo btn-undo tt mx-3 disableBtnClass `}
                  onClick={() => undo()}
                  id="undo"
                  data-bs-placement="left"
                  title="undo"
                >
                  <img src="/image/undo-active.svg" id="undoBtn" alt="" />
                </div>

                <div
                  className={`control-opt-preview mx-3 disableBtnClass `}
                  id="control-opt-preview"
                  onClick={() => viewControls(true)}
                >
                  View
                </div>

                <div className="control-separator  mx-3">
                  <img id="modeSep" src="/image/control-separator.svg" alt="" />
                </div>

                <div
                  className={`control-opt-edit mx-3 disableBtnClass `}
                  id="control-opt-edit"
                  onClick={() => editControls()}
                >
                  Edit
                </div>

                <div
                  className={`control-redo btn-redo mx-3 tt disableBtnClass `}
                  onClick={() => redo()}
                  id="redo"
                  data-bs-placement="right"
                  title="redo"
                >
                  <img src="/image/redo-active.svg" id="redoBtn" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div id="top-bar" className="row playlist-top-bar">
            <div className="playlist-toolbar">
              <div
                className="d-flex justify-content-center m-4"
                id="workspace-controls"
              >
                <div
                  className={`${"edit-control-options d-flex justify-content-center align-items-center"} ${
                    isDisabled && !runEditFeaturesTour ? "disabled-ctrl" : ""
                  }`}
                  id="edit-control-options-left"
                >
                  {/* Cut */}
                  <div
                    className=" cut icon-spacing disableBtnClass icon-cont tt btn-hook"
                    id="btn-cut"
                    data-bs-placement="bottom"
                    title="cut"
                  >
                    <img
                      src="/image/workspace/controls/cut.png"
                      id="btn-cut-icon"
                      onClick={() => handleCut()}
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  {/* Silence */}
                  <div
                    className=" silence icon-spacing disableBtnClass icon-cont tt btn-hook"
                    id="btn-silence-cont"
                    data-bs-placement="bottom"
                    title="silence"
                  >
                    <img
                      src="/image/workspace/controls/silence.png"
                      id="btn-silence"
                      onClick={handleInsertSilence}
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  {/* Copy */}
                  <div
                    className={`copy icon-spacing disableBtnClass icon-cont tt btn-hook  `}
                    id="btn-copy-cont"
                    onClick={() => {
                      handleCopy("");
                    }}
                    data-bs-placement="bottom"
                    title="copy"
                  >
                    <img
                      src="/image/workspace/controls/copy.png"
                      id="btn-copy"
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  {/* Paste */}
                  <div
                    className=" insert insert-menu-closed icon-spacing disableBtnClass icon-cont tt"
                    id="btn-insert"
                    data-bs-placement="bottom"
                    title="paste"
                  >
                    <img
                      src="/image/workspace/controls/paste.png"
                      className={`transport-btn  `}
                      onClick={pasteOperation}
                      id="btn-insert-icon"
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  {/* Insert */}
                  <div
                    className=" insert-alt insert-alt-menu-closed icon-spacing disableBtnClass icon-cont tt"
                    id="btn-insert-alt"
                    data-bs-placement="bottom"
                    title="insert"
                  >
                    <img
                      src="/image/workspace/controls/insert.png"
                      className={`transport-btn  `}
                      onClick={insertOperation}
                      id="btn-insert-alt-icon"
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="justify-content-center align-items-center">
                    <div className="preview-control-options enabled-ctrl">
                      {/* LOOP */}
                      <div
                        className="btn-loop loopInactive icon-spacing disableBtnClass icon-cont tt btn-hook"
                        id="btn-loop"
                        data-bs-placement="bottom"
                        title="loop"
                      >
                        <img
                          src="/image/workspace/controls/loop.png"
                          id="btn-loop-icon"
                          onClick={handleToggleLoop}
                          alt=""
                          style={{ width: "1.4vw" }}
                        />
                      </div>

                      {/* PUSH-TO-START */}
                      <div
                        className="btn-rewind icon-spacing icon-cont disableBtnClass tt btn-hook"
                        id="btn-rewind-cont"
                        data-bs-placement="bottom"
                        title="push to start"
                      >
                        <img
                          src="/image/workspace/controls/push_to_start.png"
                          id="btn-rewind"
                          alt=""
                          style={{ width: "1.4vw" }}
                          onClick={pushToStart}
                        />
                      </div>

                      {/* PLAY */}
                      <div
                        className={`play btn-play icon-spacing disableBtnClass icon-cont tt btn-hook  `}
                        id="btn-play"
                        onClick={handlePlayPause}
                        data-bs-placement="bottom"
                        title="play"
                      >
                        <img
                          src="/image/workspace/controls/play.png"
                          id="btn-play-icon"
                          alt=""
                          style={{ width: "1.4vw" }}
                        />
                      </div>

                      {/* PUSH-TO-END */}
                      <div
                        className="btn-forward icon-spacing disableBtnClass icon-cont tt btn-hook"
                        id="btn-forward-cont"
                        data-bs-placement="bottom"
                        title="push to end"
                      >
                        <img
                          src="/image/workspace/controls/push_to_end.png"
                          id="btn-forward"
                          alt=""
                          style={{ width: "1.4vw" }}
                          onClick={pushToEnd}
                        />
                      </div>

                      {/* FLAG */}
                      <div
                        className="flag icon-spacing disableBtnClass icon-cont tt btn-hook"
                        id="cm-flag"
                        data-bs-placement="bottom"
                        title="add flag"
                      >
                        <img
                          src="/image/workspace/controls/flag.png"
                          id="btn-flag"
                          alt=""
                          style={{ width: "1.4vw" }}
                          onClick={addFlag}
                        />
                      </div>

                      {/* Annotations */}
                      {/* <div
                        className={`${"disable annotations button icon-spacing disableBtnClass icon-cont tt btn-hook"} ${
                          isDisabled ? "disabled-ctrl" : ""
                        } `}
                        id="btn-annotations"
                        onClick={multiSelectOperation}
                        data-bs-placement="bottom"
                        title="multiselect"
                      >
                        <img
                          src="/image/workspace/controls/annotations.png"
                          id="btn-annot-icon"
                          alt=""
                          style={{ width: "1.4vw" }}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>

                <div
                  className={`${"edit-control-options d-flex justify-content-center align-items-center"} ${
                    isDisabled && !runEditFeaturesTour ? "disabled-ctrl" : ""
                  }`}
                  id="edit-control-options-right"
                >
                  <div
                    className={` spk-change speakers-change-disabled icon-spacing disableBtnClass icon-cont tt btn-hook  `}
                    id="speakers-change"
                    onClick={() => changeSpeaker()}
                    data-bs-placement="bottom"
                    title="change speaker"
                  >
                    <img
                      src="/image/workspace/controls/speakers.png"
                      className="transport-btn"
                      id="btn-spk-icon"
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  <div
                    className="btn-fades waveform-disable icon-spacing disableBtnClass icon-cont tt btn-hook"
                    id="btn-waveform-selector"
                    onClick={fadeOperation}
                    data-bs-placement="bottom"
                    title="fades"
                  >
                    <img
                      src="/image/workspace/controls/fades.png"
                      className="transport-btn"
                      id="waveforms-selector"
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  <div
                    className={` btn-record record-disabled icon-spacing disableBtnClass icon-cont tt btn-hook  `}
                    onClick={handleNestedRecord}
                    id="btn-record"
                    data-bs-placement="bottom"
                    title="record"
                  >
                    <img
                      src="/image/workspace/controls/record.png"
                      className="transport-btn"
                      id="btn-record-icon"
                      alt=""
                      style={{ width: "1.4vw" }}
                    />
                  </div>

                  <div
                    className={`import import-disabled icon-spacing disableBtnClass icon-cont tt btn-hook  `}
                    id="btn-import"
                    data-bs-placement="bottom"
                    title="import"
                  >
                    {/* <img src="/image/workspace/controls/import_track.png" className="transport-btn" id="btn-import-icon" alt="" /> */}
                    <FileUploader
                      imgSrc="/image/workspace/controls/import_track.png"
                      src="workspace"
                      onFileImport={handleFileImportFunction}
                      style={{ width: "1.4vw" }}
                    />
                    {nestedTransriptionModal && (
                      <TranscriptionModal
                        onClose={closeTranscriptionModal}
                        onTranscriptYes={handleTranscriptionYes}
                        onTranscriptNo={handleTranscriptionNo}
                      ></TranscriptionModal>
                    )}
                  </div>

                  <div
                    className="icon-spacing disableBtnClass icon-cont btn-hook tooltip-magic-btn"
                    id="btn-magic"
                    onClick={magicbutton}
                  >
                    <ul
                      className="dd-magic dropdown-content"
                      style={{ display: "none" }}
                    >
                      <li
                        className="dd-item dd-item-auto-level"
                        id="btn-auto-level"
                      >
                        <img
                          title="Auto Level Sounds"
                          onClick={openAutolevelModal}
                          src="/image\workspace\auto-level.png"
                          // style={{
                          //   height: "max-content",
                          //   width: "1.5vw",
                          //   marginBottom: "2rem",
                          // }}
                          alt=""
                        />
                      </li>
                      <li className="dd-item" id="btn-auto-filter">
                        <img
                          title="Auto Filter Words"
                          onClick={autoFilterWords}
                          src="/image\workspace\filter-words.png"
                          // style={{
                          //   height: "max-content",
                          //   width: "1.5vw",
                          //   marginBottom: "2rem",
                          // }}
                          alt=""
                        />
                      </li>
                      <li className="dd-item" id="btn-long-gaps">
                        <img
                          title="Auto Filter Gaps"
                          onClick={openModal}
                          src="/image\workspace\filter-long-gaps.png"
                          // style={{
                          //   height: "max-content",
                          //   width: "1.5vw",
                          //   marginBottom: "2rem",
                          // }}
                          alt=""
                        />
                      </li>
                    </ul>
                    <img
                      src="/image/magic.png"
                      className="transport-btn"
                      id="btn-magic-icon"
                      alt=""
                      title="Magic Button"
                      style={{ width: "1.4vw" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showFlagModal && <FlagModal onClose={closeFlagModal} />}
          {showAutoLEvelModal && (
            <AutoLevelModal onClose={closeAutoLevelModal} />
          )}
          {nestedRecordBtnClicked &&
            createPortal(
              <div className="nested-recording-container">
                <Recordingspace
                  env="workspace"
                  className=""
                  onClose={closeNestedRecording}
                  onSelect={handleRecordFileFromRecordspace}
                ></Recordingspace>
              </div>,
              document.querySelector("body"),
            )}

          <div id="message" style={{ display: "none" }}></div>
          <div
            className="nested-file-import"
            id="nested-file-import"
            style={{ visibility: "hidden", position: "fixed" }}
          />
        </center>
      </div>
      <div
        className="sub-menus-section"
        id="sub-menus-section"
        style={{
          visibility: "hidden",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div
          className="justify-content-center text-white sub-menu-item"
          id="waveforms-sub-menu"
          style={{ width: "fit-content" }}
        >
          <div
            className="text-center fade-expo tt"
            id="exponential"
            style={{
              position: "absolute",
              zIndex: 10,
              marginLeft: "-7vw",
            }}
            data-bs-placement="top"
            title="exponential"
          >
            <img
              src="/image/workspace/controls/exponential.png"
              className="text-center fade-icons"
              onClick={() => applyFade("exponential")}
              style={{ cursor: "pointer" }}
              alt="exponential"
            />
          </div>
          <div
            className="text-center fade-line tt"
            id="linear"
            style={{
              position: "absolute",
              zIndex: 10,
              marginLeft: "1vw",
            }}
            data-bs-placement="top"
            title="linear"
          >
            <img
              src="/image/workspace/controls/linear.png"
              className="text-center fade-icons"
              onClick={() => applyFade("linear")}
              style={{ cursor: "pointer" }}
              alt="linear"
            />
          </div>
          <div
            className="text-center fade-log tt"
            id="logarithmic"
            style={{
              position: "absolute",
              zIndex: 10,
              marginLeft: "-3vw",
            }}
            data-bs-placement="top"
            title="logarithmic"
          >
            <img
              src="/image/workspace/controls/logarithmic.png"
              className="text-center fade-icons"
              onClick={() => applyFade("logarithmic")}
              style={{ cursor: "pointer" }}
              alt="logarithmic"
            />
          </div>
          <div
            className="text-center fade-curve tt"
            id="sCurve"
            style={{
              position: "absolute",
              zIndex: 10,
              marginLeft: "5vw",
            }}
            data-bs-placement="top"
            title="sCurve"
          >
            <img
              src="/image/workspace/controls/scurve.png"
              className="text-center fade-icons"
              onClick={() => applyFade("sCurve")}
              style={{ cursor: "pointer" }}
              alt="sCurve"
            />
          </div>
        </div>
      </div>

      <div
        className="sub-menus"
        id="sub-menus"
        style={{
          visibility: "hidden",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div
          className="justify-content-center sub-menu-item align-items-center"
          id="paste-sub-menu"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            gap: "34px",
          }}
        >
          <div
            className="text-center insert-before d-flex tt disableBtnClass"
            data-bs-placement="left"
            title="paste before"
            style={{ zIndex: 10 }}
          >
            <img
              className="paste-before-icon"
              src="/image/workspace/controls/Paste before.png"
              onClick={() => handlePasteandInsertOperation("before", "paste")}
              style={{
                height: "0.9765625vw",
                width: "2.0833333333333335vw",
                cursor: "pointer",
              }}
              alt="Paste before"
            />
          </div>
          <div className="text-center" style={{ zIndex: 10 }}>
            <span
              className="paste-icon"
              id="paste-center"
              onClick={() => handlePasteandInsertOperation("center", "paste")}
              style={{ cursor: "pointer", fontSize: "1.0416666666666667vw" }}
            >
              paste
            </span>
          </div>
          <div
            className="text-center paste-after d-flex tt disableBtnClass"
            data-bs-placement="right"
            title="paste after"
            style={{ zIndex: 10 }}
          >
            <img
              className="paste-after-icon"
              src="/image/workspace/controls/Paste After.png"
              onClick={() => handlePasteandInsertOperation("after", "paste")}
              style={{
                height: "0.9765625vw",
                width: "2.0833333333333335vw",
                cursor: "pointer",
              }}
              alt="Paste after"
            />
          </div>
        </div>
      </div>

      <div
        className="sub-menus-insert"
        id="sub-menus-insert"
        style={{
          visibility: "hidden",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div
          className="justify-content-center sub-menu-item align-items-center"
          id="insert-sub-menu"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            gap: "34px",
          }}
        >
          <div
            className="text-center insert-before d-flex tt disableBtnClass"
            data-bs-placement="left"
            title="insert before"
            style={{ zIndex: 10 }}
          >
            <img
              className="insert-before-icon"
              src="/image/workspace/controls/Paste before.png"
              onClick={() => handlePasteandInsertOperation("before", "insert")}
              style={{
                height: "0.9765625vw",
                width: "2.0833333333333335vw",
                cursor: "pointer",
              }}
              alt="Paste before"
            />
          </div>
          <div className="text-center" style={{ zIndex: 10 }}>
            <span
              className="insert-icon"
              id="paste-center"
              // onClick={() => handlePaste('center')}
              style={{ cursor: "pointer", fontSize: "1.0416666666666667vw" }}
            >
              Insert
            </span>
          </div>
          <div
            className="text-center insert-after d-flex tt disableBtnClass"
            data-bs-placement="right"
            title="insert after"
            style={{ zIndex: 10 }}
          >
            <img
              className="insert-after-icon"
              src="/image/workspace/controls/Paste After.png"
              onClick={() => handlePasteandInsertOperation("after", "insert")}
              style={{
                height: "0.9765625vw",
                width: "2.0833333333333335vw",
                cursor: "pointer",
              }}
              alt="insert after"
            />
          </div>
        </div>
      </div>
      <div
        className="sub-menus-fade"
        id="sub-menus-fade"
        style={{
          visibility: "hidden",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div
          className="justify-content-center sub-menu-item align-items-center"
          id="insert-sub-menu"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            gap: "34px",
          }}
        >
          <div
            className="text-center insert-before d-flex tt disableBtnClass"
            data-bs-placement="left"
            title="fade in"
            style={{ zIndex: 10 }}
          >
            <span
              className="insert-icon"
              id="paste-center"
              onClick={() => openFade("fadein")}
              style={{ cursor: "pointer", fontSize: "1.0416666666666667vw" }}
            >
              Fade in
            </span>
          </div>
          <div className="control-separator  mx-3">
            <img id="modeSep" src="/image/control-separator.svg" alt="" />
          </div>
          <div
            className="text-center insert-after d-flex tt disableBtnClass"
            data-bs-placement="right"
            title="insert after"
            style={{ zIndex: 10 }}
          >
            <span
              className="insert-icon"
              id="paste-center"
              onClick={() => openFade("fadeout")}
              style={{ cursor: "pointer", fontSize: "1.0416666666666667vw" }}
            >
              Fade out
            </span>
          </div>
        </div>
      </div>
      <div
        class="change-spk-options justify-content-center sub-menu-item"
        id="change-speakers"
        style={{
          visibility: "hidden",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "13%",
        }}
      ></div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <div id="half-modals-container" className="gapRemoval-wrapper">
          <div className="half-modals-content" id="half-modals-content">
            <div className="half-modals-header">
              <p
                style={{ margin: "0" }}
                id="auto-filter-confirmation-modal-header"
              >
                Gap removal confirmation
              </p>
              <img
                title="close"
                className="img-fluid"
                id="closeWorkspaceModalIcon"
                src="/image/exit-workspace.svg"
                alt=""
                onClick={closeModal}
              />
            </div>
            <div
              className="half-modals-sub-header"
              id="auto-filter-confirmation-modal-sub-header"
            >
              Are you sure you want to remove all the unnecessary gaps? If so
              please select the gap length and click on Yes
            </div>
            <div className="half-modals-speaker">
              Length in seconds:
              <input
                type="number"
                className="sfx-min-length sfx-length-input"
                min="1"
                max="5"
                step=".01"
                id="sfx-min-length"
                value={value}
                onInput={handleChange}
                onChange={() => {}}
              />
              <input
                id="len-control"
                className="gapRemoval-slider"
                type="range"
                min="1"
                max="5"
                value={value}
                title="SFX Length"
                step=".1"
                onChange={handleChange}
              />
              <span className="sfx-max-length" id="sfx-max-length">
                5
              </span>
            </div>
            <div className="half-modals-action">
              <div className="half-modals-actions text-center">
                <button
                  type="button"
                  id="btn-auto-filter-okay"
                  className="half-modals-action-button"
                  onClick={() => removeLongGaps(value)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  id=""
                  className="half-modals-action-button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {isModalOpenModal && (
        <SfxMusicLengthModal
          sfxcloseModal={sfxcloseModal}
          position={pastePosition}
          onSetLength={handleSetLength}
        />
      )}
      {openUpdateSpeakerModal && (
        <UpdateSpeakerModal
          onClose={closeUpdateSpeakerModal}
          handleCut={handleCut}
        ></UpdateSpeakerModal>
      )}
    </>
  );
};

export default EditControls;
