import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";

function HighExportModal(props) {
  const [halfModalSubHeaderText, sethalfModalSubHeaderText] = useState(
    "You will receive the email once we finish processing your audio file.",
  );
  useEffect(() => {
    if (props.message.success == false) {
      sethalfModalSubHeaderText("Please try again");
    }
  }, []);

  function closeHalfModal() {
    props.onClose();
  }

  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="half-modals-content" id="half-modals-content">
          <div
            className="half-modals-header"
            style={{ height: "unset !important" }}
          >
            <p style={{ margin: "0" }} id="hq-export-modal-header">
              {props.message.message}
            </p>
            <img
              title="close"
              className="img-fluid"
              onClick={closeHalfModal}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>
          <div
            className="half-modals-sub-header"
            id="hq-export-modal-sub-header"
          >
            {halfModalSubHeaderText}
          </div>
          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                onClick={closeHalfModal}
                className="half-modals-action-button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default HighExportModal;
