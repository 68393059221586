import React from "react";
import Joyride from "react-joyride";
import store from "../../redux/Store";
import "../../css/dark/ProductTours.css";
import { useDispatch, useSelector } from "react-redux";
import { workSpaceTourActions } from "../../redux/slice/WorkSpaceTourSlice";
import { useEffect, useState } from "react";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import axios from "axios";
import topbar from "topbar";

// Custom Tooltip Component with Skip Button and Step Title
const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
  totalSteps,
  goToNextStep,
  goToPreviousStep,
}) => {
  return (
    <div {...tooltipProps} className="tooltip-container">
      {step.title && <div className="tooltip-title">{step.title}</div>}

      <div className="tooltip-content">{step.content}</div>
      <div className="tooltip-footer">
        <button {...skipProps} className="tooltip-skip-button">
          Skip
        </button>
        {index > 0 && (
          <button
            {...backProps}
            className="tooltip-back-button"
            onClick={goToPreviousStep}
          >
            Back
          </button>
        )}
        <button
          {...primaryProps}
          className="tooltip-next-button"
          onClick={goToNextStep}
        >
          {isLastStep ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

// Tour Component
const WorkspaceTour = (props) => {
  const dispatch = useDispatch();
  const runWorkSpaceTour = useSelector(
    (state) => state.workSpaceTour.workSpaceTour,
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [autoAdvanceTimeout, setAutoAdvanceTimeout] = useState(null);

  const steps = [
    {
      target: "#projectname",
      title: "Project Title",
      content:
        "In edit mode, double-click the title to make edits. When you're ready to proceed, click 'Next' to continue the tour.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#speakers-sliders",
      title: "Speakers",
      content:
        "Slide/Swipe to select amongst the speakers. Right click to edit the name of the speaker or delete the speaker.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#waveform",
      title: `Speaker's Tracks`,
      content:
        "The track selected in the speaker section is highlighted here in blue-violet. Click and drag to select a segment.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#speaker-volume",
      title: "Speaker Volume Control",
      content:
        "To control volume, double-click on the speaker icon to select specific speaker and change volume. Also you can single-click on a track for track-level volume control, or select a portion in the playlist to adjust its volume.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#sfx-slider-cont",
      title: "SFX & Music",
      content:
        "Slide/Swipe to select amongst the sound effects and music tracks. Right click to edit the name or delete the track.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#sfxarea",
      title: "SFX & Music Tracks",
      content:
        "The track selected in the SFX & Music section is highlighted here in blue-violet. Click and drag to select a segment.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#sfx-volume",
      title: "SFX & Music Volume Control",
      content:
        "To control volume, double-click on the SFX icon to select specific SFX and change volume. Also you can single-click on a track for track-level volume control, or select a portion in the playlist to adjust its volume.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#globalview",
      title: "Global View",
      content:
        "The whole audio track is shown here.The red line shows the current timestamp of the speaker/sfx track (or selection). Single click to jump to the corresponding timestamp in speaker/sfx track. Click and drag to select a segment.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#transcript-text",
      title: "Transcript Panel",
      content:
        "Single click on it to open the transcript panel. Once opened, you can double click on any word to edit it when you're in the edit mode.",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "#control-mode",
      title: "View/Edit Controls",
      content:
        "Option to toggle between View and Edit mode. You can also Undo and Redo from here.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#toggleBtn",
      title: "Edit Modes",
      content:
        "To switch between Transcript driven mode and Audio driven mode, click on this option.",
      placement: "top",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const fetchTourGuideData = async () => {
      try {
        const Tour_name = "workspace_view"; // Set the current tour name
        const localStorageKey = `${Koolioai.username}${Tour_name}`; // Generate the key

        if (localStorage.getItem(localStorageKey) === "true") {
          return;
        }

        // Fetch tokens
        const tokens = await Promise.all([
          Koolioai.authToken(),
          Koolioai.accessToken(),
        ]);

        // Make the GET request
        const response = await axios.get(
          _config.api + "/demo-tour-guide-shown",
          {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          },
        );

        // Check the `transcript_tour` status
        if (response.data.demo_tour_guide.workspace_view === true) {
          localStorage.setItem(localStorageKey, "true");
        } else {
          if (props.isLoading === true) {
            // Start the tour and mark it as completed
            dispatch(workSpaceTourActions.startWorkSpaceTour());

            // Make the POST request to update the status
            axios
              .post(
                _config.api + "/demo-tour-guide-shown",
                { workspace_view: true },
                {
                  headers: {
                    Authorization: `${tokens[0]}`,
                    AccessToken: `${tokens[1]}`,
                  },
                },
              )
              .then(() => {
                localStorage.setItem(localStorageKey, "true"); // Mark as completed in localStorage
              })
              .catch((error) =>
                console.error("Error updating tour guide data:", error),
              );
          }
        }
      } catch (error) {
        console.error("Error fetching or updating tour guide data:", error);
      }
    };

    fetchTourGuideData();
  }, [dispatch, props.isLoading]);

  useEffect(() => {
    // Clear any ongoing timeouts or intervals
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);

    // Hide the top bar on the last step
    if (stepIndex === steps.length - 1) {
      topbar.hide();
      return; // Exit early to avoid setting up progress on the last step
    }

    if (runWorkSpaceTour) {
      topbar.show(); // Show the topbar
      topbar.progress("0"); // Reset progress

      let progress = 0;
      const interval = 32; // Milliseconds per increment
      const increment = 0.01; // Progress increment per interval
      let progressInterval;

      const startProgressBar = () => {
        progressInterval = setInterval(() => {
          progress += increment;
          if (progress >= 1) {
            clearInterval(progressInterval);
            topbar.progress("1"); // Ensure it ends at full
          } else {
            topbar.progress(progress.toString());
          }
        }, interval);
      };

      // Start the progress bar
      startProgressBar();

      // Advance to the next step after 4 seconds
      const timeout = setTimeout(() => {
        clearInterval(progressInterval); // Stop progress bar updates
        setStepIndex((prevIndex) => prevIndex + 1);
      }, 4000);

      setAutoAdvanceTimeout(timeout);

      return () => {
        clearTimeout(timeout);
        clearInterval(progressInterval);
      };
    }
  }, [runWorkSpaceTour, stepIndex]);

  // Reset the tour state after it has been run
  const handleTourEnd = () => {
    topbar.hide(); // Dispose the topbar
    dispatch(workSpaceTourActions.stopWorkSpaceTour());
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);
  };

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex((prevIndex) => prevIndex + 1);
    }
    if (stepIndex === steps.length - 1) {
      handleTourEnd(); // Handle last step manually
    }
  };

  const goToPreviousStep = () => {
    if (stepIndex > 0) {
      setStepIndex((prevIndex) => prevIndex - 1);
    }
    if (autoAdvanceTimeout) {
      clearTimeout(autoAdvanceTimeout);
      topbar.progress("0");
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      disableScrolling
      hideCloseButton
      showSkipButton={true} // Enable skip button
      showProgress={true} // Enable step progress
      scrollToFirstStep={true}
      run={runWorkSpaceTour}
      stepIndex={stepIndex}
      callback={(data) => {
        if (data.status === "finished" || data.status === "skipped") {
          handleTourEnd(); // Stop tour when it's done
        }
      }}
      styles={{
        options: {
          zIndex: 10000,
          overlayColor: "rgba(24,24,24,1)",
          arrowColor: "#C4C4C4",
        },
      }}
      tooltipComponent={(props) => (
        <CustomTooltip
          {...props}
          totalSteps={steps.length}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
    />
  );
};

export default WorkspaceTour;
