import { createSlice } from "@reduxjs/toolkit";

const initialcopyBuffer = {
  copyBuffer: null,
  createdFrom: null, //This is to keep origin of copy buffer so that we can track the sfx copy buffer
};
const copyBufferSlice = createSlice({
  name: "copyBuffer",
  initialState: initialcopyBuffer,
  reducers: {
    updateCopyBuffer(state, action) {
      state.copyBuffer = action.payload.copiedBuffer;
    },
    updatedCreatedFrom(state, action) {
      state.createdFrom = action.payload.createdFrom;
    },
    clearCopyBuffer(state) {
      state.copyBuffer = null;
      state.createdFrom = null;
    },
  },
});
export default copyBufferSlice.reducer;
export const CopyBufferActions = copyBufferSlice.actions; // for copy buffer
