// This slice is for notifications update modal and we will use it when we open any notification details
import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  data: "",
};

const NotificationUpdateModalSlice = createSlice({
  name: "notificationUpdateReleaseModal",
  initialState: initialVal,
  reducers: {
    updateNotificationInfo(state, action) {
      state.data = action.payload.data;
    },
    clearNotificationInfo(state) {
      state.data = "";
    },
  },
});

export const NotificationUpdateSliceActions =
  NotificationUpdateModalSlice.actions;
export default NotificationUpdateModalSlice.reducer;
