import { createSlice } from "@reduxjs/toolkit";

/*************************GenAI websocket slice data*****************************/

const initialGenAIValue = {
  model: "",
  value: "",
  numberOfgenAi: 0,
  numberOfgenAiProduced: 0,
};

const genAISlice = createSlice({
  name: "genAi",
  initialState: initialGenAIValue,
  reducers: {
    storeWebsocketData(state, action) {
      state.model = action.payload.model;
      state.value = action.payload.value;
    },
    clearWebsocketData(state) {
      state.model = "";
      state.value = "";
      state.numberOfgenAiProduced = 0;
      state.numberOfgenAi = 0;
    },
    setLengthOfSfxMusic(state, action) {
      state.numberOfgenAi = action.payload.numberOfgenAi;
    },
    increaseGenAiCounter(state) {
      state.numberOfgenAiProduced = state.numberOfgenAiProduced + 1;
    },
  },
});

export const genAiActions = genAISlice.actions;
export default genAISlice.reducer;
