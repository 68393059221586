import { createSlice } from "@reduxjs/toolkit";

/****************************** Multi select slice ************************************ */
const initialSelection = {
  isMultiselect: false,
};

const multiselectSlice = createSlice({
  name: "multiSelectedWords",
  initialState: initialSelection,
  reducers: {
    updateMultiselect(state, action) {
      state.isMultiselect = action.payload.isMultiselect;
    },
    clearMultiselect(state) {
      state.isMultiselect = false;
    },
  },
});
/******************************* */
export const multiSelectActions = multiselectSlice.actions; // to check weather we have to multi select or not
export default multiselectSlice.reducer;
