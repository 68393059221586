import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  showModal: false,
  clickedAnnot: null,
};

const sfxModalSlice = createSlice({
  name: "showSfxModal",
  initialState: initialVal,
  reducers: {
    updateSfxModal(state, action) {
      state.showModal = action.payload.showModal;
      state.clickedAnnot = action.payload.clickedAnnot || null;
    },
    clearData(state) {
      state.showModal = false;
    },
  },
});

export const sfxModalSliceActions = sfxModalSlice.actions;
export default sfxModalSlice.reducer;
