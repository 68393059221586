import {
  getAccessibleAttributes,
  getUserPlan,
} from "../services/globalServices";
import { Koolioai, _config } from "./cognitoAuth";
import axios from "axios";
import { getRemainingDays } from "./utils";

export class ACManager {
  constructor() {
    this.role = null;
    this.allowedDuration = 0;
    this.allowedNumberOfProjects = 0;
    this.allowedNumberOfPublishing = 0;
    this.allowedNumberOfSfx = 0;
    this.allowedNumberOfShowNotes = 0;
    this.allowedOperations = [];
    this.planId = null;
    this.planName = "";
    this.isPlanActive = false;
    this.planExpiry = null;
  }

  initiate() {
    return Promise.all([getAccessibleAttributes(), getUserPlan()]).then(
      (attr) => {
        this.role = attr[0].role;
        this.allowedDuration = attr[0].allowed_duration;
        this.allowedNumberOfProjects = attr[0].allowed_number_of_projects;
        this.allowedNumberOfPublishing = attr[0].allowed_number_of_publishing;
        this.allowedNumberOfSfx = attr[0].allowed_number_of_sfx;
        this.allowedNumberOfShowNotes = attr[0].allowed_number_of_show_notes;
        this.allowedOperations = attr[0].operations;
        this.planName = attr[0].plan_name;
        this.planId = attr[1].plan_id;
        this.isPlanActive = attr[1].is_active;
        this.planExpiry = attr[1].expiry;

        let planName = this.planName;
        localStorage.setItem("planName", JSON.stringify(planName));

        // if (this.planName === "Free" || this.role === "trial") {
        //   // document
        //   //   .getElementById("subscription-btn")
        //   //   .classList.remove("d-none");
        //   // document.getElementById("subscription-btn").classList.add("block");
        // } else {
        //   const remainingDays = getRemainingDays();
        //   console.log(remainingDays);
        //   if (remainingDays <= 0) {
        //     document
        //       .getElementById("subscription-btn")
        //       .classList.remove("d-none");
        //     document
        //       .getElementById("subscription-btn")
        //       .classList.add("d-block");
        //   } else {
        //     console.log("inside");
        //     console.log(this.planName);
        //     // console.log(this.getPlanName());
        //     // document.getElementById("account-role").innerHTML = this.planName;
        //     // let expiryText = "(Expires on ";
        //     // if (remainingDays < 0) expiryText = "(Expired on ";

        //     // document.getElementById("account-validity").innerHTML =
        //     //   expiryText + this.planExpiry + ")";
        //     // document.getElementById("account-validity").style.visibility =
        //     //   "visible";
        //     // document
        //     //   .getElementById("subscription-btn")
        //     //   .classList.remove("d-block");
        //     // document.getElementById("subscription-btn").classList.add("d-none");
        //   }
        // }
        getAllFeatureCountForTrial(); //!check this line
      },
    );
  }

  checkPermission(operation) {
    /*return this.allowedOperations.includes(operation)*/
    const errorMessage = "Kindly upgrade your account.";
    const successMessage = "success";
    const isAllowed = this.allowedOperations.includes(operation);
    const response = {
      success: isAllowed,
      message: isAllowed ? successMessage : errorMessage,
    };
    return response;
  }

  checkAllowedNumberOfPublishingCount() {
    return parseInt(this.allowedNumberOfPublishing);
  }
  checkAllowedDuration() {
    return parseInt(this.allowedDuration);
  }

  checkAllowedSFXCount() {
    return parseInt(this.allowedNumberOfSfx);
  }

  checkAllowedShowNotesCount() {
    return parseInt(this.allowedNumberOfShowNotes);
  }

  checkAccExpiry() {
    return !this.isPlanActive;
  }

  getPlanID() {
    return this.planId;
  }
  getPlanName() {
    return this.planName;
  }
}

export async function getAllFeatureCountForTrial() {
  await prepareInitialFeatureWiseCountListObj();
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/trial-feature-usage-count", {
          params: {
            username: Koolioai.username,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          //console.log('response.data = ', response.data.counts)
          if (response.data.counts && response.data.counts.length > 0)
            prepareFeatureWiseCountListObjFromDB(response.data.counts);
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

async function prepareInitialFeatureWiseCountListObj() {
  let totalDuration = 0;

  for (let i = 0; i < Koolioai.kooliospace.length; i++) {
    //!kooliospace die ki nise jante hobe cause congnitoAuth e koolioSpace bolte kisu nai
    totalDuration = totalDuration + Koolioai.kooliospace[i].duration;
  }

  let featureWiseCountList = [];
  featureWiseCountList.push({
    feature: "allowed_duration",
    count: totalDuration,
  });
  featureWiseCountList.push({
    feature: "allowed_number_of_projects",
    count: Koolioai.kooliospace.length,
  });
  featureWiseCountList.push({
    feature: "allowed_number_of_publishing",
    count: 0,
  });
  featureWiseCountList.push({
    feature: "allowed_number_of_sfx",
    count: 0,
  });
  featureWiseCountList.push({
    feature: "allowed_number_of_show_notes",
    count: 0,
  });
  localStorage.setItem(
    "featureWiseCount",
    JSON.stringify(featureWiseCountList),
  );
}

function prepareFeatureWiseCountListObjFromDB(list) {
  let featureWiseCountList = JSON.parse(
    localStorage.getItem("featureWiseCount"),
  );
  if (featureWiseCountList) {
    for (let i = 0; i < featureWiseCountList.length; i++) {
      let featureCount = featureWiseCountList[i];
      for (let j = 0; j < list.length; j++) {
        if (featureCount.feature === list[j].feature)
          featureCount.count = list[j].usage_count;
      }
    }
  }
  localStorage.setItem(
    "featureWiseCount",
    JSON.stringify(featureWiseCountList),
  );
}
