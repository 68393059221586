// This slice is used to trigger saveState() which is used to perform undo redo

import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  value: false,
};

const ToggleSlice = createSlice({
  name: "toggleSlice",
  initialState: initialVal,
  reducers: {
    updateToggleVal(state, action) {
      state.value = action.payload.value;
    },
  },
});

export const ToggleSliceActions = ToggleSlice.actions;
export default ToggleSlice.reducer;
