import React, { useEffect, useState } from "react";
import "../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { koolioModals } from "../../utils/Res";
import { createPortal } from "react-dom";
import { cancelPlan, showLoader } from "../pages/loginPage/loginUtils";
import { showplaylistloader } from "../pages/kooliospace/kooliospaceUtils";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

export default function CancelSubscriptionModal({
  message1,
  message2,
  onCancelSubscriptionNo,
}) {
  const [closeModal, setCloseModal] = useState(true);
  useEffect(() => {
    koolioModals();
  }, []);

  return createPortal(
    <>
      {closeModal && (
        <div id="half-modals-container">
          <div className="half-modals-content" id="half-modals-content">
            <p
              style={{ marginBottom: "1%", fontSize: "1.3rem" }}
              id="annotation-modal-header"
            >
              {message1}
            </p>
            <p style={{ margin: "0" }} id="annotation-modal-header">
              {message2}
            </p>
            <div className="half-modals-action">
              <div
                className="half-modals-actions text-center"
                style={{ width: "15%" }}
              >
                <button
                  id="transyes"
                  type="button"
                  onClick={() => {
                    cancelPlan();
                    // showLoader();
                    setCloseModal(false);
                  }}
                  className="half-modals-action-button"
                >
                  Yes
                </button>
              </div>
              <div
                className="half-modals-actions text-center"
                style={{ width: "15%" }}
              >
                <button
                  id="transno"
                  type="button"
                  onClick={() => onCancelSubscriptionNo(false)}
                  className="half-modals-action-button"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>,
    document.querySelector("body"),
  );
}
