import $ from "jquery";
import { exportHighQualityAudio } from "../utils";
import { closeExportToPodcastApiSliceActions } from "../../redux/slice/CloseExportToPodcastApiSlice";
import store from "../../redux/Store";
let publishLQAudio = false;
export let jobHeadOFAudioToPublish = "";
export let audioInfoFromBackendToPublishInHeadliner;
export let closeHeadlinerModalTimeOut;

export function updateValueForHeadliner(value) {
  audioInfoFromBackendToPublishInHeadliner = value;
}

export function prepareModalForHeadliner() {
  document.getElementById("api-header").innerHTML = "Headliner Settings";
  document.getElementById("api-key").style.display = "none";
  document.getElementById("episode-content").style.display = "none";
  document.getElementById("api-key").style.display = "none";
  document.getElementById("podcast-id").style.display = "none";
  document.getElementById("episode-title").style.display = "none";
  document.getElementById("episode-content").style.display = "none";
  document.getElementById("publish-podcast-button").style.display = "none";
  document.getElementById("cancel-podcast-button").style.display = "none";
  document.getElementById("headliner-msg").classList.remove("d-none");
  document.getElementById("headliner-msg").classList.add("d-block");

  if (
    audioInfoFromBackendToPublishInHeadliner !== undefined &&
    jobHeadOFAudioToPublish !== ""
  ) {
    if (
      audioInfoFromBackendToPublishInHeadliner.job_head ===
      jobHeadOFAudioToPublish
    ) {
      prepareHeadlinerButton(
        audioInfoFromBackendToPublishInHeadliner.export_url,
      );
    } else {
      $("#btn-invoke-headliner").click();
    }
  } else {
    $("#btn-invoke-headliner").click();
  }
}

export async function prepareHeadlinerButton(audioURL) {
  const element = document.getElementById("btn-headliner");
  element.setAttribute("data-audio-url", audioURL);
  element.setAttribute("data-clip-title", "Koolio Clip");
  element.setAttribute("data-color", "rgba(1, 2, 3, 0.5)");
  window.headliner.render(element);

  document.getElementById("headliner-msg").classList.remove("d-block");
  document.getElementById("headliner-msg").classList.add("d-none");
  jobHeadOFAudioToPublish = "";
  audioInfoFromBackendToPublishInHeadliner = undefined;

  closeHeadlinerModalTimeOut = setTimeout(
    function () {
      //   closeExportToPodcastApiModal();
      //   closeHalfModal();
      store.dispatch(
        closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
          value: true,
        }),
      );
      clearTimeout(closeHeadlinerModalTimeOut);
      closeHeadlinerModalTimeOut = undefined;
    },
    1 * 60 * 1000,
  );
}

export async function publishAudioToHeadliner(fileToUpload, options) {
  const publishInfo = {
    titleOrPodcastId: "",
    apiProvider: "headliner",
  };
  removeHeadlinerButton();
  if (!publishLQAudio) {
    return exportHighQualityAudio(options, false, true, publishInfo)
      .then((res) => Promise.resolve())
      .catch((error) => {
        // Handle the error here
        // console.error("An error occurred:", error);
        // You can choose to rethrow the error or handle it gracefully
        // throw error; // Rethrowing the error
      });
  } else {
    let audioURL = URL.createObjectURL(fileToUpload) + "";
    audioURL = audioURL.replace("blob:", "");
    const partsOfAudioUrl = audioURL.split("/");
    if (partsOfAudioUrl !== undefined) {
      audioURL = audioURL.replace(
        partsOfAudioUrl[partsOfAudioUrl.length - 1],
        options.filename,
      );
    }
    audioURL = audioURL + "." + options.format;
    prepareHeadlinerButton(audioURL);
  }
}

export function setLQFlag() {
  if (document.getElementById("lq").checked) {
    publishLQAudio = true;
  } else {
    publishLQAudio = false;
  }
}

export function removeHeadlinerButton() {
  if (document.getElementById("btn-headliner").children.length > 0) {
    $("#btn-headliner").html("");
  }
}
