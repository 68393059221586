import store from "../redux/Store";
import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";
import { ACM } from "../utils/utils";
import { showMessage } from "../utils/koolio-workspace";

export async function getAllPrevGenSFX(word = null) {
  // const permResponse = ACM.checkPermission('gen-ai')
  // if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
  //     if(fromMoreOptions) {
  //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
  //     else {
  //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
  //     }
  // }
  // if(document.getElementById("prev_gen_sfx").children.length) {
  //     handlePrevGenSFX(document.getElementById("prev_gen_sfx").children)
  //     return
  // }

  let sfxTracks;
  // initiateGenLoader("sfx")
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const username = Koolioai.username;
  try {
    return Promise.all([Koolioai.authToken()])
      .then((token) =>
        axios.get(_config.api + "/gen-ai", {
          params: {
            sentence: word,
            combineResult: true,
            offset: 0,
            size: 8,
            jobname: jobname,
            username,
            getPreviousGenAi: true,
            get_music: false,
          },
          headers: {
            Authorization: `${token[0]}`,
          },
        }),
      )
      .then((response) => {
        sfxTracks = response.data[word] || response.data.data || [];
        return sfxTracks;
      });
  } catch (error) {
    console.error(error);
  }
  // return Koolioai.authToken()
  //     .then(token => axios
  //         .get(_config.api + "/gen-ai", {
  //             params: {
  //                 sentence: word,
  //                 combineResult: true,
  //                 offset: 0,
  //                 size : 8,
  //                 jobname,
  //                 username : Koolioai.username,
  //                 getPreviousGenAi :  true,
  //                 get_music : false
  //             },
  //             headers: {
  //                 Authorization: `${token}`
  //             }
  //         })
  //         .then(response => {
  //             console.log(response);
  //             sfxTracks = response.data[word] || response.data.data || []
  //             searchSfxSuccessHandler(sfxTracks, word, playlist.getTimeSelection().start, playlist.getTimeSelection().end, false, null, true)
  //                 .catch(e => {
  //                     console.error(e)
  //                 })
  //         }).catch(() => Promise.resolve([])))
}

export async function getAllPrevGenMusics(word = null) {
  // const permResponse = ACM.checkPermission('gen-ai')
  // if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
  //     if(fromMoreOptions) {
  //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
  //     else {
  //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
  //     }
  // }
  // if(document.getElementById("prev_gen_musics").children.length) {
  //     handlePrevGenMusics(document.getElementById("prev_gen_musics").children)
  //     //$("#gen_music_citation > div").removeClass("gen-ai-text-slide")
  //     return
  // }

  let musicTracks;
  // initiateGenLoader("music")
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const username = Koolioai.username;
  return Koolioai.authToken().then((token) =>
    axios
      .get(_config.api + "/gen-ai", {
        params: {
          sentence: word,
          combineResult: true,
          offset: 0,
          size: 8,
          jobname,
          username,
          getPreviousGenAi: true,
          get_music: true,
        },
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        // console.log(response);
        musicTracks = response.data[word] || response.data.data || [];
        // console.log(musicTracks);
        return musicTracks;
      })
      .catch(() => Promise.resolve([])),
  );
}

// for retrieving previous genAi images

export async function getAllPrevGenImage(
  idno = null,
  create = false,
  history = false,
) {
  // const permResponse = ACM.checkPermission('gen-ai')
  // if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
  //     if(fromMoreOptions) {
  //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
  //     else {
  //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
  //     }
  // }

  // if(document.getElementById("prev_gen_image").children.length) {
  //     if(!idno && !history){
  //         handlePrevGenImage(document.getElementById("prev_gen_image").children)
  //         return
  //     }
  // }

  let imageTracks;
  let getPregen = true;
  if (create == true) {
    getPregen = false;
  }
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const username = Koolioai.username;
  // if(!idno && !history)
  // initiateGenLoader("image")
  try {
    return Koolioai.authToken().then((token) =>
      axios
        .get(_config.api + "/img-gen-ai", {
          params: {
            id: idno,
            jobname,
            username,
            getPreviousGenAi: getPregen,
            desiredImgCount: 1,
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          imageTracks = response.data.data || [];
          return imageTracks;
        })
        .catch(() => Promise.resolve([])),
    );
  } catch (err) {
    // console.log(err);
  }
}

// for show notes generations
export async function showNotesGenerate() {
  const permResponse = ACM.checkPermission("generate-notes");
  if (!permResponse.success)
    return showMessage(
      permResponse.message,
      "AI analytics is not available in the free plan",
      2000,
      "error",
      "more-options",
    );
  // if (!(transcriptionCompleted)) {
  //     moreOptionsAlert('transcription in progress, operation disabled')
  //     return
  // }
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const username = Koolioai.username;
  const params = {
    jobname,
    username,
  };

  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/analytics-chatgpt", {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          document.getElementById("show-notes-spinner").style.display = "none";
          document
            .getElementById("show-notes-project-button")
            .classList.remove("btn-active");
          document.getElementById("show-notes-project-button").disabled = false;
          return Promise.reject(e);
        });
    },
  );
}
