import { Koolioai, _config } from "../../../utils/cognitoAuth";
import axios from "axios";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Navigate } from "react-router-dom";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoIdToken,
} from "amazon-cognito-identity-js";
import { v4 as uuidv4 } from "uuid";
import {
  checkAccountDetails,
  initiateACM,
  showStatText,
  ACM,
} from "../../../utils/utils";
import ShowMessageModal from "../../modals/ShowMessageModal";
import SubscriptionSuccessModal from "../../modals/SubscriptionSuccessModal";
import React from "react";
import ReactDOM from "react-dom/client";
import topbar from "topbar";
import anime from "animejs";
// import { ENV } from "../../../utils/cognitoAuth";
let redirectingSeconds;
let resendOtpSeconds;
var isSubmited = false;
var isForgetPassSuccess = false;
var isForgetPass = false;
export let location = window.location;
let ENV =
  location.host.includes("localhost") || location.host.includes("127.0.0.1")
    ? "development"
    : location.host.split(".")[0] === "production"
      ? "app"
      : location.host.split(".")[0];
const UPools = {
  development: "us-east-2_e2fYbYD6n",
  qa: "us-east-2_aFVhRYQZS",
  app: "us-east-2_2hFTmQBac",
};
const UClients = {
  development: "2fmctevbp6rfn2eopptfgg0mum",
  qa: "71fpecuq3db2v79j6ck2b9fd42",
  app: "2uck12qofspo5sahaflbtsqouk",
};

export const poolData = {
  UserPoolId: UPools[ENV],
  ClientId: UClients[ENV],
};

// login page functions
export const ConfirmationMessage =
  "A verification link has been sent to your email address.";
export const ConfirmationMessageSignUP =
  ConfirmationMessage +
  "<br/> Click on that link to confirm your email address and then login.";
export const ConfirmationMessageLogin =
  ConfirmationMessage +
  "<br /> <a onclick='resendVerification(event, \"{{username}}\")'>Didn't receive? Click here to resend</a> <br/>";

// Terms and conditions

export function checkUncheck() {
  let conditionCheckPassed = false;

  var checkbox = document.getElementById("terms-checkbox");
  var signUpBtn = document.getElementById("koolio-sign-up");
  var requestAccessBtn = document.getElementById("koolio-request-access");

  if (checkbox.classList.contains("unchecked")) {
    checkbox.classList.add("checked");
    checkbox.classList.remove("unchecked");
    checkbox.setAttribute("src", "../../../image/checkbox-checked.png");
    signUpBtn.disabled = false;
    conditionCheckPassed = true;
    // INVITE ONLY
    // requestAccessBtn.style.opacity = "1";
    // requestAccessBtn.style.cursor = "pointer";
    signUpBtn.style.opacity = "1";
    signUpBtn.style.cursor = "pointer";
  } else {
    signUpBtn.disabled = true;
    checkbox.classList.add("unchecked");
    checkbox.classList.remove("checked");
    checkbox.setAttribute("src", "../../../image/checkbox-unchecked.png");
    conditionCheckPassed = false;
    // INVITE ONLY
    // requestAccessBtn.style.opacity = "0.5";
    // requestAccessBtn.style.cursor = "not-allowed";
    signUpBtn.style.opacity = "0.5";
    signUpBtn.style.cursor = "not-allowed";
  }
}

export function terms() {
  document.getElementById("terms").style.display = "block";
}

export function declineTerms() {
  document.getElementById("terms").style.display = "none";
  document.getElementById("terms-checkbox").classList.add("unchecked");
  document.getElementById("terms-checkbox").classList.remove("checked");

  // Changing the src attribute
  document
    .getElementById("terms-checkbox")
    .setAttribute("src", "../../../image/checkbox-unchecked.png");
}

export function acceptTerms() {
  document.getElementById("terms").style.display = "none";

  document.getElementById("terms-checkbox").classList.add("checked");
  document.getElementById("terms-checkbox").classList.remove("unchecked");
  document
    .getElementById("terms-checkbox")
    .setAttribute("src", "../../../image/checkbox-checked.png");
}

export function showError(msg, type) {
  const element = document.getElementById(type + "-message");
  if (element) {
    element.innerHTML = msg;
  } else {
    console.error("Element with id " + type + "-message not found");
  }
  return false;
}

export function continueSession() {
  window.location.pathname = "/kooliospace";
  document.getElementById("add-project-user").innerText =
    "Welcome, " + Koolioai.username;
  document.getElementById("user-profile-home").src =
    "https://ui-avatars.com/api/?name=" +
    Koolioai.username.replaceAll(" ", "+") +
    "&background=181818&color=fff&rounded=true&size=38";
  document.getElementById("continue-screen").style.cssText =
    "visibility: visible !important";
  //anime.timeline({ loop: false })
  //    .add({
  //        targets: '.add-btn',
  //        rotate: 45,
  //        duration: 400,
  //        easing: 'easeOutExpo'
  //    })
  //    .add({
  //        targets: '#continue-screen',
  //        opacity: [0, 1],
  //        delay: 3000,
  //        easing: 'easeOutExpo'
  //    })
}

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function signupAuth(event) {
  event.preventDefault();
  // if (userNamePassed && userEmailPassed && userPassPassed && userConPassPassed && conditionCheckPassed) {
  topbar.show();
  //     $('.signup-button').addClass("btn-active");
  (function step() {
    setTimeout(function () {
      if (topbar.progress("+.01") < 1) step();
    }, 30);
  })();
  const elements = document.getElementById("signup-form").elements;
  elements[4].disabled = true;
  const username = elements[0].value.trim();
  const email = elements[1].value.trim();
  // console.log(email);
  const isValidEmail = validateEmail(email);
  // console.log(isValidEmail);

  if (username.match(/\s/g)) {
    showError("Please remove the white space from username", "signup");
    elements[4].disabled = false;
    topbar.hide();
    return false;
  }

  if (!validateEmail(email)) {
    showError("Please enter a valid email address", "signup");
    elements[4].disabled = false;
    topbar.hide();
    // $('.signup-button').removeClass("btn-active")
    return false;
  }
  const password = elements[2].value.trim();
  const conf_password = elements[3].value;
  var regularExpression =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;

  // console.log(username, email, password, conf_password);

  if (password.match(regularExpression)) {
    if (password !== conf_password) {
      showError("Passwords didn't match", "signup");
      elements[4].disabled = false;
      topbar.hide();
      // $('.signup-button').removeClass("btn-active")
      return false;
    }
  } else {
    if (!password.match(/(?=.{8,})/)) {
      showError("Passwords must have at least 8 characters", "signup");
    } else if (!password.match(/(?=.*[a-z])/)) {
      showError(
        'Passwords must have at least one lowercase ("a" - "z")',
        "signup",
      );
    } else if (!password.match(/(?=.*[A-Z])/)) {
      showError(
        'Passwords must have at least one uppercase ("A" - "Z")',
        "signup",
      );
    } else if (!password.match(/(?=.*\d)/)) {
      showError('Passwords must have at least one digit ("0" - "9")', "signup");
    } else if (!password.match(/(?=.*[!@#$%^&*])/)) {
      showError(
        "Passwords must have at least one special symbol from the following: !@#$%^&*",
        "signup",
      );
    }
    //showError('Passwords must have at least 8 characters, one uppercase ("A" - "Z"), one digit ("0" - "9") and one special symbol.', 'signup')
    elements[4].disabled = false;
    topbar.hide();
    // $('.signup-button').removeClass("btn-active")
    return false;
  }

  var attributes = [
    {
      Name: "email",
      Value: email,
    },
    {
      Name: "custom:role",
      Value: "role with free features", //trial
    },
    {
      Name: "custom:plan",
      Value: "f3ce00b8-d019-3dfe-8446-c64a843894e2", //'f5cf82e5-62fc-3db3-bd43-aee7091e52a0'
    },
  ];
  return new Promise((resolve, reject) => {
    let callback = function (err, result) {
      if (err) {
        if (err.toString().includes("EmailAlreadyExistException")) {
          showError("Email already exists.", "signup");
        } else if (
          err.toString().includes("UsernameAlreadyExistException") ||
          err.toString().includes("UsernameExistsException")
        ) {
          showError("User Name already exists.", "signup");
        } else if (
          err.toString().includes("Username cannot be of email format")
        ) {
          showError("Username cannot be of email format", "signup");
        } else {
          showError("Signup Failed.", "signup");
        }
        elements[4].disabled = false;
        topbar.hide();

        reject(err);
        return false;
      }

      if (!result.userConfirmed) {
        //  login()
        // showError(ConfirmationMessageSignUP, "login");
        elements[4].disabled = false;
        resolve(ConfirmationMessageSignUP);
      }
      topbar.hide();

      // $('.signup-button').removeClass("btn-active")
    };

    Koolioai.signUp(username, password, attributes, callback);
  });
  // $('.signup-button').removeClass("btn-active")
}

// export function loginAuth(event) {
//   event.preventDefault();
//   topbar.show();
//   document.getElementById("logInButton").classList.add("btn-active");
//   (function step() {
//     setTimeout(function () {
//       if (topbar.progress("+.01") < 1) step();
//     }, 32);
//   })();

//   const elements = document.getElementById("login-form").elements;
//   // elements[2].disabled = true;
//   const username = elements[0].value.trim();
//   const password = elements[1].value;

//   if (username.match(/\s/g)) {
//     showError("No spaces are allowed in the username", "login");
//     elements[2].disabled = false;
//     topbar.hide();
//     document.getElementById("logInButton").classList.remove("btn-active");
//     return false;
//   }

//   if (!(username && password)) {
//     showError("Username or Password cannot be empty", "login");
//     elements[2].disabled = false;
//     topbar.hide();
//     document.getElementById("logInButton").classList.remove("btn-active");
//     return false;
//   }
//   let authenticationData = {
//     Username: username,
//     Password: password,
//   };
//   let LoginInfo = {
//     user: authenticationData,
//     success: false,
//   };
//   let success = function loginSuccess(result) {
//     console.log(result);
//     console.log("result is", JSON.parse(JSON.stringify(result)));
//     LoginInfo.success = true;
//     console.log(LoginInfo.success);
//     document.getElementById("logInButton").classList.remove("btn-active");
//     //window.location.pathname = "/kooliospace";
//     //window.location.href = "/kooliospace";
//     console.log(LoginInfo);
//     topbar.hide();
//     return LoginInfo;
//   };
//   let failure = function loginFailure(error) {
//     console.log(error);
//     topbar.hide();
//     document.getElementById("logInButton").classList.remove("btn-active");
//     if (error.code === "UserNotConfirmedException") {
//       showError(
//         ConfirmationMessageLogin.replace("{{username}}", username),
//         "login",
//       );
//     } else {
//       showError(error.message, "login");
//     }
//     //elements[2].disabled = false;
//     console.log(LoginInfo);
//     return LoginInfo;
//   };
//   Koolioai.signIn(authenticationData, success, failure);
// }

export function loginAuth(event) {
  event.preventDefault();
  topbar.show();

  document.getElementById("logInButton").classList.add("btn-active");
  (function step() {
    setTimeout(function () {
      if (topbar.progress("+.01") < 1) step();
    }, 32);
  })();
  const elements = document.getElementById("login-form").elements;
  const username = elements[0].value.trim();
  const password = elements[1].value;
  let authenticationData = {
    Username: username,
    Password: password,
  };
  let LoginInfo = {
    user: authenticationData,
    success: false,
  };

  return new Promise((resolve, reject) => {
    let success = function loginSuccess(result) {
      LoginInfo.success = true;
      document.getElementById("logInButton").classList.remove("btn-active");
      topbar.hide();
      resolve(LoginInfo);
    };

    let failure = function loginFailure(error) {
      // console.log(error);
      topbar.hide();
      document.getElementById("logInButton").classList.remove("btn-active");
      if (error.code === "UserNotConfirmedException") {
        showError(
          ConfirmationMessageLogin.replace("{{username}}", username),
          "login",
        );
      } else {
        showError(error.message, "login");
      }
      // console.log(LoginInfo);
      reject(LoginInfo);
    };

    Koolioai.signIn(authenticationData, success, failure);
  });
}

// export function handleLogin(e) {
//   e.preventDefault();
//   console.log("hii", _config, Koolioai);
//   loginAuth(e);
//   // eslint-disable-next-line no-undef
//   // Navigate("kooliospace", {state:{name:{username}},replace: true}) // replace: true means that the history stack will not store this now,
// }

// export function handleSignup(e) {
//   e.preventDefault();
//   console.log("hii", _config, Koolioai);
//   signupAuth(e);
// }

export function onSignup(token) {
  return axios
    .post(_config.api + "/verify-captcha", token, {
      headers: {
        //Authorization: `${tokens[0]}`,
        //AccessToken: `${tokens[1]}`
      },
    })
    .then((response) => {
      if (response.data.success) {
        sessionStorage.setItem("captchaVerified", true);
        loginAuth();
      }
    })
    .catch((error) => {
      console.error("Error verifying reCAPTCHA:", error);
    });
}

export function onLogin(token) {
  return axios
    .post(_config.api + "/verify-captcha", token, {
      headers: {
        //Authorization: `${tokens[0]}`,
        //AccessToken: `${tokens[1]}`
      },
    })
    .then((response) => {
      if (response.data.success) {
        sessionStorage.setItem("captchaVerified", true);
        //loginAuth(event)
      }
    })
    .catch((error) => {
      //console.error('Error verifying reCAPTCHA:', error)
    });
}

export function showForgotPassword(event) {
  // Implement your logic for showing forgot password form
  event.preventDefault();
  document.getElementById("login-form").style.display = "none";
  document.getElementById("forgot-pwd-form").style.display = "";
  document.getElementById("forgot-username-form").style.display = "none";
  document.getElementById("login-message").innerHTML = "";
  document.getElementById("username-forgot-password").value = "";
  document.getElementById("username-forgot").value = "";
  if (navigator.userAgent.toLowerCase().indexOf("firefox") < 0)
    document.getElementById("forgot-pwd-form").reset();
}

export function showForgotUsername(event) {
  event.preventDefault();
  document.getElementById("forgot-username-form").style.display = "";
  document.getElementById("forgot-pwd-form").style.display = "none";
  document.getElementById("login-form").style.display = "none";
  document.getElementById("login-message").innerHTML = "";
  document.getElementById("username-forgot-password").value = "";
  document.getElementById("username-forgot").value = "";
  if (navigator.userAgent.toLowerCase().indexOf("firefox") < 0)
    document.getElementById("forgot-username-form").reset();
}

export function showNewPassword(event) {
  if (event) {
    event.preventDefault();
  }
  clearInterval(redirectingSeconds);
  topbar.hide();
  document.getElementById("forgot-pwd-form").style.display = "none";
  document.getElementById("forgot-username-form").style.display = "none";
  document.getElementById("login-form").style.display = "none";
  document.getElementById("forgot-new-pwd-form").style.display = "";
  // document.getElementById('login-message').innerHTML = "A verification code has been sent to the corresponding email."
  document.getElementById("forgetPasswordResendOtp").style.cursor =
    "not-allowed";
  document.getElementById("forgetPasswordResendOtp").disabled = true;
  if (!isSubmited || isForgetPass) {
    document.getElementById("login-message").innerHTML =
      "A verification code has been sent to the corresponding email.";
  }
  let seconds3 = 60;
  if (isForgetPass) {
    clearInterval(redirectingSeconds);
    resendOtpSeconds = setInterval(function () {
      document.getElementById("sendOtpTimer").innerHTML = "00:" + seconds3;
      seconds3--;
      if (seconds3 < 0) {
        document.getElementById("forgetPasswordResendOtp").disabled = false;
        document.getElementById("forgetPasswordResendOtp").style.color =
          "#c4c4c4";
        document.getElementById("forgetPasswordResendOtp").style.cursor =
          "pointer";
        document.getElementById("sendOtpTimer").innerHTML = "";
        clearInterval(resendOtpSeconds);
      }
    }, 1000);
  } else {
    clearInterval(resendOtpSeconds);
    redirectingSeconds = setInterval(function () {
      if (!isSubmited || (isSubmited && seconds3 > 0 && !isForgetPassSuccess)) {
        document.getElementById("sendOtpTimer").innerHTML = "00:" + seconds3;
      }
      seconds3--;
      if (seconds3 < 0) {
        document.getElementById("forgetPasswordResendOtp").disabled = false;
        document.getElementById("forgetPasswordResendOtp").style.cursor =
          "pointer";
        document.getElementById("forgetPasswordResendOtp").style.color =
          "#c4c4c4";
        document.getElementById("sendOtpTimer").innerHTML = "";
        clearInterval(redirectingSeconds);
      }
    }, 1000);
  }
}

export function newPassword(event) {
  if (event) {
    event.preventDefault();
  }

  document.getElementById("login-message").innerHTML =
    "A verification code has been sent to the corresponding email.";
  isSubmited = true;
  isForgetPass = false;

  document.getElementById("submit-verification").disabled = true;
  let password = document.getElementById("new-pass-forgot-password").value;
  let regularExpression =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;

  if (!password.match(regularExpression)) {
    if (!password.match(/(?=.{8,})/)) {
      showError("Passwords must have at least 8 characters", "login");
    } else if (!password.match(/(?=.*[a-z])/)) {
      showError(
        'Passwords must have at least one lowercase ("a" - "z")',
        "login",
      );
    } else if (!password.match(/(?=.*[A-Z])/)) {
      showError(
        'Passwords must have at least one uppercase ("A" - "Z")',
        "login",
      );
    } else if (!password.match(/(?=.*\d)/)) {
      showError('Passwords must have at least one digit ("0" - "9")', "login");
    } else if (!password.match(/(?=.*[!@#$%^&*])/)) {
      showError(
        "Passwords must have at least one special symbol from the following: !@#$%^&*",
        "login",
      );
    }
    //showError('Passwords must have at least 8 characters, one uppercase ("A" - "Z"), one digit ("0" - "9") and one special symbol.', 'login')
    document.getElementById("submit-verification").disabled = false;
    // elements[4].classList.remove('disabled')
    topbar.hide();

    // INVITE ONLY
    // $('.signup-button').removeClass("btn-active")
    return false;
  }
  document.getElementById("login-message").innerHTML = "";
  document.getElementById("sendOtpTimer").innerHTML = "";
  Koolioai.confirmForgetPassword();
  clearInterval(redirectingSeconds);
}

export function resetForgotPassword(event) {
  if (event) {
    event.preventDefault();
  }
  document.getElementById("forgot-pwd-form").style.display = "none";
  document.getElementById("forgot-username-form").style.display = "none";
  document.getElementById("login-form").style.display = "";
  document.getElementById("login-message").innerHTML = "";
  clearInterval(redirectingSeconds);
}

export function resetNewPassword(event) {
  if (event) {
    event.preventDefault();
  }
  clearInterval(redirectingSeconds);
  document.getElementById("forgot-pwd-form").style.display = "none";
  document.getElementById("forgot-username-form").style.display = "none";
  document.getElementById("login-form").style.display = "";
  document.getElementById("forgot-new-pwd-form").style.display = "none";
  document.getElementById("login-message").innerHTML = "";
  document.getElementById("verification-code-forgot-password").value = "";
  document.getElementById("new-pass-forgot-password").value = "";
}

export function forgotPassword(event) {
  event.preventDefault();
  topbar.show();
  isForgetPass = true;
  (function step() {
    setTimeout(function () {
      if (topbar.progress("+.01") < 1) step();
    }, 30);
  })();
  //  $(".forgot-button").addClass("btn-active");
  document.getElementById("forgot-button").classList.add("btn-active");
  document.getElementById("login-message").innerHTML = "";
  const elements = document.getElementById("forgot-pwd-form").elements;
  elements[1].disabled = true;
  // elements[1].classList.add('disabled')
  const username = elements[0].value.trim();
  if (!username) {
    topbar.hide();
    showError("Username cannot be empty", "login");
    elements[1].disabled = false;
    // elements[1].classList.remove('disabled')
    return false;
  }

  if (username.match(/\s/g)) {
    showError("No spaces are allowed in the username", "login");
    elements[1].disabled = false;
    // elements[2].classList.remove('disabled')
    topbar.hide();
    // $(".forgot-button").removeClass("btn-active");
    document.getElementById("forgot-button").classList.remove("btn-active");
    return false;
  }

  let failure = function loginFailure(error) {
    topbar.hide();
    elements[1].disabled = false;
    showError(error.message, "login");
    //$(".forgot-button").removeClass("btn-active");
    document.getElementById("forgot-button").classList.remove("btn-active");
  };
  let success = function loginSuccess(result) {
    topbar.hide();
  };
  Koolioai.forgotPassword(username, success, failure);
}

export function forgotUsername(event) {
  event.preventDefault();
  topbar.show();
  (function step() {
    setTimeout(function () {
      if (topbar.progress("+.01") < 1) step();
    }, 30);
  })();
  // $("#forgot-username-button").addClass("btn-active");
  document.getElementById("forgot-username-button").classList.add("btn-active");

  document.getElementById("login-message").innerHTML = "";
  const elements = document.getElementById("forgot-username-form").elements;
  elements[1].disabled = true;
  const email = elements[0].value.trim();

  if (!validateEmail(email)) {
    showError("Please enter a valid email address", "login");
    elements[1].disabled = false;
    // elements[4].classList.remove('disabled')
    // $("#forgot-username-button").removeClass("btn-active");
    document
      .getElementById("forgot-username-button")
      .classList.remove("btn-active");
    topbar.hide();
    return false;
  }
  Koolioai.forgotUsername(email);
  topbar.hide();
}

export function forgotPasswordSuccess(result) {
  isForgetPass = false;
  isForgetPassSuccess = true;
  document.getElementById("sendOtpTimer").innerHTML = "";
  const elements = document.getElementById("forgot-pwd-form").elements;
  elements[1].disabled = false;
  resetForgotPassword();

  //$('.forgot-button').removeClass('btn-active')
  document.getElementById("forgot-button").classList.remove("btn-active");
  showNewPassword();
  let seconds = 7;
  redirectingSeconds = setInterval(function () {
    document.getElementById("login-message").innerHTML =
      "Password reset successful! Redirecting to login page " +
      seconds +
      " seconds";
    seconds--;
    if (seconds < 0) {
      resetNewPassword();
    }
  }, 1000);
}

export function forgotPasswordFailure(_error) {
  document.getElementById("submit-verification").disabled = false;
  document.getElementById("login-message").innerHTML =
    "Incorrect OTP! Please try again.";
}

export function resendOTP() {
  isSubmited = false;
  isForgetPass = false;
  clearInterval(redirectingSeconds);
  document
    .getElementById("forgetPasswordResendOtp")
    .addEventListener("click", function () {
      document.getElementById("login-message").innerHTML =
        "A verification code has been sent to the corresponding email.";
      document.getElementById("forgetPasswordResendOtp").disabled = true;
      document.getElementById("forgetPasswordResendOtp").style.cursor =
        "not-allowed";
      let elements2 = document.getElementById("forgot-pwd-form").elements;
      elements2[1].disabled = true;
      const username2 = elements2[0].value.trim();
      let failure = function loginFailure(error) {
        topbar.hide();
        elements2[1].disabled = false;
        showError(error.message, "login");
        //$(".forgot-button").removeClass("btn-active");
        document.getElementById("forgot-button").classList.remove("btn-active");
      };
      let success = function loginSuccess(result) {
        topbar.hide();
      };
      let seconds2 = 60;
      redirectingSeconds = setInterval(function () {
        document.getElementById("sendOtpTimer").innerHTML = "00:" + seconds2;
        seconds2--;
        if (seconds2 < 0) {
          document.getElementById("forgetPasswordResendOtp").disabled = false;
          document.getElementById("forgetPasswordResendOtp").style.cursor =
            "pointer";
          document.getElementById("forgetPasswordResendOtp").style.color =
            "#c4c4c4";
          clearInterval(redirectingSeconds);
          document.getElementById("sendOtpTimer").innerHTML = "";
        }
      }, 1000);
      // let callback = function (error, result) {
      //   if (error) {
      //     elements2[1].disabled = false;
      //     showError(error.message, "login");
      //     // $(".forgot-button").removeClass("btn-active");
      //     document
      //       .getElementById("forgot-button")
      //       .classList.remove("btn-active");
      //   } else {
      //   }
      // };

      Koolioai.forgotPassword(username2, success, failure);
    });
}

export async function validateCaptcha(token) {
  //event.preventDefault()
  // handleLogin();
  // const captchaVerified = sessionStorage.getItem("captchaVerified")
  // if(event.target.id === "login-form"){
  //     if(!captchaVerified) document.getElementById("loginCaptcha").click()
  //     else loginAuth (event)
  // }
  // else if(event.target.id === "signup-form"){
  //     if(!captchaVerified) document.getElementById("signupCaptcha").click()
  //     else signupAuth (event)
  // }
}

export let GOOGLE_KEY = "";

export async function setGoogleKeyDependingOnENV() {
  if (
    location.origin.includes("development.app.koolio.ai") ||
    location.origin.includes("localhost") ||
    location.origin.includes("127.0.0.1")
  ) {
    GOOGLE_KEY =
      "162974364783-0hmimkqe3qkq8d06708rdeviaeihs9u1.apps.googleusercontent.com";
  } else if (location.origin.includes("qa.app.koolio.ai")) {
    GOOGLE_KEY =
      "672739537382-n7ee7t4v18c9p0n0gnupbbd19o7rl617.apps.googleusercontent.com";
  } else {
    GOOGLE_KEY =
      "455603282234-nheccr71de3gr3l3t6s6vjl0bhouq657.apps.googleusercontent.com";
  }
}

export const authUrl = "https://development.accounts.koolio.ai";
// const poolData = {
//   UserPoolId: "us-east-2_e2fYbYD6n",
//   ClientId: "2fmctevbp6rfn2eopptfgg0mum",
// };
// const redirectURL = "http://localhost:3000";
export const redirectURL = location.origin;

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function authorizeWithCognito() {
  const body = new URLSearchParams({
    response_type: "code",
    client_id: poolData.ClientId,
    redirect_uri: redirectURL + "/",
    state: uuidv4(),
    identity_provider: "Google",
    prompt: "select_account",
  }).toString();

  const url = authUrl + "/oauth2/authorize?" + body;

  window.location.href = url;

  // axios.get(url).catch((err) => console.log(err));
}

export function signInViaAuthCode(tokens) {
  const userName = parseJwt(tokens.id_token)["cognito:username"];
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: userName,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  const sessionData = {
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.access_token }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: tokens.refresh_token,
    }),
    IdToken: new CognitoIdToken({ IdToken: tokens.id_token }),
  };

  const session = new CognitoUserSession(sessionData);
  cognitoUser.setSignInUserSession(session);

  // window.location.href = "/kooliospace";
  return userData;
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function hideLoader() {
  let loader = document.getElementById("playlist-loader");
  loader.style.display = "none";
}

export function showLoader() {
  let loader = document.getElementById("playlist-loader");
  loader.style.display = "block";
}

export async function subscribePlan(selectedPlan, paymentVerfied = false) {
  topbar.show();
  (function step() {
    setTimeout(function () {
      if (topbar.progress("+.01") < 1) step();
    }, 30);
  })();
  const tokens = await Promise.all([
    Koolioai.authToken(),
    Koolioai.accessToken(),
  ]);
  // if payment not verified then user might be trying to upgrade to Free Plan.
  if (!paymentVerfied) {
    let plan = {
      plan_id: selectedPlan.id, //'f981025a-cbbb-385f-8e9d-1f7bcff12f5e',
      role_name: selectedPlan.role.name, //'new-trial'
    };
    try {
      const res = await axios.post(_config.api + "/payment-status", plan, {
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      });
    } catch (err) {
      showStatText(
        "Sorry! Plan subscription is unsuccessful. Please try again later.",
        false,
      );
      topbar.hide();
      return;
    }
  }
  <ShowMessageModal message={"Subscribing to new plan, please wait..."} />;
  showStatText("Subscribing to new plan, please wait...");
  //await timeout(3000);
  setTimeout(() => {
    initiateACM().then((acm) => {
      const container = document.getElementById("post-content");
      const root = ReactDOM.createRoot(container);
      root.render(
        <ShowMessageModal
          message1={"Congratulations!"}
          message2={`You have successfully subscribe to ${ACM.planName} Plan!`}
        />,
      );
      hideLoader();
      getPlanCancellationStatusFromDB();
      // checkAccountDetails();
      topbar.hide();
    });
  }, 5000);
}

/************************************ Cancel Subscription Part ****************************** */
async function getPlanCancellationStatusFromDB() {
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      axios
        .get(_config.api + "/check-subscription-status-2", {
          // To check is subscription is active or not
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((res) => {
          localStorage.setItem(
            "subscription_status",
            JSON.stringify(res.data.subscription_status),
          );
          // getPlanCancellationStatus()
          return Promise.resolve(res.data.subscription_status);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

async function getPlanCancellationStatusFromLS() {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("subscription_status") === null)
      reject(new Error("Key does not exist"));
    else {
      const subscription_status = JSON.parse(
        localStorage.getItem("subscription_status"),
      );
      if (subscription_status === "active") {
        document
          .getElementById("cancel-subscription-btn")
          .classList.remove("d-none");
        document
          .getElementById("cancel-subscription-btn")
          .classList.add("d-block");
      } else {
        document
          .getElementById("cancel-subscription-btn")
          .classList.remove("d-block");
        document
          .getElementById("cancel-subscription-btn")
          .classList.add("d-none");
      }

      resolve(subscription_status);
    }
  });
}
async function getPlanCancellationStatus() {
  getPlanCancellationStatusFromLS().catch(() => {
    getPlanCancellationStatusFromDB();
  });
}

export async function cancelPlan() {
  try {
    const params = {};
    Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) => {
        axios
          .post(_config.api + "/cancel-subscription", params, {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          })
          .then(() => {
            const container = document.getElementById("post-content");
            const root = ReactDOM.createRoot(container);
            root.render(
              <ShowMessageModal
                message1={"Subscription Cancelled Successfully"}
                message2={
                  "Your Koolio Subscription plan is successfully cancelled!"
                }
              />,
            );
            hideLoader();
            localStorage.setItem(
              "subscription_status",
              JSON.stringify("inactive"),
            );
            // document.getElementById(
            //   "cancel-subscription-btn",
            // ).style.visibility = "hidden";
            getPlanCancellationStatus();
          });
      },
    );
  } catch (err) {
    const container = document.getElementById("post-content");
    const root = ReactDOM.createRoot(container);
    root.render(
      <ShowMessageModal
        message1={"Subscription Cancellation Failed"}
        message2={"Your Koolio Subscription plan cancellation is failed"}
      />,
    );
  }
  return;
}
//for handling koolio taglines animation during login
export function mediaQueriesss() {
  document.getElementById("landing-screen").style.cssText =
    "visibility: visible !important";

  anime
    .timeline({
      easing: "easeOutExpo",
      duration: 750,
    })
    .add(
      {
        targets: ".landing-taglines",
        easing: "easeOutBack",
        opacity: [0, 1],
        duration: 2000,
        delay: 500,
      },
      "-=600",
    )
    .add(
      {
        targets: ".landing-taglines",
        easing: "easeOutBack",
        scale: 0.5,
        duration: 900,
        delay: 500,
        opacity: [1, 0],
      },
      "-=600",
    )
    .add(
      {
        targets: ".koolio-logo",
        opacity: [0, 1],
      },
      "-=600",
    )
    .add(
      {
        targets: ".landing-koolio-text",
        easing: "easeOutSine",
        opacity: [0, 1],
      },
      "-=700",
    )
    .add({
      complete: () => {
        // Your condition goes here
        if (document.getElementById("koolio-login-logo")) {
          document
            .getElementById("koolio-login-logo")
            .classList.remove("d-none");
          document.getElementById("koolio-space").classList.remove("d-none");
        }
      },
    });
}
