import React, { useEffect } from "react";
import { Tooltip } from "bootstrap";
import "@popperjs/core";

const useBootstrapTooltips = (elementId = null) => {
  useEffect(() => {
    // Function to hide the tooltip
    const hideTooltip = (tooltipInstance) => {
      if (tooltipInstance) {
        tooltipInstance.hide();
      }
    };

    // Function to initialize tooltips on a set of elements
    const initializeTooltips = (elements) => {
      elements.forEach((tooltipTriggerEl) => {
        // Dispose of existing tooltip instance before reinitializing
        const existingTooltip = Tooltip.getInstance(tooltipTriggerEl);
        if (existingTooltip) existingTooltip.dispose();

        // Initialize a new tooltip instance
        const tooltipInstance = new Tooltip(tooltipTriggerEl, {
          trigger: "hover", // https://stackoverflow.com/a/33585981
          boundary: "scrollParent", // Keep the tooltip within the parent container
          animation: true, // Animate the tooltip to add extra delay
          delay: { show: 500, hide: 100 }, // Increase the delay durations to avoid flickering
        });

        // Define event handlers
        const handleMouseLeave = () => hideTooltip(tooltipInstance);
        const handleClick = () => hideTooltip(tooltipInstance);

        // Attach event listeners to hide tooltips on mouse leave or click
        tooltipTriggerEl.addEventListener("mouseleave", handleMouseLeave);
        tooltipTriggerEl.addEventListener("click", handleClick);

        // Save event handlers for cleanup later
        tooltipTriggerEl._tooltipHandlers = { handleMouseLeave, handleClick };
      });
    };

    // Function to clean up tooltips (dispose and remove event listeners)
    const cleanupTooltips = (elements) => {
      elements.forEach((tooltipTriggerEl) => {
        const existingTooltip = Tooltip.getInstance(tooltipTriggerEl);
        if (existingTooltip) {
          existingTooltip.hide(); // Hide the tooltip
          existingTooltip.dispose(); // Properly dispose of the tooltip instance
        }

        // Clean up event listeners
        const handlers = tooltipTriggerEl._tooltipHandlers;
        if (handlers) {
          tooltipTriggerEl.removeEventListener(
            "mouseleave",
            handlers.handleMouseLeave,
          );
          tooltipTriggerEl.removeEventListener("click", handlers.handleClick);
          delete tooltipTriggerEl._tooltipHandlers; // Remove the custom handler reference
        }
      });
    };

    // Initialize tooltips globally or on specific elements
    const initializeAllTooltips = () => {
      // If `elementId` is provided, reinitialize tooltips so that we can fetch all new dynamically created tooltips
      const tooltipElements = elementId
        ? Array.from(document.querySelectorAll(`[title]`))
        : Array.from(document.querySelectorAll("[title]")); // Otherwise, initialize globally

      initializeTooltips(tooltipElements);
    };

    // Initial tooltip setup on component mount
    initializeAllTooltips();

    // Cleanup tooltips and event listeners on unmount
    return () => {
      const tooltipElements = elementId
        ? Array.from(document.querySelectorAll(`[title]`))
        : Array.from(document.querySelectorAll("[title]")); // Cleanup globally or for specific elements
      cleanupTooltips(tooltipElements);
    };
  }, [elementId]); // Re-run the effect if `elementId` changes
};

export default useBootstrapTooltips;
