// this slice is for when we move one publish form to other
import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  returnToPodcastClicked: "",
};

const closeExportToPodcastApiModal = createSlice({
  name: "closeExportToPodcastApiSlice",
  initialState: initialVal,
  reducers: {
    updateStateOfPodastClicked(state, action) {
      state.returnToPodcastClicked = action.payload.value;
      // console.log(state.returnToPodcastClicked);
    },
    clearStateOfPodcastClicked(state) {
      state.returnToPodcastClicked = "";
    },
  },
});

export default closeExportToPodcastApiModal.reducer;
export const closeExportToPodcastApiSliceActions =
  closeExportToPodcastApiModal.actions; // for copy buffer
