import { data } from "../components/operations/operations";
import {
  hideplaylistloader,
  showplaylistloader,
  updateKooliospace,
} from "../components/pages/kooliospace/kooliospaceUtils";
import store from "../redux/Store";
import { operationListSliceActions } from "../redux/slice/OperationListSlice";
import { projectDataSliceActions } from "../redux/slice/ProjectDataSlice";
import { projectTitleSliceActions } from "../redux/slice/ProjectTitleSlice";
import { UndoRedoSliceActions } from "../redux/slice/UndoRedoSlice";
import { addOperationsToDB } from "./indexedDButils";
import { ACM, create_UUID, restThings, sendOperation, showStatText } from "./utils";

export async function editTitleOperation(title, oldTitle) {
  // const permResponse = ACM.checkPermission('editProjectTitle')
  // if (!permResponse.success) return showStatText(permResponse.message)
  showplaylistloader();
  const operationId = create_UUID();
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  const projectEnv = store.getState().projectTitle.env;
  console.log(projectEnv);
  // OPStack.push(operationId)
  store.dispatch(
    UndoRedoSliceActions.sendResultstatAndType({
      resultStat,
      type: "editTitle",
    }),
  );

  updateProjectTitle(title);

  // const parentRefid = playlist.jobCode === 101 ? playlist.getJobHead() : recordPlaylist.getJobHead()
  const params = {
    jobname,
    refid: operationId,
    parentRefid: resultStat.refid,
    worker: "editTitle",
    inputs: {
      title,
    },
  };

  let newResultStat = JSON.parse(JSON.stringify(resultStat));
  newResultStat.fades = resultStat.fades ? resultStat.fades : [];
  newResultStat.sfx_src = resultStat.sfx_src;
  newResultStat.speakers_src = resultStat.speakers_src;
  newResultStat.project_title = title;
  newResultStat.refid = operationId;
  await restThings(params, newResultStat);
  store.dispatch(
    projectTitleSliceActions.updateProjectTitle({
      env: "workspace",
      projectTitle: title,
    }),
  ); //changing the title name after resultstat is changed
  
  hideplaylistloader();
  updateOperationSpace();
}

export function updateProjectTitle(title) {
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;
  let koolioSpace = JSON.parse(localStorage.getItem("kooliospace"));
  for (let i = 0; i < koolioSpace.length; i++) {
    if (koolioSpace[i].jobname === jobname) {
      koolioSpace[i].project_title = title;
      updateKooliospace(koolioSpace[i]);
      break;
    }
  }
  return Promise.resolve();
}

export function updateOperationSpace() {
  if (data.undoStack.length) {
    //enable undo icon
    document.getElementById("undoBtn").style.opacity = "1";
  } else {
    //disable the undo icon
    document.getElementById("undoBtn").style.opacity = "0.2";
  }
  if (data.redoStack.length) {
    //enable redo icon
    document.getElementById("redoBtn").style.opacity = "1";
  } else {
    //disable the redo icon
    document.getElementById("redoBtn").style.opacity = "0.2";
  }
}
