import { createSlice } from "@reduxjs/toolkit";

const initialvalue = {
  options: {
    format: "wav",
    filename: "",
    sampleRate: 44100,
    channels: 2,
    normalize: true,
    bitdepth: 16,
  },
};

const exportOptionSlice = createSlice({
  name: "exportOptionSlice",
  initialState: initialvalue,
  reducers: {
    updateFormat(state, action) {
      state.options.format = action.payload.format;
    },
    updateFileName(state, action) {
      state.options.filename = action.payload.fileName;
    },
    updateSamplerate(state, action) {
      state.options.sampleRate = action.payload.sampleRate;
    },
    updateChannels(state, action) {
      state.options.channels = action.payload.channels;
    },
    updateNormalize(state, action) {
      state.options.normalize = action.payload.normalize;
    },
    updatebitDepth(state, action) {
      state.options.bitdepth = action.payload.bitdepth;
    },
    clearData(state) {
      state.options = {
        format: "wav",
        filename: "",
        sampleRate: 44100,
        channels: 1,
        normalize: true,
        bitdepth: 16,
      };
    },
  },
});

export const exportOptionSliceActions = exportOptionSlice.actions;
export default exportOptionSlice.reducer;
