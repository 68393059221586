import { createSlice } from "@reduxjs/toolkit";

const initialvaue = {
  wavsurferObjGlobal: {},
};
const wavesurferObjGlobalslice = createSlice({
  name: "wavsurferObjGlobal",
  initialState: initialvaue,
  reducers: {
    updateWavesurferObjGlobal(state, action) {
      state.wavsurferObjGlobal = action.payload.wavesurferObjGlobal;
    },
    clearWavesurferObjGlobal(state) {
      state.wavsurferObj = {};
    },
  },
});
export const wavesurferObjGlobalActions = wavesurferObjGlobalslice.actions;
export default wavesurferObjGlobalslice.reducer;
