import React from "react";
import ReactDOM from "react-dom/client";
import "./css/dark/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
let GOOGLE_KEY;
async function setGoogleKeyDependingOnENV() {
  if (
    window.location.origin.includes("development.test.koolio.ai") ||
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    GOOGLE_KEY =
      "162974364783-0hmimkqe3qkq8d06708rdeviaeihs9u1.apps.googleusercontent.com";
  } else if (window.location.origin.includes("qa.test.koolio.ai")) {
    GOOGLE_KEY =
      "672739537382-n7ee7t4v18c9p0n0gnupbbd19o7rl617.apps.googleusercontent.com";
  } else {
    GOOGLE_KEY =
      "455603282234-nheccr71de3gr3l3t6s6vjl0bhouq657.apps.googleusercontent.com";
  }
}
setGoogleKeyDependingOnENV();
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_KEY} access_type="offline">
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
