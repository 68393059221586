import { createSlice } from "@reduxjs/toolkit";

const initailList = {
  annotationList: [],
  sfxAnnotationList: [],
  highlightAnnotationList: [],
  highlightSFXAnnotationList: [],
  // regionStartTime: 0,
  // regionEndTime: 0,
};
const annotationList = createSlice({
  name: "selectedRegion",
  initialState: initailList,
  reducers: {
    updateAnnotationList(state, action) {
      state.annotationList = action.payload;
    },
    updateSfxAnnotationList(state, action) {
      state.sfxAnnotationList = action.payload;
    },
    updateHighlightAnnotationList(state, action) {
      state.highlightAnnotationList.push(action.payload);
    },
    updateHighlightSFXAnnotationList(state, action) {
      state.highlightSFXAnnotationList.push(action.payload);
    },
    clearAnnotationList(state) {
      state.highlightAnnotationList = [];
    },
    clearSFXAnnotationList(state) {
      state.highlightSFXAnnotationList = [];
    },
  },
});

export const annotationListActions = annotationList.actions;
export default annotationList.reducer;
