import React, { useEffect, useState } from "react";
import "../../../css/dark/moreoption.css";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import ProjectTitle from "../workspace/navbarWorkspace/Title";
import PublishModalContent from "../workspace/modals/PublishModals";
import store from "../../../redux/Store";
import { exportOptionSliceActions } from "../../../redux/slice/ExportOptionsSlice";
import {
  checkFileConversionStatus,
  exportHighQualityAudio,
  historyOptions,
  isNativeExport,
  selectedApiProvider,
  showStatText,
} from "../../../utils/utils";
import HighExportModal from "../workspace/modals/HighExportModal";
import GenAIPortal from "./GenAiPortal";
import { SelectedApiProviderSliceActions } from "../../../redux/slice/SelectedApiProviderSlice";
import {
  calculateFileSize,
  getFormat,
  getSizeOfTheProject,
  openDropdown,
  selectUserName,
  setSharedUserList,
  shareInputKeyDown,
  shareOptions,
  showMessage,
} from "../../../utils/koolio-workspace";
import { useSelector } from "react-redux";
import $ from "jquery";

import { Koolioai, _config } from "../../../utils/cognitoAuth";
import axios from "axios";
import { getKooliospace } from "../../../services/KooliospaceServices";
import { saveKooliospace } from "../kooliospace/kooliospaceUtils";
import ProjectShareModal from "../workspace/modals/ProjectShareModal";
import { ShowProjectModalActions } from "../../../redux/slice/ShowProjectShareModal";
import DissociationModal from "../workspace/modals/DissociationModal";
import topbar from "topbar";
import useBootstrapTooltips from "../../../hooks/useBootstrapTooltips";
import { use } from "i18next";
import MoreOptionsTour from "../../tours/MoreOptionsTour";
// import genai from './genAi';

const Moreoptions = (props) => {
  // const { state } = location;
  // const shouldApplyBlur = state && state.blurBackground;
  const navigate = useNavigate();
  const showProjectModal = useSelector((state) => state.showProjectModal);
  const showDissociationModal = useSelector((state) => state.showProjectModal);

  const [selectedOption, setSelectedOption] = useState("export");
  const [displayProjectModal, setDisplayProjectModal] = useState(false);
  const [displayDissocationModal, setDisplayDissocationModal] = useState(false);
  const [shareProjectMessage, setshareProjectMessage] = useState("");
  const [dissociationProjectMessage, setDissociationProjectMessage] =
    useState("");

  const fileName = document.getElementById("projectname").textContent;
  const state = store.getState();
  const [jobName, setJobName] = useState("");
  const [editedProjectName, setEditedProjectName] = useState("");
  useBootstrapTooltips(selectedOption);

  useEffect(() => {
    setSelectedOption("export");
  }, []);
  useEffect(() => {
    console.log(showProjectModal);
    if (showProjectModal.showModal) {
      setDisplayProjectModal(true);
      setshareProjectMessage(showProjectModal.message);
    }
    if (showProjectModal.showDissociationModal) {
      setDisplayDissocationModal(true);
      setDissociationProjectMessage(showProjectModal.dissociatedInfo);
    }
  }, [showProjectModal]);

  function closeProjectModal() {
    setDisplayProjectModal(false);
    setDisplayDissocationModal(false);
    store.dispatch(ShowProjectModalActions.clearData());
  }

  async function deleteKoolioJob(kooliojob) {
    // ksUpdatedLocally.push(true);
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
      .then((tokens) =>
        axios.delete(_config.api + "/kooliospace", {
          data: {
            jobname: kooliojob,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        }),
      )
      .then(() =>
        getKooliospace().then((kooliospace) => {
          // console.log(kooliospace);
          kooliospace = kooliospace.filter((a) => a.jobname !== kooliojob);
          // console.log(kooliospace);
          saveKooliospace(kooliospace);
          navigate(`/kooliospace`, { replace: true });
          return Promise.resolve();
        }),
      );
  }

  function handleDeleteYes() {
    topbar.show();
    (function step() {
      setTimeout(function () {
        if (topbar.progress("+.01") < 1) step();
      }, 32);
    })();
    deleteKoolioJob(jobName).then(() => {
      topbar.hide();
    });
  }

  const handleHistoryOptions = () => {
    document.getElementById("history-icon").style.opacity = "1";
    document.getElementById("share-icon").style.opacity = "0.5";
    document.getElementById("export-icon").style.opacity = "0.5";
    document.getElementById("genAi-icon").style.opacity = "0.5";
    document.getElementById("delete-icon").style.opacity = "0.5";
    setSelectedOption("history");
  };

  const selectShareOptions = () => {
    document.getElementById("history-icon").style.opacity = "0.5";
    document.getElementById("share-icon").style.opacity = "1";
    document.getElementById("export-icon").style.opacity = "0.5";
    document.getElementById("genAi-icon").style.opacity = "0.5";
    document.getElementById("delete-icon").style.opacity = "0.5";
    setSelectedOption("share");
  };

  const exportOptions = () => {
    document.getElementById("history-icon").style.opacity = "0.5";
    document.getElementById("share-icon").style.opacity = "0.5";
    document.getElementById("export-icon").style.opacity = "1";
    document.getElementById("genAi-icon").style.opacity = "0.5";
    document.getElementById("delete-icon").style.opacity = "0.5";
    setSelectedOption("export");
  };

  const [showGenAI, setShowGenAI] = useState(false);

  function closeGenAIOptions() {
    setShowGenAI(false);
  }
  function handleBlur() {
    const oldProjectName = $("#exportprojectname").text();
    let newProjectName = this.textContent;
    // console.log(newProjectName);
    setEditedProjectName(newProjectName);
    // console.log(editedProjectName);
    if (!newProjectName.trim().length) {
      document.getElementById("exportprojectname").textContent = oldProjectName;
      setEditedProjectName(newProjectName);
      // console.log(editedProjectName);
      alert("The Export Filename Cannot Be Empty!"); // TODO: Implement proper showStatText() call
      showStatText("The Export Filename Cannot Be Empty!");
      return false;
    }
    this.setAttribute("contenteditable", false);
  }
  const exportProjectnameDblClick = (event) => {
    if (event) event.preventDefault();

    // const oldProjectName = $("#exportprojectname").text();

    $("#exportprojectname").attr("contenteditable", "true");
    $("#exportprojectname").trigger("focus");
    $("#exportprojectname").on("keypress", function (e) {
      if (e.which === 13 || e.key === "Enter") {
        e.target.blur();
        e.preventDefault();

        const newProjectName = $("#exportprojectname").text();

        if (!newProjectName.trim().length) {
          return; // Update the project name in the store here
        }
      }
    });

    $("#exportprojectname").off("blur").on("blur", handleBlur);
  };
  function DownloadFile() {
    let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    let wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;

    if (
      wavesurferObj &&
      wavesurferObj.backend &&
      wavesurferObj.backend.buffer &&
      wavesurferObjSFX &&
      wavesurferObjSFX.backend &&
      wavesurferObjSFX.backend.buffer
    ) {
      var originalBuffer = wavesurferObj.backend.buffer;
      var originalBufferSFX = wavesurferObjSFX.backend.buffer;

      // Assuming both buffers are of the same length
      var data_left = originalBuffer.getChannelData(0);
      var data_right = originalBufferSFX.getChannelData(0);

      var mixedChannel = new Float32Array(data_left.length);

      for (var i = 0; i < data_left.length; i++) {
        mixedChannel[i] = (data_left[i] + data_right[i]) / 2;
      }

      // Create a new buffer with the mixed channel
      var mixedBuffer = wavesurferObj.backend.ac.createBuffer(
        1,
        mixedChannel.length,
        originalBuffer.sampleRate,
      );
      mixedBuffer.copyToChannel(mixedChannel, 0);

      // Convert the audio buffer to a WAV file using a library like audiobuffer-to-wav
      var toWav = require("audiobuffer-to-wav");
      var wav = toWav(mixedBuffer);

      var blob = new Blob([wav], { type: "audio/wav" });
      forceDownload(blob);
    } else {
      return false;
    }

    function forceDownload(blob) {
      var url = (window.URL || window.webkitURL).createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      // a.download = "testDownload.wav";
      if (editedProjectName.length > 0) {
        a.download = editedProjectName;
      } else {
        a.download = fileName;
      }
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    }
  }
  const genAiOptions = () => {
    document.getElementById("history-icon").style.opacity = "0.5";
    document.getElementById("share-icon").style.opacity = "0.5";
    document.getElementById("export-icon").style.opacity = "0.5";
    document.getElementById("genAi-icon").style.opacity = "1";
    document.getElementById("delete-icon").style.opacity = "0.5";
    // console.log("click");
    setShowGenAI(true);
    // navigate("/genai");
  };

  const deleteOptions = () => {
    document.getElementById("history-icon").style.opacity = "0.5";
    document.getElementById("share-icon").style.opacity = "0.5";
    document.getElementById("export-icon").style.opacity = "0.5";
    document.getElementById("genAi-icon").style.opacity = "0.5";
    document.getElementById("delete-icon").style.opacity = "1";
    setSelectedOption("delete");
  };

  function exportFormatSettings() {
    if (document.getElementById("wav").checked) {
      document.getElementById("wav-options").style.display = "block";
      document.getElementById("mp3-options").style.display = "none";
      document.getElementById("m4a-options").style.display = "none";
      document.getElementById("btn-lq-download").disabled = false;
      $("#btn-lq-download").css({ opacity: "1", cursor: "pointer" });
    } else if (document.getElementById("mp3").checked) {
      document.getElementById("wav-options").style.display = "none";
      document.getElementById("mp3-options").style.display = "block";
      document.getElementById("m4a-options").style.display = "none";
      document.getElementById("btn-lq-download").disabled = true;
      document.getElementById("btn-lq-download").style.marginLeft = "0";
      document.getElementById("btn-lq-download").style.marginRight = "0";
      $("#btn-lq-download").css({ opacity: "0.5", cursor: "not-allowed" });
    } else if (document.getElementById("m4a").checked) {
      document.getElementById("wav-options").style.display = "none";
      document.getElementById("mp3-options").style.display = "none";
      document.getElementById("m4a-options").style.display = "block";
      document.getElementById("btn-lq-download").disabled = true;
      document.getElementById("btn-lq-download").style.marginLeft = "0";
      document.getElementById("btn-lq-download").style.marginRight = "0";
      $("#btn-lq-download").css({ opacity: "0.5", cursor: "not-allowed" });
    }
    getSizeOfTheProject();
  }

  const [showHqModal, setShowHqModal] = useState(false);
  const [showHqMessage, setShowHqMessage] = useState({});

  function closeHqModal() {
    setShowHqModal(false);
    setShowHqMessage({});
  }
  async function exportProject(audioQuality, aiEnabled = false) {
    // if (event) event.preventDefault();y
    const format = getFormat();
    const filename = document.getElementById("exportprojectname").innerText;
    const bitDepthorRate = document.getElementById(format + "-bit-depth").value;
    const sampleRate = document.getElementById(format + "-sample-rate").value;
    const channels = document.getElementById(format + "-channel").value;
    const normalize = $("#normalize-checkbox-uo").hasClass("checked");
    const options = {
      format,
      filename,
      sampleRate: parseInt(sampleRate),
      channels: parseInt(channels),
      normalize,
    };
    if (format === "wav") {
      options.bitDepth = bitDepthorRate;
      if (options.bitDepth === 32) {
        options.bitRate = "2048";
      }
    } else options.bitRate = bitDepthorRate;
    // var fileSize = await calculateFileSize(options)
    if (audioQuality === "high") {
      try {
        // $('#half-modals').html(highQualityExportModal)
        // koolioModals()
        let response = await exportHighQualityAudio(options, aiEnabled, false);
        setShowHqMessage({
          message: response.data.status_message,
          success: true,
        });
      } catch (error) {
        setShowHqMessage({ message: error, success: false });
      }
      setShowHqModal(true);
    } else {
      options.format = "wav";
      DownloadFile();
    }
    return;
  }

  const [showPublishModal, setShowPublishModal] = useState(false);
  const publishProjectModal = () => {
    setShowPublishModal(true);
  };

  function handleClosePublishModal() {
    setShowPublishModal(false);
    const state = store.getState().selectedApiProvider;
    if (state.selectedApiProvider) {
      store.dispatch(SelectedApiProviderSliceActions.clearApiProvider());
    }
  }

  const closeMoreOptions = (val = null) => {
    // navigate("/Workspace" )
    return props.onClose();
  };

  function handleNormalizeFunction() {
    let icon = document.getElementById("normalize-checkbox-uo");
    if (icon.src.endsWith("checkbox-checked.png")) {
      icon.src = "/image/checkbox-unchecked.png";
      store.dispatch(
        exportOptionSliceActions.updateNormalize({ normalize: false }),
      );
    } else {
      icon.src = "/image/checkbox-checked.png";
      store.dispatch(
        exportOptionSliceActions.updateNormalize({ normalize: true }),
      );
    }
  }

  // const [fileName, setFileName] = useState('')
  // const fileName = useSelector(state => state.exportOptionSlice.options.filename);

  useEffect(() => {
    // let fileName = state.exportOptionSlice.options.filename
    // setFileName(fileName)
    let resultStat = state.projectData.resultStat;
    const jobname = resultStat.jobname;
    setJobName(jobname);
  }, []);

  const [selectedChannel, setSelectedChannel] = useState("2"); // Default selected value is 'stereo (2)'

  const handleChannels = (event) => {
    setSelectedChannel(event.target.value);
    store.dispatch(
      exportOptionSliceActions.updateChannels({ channels: event.target.value }),
    );
  };

  const handleBitRate = (event) => {
    store.dispatch(
      exportOptionSliceActions.updateSamplerate({
        sampleRate: event.target.value,
      }),
    );
  };
  const handleBitDepth = (event) => {
    store.dispatch(
      exportOptionSliceActions.updatebitDepth({ bitdepth: event.target.value }),
    );
  };

  async function handleHqexport() {
    let jobname = store.getState().projectData.resultStat.jobname;
    if (!(await checkFileConversionStatus(jobname))) {
      return showMessage(
        "Export is not available currently",
        "File conversion in progress. Please, try after some time",
        2000,
        "error",
        "more-options",
      );
    }
    // const dataSynced = await syncOperationsInBackend();
    // if (!dataSynced) {
    //   return showMessage(
    //     "Operations synchronizing...",
    //     "Operations synchronization in progress. Please, try after some time",
    //     2000,
    //     "error",
    //     "more-options",
    //   );
    // } else {
    let totalOperation = store.getState().operationCountData.totalOperation;
    //  && document.getElementById("ai-enhancement-checkbox-uo").classList.contains("checked")
    if (totalOperation < 401) {
      exportProject("high", true);
    } else {
      exportProject("high", false);
      //console.log('calling aiEnhancement(true)')
    }
    // }
  }
  // function exportHQAudio(event, aiEnabled) {
  //   topbar.show(); // Ensure `topbar` is available globally or part of the component context
  //   (function step() {
  //     setTimeout(function () {
  //       if (topbar.progress("+.01") < 1) step();
  //     }, 30);
  //   })();

  //   exportProject("high", aiEnabled).then(() => {
  //     topbar.hide();
  //     // The button interaction can be managed through React state instead of jQuery
  //     // $('.export-high-quality-audio').removeClass('btn-active')  // commented in original code
  //   });
  // }

  function handleNormalizeCheckBox() {
    if ($("#normalize-checkbox-uo").hasClass("unchecked")) {
      $("#normalize-checkbox-uo").addClass("checked").removeClass("unchecked");
      $("#normalize-checkbox-uo").attr("src", "/image/checkbox-checked.png");
    } else {
      $("#normalize-checkbox-uo").addClass("unchecked").removeClass("checked");
      $("#normalize-checkbox-uo").attr("src", "/image/checkbox-unchecked.png");
    }
    getSizeOfTheProject();
  }
  const [triggerTooltip, setTriggerTooltip] = useState(false);
  useEffect(() => {
    if (selectedOption === "history") {
      setTriggerTooltip(false);
      async function historyCall(props) {
        await historyOptions(0, 0, props).then(() => {
          setTriggerTooltip(true);
        });
      }
      historyCall(props);
    } else if (selectedOption === "share") {
      setTriggerTooltip(false);
      async function shareOptionFunc() {
        await shareOptions().then(() => {
          setTriggerTooltip(true);
        });
      }
      shareOptionFunc();
    } else if (selectedOption === "export") {
      getSizeOfTheProject();
    } else if (selectedOption === "delete") {
    }
  }, [selectedOption]);
  useBootstrapTooltips(triggerTooltip ? "history" : "");
  return (
    <div>
      <MoreOptionsTour moreOptionsActive={props.moreOptions} />
      <div className="more-options-menu" id="more-options-menu">
        <div
          className="more-options-sidebar bg-dark"
          style={{
            textAlign: "center",
            position: "fixed",
            top: 0,
            zIndex: 100,
            height: "100%",
          }}
        >
          <img
            className="more-opt-logo"
            src="/img/more-options/koolio.png"
            alt=""
          />
          <div className="options">
            <div
              className="history menu-option"
              style={{ position: "relative" }}
              id="history-cont"
            >
              <div
                id="history-icon"
                className="history-icon tt"
                data-bs-placement="bottom"
              >
                <img
                  src="/img/more-options/history.png"
                  className="menu-option-logo"
                  alt=""
                  title="History"
                  onClick={() => handleHistoryOptions(0, 0)}
                />
              </div>
            </div>

            <div
              className="share menu-option"
              style={{ position: "relative" }}
              id="share-cont"
            >
              <div
                id="share-icon"
                className="share-icon tt"
                data-bs-placement="bottom"
              >
                <img
                  src="/img/more-options/share.png"
                  className="menu-option-logo"
                  alt=""
                  title="Share"
                  onClick={selectShareOptions}
                />
              </div>
            </div>

            <div
              className="export menu-option"
              style={{ position: "relative" }}
              id="export-cont"
            >
              <div
                id="export-icon"
                className="export-icon tt"
                data-bs-placement="bottom"
              >
                <img
                  src="/img/more-options/export.png"
                  style={{ opacity: "1 !important" }}
                  className="menu-option-logo"
                  alt=""
                  title="Export"
                  onClick={exportOptions}
                />
              </div>
            </div>

            <div
              className="generativeAi menu-option"
              style={{ position: "relative" }}
              id="genAi-cont"
            >
              <div
                id="genAi-icon"
                className="genAi-icon tt"
                data-bs-placement="bottom"
              >
                <img
                  src="/img/more-options/gen_icon_2.png"
                  style={{ opacity: "0.5 !important" }}
                  className="menu-option-logo"
                  alt=""
                  title="Gen AI"
                  onClick={genAiOptions}
                />
              </div>
            </div>
            {showGenAI && (
              <GenAIPortal
                isWhat="sfx"
                onClose={closeGenAIOptions}
                onCloseMoreOptions={closeMoreOptions}
              ></GenAIPortal>
            )}

            <div
              className="delete menu-option"
              style={{ position: "relative" }}
              id="delete-cont"
            >
              <div
                id="delete-icon"
                className="delete-icon tt"
                data-bs-placement="bottom"
              >
                <img
                  src="/img/more-options/delete.png"
                  className="menu-option-logo"
                  alt=""
                  title="Delete"
                  onClick={deleteOptions}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="secondhalf">  */}
        {selectedOption === "export" && (
          <div className="w-100 h-100 overflow-hidden" id="export">
            <div className="row options-header-cont">
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Export Settings
              </span>
              <span
                className="col-1 close-more-options"
                //
              >
                <img
                  className=""
                  id="close-more-options-export"
                  src="/img/exit-workspace.svg"
                  onClick={closeMoreOptions}
                  title="close"
                  alt=""
                />
              </span>
            </div>

            <div className="options-body" style={{ position: "relative" }}>
              <div className="more-options-header d-flex">
                <div className="project-filename">Export Filename:</div>

                <div
                  id="exportprojectname"
                  onDoubleClick={(e) => exportProjectnameDblClick(e)}
                  className="project-output-filename submenu-opt-left"
                  style={{ position: "absolute" }}
                >
                  {fileName}
                </div>
              </div>

              <div className="export-formats d-flex line-height-opt">
                <span className="export-format">Output Format:</span>

                <div
                  className="format-opt submenu-opt-left"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "3.33vw",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                  id="output-cont"
                >
                  <div className="format-opt-button">
                    <label className="d-flex">
                      <input
                        onClick={exportFormatSettings}
                        type="radio"
                        id="wav"
                        name="format"
                        value="wav"
                        defaultChecked
                      />
                      <span className="design"></span>
                      <span className="format-label">wav</span>
                    </label>
                  </div>

                  <div className="format-opt-button">
                    <label className="d-flex">
                      <input
                        onClick={exportFormatSettings}
                        type="radio"
                        id="mp3"
                        name="format"
                        value="mp3"
                      />
                      <span className="design"></span>
                      <span className="format-label">mp3</span>
                    </label>
                  </div>

                  <div className="format-opt-button">
                    <label className="d-flex tt">
                      <input
                        onClick={exportFormatSettings}
                        type="radio"
                        id="m4a"
                        name="format"
                        value="m4a"
                      />
                      <span className="design"></span>
                      <span className="format-label">m4a</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* wav options */}
              <div
                className="format-options"
                id="wav-options"
                style={{ display: "block", position: "relative" }}
              >
                <div
                  className="export-format-one d-flex line-height-opt"
                  id="wav-d11"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">
                    Bit Depth:
                  </span>
                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="wav-bit-depth-parent"
                    onClick={() => openDropdown("wav-bit-depth")}
                  >
                    <li className="dd-selected" value="16" id="wav-bit-depth">
                      16bit (int)
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select
                      className="mr-5"
                      name="bit-depth"
                      id="bit-depth-wav"
                    >
                      <option value="8">8bit (int)</option>
                      <option defaultValue="16">16bit (int)</option>
                      <option value="32">32bit (float)</option>
                    </select>
                  </div>
                </div>

                <div
                  className="export-format-two d-flex line-height-opt"
                  id="wav-d12"
                  style={{ display: "block", position: "relative" }}
                >
                  <span className="col-2 format-settings-title">
                    Sample Rate:
                  </span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="wav-sample-rate-parent"
                    onClick={() => openDropdown("wav-sample-rate")}
                  >
                    <li
                      className="dd-selected"
                      value="44100"
                      id="wav-sample-rate"
                    >
                      44.1khz
                      <i className="dropdown-arrow-uneq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="sample-rate" id="sample-rate-wav">
                      <option value="32000">32khz</option>
                      <option defaultValue="44100">44.1khz</option>
                      <option value="48000">48khz</option>
                    </select>
                  </div>
                </div>

                <div
                  className="export-format-three d-flex line-height-opt"
                  id="wav-d13"
                  style={{ display: "block", position: "relative" }}
                >
                  <span
                    className="col-2 format-settings-title"
                    style={{ position: "relative" }}
                  >
                    Channels:
                  </span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="wav-channels-parent"
                    onClick={() => openDropdown("wav-channel")}
                  >
                    <li className="dd-selected" value="2" id="wav-channel">
                      stereo (2)
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="channels" id="channels-wav">
                      <option value="1">mono (1)</option>
                      <option defaultValue="2">stereo (2)</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* mp3 options */}
              <div
                className="format-options"
                id="mp3-options"
                style={{ display: "none" }}
              >
                <div
                  className="export-format-one d-flex line-height-opt"
                  id="mp3-d21"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">Bitrate:</span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="mp3-bit-depth-parent"
                    onClick={() => openDropdown("mp3-bit-depth")}
                  >
                    <li className="dd-selected" value="320" id="mp3-bit-depth">
                      320kbps
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="bit-depth" id="bit-depth-mp3">
                      <option value="128">128kbps</option>
                      <option value="160">160kbps</option>
                      <option value="192">192kbps</option>
                      <option value="256">256kbps</option>
                      <option defaultValue="320">320kbps</option>
                    </select>
                  </div>
                </div>

                <div
                  className="export-format-two d-flex line-height-opt"
                  id="mp3-d22"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">
                    Sample Rate:
                  </span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="mp3-sample-rate-parent"
                    onClick={() => openDropdown("mp3-sample-rate")}
                  >
                    <li
                      className="dd-selected"
                      value="44100"
                      id="mp3-sample-rate"
                    >
                      44.1khz
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="sample-rate" id="sample-rate-mp3">
                      <option value="32000">32khz</option>
                      <option defaultValue="44100">44.1khz</option>
                      <option value="48000">48khz</option>
                    </select>
                  </div>
                </div>

                <div
                  className="export-format-three d-flex line-height-opt"
                  id="mp3-d23"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">Channels:</span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="mp3-channel-parent"
                    onClick={() => openDropdown("mp3-channel")}
                  >
                    <li className="dd-selected" value="2" id="mp3-channel">
                      stereo (2)
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="channels" id="channels-mp3">
                      <option value="1">mono (1)</option>
                      <option defaultValue="2">stereo (2)</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* m4a options */}
              <div
                className="format-options"
                id="m4a-options"
                style={{ display: "none" }}
              >
                <div
                  className="export-format-one d-flex line-height-opt"
                  id="m4a-d31"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">Bitrate:</span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="m4a-bit-depth-parent"
                    onClick={() => openDropdown("m4a-bit-depth")}
                  >
                    <li className="dd-selected" value="256" id="m4a-bit-depth">
                      256kbps
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="bit-depth" id="bit-depth-m4a">
                      <option value="128">128kbps</option>
                      <option value="160">160kbps</option>
                      <option value="192">192kbps</option>
                      <option value="224">224kbps</option>
                      <option defaultValue="256">256kbps</option>
                    </select>
                  </div>
                </div>

                <div
                  className="export-format-two d-flex line-height-opt"
                  id="m4a-d32"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">
                    Sample Rate:
                  </span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="m4a-sample-rate-parent"
                    onClick={() => openDropdown("m4a-sample-rate")}
                  >
                    <li
                      className="dd-selected"
                      value="44100"
                      id="m4a-sample-rate"
                    >
                      44.1khz
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="sample-rate" id="sample-rate-m4a">
                      <option value="32000">32khz</option>
                      <option defaultValue="44100">44.1khz</option>
                      <option value="48000">48khz</option>
                    </select>
                  </div>
                </div>

                <div
                  className="export-format-three d-flex line-height-opt"
                  id="m4a-d33"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title">Channels:</span>

                  <ul
                    className="dropdown dropdown-format-options submenu-opt-left"
                    id="m4a-channel-parent"
                    onClick={() => openDropdown("m4a-channel")}
                  >
                    <li className="dd-selected" value="2" id="m4a-channel">
                      stereo (2)
                      <i className="dropdown-arrow-eq fas fa-caret-down"></i>
                    </li>
                  </ul>

                  <div className="format-opt-sub-menu d-none">
                    <select name="channels" id="channels-m4a">
                      <option value="1">mono (1)</option>
                      <option defaultValue="2">stereo (2)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="format-options" id="normalize-audio">
                <div
                  className="export-format-three d-flex line-height-opt"
                  id="normalize-d33"
                  style={{ position: "relative" }}
                >
                  <span className="col-2 format-settings-title normalize-label mr-5">
                    <label
                      id="label-for-normalize"
                      htmlFor="normalize-checkbox-uo"
                    >
                      Normalize Audio:
                      <img
                        src="/image/checkbox-checked.png"
                        className="checked tt"
                        id="normalize-checkbox-uo"
                        data-bs-placement="top"
                        onClick={handleNormalizeCheckBox}
                        title="This will normalize your audio quality"
                        alt=""
                      />
                    </label>
                  </span>
                  {/* Uncomment the below section if needed for AI Enhancement */}
                  {/* <span className="col-3 format-settings-title normalize-label ml-5">
            <label id="label-for-ai-enhancement" htmlFor="ai-enhancement-checkbox-uo" className="ml-3">
                AI Audio Enhancement:
                <img
                    src="/static/img/checkbox-unchecked.png"
                    className="unchecked tt"
                    id="ai-enhancement-checkbox-uo"
                    data-bs-placement="top"
                    title="This will improve your HQ audio using AI"
                    alt=""
                />
            </label>
        </span> */}
                  <div className="format-opt-sub-menu d-none">
                    <select name="channels" id="channels-m4a">
                      <option value="1">mono (1)</option>
                      <option selected value="2">
                        stereo (2)
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="export-options d-flex">
                <div className="action-project line-height-opt normal-export">
                  <button
                    onClick={(event) => exportProject(event, "normal")}
                    id="btn-lq-download"
                    className="action-project-button"
                  >
                    Instant Download
                  </button>
                </div>

                <div className="action-project line-height-opt high-quality-export">
                  <button
                    className="action-project-button export-high-quality-audio"
                    id="btn-hq-export"
                    onClick={handleHqexport}
                  >
                    Enhanced Audio
                  </button>
                </div>

                <div
                  className="action-project line-height-opt high-quality-export"
                  id="publish-cont"
                >
                  <button
                    className="action-project-button"
                    id="publish-button"
                    onClick={publishProjectModal}
                  >
                    Publish
                  </button>
                </div>
              </div>

              <span id="project-size">Project size: 0 MB</span>
            </div>
            {showPublishModal && (
              <PublishModalContent
                onClose={handleClosePublishModal}
              ></PublishModalContent>
            )}
            {showHqModal && (
              <HighExportModal
                onClose={closeHqModal}
                message={showHqMessage}
              ></HighExportModal>
            )}
            {/* ... Other components and code in your component ... */}
          </div>
        )}

        {selectedOption === "history" && (
          <div className="w-100 h-100 overflow-hidden" id="history">
            <div
              className="row options-header-cont"
              style={{ position: "relative" }}
            >
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Project History
              </span>
              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-more-options-history"
                  src="/image/exit-workspace.svg"
                  onClick={closeMoreOptions}
                  alt=""
                />
              </span>
            </div>

            <div className="options-body" style={{ position: "relative" }}>
              <hr
                style={{
                  height: "0.1vh",
                  width: "75vw",
                  color: "#E2522B",
                  opacity: 0.4,
                }}
              />

              <div className="history-heading more-options-header col-9 text-center d-flex">
                <div id="collaborators" className="history-header-data col-3">
                  Collaborators
                </div>
                <div id="timestamp" className="history-header-data col-3">
                  Timestamp
                </div>
                <div id="operations" className="history-header-data col-3">
                  Operations
                </div>
                <div id="actions" className="history-header-data col-3">
                  Actions
                </div>
              </div>

              <hr
                className="mb-4"
                style={{
                  height: "0.1vh",
                  width: "75vw",
                  color: "#E2522B",
                  opacity: 0.4,
                }}
              />

              <div id="history-main" className="history-main col-9">
                {/* Add dynamic content here */}
              </div>

              <div
                className="col-9 d-flex justify-content-center align-items-center"
                id="historyPageControl"
              >
                <nav aria-label="Page navigation example">
                  <ul className="pagination" id="historyPageContainer">
                    {/* Add pagination content here */}
                  </ul>
                </nav>
              </div>

              {/* <img className="" id="history-page-arrow-left" src="/static/img/arrow-right.png" alt="right" />
    <img className="" id="history-page-arrow-right" src="/static/img/arrow-left.png" alt="left"  /> */}
              {/* <div style={{ color: '#8A2BE2' }} id="history-loading"></div> */}

              {/* Add additional content or actions */}
            </div>
          </div>
        )}

        {selectedOption === "share" && (
          <div className="w-100 h-100 overflow-hidden" id="share">
            <div
              className="row options-header-cont"
              style={{ position: "relative" }}
            >
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Invite Friends
              </span>
              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-more-options-share"
                  src="/img/exit-workspace.svg"
                  onClick={closeMoreOptions}
                  alt=""
                />
              </span>
            </div>

            <div className="options-body">
              <div className="share-window d-flex line-height-opt-bottom align-items-start">
                <div className="share-email-list">
                  <input
                    className="share-email-list-msg"
                    id="shareEmailListMsg"
                    type="text"
                    placeholder="Type one or more names or emails"
                    isVerified="false"
                    autoComplete="off"
                    onKeyDown={shareInputKeyDown}
                    onFocus={setSharedUserList}
                  />
                  <div className="suggestion" id="suggestionList">
                    <ul className="searchList" id="searchSuggestionList"></ul>
                  </div>
                </div>
                <div
                  className="position-relative margin-left-alt"
                  id="share-modes-grandparent"
                  style={{ position: "relative" }}
                >
                  <ul
                    className="dropdown-share dropdown-format-options"
                    id="share-modes-parent"
                  >
                    <li
                      className="dd-selected-alt"
                      value="view"
                      onClick={() => openDropdown("share-modes")}
                      id="share-modes"
                    >
                      View
                      <i
                        className="fas fa-caret-down"
                        style={{
                          position: "absolute",
                          top: "22%",
                          right: "9%",
                        }}
                      ></i>
                    </li>
                  </ul>
                  <div className="format-opt-sub-menu1 d-none">
                    <select name="permission" id="share-modes-select">
                      <option value="view">View</option>
                      <option value="edit">Edit</option>
                    </select>
                  </div>
                </div>
                <div className="action-project margin-left-alt">
                  <button
                    id="share-project"
                    className="share-project-button"
                    onClick={selectUserName}
                  >
                    Send Invites
                  </button>
                </div>
              </div>

              <hr
                style={{
                  height: "0.1vh",
                  width: "75vw",
                  color: "#E2522B",
                  opacity: 0.4,
                }}
              />

              <div className="history-heading more-options-header col-9 d-flex text-center">
                <div id="collaborator" className="history-header-data col-3">
                  Shared With
                </div>
                <div id="permission" className="history-header-data col-3">
                  Mode
                </div>
                <div id="timestamp" className="history-header-data col-3">
                  Shared at
                </div>
                <div id="action" className="history-header-data col-3">
                  Actions
                </div>
              </div>

              <hr
                className="mb-4"
                style={{
                  height: "0.1vh",
                  width: "75vw",
                  color: "#E2522B",
                  opacity: 0.4,
                }}
              />

              <div id="share-history-main" className="history-main"></div>

              <div
                style={{ color: "#8A2BE2" }}
                id="share-history-loading"
              ></div>
            </div>
            {displayProjectModal && (
              <ProjectShareModal
                onClose={closeProjectModal}
                message={shareProjectMessage}
              ></ProjectShareModal>
            )}
            {displayDissocationModal && (
              <DissociationModal
                onClose={closeProjectModal}
                message={dissociationProjectMessage}
              ></DissociationModal>
            )}
          </div>
        )}

        {/* {selectedOption === 'genAi' &&(
  <div className="w-100" id="genSFX">
  <div className="row options-header-cont" style={{ position: 'relative' }}>
    <span className="col-11 more-options-menu-header" style={{ position: 'relative', padding: 0 }}>SFX Generation</span>
    <span className="col-1 close-more-options">
      <img className="img-fluid" id="close-gen-ai-options-sfx" src="/img/exit-workspace.svg"alt="" />
    </span>
  </div>

  <div className="options-body" style={{ position: 'relative', width: '80%' }}>
    <div className="more-options-header-genAi">
      This will provide you various options to create and choose your own SFX with AI.
    </div>

    <div id="genAiSFXContent" className="genAiContent col-12">
      <div id="prevGenSFXContainer" className="col-4">
        <p style={{ color: 'rgb(226, 82, 43)' }}>Previously Generated SFX</p>
        <img className="gen-music-arrows"  id="gen-sfx-arrow-up" src="./img/more-options/angle-up-solid.png" style={{ opacity: 0.5 }} alt="up" />
        <div id="prev_gen_sfx"></div>
        <img className="gen-music-arrow" id="gen-sfx-arrow-down" src="./img/more-options/angle-down-solid.png" alt="down" />
      </div>
      <div className="col-7" style={{ position: 'relative' }}>
        <div id="genSFXInputContainer">
          <input id="genSFXSearchBar"autoComplete="off" style={{ textIndent: 0, marginLeft: 0, width: '80%', padding: '0% 2%' }} className="transcriptSearch sfx" type="text" placeholder="Type your prompt here..." />
          <div id="genSFXInfoText" className="gen-info-icon tt" data-bs-placement="bottom" title="Describe the sound, scenario, and evolution. e.g., 'Whispering wind gradually becoming ominous in a spooky forest.'">
            <i className="fas fa-info-circle"></i>
          </div>
        </div>
        <input id="sfxInvisibleSearchBar" autoComplete="off" style={{ textIndent: 0, marginLeft: 0, display: 'none' }} className="transcriptSearch sfx" type="text" />
        <br />
        <div style={{ height: '3vh', position: 'absolute', width: '100%' }} className="mt-2">
          <div id="sfxSearchCommand" className="noresults-font-style" style={{ display: 'none' }}>Please input a search text to create your SFX</div>
          <div id="create-sfx-progress-box" style={{ height: '4vh' }}></div>
          <div id="gen-sfx-loader" style={{ position: 'relative', backgroundColor: 'rgba(0,0,0,0)', display: 'none', zIndex: 15 }}>
            <img src="static/img/blueviolet-loadingspinner.gif" style={{ position: 'absolute', left: '48%', top: '50%', width: '3%' }} className="ajax-loader" />
          </div>
          <div id="modal_gen_sfx" className="mt-2"></div>
          <div id="modal_gen_sfx_hidden" style={{ display: 'none' }}></div>
        </div>
        <div id="createGenSFX" style={{ position: 'absolute', top: '0%', right: '-5%' }} className="">
          <div className="action-project d-flex justify-content-center" id="sfx-gen-button-box">
            <button id="create-gen-sfx-button" className="create-music-button gen-music-button">Create</button>
          </div>
        </div>
      </div>
    </div>

    <div id="gen-sfx-spinner" className="" style={{ display: 'none', position: 'absolute', top: '40%', left: '44%' }}>
      <img src="static/img/blueviolet-loadingspinner-playlist.gif" style={{ width: 'calc(4vw + 4vh)' }} className="ajax-loader" />
      <p style={{ display: 'block', color: '#c4c4c4' }}>Please wait...</p>
    </div>
  </div>
  <div className="footer">
    <div id="gen_sfx_citation" style={{ display: 'block', cursor: 'pointer' }}>
      <div className="gen-ai-text-slide text-right" style={{ position: 'relative', right: '5%' }}>* References</div>
    </div>
    <span id="stat-text-modal"></span>
  </div>
</div>

)} */}

        {selectedOption === "delete" && (
          <div className="w-100 h-100 overflow-hidden" id="delete">
            <div
              className="row options-header-cont"
              style={{ position: "relative" }}
            >
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Delete Project
              </span>
              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-more-options-delete"
                  src="/img/exit-workspace.svg"
                  onClick={closeMoreOptions}
                  alt=""
                />
              </span>
            </div>

            <div
              id="delete-body"
              className="options-body"
              style={{ position: "absolute" }}
            >
              <div className="more-options-header" id="delete-header">
                Deleting this project will remove it from your kooliospace
              </div>

              <div className="action-project-delete line-height-opt-alt">
                <button
                  id="delete-project-button"
                  className="action-project-button"
                  onClick={handleDeleteYes}
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    // </div>
  );
};

export default Moreoptions;
