import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ children }) {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.signInUser);

  if (!user.userName) {
    return <Navigate to="/" state={{ path: pathname }} />;
  }

  return <>{children}</>;
}
