import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../../../css/dark/KoolioNavbar.css";
import { showNotifications } from "./kooliospaceUtils";
import ProfileModal from "../../../components/modals/profileModal";
import { Koolioai } from "../../../utils/cognitoAuth";
import { fetchuserName } from "../../../utils/utils";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-regular-svg-icons";
// import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
function KoolioNavbar({ fileList, prepareKooliospaceUI }) {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showProfile, setShowProfile] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationClick, setNotificationClick] = useState(true);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const notificationContainer = document.querySelector(".notification");
      const notificationIcon = document.getElementById("notification_icon");
      if (
        notificationContainer &&
        !notificationContainer.contains(event.target)
      ) {
        if (notificationContainer.style.display === "block") {
          notificationContainer.style.display = "none";
          notificationIcon.classList.replace(
            "notification_icon_active",
            "notification_icon_inactive",
          );
        } else {
          setNotificationClick((prevState) => true);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // function handleNotifications(){
  //     showNotifications()
  // }
  // return (
  //     <Navbar className="navbar" data-bs-theme="dark">
  //         <div  className=" d-flex justify-content-end container-fluid">
  //             <Nav className=" mr-auto">
  //                 <Nav.Link href="">
  //                     <img  style={{ zIndex: "11",height:"20px" }}  src="../../image/search-icon.png"  alt="" />
  //                 </Nav.Link>
  //                 <Nav.Link href="">
  //                     <img className="notification_icon notification_icon_inactive" style={{ zIndex: "11" }}  src="../../image/notification-icon.png" alt=""  onClick={handleNotifications}/>

  //                 </Nav.Link>
  //                 <Nav.Link href="">
  //                     <img className="user-profile spk-slid" style={{ zIndex: "11" }} id="user-profile-home" src="https://ui-avatars.com/api/?name=Ayush845&background=181818&color=fff&rounded=true&size=31" alt="" />
  //                 </Nav.Link>
  //             </Nav>
  //         </div>
  //     </Navbar>
  // );
  const handleNotifications = (event) => {
    setNotificationClick((prevState) => !prevState);
    if (notificationClick) {
      showNotifications();
    }
  };

  const hideNotificationWindow = () => {
    // Implement your hideNotificationWindow function logic here
  };

  const userProfileModal = (event, flag) => {
    event.preventDefault();
    setShowProfileModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowProfileModal(false); // Close the modal
  };

  useEffect(() => {
    const profileImageUrl = fetchuserName();
    setShowProfile(profileImageUrl);
  }, []);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    const list = fileList;
    const filteredUploadFileList = list.filter((uploadedFile) =>
      uploadedFile.projectTitle
        .toLowerCase()
        .includes(e.target.value.toLowerCase()),
    );
    if (e.target.value.length > 1) {
      prepareKooliospaceUI(filteredUploadFileList);
    } else {
      prepareKooliospaceUI(fileList);
    }
    e.preventDefault();
  };

  // Handle click outside the search bar
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      document.getElementById("search_icon").classList.remove("d-none");
      document.getElementById("searchBoxInKoolioSpace").classList.add("d-none");
    }
  };

  // Add event listener for clicks outside the search bar
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleSearchIconClick() {
    document.getElementById("search_icon").classList.add("d-none");
    document
      .getElementById("searchBoxInKoolioSpace")
      .classList.remove("d-none");
  }
  return (
    <>
      <header
        className="site-header-home container-fluid"
        id="site-header-home"
        style={{ padding: 0 }}
      >
        <div className="row koolio-nav" id="header">
          <div className="Container_notification_in_koolio">
            {/* <img className="notification_icon notification_icon_inactive" id="notification_icon" style={{ zIndex: "11" }}  src="../../image/notification-icon.png" alt=""  onClick={handleNotifications}/> */}
            <i
              className="fa-regular fa-bell notification_icon notification_icon_inactive"
              id="notification_icon"
              title="notification"
              style={{ zIndex: "11" }}
              onClick={handleNotifications}
            />
            {/* <i className="fa-regular fa-bell notification_icon notification_icon_inactive" id="notification_icon" onClick={showNotifications}></i> */}
            <h5 className="pending_notification" id="pending_notification">
              9+
            </h5>
          </div>

          <div className="notification" id="notification-div">
            <ul className="notification-list" id="notification-list">
              {/* <li className="notification-content"> saha has invited you to collaborate on a koolio project 1 minute moment rainbow </li> */}
            </ul>
          </div>

          <div className="Container_search_in_koolio">
            <input
              className="search_in_koolio d-none"
              id="searchBoxInKoolioSpace"
              onFocus={hideNotificationWindow}
              type="text"
              placeholder="Search..."
              ref={searchRef}
              // autoComplete="on"
              value={searchTerm}
              onChange={(e) => handleChange(e)}
            />
            <img
              className="search_icon"
              src="/image/search-icon.png"
              id="search_icon"
              alt=""
              title="search-icon"
              onClick={handleSearchIconClick}
            />
          </div>
          <div className="col-3 text-left">
            {/* <!-- <img src="static/img/logo-test.png" alt="" /> --> */}
          </div>

          <div className="col-6 text-center">
            <center>
              {/* <!-- <span id='save-icon' className='save-icon' hidden>
                            <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        <span id='projectname' onDoubleClick={projectnameDblClick} className='project-name'></span> --> */}
            </center>
          </div>

          <div
            className="col-3 text-right"
            id="right-home"
            style={{ padding: 0, position: "relative" }}
          >
            <span onClick={(event) => userProfileModal(event, false)}>
              <img
                className="user-profile spk-slid"
                style={{ zIndex: 11, cursor: "pointer" }}
                id="user-profile-home"
                src={showProfile}
                title="user-profile"
                alt=""
              />
            </span>
            <span id="kooliospace-close-home" style={{ cursor: "pointer" }}>
              <img
                title="close project"
                className="img-fluid invisible close-workspace"
                id="close-workspace-home"
                src="static/img/exit-workspace.svg"
                alt=""
              />
            </span>
          </div>
        </div>
      </header>
      {showProfileModal && <ProfileModal closeModal={closeModal} />}
    </>
  );
}

export default KoolioNavbar;
