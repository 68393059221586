import React, { useEffect, useState } from "react";
import "../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { koolioModals } from "../../utils/Res";
import { createPortal } from "react-dom";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

export default function ShowMessageModal({ message1, message2 }) {
  const [closeModal, setCloseModal] = useState(true);
  useEffect(() => {
    koolioModals();
  }, []);
  return createPortal(
    <>
      {closeModal && (
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p id="planCancelSuccessModal-modal-header">{message1}</p>
            <img
              title="close"
              className="img-fluid"
              onClick={() => {
                setCloseModal(false);
              }}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "not-allowed", visibility: "hidden" }}
              src="./image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>
          <div
            className="half-modals-sub-header"
            id="planCancelSuccessModal-modal-sub-header"
          >
            <p> {message2}</p>
          </div>
          <div className="half-modals-action">
            <span className="half-modals-actions text-center">
              <button
                type="button"
                onClick={() => {
                  setCloseModal(false);
                }}
                className="half-modals-action-button"
              >
                Ok
              </button>
            </span>
          </div>
        </div>
      )}
    </>,
    document.querySelector("body"),
  );
}
