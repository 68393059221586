import { createSlice } from "@reduxjs/toolkit";

const intialVal = {
  screenReloads: false,
};

const screenReloadSlice = createSlice({
  name: "screenReload",
  initialState: intialVal,
  reducers: {
    updateScreenReload(state, action) {
      state.screenReloads = action.payload.value;
    },
  },
});

export const screenReloadSliceActions = screenReloadSlice.actions;
export default screenReloadSlice.reducer;
