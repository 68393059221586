//==================================*******Audio conversion for IndexedDB**********=========================================

import axios from "axios";
import { hideplaylistloader } from "../components/pages/kooliospace/kooliospaceUtils";
import store from "../redux/Store";
import { projectDataSliceActions } from "../redux/slice/ProjectDataSlice";
import { getOperationsList } from "../services/globalServices";

// Returns Uint8Array of WAV bytes
function getWavBytes(buffer, options) {
  const type = options.isFloat ? Float32Array : Uint16Array;
  const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT;

  const headerBytes = getWavHeader(Object.assign({}, options, { numFrames }));
  const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

  // prepend header, then add pcmBytes
  wavBytes.set(headerBytes, 0);
  wavBytes.set(new Uint8Array(buffer), headerBytes.length);

  return wavBytes;
}

// adapted from https://gist.github.com/also/900023
// returns Uint8Array of WAV header bytes
function getWavHeader(options) {
  const numFrames = options.numFrames;
  const numChannels = options.numChannels || 2;
  const sampleRate = options.sampleRate || 44100;
  const bytesPerSample = options.isFloat ? 4 : 2;
  const format = options.isFloat ? 3 : 1;

  const blockAlign = numChannels * bytesPerSample;
  const byteRate = sampleRate * blockAlign;
  const dataSize = numFrames * blockAlign;

  const buffer = new ArrayBuffer(44);
  const dv = new DataView(buffer);

  let p = 0;

  function writeString(s) {
    for (let i = 0; i < s.length; i++) {
      dv.setUint8(p + i, s.charCodeAt(i));
    }
    p += s.length;
  }

  function writeUint32(d) {
    dv.setUint32(p, d, true);
    p += 4;
  }

  function writeUint16(d) {
    dv.setUint16(p, d, true);
    p += 2;
  }

  writeString("RIFF"); // ChunkID
  writeUint32(dataSize + 36); // ChunkSize
  writeString("WAVE"); // Format
  writeString("fmt "); // Subchunk1ID
  writeUint32(16); // Subchunk1Size
  writeUint16(format); // AudioFormat https://i.stack.imgur.com/BuSmb.png
  writeUint16(numChannels); // NumChannels
  writeUint32(sampleRate); // SampleRate
  writeUint32(byteRate); // ByteRate
  writeUint16(blockAlign); // BlockAlign
  writeUint16(bytesPerSample * 8); // BitsPerSample
  writeString("data"); // Subchunk2ID
  writeUint32(dataSize); // Subchunk2Size

  return new Uint8Array(buffer);
}

export async function convertAudioBufferToBlob(audioBuffer) {
  var channelData = [],
    totalLength = 0,
    channelLength = 0;

  for (var i = 0; i < audioBuffer.numberOfChannels; i++) {
    channelData.push(audioBuffer.getChannelData(i));
    totalLength += channelData[i].length;
    if (i == 0) channelLength = channelData[i].length;
  }

  // interleaved
  const interleaved = new Float32Array(totalLength);

  for (
    let src = 0, dst = 0;
    src < channelLength;
    src++, dst += audioBuffer.numberOfChannels
  ) {
    for (var j = 0; j < audioBuffer.numberOfChannels; j++) {
      interleaved[dst + j] = channelData[j][src];
    }
    //interleaved[dst] = left[src];
    //interleaved[dst + 1] = right[src];
  }

  // get WAV file bytes and audio params of your audio source
  const wavBytes = getWavBytes(interleaved.buffer, {
    isFloat: true, // floating point or 16-bit integer
    numChannels: audioBuffer.numberOfChannels,
    sampleRate: audioBuffer.sampleRate,
  });
  const wav = new Blob([wavBytes], { type: "audio/wav" });
  return wav;
}

export async function getArrayBufferFromSrcURL(url) {
  console.trace("kidda");
  if (url instanceof File) {
    const arrayBuffer = await url.arrayBuffer();
    return arrayBuffer;
  } else if (url instanceof AudioBuffer) {
    const blob = await convertAudioBufferToBlob(url);
    const arrayBuffer = await blob.arrayBuffer();
    return arrayBuffer;
  } else if (url instanceof ArrayBuffer) {
    return url;
  } else if (url instanceof Float32Array) {
    return url;
  } else {
    let res;
    try {
      res = await fetch(url);
      // Handle the response data as needed, e.g., res.json() or res.text()
      const blob = await res.blob(); // request as Blob
      // return blob
      const arrayBuffer = await blob.arrayBuffer();
      return arrayBuffer;
    } catch (error) {
      console.error("An error occurred while fetching the URL:", error);
      try {
        const response = await axios.get(url, { responseType: "blob" });
        if (response.status === 404) {
          hideplaylistloader();
        }
        const blob = response.data;
        const arrayBuffer = await blob.arrayBuffer();
        hideplaylistloader();
        return arrayBuffer;
      } catch (error) {
        let decodedURL = decodeURI(url);
        decodedURL = decodedURL.replace(" ", "+"); // change the pathname
        const response = await axios.get(decodedURL, { responseType: "blob" });
        if (response.status === 404) {
          hideplaylistloader();
        }
        const blob = response.data;
        const arrayBuffer = await blob.arrayBuffer();
        hideplaylistloader();
        return arrayBuffer;
      } // Ensure this function is defined elsewhere
    }
  }
}
//converting srcURL into audioBuffer
export async function getAudioBufferFromSrcURL(url) {
  let audioContext;
  try {
    audioContext = new AudioContext();
  } catch (e) {
    console.error("Web Audio API is not supported in this browser");
    return null;
  }

  let audioBuffer;
  try {
    const arrayBuffer = await getArrayBufferFromSrcURL(url);
    audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  } catch (error) {
    console.error("Error converting URL to AudioBuffer:", error);
    return null;
  }

  return audioBuffer;
}
//==================================*******Audio conversion for IndexedDB**********=========================================

//=====================*****************IndexedDB interaction functions***************======================
const DB_VERSION = 2; // Increase the version to trigger an upgrade
const STORE_RESULTSTAT = "koolio_audio_data";
const STORE_OPERATION = "koolio_operations_data";
let indexDBResultStat;

async function initializeIndexedDB() {
  return new Promise((resolve, reject) => {
    const DB_NAME = `koolio_AudioDB_{Koolioai.getDisplayName()}`;
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const storeIndexedDb = db.createObjectStore(STORE_RESULTSTAT, {
        keyPath: "id",
        autoIncrement: true,
      });
      storeIndexedDb.createIndex("jobname", "jobname", { unique: true });
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function getResultStatFromIndexedDB(jobname) {
  try {
    let selectedFile = await retrieveAudioFileByJobname(jobname);
    return selectedFile.data;
  } catch (error) {
    //console.error("Failed to load audio file from IndexedDB:", error)
    return null;
  }
}

async function addAudioFile(localResultStat, jobname, createdAt) {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
    const request = storeIndexedDb.add({
      data: localResultStat,
      jobname: jobname,
      created_at: createdAt,
      updatedAt: new Date().getTime(),
    });

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function storeAudioFile(file, jobname, created_at, newResultStat = {}) {
  try {
    const arraybuffer = await file.arrayBuffer();
    const speakerSegment = {
      ...newResultStat.speakers_segments[0],
      src: arraybuffer,
    };
    let resultStat = { ...newResultStat };
    resultStat.speakers_segments = [speakerSegment];
    resultStat.speakers_src = arraybuffer;
    await addAudioFile(resultStat, jobname, created_at);
    // uploadedFile = null
  } catch (error) {
    console.error("Failed to store audio file in IndexedDB:", error);
  }
}

async function getAllAudioFiles() {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readonly");
    const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
    const request = storeIndexedDb.getAll();

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function retrieveAudioFileByJobname(jobname) {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readonly");
    const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
    const request = storeIndexedDb.index("jobname").get(jobname);
    request.onsuccess = (event) => {
      const audioFile = event.target.result;
      if (audioFile) {
        resolve(audioFile);
      } else {
        // reject(`Audio file with jobname ${jobname} not found in IndexedDB`)
        resolve(null);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function updateTranscriptionIndexDB(jobnameID, jobheadID, resultStat) {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
    const request = storeIndexedDb.index("jobname").get(jobnameID);

    request.onsuccess = (event) => {
      const job = event.target.result;
      const promises = resultStat.speakers_segments.map((track) => {
        return new Promise((r) => r(getArrayBufferFromSrcURL(track.src)));
      });
      Promise.all(promises).then((arrayBuffers) => {
        arrayBuffers.map((ab, i) => (resultStat.speakers_segments[i].src = ab));
        if (jobheadID) resultStat.refid = jobheadID;
        resultStat.speakers_src = job.data.speakers_src;
        job.data = resultStat;
        store.dispatch(
          projectDataSliceActions.projectReducerData({ resultStat }),
        );
        //indexDBResultStat = currentlocalResultStat
        const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
        const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
        const requestUpdate = storeIndexedDb.put(job);
        requestUpdate.onerror = (event) => {
          reject(event.target.error);
        };
        requestUpdate.onsuccess = (event) => {
          resolve(event.target.result);
        };
      });
    };
    request.onerror = (event) => {
      return reject(event.target.error);
    };
  });
}

async function deleteIndexDBProjectResultstat(jobnameIndex) {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
    const request = storeIndexedDb.index("jobname").get(jobnameIndex);

    request.onsuccess = (event) => {
      const resultstat = event.target.result;
      if (resultstat) {
        const requestUpdate = storeIndexedDb.delete(resultstat.id);
        requestUpdate.onerror = (event) => {
          reject(event.target.error);
        };
        requestUpdate.onsuccess = (event) => {
          resolve(event.target.result);
        };
      } else {
        resolve(event.target.result);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function deleteOldIndexDBdata(deleteFromIndex) {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_RESULTSTAT);
    const request = storeIndexedDb.getAll();

    request.onsuccess = (event) => {
      const resultsStats = event.target.result;
      resultsStats.sort(function (a, b) {
        return b.updatedAt - a.updatedAt;
      });
      if (resultsStats.length > deleteFromIndex) {
        let promises = resultsStats.map((res, i) => {
          if (i < deleteFromIndex) {
            return new Promise((r) => r());
          } else {
            deleteIndexDBProject(res.jobname).then(() => {
              return new Promise((r) => r());
            });
          }
        });
        Promise.all(promises).then(() => {
          resolve();
        });
      } else {
        resolve(event.target.result);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

//async function updateLocalResultStat(localResultStat, jobhead){

//    //localResultStat.n_speakers = resultStat.n_speakers
//    //localResultStat.refid = jobhead
//    //localResultStat.project_title = document.getElementById('projectname').innerText
//    //localResultStat.duration = playlist.duration
//    //localResultStat.flags = playlist.flags
//    //localResultStat.sfx = {}
//    //localResultStat.speakers = {}
//    //playlist.customClasses.forEach(cc=>{
//    //    if(cc.trackType === "speakers"){
//    //        localResultStat.speakers[cc.customClass] = cc
//    //    }
//    //    else if(cc.trackType === "sfx"){
//    //        localResultStat.sfx[cc.customClass] = cc
//    //    }
//    //})
//    //localResultStat.speakers_segments = []
//    //localResultStat.sfx_segments = []
//    //const promises = playlist.tracks.map(track=>{
//    //    return new Promise(r => r(createNewSegment(track)))

//    //})
//    //return Promise.all(promises)
//    //    .then(tracks => {
//    //        tracks.map((track)=>{
//    //            if(track.speaker_label.split("-")[0] === "speakers"){
//    //                localResultStat.speakers_segments.push(track)
//    //            }
//    //            else if(track.speaker_label.split("-")[0] === "sfx"){
//    //                localResultStat.sfx_segments.push(track)
//    //            }
//    //        })
//    //        return localResultStat
//    //    })
//}

async function updateResultStatForIndexedDB() {
  let currentResultStat = store.getState().projectData.resultStat;
  let res = { ...currentResultStat };
  //speakers_src
  res.speakers_segments = [];
  res.sfx_segments = [];
  let allSegments = [
    ...currentResultStat.speakers_segments,
    ...currentResultStat.sfx_segments,
  ];
  let speakerSrc = await getArrayBufferFromSrcURL(
    currentResultStat.speakers_src,
  );
  let sfxSrc = await getArrayBufferFromSrcURL(currentResultStat.sfx_src);
  res.speakers_src = speakerSrc;
  res.sfx_src = sfxSrc;
  res.fades = [];
  let fades = currentResultStat.fades || [];
  fades.map((fade) => {
    res.fades.push(fade);
  });
  //res.fades = [...fades]
  let promises = allSegments.map((segment) => {
    return new Promise(async (r) => {
      let indexedDBSegment = { ...segment };
      if (segment.src) {
        indexedDBSegment.src = segment.src;
        //if(segment.src instanceof AudioBuffer){
        //    const trackBlob = await convertAudioBufferToBlob(segment.src)
        //    const trackArrayBuffer = await trackBlob.arrayBuffer()
        //    indexedDBSegment.src = trackArrayBuffer
        //    r(indexedDBSegment)
        //}
        //else {
        //    indexedDBSegment.src = segment.src
        //    r(indexedDBSegment)
        //}
        r(indexedDBSegment);
      } else {
        r(indexedDBSegment);
      }
    });
  });
  return Promise.all(promises).then((segments) => {
    segments.map((seg) => {
      if (seg.speaker_label.split("-")[0] === "speakers") {
        res.speakers_segments.push(seg);
      } else if (seg.speaker_label.split("-")[0] === "sfx") {
        res.sfx_segments.push(seg);
      }
    });
    return res;
  });
}

async function updateResultstatByJobname(jobname, jobhead) {
  const db = await initializeIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
    const store = transaction.objectStore(STORE_RESULTSTAT);
    const request = store.index("jobname").get(jobname);

    request.onsuccess = (event) => {
      const job = event.target.result;

      updateResultStatForIndexedDB()
        .then((updatedResultStat) => {
          job.data = updatedResultStat;
          job.updatedAt = new Date().getTime();
          const transaction = db.transaction(STORE_RESULTSTAT, "readwrite");
          const store = transaction.objectStore(STORE_RESULTSTAT);
          const requestUpdate = store.put(job);
          requestUpdate.onerror = (event) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            console.log("undate of resultstat is scucessful 1");
            resolve(event.target.result);
          };
        })
        .catch((e) => {
          resolve();
        });
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function updateResultsStatFromUndoStack(jobname, resultsStat) {
  //const db = await initializeIndexedDB()
  //return new Promise((resolve, reject) => {
  //    const transaction = db.transaction(STORE_NAME, "readwrite")
  //    const store = transaction.objectStore(STORE_NAME)
  //    const request = store.index("jobname").get(jobname)
  //    request.onsuccess = (event) => {
  //        const job = event.target.result
  //        job.data = resultsStat
  //        const requestUpdate = store.put(job)
  //        requestUpdate.onerror = (event) => {
  //            reject(event.target.error)
  //        }
  //        requestUpdate.onsuccess = (event) => {
  //            resolve(event.target.result)
  //        }
  //    }
  //    request.onerror = (event) => {
  //        reject(event.target.error)
  //    }
  //})
}

export {
  initializeIndexedDB,
  getResultStatFromIndexedDB,
  addAudioFile,
  storeAudioFile,
  getAllAudioFiles,
  retrieveAudioFileByJobname,
  updateTranscriptionIndexDB,
  deleteIndexDBProjectResultstat,
  deleteOldIndexDBdata,
  updateResultStatForIndexedDB,
  updateResultstatByJobname,
  updateResultsStatFromUndoStack,
};

//=============*************IndexedDB Operations Store**************========================
async function initializeOperationListInIndexedDB() {
  return new Promise((resolve, reject) => {
    const DB_NAME = `koolio_OperationsDB_${"Koolioai.getDisplayName()"}`;
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const storeIndexedDb = db.createObjectStore(STORE_OPERATION, {
        keyPath: "refid",
      });
      storeIndexedDb.createIndex("refid", "refid", { unique: true });
      storeIndexedDb.createIndex("alias_id", "alias_id");
      storeIndexedDb.createIndex("jobname", "jobname");
      storeIndexedDb.createIndex("jobname, deleted", ["jobname", "deleted"]);
      storeIndexedDb.createIndex("jobname, frontend_apply_status, deleted", [
        "jobname",
        "frontend_apply_status",
        "deleted",
      ]);
      storeIndexedDb.createIndex("jobname, synchronized", [
        "jobname",
        "synchronized",
      ]);
      //storeIndexedDb.createIndex("frontend_apply_status", "frontend_apply_status", { unique: false })
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

//function updateLatestLocalRefid(){
//    let latestLocalOpRefidData = JSON.parse(localStorage.getItem("latestLocalOpRefid"))
//    if(!latestLocalOpRefidData){
//        latestLocalOpRefidData = {
//            [playlist.jobname] : playlist.jobhead
//        }
//    }
//    else{
//        latestLocalOpRefidData[playlist.jobname] = playlist.jobhead
//    }
//    localStorage.setItem("latestLocalOpRefid", JSON.stringify(latestLocalOpRefidData))
//}

//async function modifyUndoStack(){
//    if(playlist.undoStack.length > 9){
//        while (playlist.undoStack.length > 5){
//            let op = playlist.undoStack.shift()
//            updateResultsStatFromUndoStack(playlist.jobname, op.resultsStat)
//            updateIndexedDBoperation(op.refid, "frontend_apply_status", "completed")
//        }
//    }
//}

async function createStructuredData(
  operationData,
  containsFile,
  synchronized,
  frontend_apply_status,
  deleted,
) {
  let structuredOp = {
    alias_id: operationData.alias_id,
    created_at: operationData.created_at || new Date().toISOString(),
    operation: {
      worker: operationData.worker,
      inputs: operationData.inputs || operationData.operation.inputs,
    },
    parent_refid: operationData.parentRefid || operationData.parent_refid,
    refid: operationData.refid,
    jobname: operationData.jobname,
    operationInfo: operationData,
    containsFile: containsFile,
    frontend_apply_status: frontend_apply_status,
    synchronized: synchronized,
    deleted: deleted,
  };
  return structuredOp;
}

async function createStructuredInsertPasteData(
  operationData,
  trackInfo,
  containsFile,
  synchronized,
  frontend_apply_status,
  deleted,
) {
  let structuredOp = {
    alias_id: operationData.alias_id,
    created_at: operationData.created_at || new Date().toISOString(),
    operation: {
      worker: operationData.worker,
      inputs: operationData.inputs,
    },
    parent_refid: operationData.parentRefid,
    refid: operationData.refid,
    jobname: operationData.jobname,
    operationInfo: operationData,
    trackInfo: trackInfo,
    containsFile: containsFile,
    frontend_apply_status: frontend_apply_status,
    synchronized: synchronized,
    deleted: deleted,
  };
  return structuredOp;
}

async function structureOpForIndexedDB(
  operationData,
  trackInfo,
  containsFile,
  synchronized,
  frontend_apply_status,
  deleted,
) {
  //structure operattion data before storing it to indexedDB
  let structuredOp;
  switch (operationData.worker) {
    case "cut": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "silence": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "changeSpeaker": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "createCustomClass": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "paste": {
      const newOpData = { ...operationData };
      newOpData.inputs = { ...operationData.inputs };
      newOpData.inputs.tracks = [];
      operationData.inputs.tracks.map((track) => {
        const newTrack = { ...track };
        newOpData.inputs.tracks.push(newTrack);
      });
      const promises = operationData.inputs.tracks.map((track) => {
        return new Promise((r) => r(getArrayBufferFromSrcURL(track.src)));
        //if(track.src instanceof File){
        //    return new Promise (r=> r(track.src))
        //}
        //else{
        //    return new Promise (r=> r(getArrayBufferFromSrcURL(track.src)))
        //}
      });
      let arrayBuffers = await Promise.all(promises);
      arrayBuffers.map((ab, i) => (newOpData.inputs.tracks[i].src = ab));
      structuredOp = await createStructuredInsertPasteData(
        newOpData,
        trackInfo,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "insert": {
      const newOpData = { ...operationData };
      newOpData.inputs = { ...operationData.inputs };
      newOpData.inputs.tracks = [];
      operationData.inputs.tracks.map((track) => {
        const newTrack = { ...track };
        newOpData.inputs.tracks.push(newTrack);
      });
      const promises = operationData.inputs.tracks.map((track) => {
        return new Promise((r) => r(getArrayBufferFromSrcURL(track.src)));
        //if(track.src instanceof File){
        //    return new Promise (r=> r(track.src))
        //}
        //else{
        //    return new Promise (r=> r(getArrayBufferFromSrcURL(track.src)))
        //}
      });
      let arrayBuffers = await Promise.all(promises);
      arrayBuffers.map((ab, i) => (newOpData.inputs.tracks[i].src = ab));
      structuredOp = await createStructuredInsertPasteData(
        newOpData,
        trackInfo,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }

    case "dragAndDropSFX": {
      // const promises = operationData.inputs.items[1].tracks.map((track) => {
      //   return new Promise((r) => r(getArrayBufferFromSrcURL(track.src)));
      //   //if(track.src instanceof File){
      //   //    return new Promise (r=> r(track.src))
      //   //}
      //   //else{
      //   //    return new Promise (r=> r(getArrayBufferFromSrcURL(track.src)))
      //   //}
      // });
      // let arrayBuffers = await Promise.all(promises);
      // arrayBuffers.map(
      //   (ab, i) => (operationData.inputs.items[1].tracks[i].src = ab),
      // );

      const newOpData = { ...operationData };
      newOpData.inputs = { ...operationData.inputs };
      newOpData.inputs.tracks = [];
      operationData.inputs.items[1].tracks.map((track) => {
        const newTrack = { ...track };
        newOpData.inputs.tracks.push(newTrack);
      });
      const promises = operationData.inputs.items[1].tracks.map((track) => {
        return new Promise((r) => r(getArrayBufferFromSrcURL(track.src)));
        //if(track.src instanceof File){
        //    return new Promise (r=> r(track.src))
        //}
        //else{
        //    return new Promise (r=> r(getArrayBufferFromSrcURL(track.src)))
        //}
      });
      let arrayBuffers = await Promise.all(promises);
      // console.log(arrayBuffers);
      arrayBuffers.map((ab, i) => (newOpData.inputs.tracks[i].src = ab));
      structuredOp = await createStructuredData(
        //operationData,
        newOpData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "fades": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "removeFades": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "multiselect-cut": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "multiselect-silence": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "long-gap-removal": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "multiselect-changeSpeaker": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "multiselect-replace": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "multiVolumeChange": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "volumeChange": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "subVolume": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "subPanning": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "deleteCustomClass": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "cutDeleteCustomClass": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "updateTranscript": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "addAnnotation": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "editTitle": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "editTrackInfo": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "resizeSpeakerAnnotation": {
      const promises = operationData.inputs.tracks.map((track) => {
        return new Promise((r) => r(getArrayBufferFromSrcURL(track.src)));
        //if(track.src instanceof File){
        //    return new Promise (r=> r(track.src))
        //}
        //else{
        //    return new Promise (r=> r(getArrayBufferFromSrcURL(track.src)))
        //}
      });
      let arrayBuffers = await Promise.all(promises);
      arrayBuffers.map((ab, i) => (operationData.inputs.tracks[i].src = ab));
      structuredOp = await createStructuredInsertPasteData(
        operationData,
        trackInfo,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "resizeSfx": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "undo": {
      await updateIndexedDBoperation(
        operationData.parentRefid,
        "deleted",
        "true",
      );
      await updateIndexedDBoperationsByAliasId(
        "alias_id",
        operationData.refid,
        "deleted",
        "true",
      );
      //updateLatestLocalRefid()
      break;
    }
    case "redo": {
      await updateIndexedDBoperation(operationData.refid, "deleted", "false");
      await updateIndexedDBoperationsByAliasId(
        "alias_id",
        operationData.refid,
        "deleted",
        "false",
      );
      //updateLatestLocalRefid()
      break;
    }
    case "replaceAnnot": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "restore": {
      structuredOp = await createStructuredData(
        operationData,
        containsFile,
        synchronized,
        frontend_apply_status,
        deleted,
      );
      break;
    }
    case "createRestoreForIndexDB": {
      structuredOp = {
        alias_id: operationData.alias_id,
        created_at: operationData.created_at || new Date().toISOString(),
        operation: {
          worker: "restore",
          inputs: {
            refid:
              operationData.alias_id || operationData.operation.inputs.refid,
          },
        },
        parent_refid:
          operationData.alias_id || operationData.operation.inputs.refid,
        refid: operationData.parent_refid,
        jobname: operationData.jobname,
        operationInfo: operationData,
        containsFile: containsFile,
        frontend_apply_status: frontend_apply_status,
        synchronized: synchronized,
        deleted: deleted,
        fromBackend: "true",
      };
      break;
    }
    default:
      break;
  }

  return structuredOp;
}

async function addOperationsToDB(
  operationData,
  trackInfo,
  containsFile,
  synchronized,
  frontend_apply_status,
  deleted,
) {
  const operationList = store.getState().operationListData.operationList;

  const structuredOperation = await structureOpForIndexedDB(
    operationData,
    trackInfo,
    containsFile,
    synchronized,
    frontend_apply_status,
    deleted,
  );
  if (structuredOperation) {
    const db = await initializeOperationListInIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_OPERATION, "readwrite");
      const storeIndexedDb = transaction.objectStore(STORE_OPERATION);

      const request = storeIndexedDb.add(structuredOperation);

      request.onsuccess = () => {
        //updateLatestLocalRefid()
        //modifyUndoStack()
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }
}

//async function modifyUndoStack(){
//    if(playlist.undoStack.length > 9){
//        while (playlist.undoStack.length > 5){
//            let op = playlist.undoStack.shift()
//            updateResultsStatFromUndoStack(playlist.jobname, op.resultsStat)
//            updateIndexedDBoperation(op.refid, "frontend_apply_status", "completed")
//        }
//    }
//}
async function getOperationsByJobnameIndexes(index, data) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readonly");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index(index).getAll(data);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      if (!operations.length) {
        resolve({
          status: 200,
          operations: operations,
        });
      } else {
        for (let i = 0; i < operations.length; i++) {
          const timeStamp = new Date(operations[i].created_at).getTime();
          operations[i].created_at = timeStamp;
        }
        operations.sort(function (a, b) {
          return a.created_at - b.created_at;
        });
        resolve({
          status: 201,
          operations: operations,
        });
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function getPendingOperationsByCurrentRefid(jobname, refid) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readonly");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb
      .index("jobname, deleted")
      .getAll([jobname, "false"]);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      if (!operations.length) {
        resolve({
          status: 200,
          operations: [],
          refid,
        });
      } else {
        let pendingOperations = [];
        for (let i = 0; i < operations.length; i++) {
          const timeStamp = new Date(operations[i].created_at).getTime();
          operations[i].created_at = timeStamp;
        }
        operations.sort(function (a, b) {
          return a.created_at - b.created_at;
        });

        if (operations.length === 1) {
          if (operations[0].refid !== refid) {
            resolve({
              status: 201,
              operations: operations,
              refid,
            });
          } else {
            resolve({
              status: 200,
              operations: [],
              refid,
            });
          }
        } else {
          let refidFound = false;
          for (let i = 0; i < operations.length; i++) {
            if (operations[i].refid === refid) {
              refidFound = true;
            } else if (refidFound) {
              pendingOperations.push(operations[i]);
            }
          }
          if (refidFound) {
            resolve({
              status: pendingOperations.length ? 201 : 200,
              operations: pendingOperations,
              refid,
            });
          } else {
            resolve({
              status: operations.length ? 201 : 200,
              operations: operations,
              refid,
            });
          }
        }
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function getOpListFromIndexedDb(jobname, refid, aliasIdFromBackend) {
  if (!aliasIdFromBackend) {
    return await getPendingOperationsByCurrentRefid(jobname, refid);
  } else {
    //while (isOperationsTransActive) {
    //    showStatText('synchronizing', false)
    //    await timeout(300)
    //}
    const res = await getOperationsList(refid, jobname);
    if (res.status === 201) {
      return new Promise((resolve) => {
        const promises = res.operations.map(async (operation) => {
          let containsFile = false;
          let trackInfo;
          let operationData = {
            alias_id: operation.alias_id,
            inputs: operation.operation.inputs,
            operation: {
              inputs: operation.operation.inputs,
              worker: operation.operation.worker,
            },
            jobname: operation.jobname,
            parentRefid: operation.parent_refid,
            refid: operation.refid,
            worker: operation.operation.worker,
            created_at: operation.created_at,
          };

          if (operation.operation.inputs.tracks) {
            trackInfo = {
              customClass: operation.operation.inputs.customClass,
              isImport: operation.operation.inputs.isImport,
            };
            containsFile = true;
          }
          let op = await getOperationByIndexData("refid", operation.alias_id);
          if (
            operation.operation.worker === op.operations[0].operation.worker
          ) {
            await updateIndexedDBoperation(
              operation.alias_id,
              "deleted",
              "true",
            );
          }
          return new Promise((r) =>
            r(
              addOperationsToDB(
                operationData,
                trackInfo,
                containsFile,
                "true",
                "pending",
                "false",
                true,
              ),
            ),
          );
        });
        Promise.all(promises).then(() => {
          const promises = res.operations.map((operation) => {
            return getOperationCreatedInBackendByIndexData(
              "refid",
              operation.refid,
            );
          });
          Promise.all(promises).then((res) => {
            let opRes = {
              status: 201,
              operations: res,
            };
            return resolve(opRes);
          });
        });
      });
    } else {
      return res;
    }
  }
}

async function getOperationByIndexData(index, data) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readonly");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index(index).getAll(data);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      if (!operations.length) {
        resolve({
          status: 200,
          operations: operations,
        });
      } else {
        for (let i = 0; i < operations.length; i++) {
          const timeStamp = new Date(operations[i].created_at).getTime();
          operations[i].created_at = timeStamp;
        }
        operations.sort(function (a, b) {
          return a.created_at - b.created_at;
        });
        resolve({
          status: 201,
          operations: operations,
        });
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function getOperationCreatedInBackendByIndexData(index, data) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readonly");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index(index).get(data);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      resolve(operations);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function restoreOperationsInIndexedDB(
  restoredRefid,
  dataKey,
  data,
  jobname,
) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index("jobname").getAll(jobname);

    request.onsuccess = (event) => {
      const operations = event.target.result;

      if (operations.length) {
        for (let i = 0; i < operations.length; i++) {
          const timeStamp = new Date(operations[i].created_at).getTime();
          operations[i].created_at = timeStamp;
        }
        operations.sort(function (a, b) {
          return b.created_at - a.created_at;
        });
        let restored = false;
        for (let i = 0; i < operations.length; i++) {
          if (restored) return resolve(event.target.result);
          if (operations[i].refid === restoredRefid) {
            restored = true;
            return resolve(event.target.result);
          }
          operations[i].dataKey = data;

          const requestUpdate = storeIndexedDb.put(operations[i]);
          requestUpdate.onerror = (event) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve(event.target.result);
          };
        }
      } else {
        resolve(event.target.result);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

//for updating data from indexedDB
async function updateIndexedDBoperation(refidKey, dataKey, data) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.get(refidKey);
    request.onsuccess = (event) => {
      const operation = event.target.result;
      operation[dataKey] = data;
      const requestUpdate = storeIndexedDb.put(operation);
      requestUpdate.onerror = (event) => {
        reject(event.target.error);
      };
      requestUpdate.onsuccess = (event) => {
        resolve(event.target.result);
      };
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function updateIndexedDBoperationsByAliasId(
  index,
  indexId,
  dataKey,
  data,
) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index(index).getAll(indexId);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      if (operations.length) {
        updateIndexedDBoperation(indexId, dataKey, data);
        operations.map((operation) => {
          operation[dataKey] = data;
          const requestUpdate = storeIndexedDb.put(operation);
          requestUpdate.onerror = (event) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve(event.target.result);
          };
        });
      } else {
        resolve(event.target.result);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

async function updateIndexDBoperationsData(index, indexId, dataKey, data) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index(index).getAll(indexId);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      if (operations.length) {
        const promises = operations.map((operation) => {
          operation[dataKey] = data;
          const requestUpdate = storeIndexedDb.put(operation);
          requestUpdate.onerror = (event) => {
            //reject(event.target.error)
            return new Promise((r) => r());
          };
          requestUpdate.onsuccess = (event) => {
            //resolve(event.target.result)
            return new Promise((r) => r());
          };
        });
        Promise.all(promises).then(() => {
          resolve();
        });
      } else {
        resolve(event.target.result);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}
//for updating data from indexedDB

async function deleteIndexDBProjectOperations(jobnameIndex) {
  const db = await initializeOperationListInIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_OPERATION, "readwrite");
    const storeIndexedDb = transaction.objectStore(STORE_OPERATION);
    const request = storeIndexedDb.index("jobname").getAll(jobnameIndex);

    request.onsuccess = (event) => {
      const operations = event.target.result;
      if (operations.length) {
        operations.map((operation) => {
          const requestUpdate = storeIndexedDb.delete(operation.refid);
          requestUpdate.onerror = (event) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve(event.target.result);
          };
        });
      } else {
        resolve(event.target.result);
      }
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

export {
  initializeOperationListInIndexedDB,
  createStructuredData,
  createStructuredInsertPasteData,
  structureOpForIndexedDB,
  addOperationsToDB,
  getOperationsByJobnameIndexes,
  getPendingOperationsByCurrentRefid,
  getOpListFromIndexedDb,
  getOperationByIndexData,
  getOperationCreatedInBackendByIndexData,
  restoreOperationsInIndexedDB,
  updateIndexedDBoperation,
  updateIndexedDBoperationsByAliasId,
  updateIndexDBoperationsData,
  deleteIndexDBProjectOperations,
};

export async function deleteIndexDBProject(jobnameIndex) {
  await deleteIndexDBProjectResultstat(jobnameIndex);
  await deleteIndexDBProjectOperations(jobnameIndex);
}
