import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../css/dark/profileModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

function LoaderModal() {
  return (
    <article className="post col-8-mod">
      <div className="post-content" id="post-content">
        <div id="playlist-globalLoader" style={{ display: "block !important" }}>
          <div id="playlist-globalLoader-bg" />
          <div
            className="spinner-container"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.4vw",
            }}
          >
            <img
              src="/image/koolio-icon-workspace.svg"
              style={{
                width: "5.856515373352855vw",
              }}
              className="ajax-loader"
              alt="Loading Spinner"
            />
            <p
              className="text-white"
              style={{
                fontSize: "1.171303074670571vw",
              }}
            >
              Please Wait...
            </p>
          </div>
        </div>
        <div
          id="playlist"
          style={{
            borderLeft: "double #E2522B",
            borderRight: "double #E2522B",
          }}
        ></div>
      </div>
    </article>
  );
}

export default LoaderModal;
