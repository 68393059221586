import React from "react";
import { declineTerms, acceptTerms } from "../pages/loginPage/loginUtils";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";
const TermsandConditionModal = () => {
  return (
    <div
      className="terms-modal modal"
      id="terms"
      style={{
        display: "block",
        marginTop: "10%",
        width: "43%",
        height: "70%",
        paddingLeft: "1%",
        paddingRight: "1%",
        transform: "translateX(-50%)",
        marginBottom: "2%",
      }}
    >
      <div className="modal-content">
        <div
          className="terms-modal-header modal-header row"
          style={{ borderBottomColor: "#E2522B" }}
        >
          <span className="terms-header-text">Terms and Conditions</span>
        </div>
        <div className="terms-content">
          <div className="terms-content-data">
            <div className="content-header text-start">
              1. Accepting these terms
            </div>
            <div className="content-data">
              This document, our rules, policies and the other documents
              referenced make up our Terms and Conditions (“Terms”). The Terms
              are a legally binding contract between you and koolio.ai. This
              contract sets out your rights and responsibilities when you use
              www.koolio.ai (“Site”), the content therein (“Content”), and
              attendant services (“Services”). Please read them carefully
            </div>
          </div>

          <div className="terms-content-data">
            <div className="content-header">2. Changes</div>
            <div className="content-data">
              We may amend the Terms at any time by posting a revised version on
              our Site. The revised version will be effective at the time we
              post it, and your continued use of our website is deemed to be
              acceptance of such changes, so please check periodically for
              updates.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">3. Access</div>
            <div className="content-data">
              You are granted a non-exclusive, limited, and revocable license to
              access the site and use its functionality on the condition that:{" "}
              <br /> (a) You are over the age of 18; <br /> (b) You only use the
              Site for lawful purposes; <br /> (c) You do not engage in any
              improper, indecent, or offensive behavior while using the Site;{" "}
              <br /> (d) You are not breaking any law in your relevant
              jurisdiction by accessing this Site.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">4. Registration</div>
            <div className="content-data">
              To use some aspects of the Services, you must register for an
              account (an "Account"). Registration data and certain other
              information about you and your Account are governed by our Privacy
              Policy. You will not: (a) select or use as a username a name of
              another person with the intent to impersonate that person; (b) use
              as a username a name subject to any rights of a person (including,
              without limitation, copyrights or trademarks) other than you
              without appropriate authorization; or (c) use, as a username, a
              name that we reasonably consider is otherwise offensive, vulgar,
              or obscene. You are solely responsible for the activity that
              occurs on your Account and for keeping your Account password
              secure. You may never use another person’s user account or
              registration information for the Services without permission. You
              must notify us immediately of any breach of security or
              unauthorized use of your Account. You should never publish,
              distribute or post login information for your Account. We will not
              be liable for any loss or damage arising from your failure to
              comply with this Section.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">5. Monitoring and compliance</div>
            <div className="content-data">
              Although koolio.ai is not obligated to monitor access to or use of
              the Site or to review or edit any Content, we have the right to do
              so for the purpose of operating the Site, to ensure compliance
              with these Terms, and to comply with applicable law or other legal
              requirements. We reserve the right, but are not obligated, to
              remove or disable access to the Site or any Content, at any time
              and without notice, including, but not limited to, if we, at our
              sole discretion, consider any Content to be objectionable or in
              violation of these Terms. We have the right to investigate
              violations of these Terms or conduct that affects the Services. We
              may also consult and cooperate with law enforcement authorities to
              prosecute users who violate the law. <br /> In case we receive a
              written claim against you claiming that your User Content
              infringes upon individual rights, we may contact you to address
              the claim and give you the opportunity to solve it directly with
              the claimant. We may also give you the opportunity to challenge
              such claim and provide your defense. Please note, however, that we
              reserve the right to decide on whether or not your User Content
              breaches our Terms and Conditions. Our decision is final and
              cannot be appealed and may result in the removal of your content,
              the suspension or the cancellation of your account, as well as, in
              the most serious cases and if permitted by law, the referral of
              the case to the relevant criminal authorities.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">6. Feedback</div>
            <div className="content-data">
              We welcome and encourage you to provide feedback, comments, and
              suggestions for improvements of the Site (“Feedback”). You may
              submit Feedback by emailing us at info@koolio.ai . You acknowledge
              and agree that if you submit any Feedback to us, you hereby grant
              to us a non-exclusive, worldwide, perpetual, irrevocable,
              fully-paid, royalty-free, sub-licensable (through several tiers)
              and transferable license under any and all intellectual property
              rights that you own or control in relation to the Feedback to use,
              reproduce, view, communicate to the public by any means, print,
              copy (whether onto hard disk or other media), edit, translate,
              perform and display (publicly or otherwise), distribute,
              redistribute, modify, adapt, make, sell, offer to sell, transmit,
              license, transfer, stream, broadcast, create derivative works
              from, and otherwise use and exploit the Feedback for any purpose.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">7. Prohibited users</div>
            <div className="content-data">
              You may not use, or encourage, promote, facilitate, instruct or
              induce others to use, the Site or its Services for any activities
              that violate any law, statute, ordinance or regulation; for any
              other illegal or fraudulent purpose or any purpose that is harmful
              to others; or to transmit, store, display, distribute or otherwise
              make available content that is illegal, fraudulent or harmful to
              others.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">8. Privacy</div>
            <div className="content-data">
              These Terms explicitly consolidate our own. By consenting to these
              Terms, you likewise consent to the terms set out in our Privacy
              Policy. All close to home data given through your utilization of
              this Site will be taken care of as per our Privacy Policy.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">9. Security</div>
            <div className="content-data">
              koolio.ai ensures the protection and honesty of the data it
              gathers by utilizing fitting authoritative conventions,
              specialized shields, and actual security controls intended to
              restrict access, identify and forestall the unapproved access,
              inappropriate divulgence, adjustment, or obliteration of the data
              under its influence.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">
              10. Our rights in the sites and services
            </div>
            <div className="content-data">
              All services available on the Site are owned by us or other
              parties that have licensed their material or provided services to
              us, and are protected by copyright, trademark, trade secret and
              other intellectual property laws. All koolio.ai trademarks and
              service marks, logos, slogans and taglines are the property of
              koolio.ai. All other trademarks, service marks, logos, slogans and
              taglines are the property of their respective owners. Except as
              otherwise specifically provided herein, nothing should be
              construed as granting any license or right to use any trademarks,
              service marks, logos, slogans or taglines displayed on the Site or
              through the Services without our express written permission, or
              the express written permission of such third-party that may own
              the trademark, service mark, logo, slogan or tagline.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">11. Fees and payment</div>
            <div className="content-data">
              Users will be provided with a limited introductory offering that
              will allow them to record, edit, and publish a limited amount of
              recordings as further specified on the Site during the signup
              process. After consuming the limited introductory offering, users
              will be required to pay the fees for the applicable subscription
              package they select, all of which will be outlined on the Site.
              All fees are payable in US dollars and are billed on a monthly
              basis.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">12. Content</div>
            <div className="content-data">
              All information, data, text, software, music, sound, photographs,
              graphics, video, messages or other materials, whether publicly
              posted or privately transmitted to the Site by users ("User
              Content"), is the sole responsibility of such users. This means
              that the user and not koolio.ai are entirely responsible for all
              such material uploaded, posted, emailed, transmitted or otherwise
              made available by using the Service. koolio.ai does not control or
              actively monitor User Content and, as such, does not guarantee the
              accuracy, integrity or quality of such content. Users acknowledge
              that by using the Service, they may be exposed to materials that
              are offensive, indecent or objectionable. Under no circumstances
              will koolio.ai be liable in any way for any materials, including,
              but not limited to, for any errors or omissions in any materials
              or any defects or errors in any printing or manufacturing, or for
              any loss or damage of any kind incurred as a result of the viewing
              or use of any materials posted, emailed, transmitted or otherwise
              made available via the Service.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">13. License of content</div>
            <div className="content-data">
              koolio.ai does not in any way own a license to the content
              uploaded by the user. koolio.ai only assists the user in
              manipulating his/her/its audio recording that is uploaded via
              audio file or recorded directly. In addition to that koolio.ai
              will not in any way display or distribute the user’s content. User
              herein acknowledges that koolio.ai can only get a license for
              content ONLY for the sole reason of assisting the user with a
              solution to modify and clean up his/her/its content and improve
              it.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">
              14. Links and third-party websites
            </div>
            <div className="content-data">
              This Site (including User Content) may contain links to other
              websites that are not owned or controlled by koolio.ai. In no
              event shall any reference to any third party, third party product
              or service be construed as an approval or endorsement by koolio.ai
              of that third party, third party product or service. koolio.ai is
              also not responsible for the content of any linked websites. Any
              third-party websites or services accessed from the Website are
              subject to the terms and conditions of those websites and or
              services and you are responsible for determining those terms and
              conditions and complying with them. The presence on the Site of a
              link to any other website(s) does not imply that koolio.ai
              endorses or accepts any responsibility for the content or use of
              such websites, and you hereby release koolio.ai from all liability
              and/damages that may arise from your use of such websites or
              receipt of services from any such websites.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">15. Termination</div>
            <div className="content-data">
              koolio.ai reserves the right to change, suspend, limit or
              discontinue any of its Services, in whole or in part at any time
              for any reason, without notice (unless required by law). <br /> We
              may refuse service to anyone and may terminate or suspend your
              services and your access to the Site in whole or in part at any
              time, for any reason, without notice (unless required by law).{" "}
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">16. Limitations</div>
            <div className="content-data">
              In no event will koolio.ai be liable to you for any indirect,
              incidental, special, consequential or punitive damages (including
              damages for loss of profits, goodwill, or any other intangible
              loss) arising out of or relating to your access to or use of, or
              your inability to access or use, the Site or any materials or
              content available through the Site, whether based on warranty,
              contract, tort (including negligence), statute, or any other legal
              theory, and whether or not koolio.ai has been informed of the
              possibility of damage.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">17. Indemnification </div>
            <div className="content-data">
              You agree that you will be responsible for your use of the Site,
              and you agree to defend and indemnify koolio.ai from and against
              every claim, liability, damage, loss, and expense, including
              reasonable attorneys’ fees and costs, arising out of or in any way
              connected with: (i) your access to, use of, or alleged use of, the
              Site; (ii) your violation of any portion of these Terms, any
              representation, warranty, or agreement referenced in these Terms,
              or any applicable law or regulation; (iii) your violation of any
              third party right, including any intellectual property right or
              publicity, confidentiality, other property, or privacy right; or
              (iv) any dispute or issue between you and any third party. We
              reserve the right, at our own expense, to assume the exclusive
              defense and control of any matter otherwise subject to
              indemnification by you (without limiting your indemnification
              obligations with respect to that matter), and in that case, you
              agree to cooperate with our defense of that claim.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">18. Waiver</div>
            <div className="content-data">
              No waiver of any provision hereof shall be effective unless made
              in writing and signed by the waiving party. The failure of any
              party to require the performance of any term or obligation of this
              Agreement, or the waiver by any party of any breach of this
              Agreement, shall not prevent any subsequent enforcement of such
              term or obligation or be deemed a waiver of any subsequent breach.
            </div>
          </div>
          <div className="terms-content-data">
            <div className="content-header">19. Entire agreement</div>
            <div className="content-data">
              This Agreement contains the entire agreement and understanding
              among the parties hereto with respect to the subject matter
              hereof, and supersedes all prior and contemporaneous agreements,
              understandings, inducements and conditions, express or implied,
              oral or written, of any nature whatsoever with respect to the
              subject matter hereof. The express terms hereof control and
              supersede any course of performance and/or usage of the trade
              inconsistent with any of the terms hereof.
            </div>
          </div>
        </div>
        <div className="terms-modal-options">
          <div className="terms-decline text-start">
            <button
              type="button"
              className="action-project-button terms-action-button btn-padding text-start declBtn"
              onClick={declineTerms}
            >
              Decline
            </button>
          </div>
          <div className="terms-accept text-start">
            <button
              type="button"
              className="action-project-button terms-action-button btn-padding text-start accBtn
              "
              onClick={acceptTerms}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsandConditionModal;
