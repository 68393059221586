import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
  uploadProgress: {},
};
const uploadProgressSlice = createSlice({
  name: "uploadProgressStatus",
  initialState: initialValue,
  reducers: {
    updateProgStatus(state, action) {
      state.uploadProgress = action.payload.uploadProgressStatus;
    },
  },
});
export const uploadProgressActions = uploadProgressSlice.actions;
export default uploadProgressSlice.reducer;
