import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  notificationsDetails: {
    notificationsList: null,
    newNotifications: 0,
  },
};
const notificationDetailsSlice = createSlice({
  name: "notificationDetails",
  initialState: initialVal,
  reducers: {
    updateNotificationsList(state, action) {
      state.notificationsDetails.notificationsList = action.payload.value;
    },
    updatenewNotifications(state, action) {
      state.notificationsDetails.newNotifications = action.payload.value;
    },
    clearNOtifiactionDetails(state) {
      state.notificationsDetails.notificationsList = null;
      state.notificationsDetails.newNotifications = 0;
    },
  },
});

export const notificationDetailsActions = notificationDetailsSlice.actions; // for list of words that need to be operated on
export default notificationDetailsSlice.reducer;
