import { createSlice } from "@reduxjs/toolkit";
// this slice is to keep track of view and edit mode

const initialVal = {
  mode: "view",
  viewMode: false,
  playPauseMode: true,
};

const viewEditSlice = createSlice({
  name: "viewEditMode",
  initialState: initialVal,
  reducers: {
    updateViewEditMode(state, action) {
      state.mode = action.payload.mode;
    },
    updateViewMode(state, action) {
      state.viewMode = action.payload.value;
    },
    updatePlayPauseMode(state, action) {
      state.playPauseMode = action.payload.value;
    },
    resetMode(state) {
      state.mode = "view";
      state.viewMode = false;
    },
  },
});

export const viewEditSliceActions = viewEditSlice.actions;
export default viewEditSlice.reducer;
