import { createSlice } from "@reduxjs/toolkit";
const sfxSlice = createSlice({
  name: "sfx",
  initialState: {
    duration: 0,
  },
  reducers: {
    setSfxDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
});

export const setSfxDurationSliceActions = sfxSlice.actions;

export default sfxSlice.reducer;
