import React, { useEffect } from "react";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { createPortal } from "react-dom";
import { koolioModals } from "../../../../utils/Res";
import {
  dissociateProject,
  shareOptions,
} from "../../../../utils/koolio-workspace";
import { shareAndCollabActions } from "../../../../redux/slice/ShareAndCollableSlice";
import store from "../../../../redux/Store";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";

const DissociationModal = (props) => {
  useEffect(() => {
    koolioModals();
  }, []);

  const handleClose = () => {
    return props.onClose();
  };
  const handleDissociation = () => {
    let info = props.message;
    dissociateProject(info.jobname, info.dissociatedUser, info.username).then(
      () => {
        if (info.count - 1 < 2) {
          store.dispatch(
            shareAndCollabActions.updateISProjectShared({
              isTheProjectShared: false,
            }),
          );
        }
        shareOptions();
      },
    );
    return props.onClose();
  };

  return createPortal(
    <div id="half-modals-container">
      <div className="half-modals-content" id="half-modals-content">
        <div className="half-modals-header">
          <p style={{ margin: 0 }} id="dissociation-confirmation-modal-header">
            Dissociation confirmation
          </p>
          <img
            title="close"
            className="img-fluid"
            onClick={handleClose}
            id="closeWorkspaceModalIcon"
            style={{ cursor: "not-allowed", visibility: "hidden" }}
            src="/image/exit-workspace.svg"
            alt=""
            width="13px"
          />
        </div>
        <div
          className="half-modals-sub-header"
          id="dissociation-confirmation-modal-sub-header"
        >
          Do you want to dissociate the collaborator from this project?
        </div>
        <div className="half-modals-action">
          <div className="half-modals-actions text-center">
            <button
              type="button"
              id="btn-dissociation-confirm"
              className="half-modals-action-button"
              onClick={() => {
                // Add the logic to handle dissociation here
                handleDissociation();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="half-modals-action-button"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body, // Ensure the correct target element for the portal
  );
};

export default DissociationModal;
