import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  runMoreOptionsTour: false,
};

const moreOptionsTourSlice = createSlice({
  name: "moreOptionsTour",
  initialState,
  reducers: {
    startMoreOptionsTour: (state) => {
      state.runMoreOptionsTour = true;
    },
    stopMoreOptionsTour: (state) => {
      state.runMoreOptionsTour = false;
    },
  },
});

export const moreOptionsTourActions = moreOptionsTourSlice.actions;
export default moreOptionsTourSlice.reducer;
