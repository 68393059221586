import { createSlice } from "@reduxjs/toolkit";
import { currenTimeScheduler } from "../../utils/utils";
const initialVal = {
  currentTime: 0,
  currentTimeScheduler: undefined,
  normalize: false,
};

const utilsSlice = createSlice({
  name: "utilsData",
  initialState: initialVal,
  reducers: {
    updateCurrentTime(state, action) {
      state.currentTime = action.payload.currentTime;
    },
    changeNormalizeValue(state, action) {
      state.normalize = action.payload;
    },
    resetCurrentTime(state) {
      state.currentTime = 0;
    },
  },
});

export const utilsSliceActions = utilsSlice.actions;
export default utilsSlice.reducer;
