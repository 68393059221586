import { createSlice } from "@reduxjs/toolkit";

const initialUploadedFile = {
  uploadedFileUrl: "",
};

const fileSelectedForWorkspaceSlice = createSlice({
  name: "UploadedFile",
  initialState: initialUploadedFile,
  reducers: {
    uploadFileToWorkspace(state, action) {
      state.uploadedFileUrl = action.payload;
      //console.log(state)
    },
    clearUploadedFileFromStore(state) {
      state.uploadedFileUrl = "";
    },
  },
});

export const uploadFileToWorkspaceActions =
  fileSelectedForWorkspaceSlice.actions;

export default fileSelectedForWorkspaceSlice.reducer;
