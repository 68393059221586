import React from "react";
import Joyride from "react-joyride";
import store from "../../redux/Store";
import "../../css/dark/ProductTours.css";
import { useDispatch, useSelector } from "react-redux";
import { editFeaturesTourActions } from "../../redux/slice/EditFeaturesTourSlice";
import { useEffect, useState } from "react";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import axios from "axios";
import topbar from "topbar";

// Custom Tooltip Component with Skip Button and Step Title
const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
  totalSteps,
  goToNextStep,
  goToPreviousStep,
}) => {
  return (
    <div {...tooltipProps} className="tooltip-container">
      {step.title && <div className="tooltip-title">{step.title}</div>}

      <div className="tooltip-content">{step.content}</div>
      <div className="tooltip-footer">
        <button {...skipProps} className="tooltip-skip-button">
          Skip
        </button>
        {index > 0 && (
          <button
            {...backProps}
            className="tooltip-back-button"
            onClick={goToPreviousStep}
          >
            Back
          </button>
        )}
        <button
          {...primaryProps}
          className="tooltip-next-button"
          onClick={goToNextStep}
        >
          {isLastStep ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

// Tour Component
const EditTour = () => {
  const dispatch = useDispatch();
  const runEditFeaturesTour = useSelector(
    (state) => state.editFeaturesTour.runEditFeaturesTour,
  );
  const mode = useSelector((state) => state.viewEditMode.mode);
  const [stepIndex, setStepIndex] = useState(0);
  const [autoAdvanceTimeout, setAutoAdvanceTimeout] = useState(null);

  const steps = [
    {
      target: "#btn-cut",
      title: "Cut",
      content:
        "To trim a specific portion of your track, click and drag to highlight the desired segment on the speaker or SFX track. Once highlighted, click the 'Cut' button to remove the selected section.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-silence-cont",
      title: "Silence",
      content:
        "To silence a selected section of the speaker or SFX track, highlight the desired part and click the 'Silence' button.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-copy-cont",
      title: "Copy",
      content:
        "Highlight the part you want to copy from the speaker or SFX track, and then click the 'Copy' button.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-insert",
      title: "Paste",
      content:
        "You can paste the copied or cut segment anywhere within the same track. Use the 'Paste', 'Paste Before' or 'Paste After' options to place it precisely where you want.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-insert-alt",
      title: "Insert",
      content:
        "You can insert the copied or cut part anywhere within the same track from which it was copied. It offers the functionality to 'insert before' or 'insert after' the existing content.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-loop",
      title: "Loop",
      content:
        "You can insert the copied or cut part anywhere within the same track from which it was copied. It offers the functionality to 'insert before' or 'insert after' the existing content.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-rewind-cont",
      title: "Push To Start",
      content: "Push the playhead to the start of the audio track.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-play",
      title: "Play",
      content: "Use this to play and pause the audio track.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-forward-cont",
      title: "Push To End",
      content: "Push the playhead to the end of the audio track.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#cm-flag",
      title: "Add Flag",
      content:
        " This is used to put flags and keep comments on the specific part of the speaker or SFX section.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#speakers-change",
      title: "Change Speaker",
      content:
        "Click on this button to change the current speaker in case of multiple speakers.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-waveform-selector",
      title: "Fade",
      content:
        "To apply fades to a selected part from the speaker or SFX tracks, click this button, which provides access to four different fade options.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-record",
      title: "Record",
      content:
        "Use this option to record anything and then insert or paste it into the current audio file.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-import",
      title: "Import",
      content:
        "Use this to import any audio file from your computer then insert or paste it in the current audio file.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#btn-magic",
      title: "Magic Button",
      content:
        "It offers a variety of magical operations that koolio has to offer, such as 'Remove long gaps', 'Auto filter words' and 'Auto level sound'.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#more-options-icon",
      title: "More Options",
      content:
        "To access features such as download, publish, restore and many more click on this icon (☰).",
      placement: "bottom-start",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const fetchTourGuideData = async () => {
      try {
        const Tour_name = "workspace_edit"; // Set the current tour name
        const localStorageKey = `${Koolioai.username}${Tour_name}`; // Generate the key

        // Check localStorage to avoid re-triggering the tour
        if (localStorage.getItem(localStorageKey) === "true") {
          return;
        }

        const tokens = await Promise.all([
          Koolioai.authToken(),
          Koolioai.accessToken(),
        ]);

        const response = await axios.get(
          _config.api + "/demo-tour-guide-shown",
          {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          },
        );

        if (response.data.demo_tour_guide.workspace_edit === true) {
          localStorage.setItem(localStorageKey, "true");
        } else {
          if (mode === "edit") {
            // Start the tour
            dispatch(editFeaturesTourActions.startEditFeaturesTour());

            // Update backend and set localStorage
            axios
              .post(
                _config.api + "/demo-tour-guide-shown",
                { workspace_edit: true },
                {
                  headers: {
                    Authorization: `${tokens[0]}`,
                    AccessToken: `${tokens[1]}`,
                  },
                },
              )
              .then(() => {
                localStorage.setItem(localStorageKey, "true"); // Mark as completed in localStorage
              })
              .catch((error) =>
                console.error("Error updating tour guide data:", error),
              );
          }
        }
      } catch (error) {
        console.error("Error fetching or updating tour guide data:", error);
      }
    };

    fetchTourGuideData();
  }, [dispatch, mode]);

  useEffect(() => {
    // Clear any ongoing timeouts or intervals
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);

    // Hide the top bar on the last step
    if (stepIndex === steps.length - 1) {
      topbar.hide();
      return; // Exit early to avoid setting up progress on the last step
    }

    if (runEditFeaturesTour) {
      topbar.show(); // Show the topbar
      topbar.progress("0"); // Reset progress

      let progress = 0;
      const interval = 32; // Milliseconds per increment
      const increment = 0.01; // Progress increment per interval
      let progressInterval;

      const startProgressBar = () => {
        progressInterval = setInterval(() => {
          progress += increment;
          if (progress >= 1) {
            clearInterval(progressInterval);
            topbar.progress("1"); // Ensure it ends at full
          } else {
            topbar.progress(progress.toString());
          }
        }, interval);
      };

      // Start the progress bar
      startProgressBar();

      // Advance to the next step after 4 seconds
      const timeout = setTimeout(() => {
        clearInterval(progressInterval); // Stop progress bar updates
        setStepIndex((prevIndex) => prevIndex + 1);
      }, 4000);

      setAutoAdvanceTimeout(timeout);

      return () => {
        clearTimeout(timeout);
        clearInterval(progressInterval);
      };
    }
  }, [runEditFeaturesTour, stepIndex]);

  // Reset the tour state after it has been run
  const handleTourEnd = () => {
    topbar.hide(); // Dispose the topbar
    dispatch(editFeaturesTourActions.stopEditFeaturesTour());
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);
  };

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex((prevIndex) => prevIndex + 1);
    }
    if (stepIndex === steps.length - 1) {
      handleTourEnd(); // Handle last step manually
    }
  };

  const goToPreviousStep = () => {
    if (stepIndex > 0) {
      setStepIndex((prevIndex) => prevIndex - 1);
    }
    if (autoAdvanceTimeout) {
      clearTimeout(autoAdvanceTimeout);
      topbar.progress("0");
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      disableScrolling
      hideCloseButton
      showSkipButton={true} // Enable skip button
      showProgress={true} // Enable step progress
      scrollToFirstStep={true}
      run={runEditFeaturesTour}
      stepIndex={stepIndex}
      callback={(data) => {
        if (data.status === "finished" || data.status === "skipped") {
          handleTourEnd(); // Stop tour when it's done
        }
      }}
      styles={{
        options: {
          zIndex: 10000,
          overlayColor: "rgba(24,24,24,1)",
          arrowColor: "#C4C4C4",
        },
      }}
      tooltipComponent={(props) => (
        <CustomTooltip
          {...props}
          totalSteps={steps.length}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
    />
  );
};

export default EditTour;
