// This slice will check from the recordspace btn is getting trigegered so that the close workspace btn can implemented accordingly

import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  btnCLickedFrom: "",
};

const recordspaceBtnTriggerSlice = createSlice({
  name: "recordspaceBtnTrigger",
  initialState: initialValue,
  reducers: {
    storeBtnClickedEnv(state, action) {
      // console.log(action);
    },
  },
});

export const recordSpaceBtnActions = recordspaceBtnTriggerSlice.actions;
export default recordspaceBtnTriggerSlice.reducer;
