import React, { useEffect, useRef, useState } from "react";
import "../../../css/dark/moreoption.css";
import {
  getAllPrevGenImage,
  getAllPrevGenMusics,
  getAllPrevGenSFX,
  showNotesGenerate,
} from "../../../services/GenAiServices";
import { Koolioai, _config } from "../../../utils/cognitoAuth";
import axios from "axios";
import store from "../../../redux/Store";
import { genAiActions } from "../../../redux/slice/GenAiWebsocketDataSlice";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import GenAImodals from "./GenAImodals";
import ShowNotesModal, {
  ShowNotesErrorModal,
} from "../workspace/modals/ShowNotesModal";
import {
  hideplaylistloader,
  showplaylistloader,
} from "../kooliospace/kooliospaceUtils";
import {
  getAINotesFromDB,
  sendEditedAiNotesToBackend,
  showMessage,
} from "../../../utils/koolio-workspace";
import { getAllFeatureCountForTrial } from "../../../utils/Access-control-manager";
import {
  ACM,
  create_UUID,
  getCurrentFeatureWiseCount,
  pasteOperation,
  showStatText,
} from "../../../utils/utils";
import topbar from "topbar";
import useBootstrapTooltips from "../../../hooks/useBootstrapTooltips";
import { Tooltip, Popover } from "bootstrap";
import "@popperjs/core";
import { customGenaiSliceActions } from "../../../redux/slice/GenaiSelectedSfxMusicSlice";
import $ from "jquery";
import { createPortal } from "react-dom";
import GenaiLengthModal from "../../modals/GenaiLengthModal";
// import ProjectTitle from '../workspace/navbarWorkspace/Title';

const GenAi = (props) => {
  const [elementId, setElementId] = useState("");

  useBootstrapTooltips(elementId);

  const isMounted = useRef(true);

  // const { state } = location;
  // const shouldApplyBlur = state && state.blurBackground;
  const dispatch = useDispatch();
  document.querySelector("body").style.overflow = "hidden";

  const [selectedOption, setSelectedOption] = useState(
    props.isWhat === "music" ? "genMusic" : "genSFX",
  );

  function handlePrevGenSFX(sfxTracks) {
    if (!sfxTracks.length) {
      document.getElementById("prevGenSFXContainer").style.display = "none";
      document.getElementById("genAiSFXContent").style.justifyContent =
        "center";
    } else {
      document.getElementById("prevGenSFXContainer").style.display = "";
      document.getElementById("genAiSFXContent").style.justifyContent = "";
      const prevGenBox = document.getElementById("prev_gen_sfx");
      if (prevGenBox.scrollHeight > prevGenBox.offsetHeight) {
        document.getElementById("gen-sfx-arrow-up").style.visibility =
          "visible";
        document.getElementById("gen-sfx-arrow-down").style.visibility =
          "visible";
      } else {
        document.getElementById("gen-sfx-arrow-up").style.visibility = "hidden";
        document.getElementById("gen-sfx-arrow-down").style.visibility =
          "hidden";
      }
    }
    // destroyGenLoader("sfx");
  }

  function openGenAiContent() {
    // if(!ACM.checkPermission('gen-ai').success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
    //     if(fromMoreOptions) {
    //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'more-options')}
    //     else {
    //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
    //     }
    // }
    // document.getElementById('musicSearchCommand').style.display = 'none'
    // document.getElementById('imageSearchCommand').style.display = 'none'
    // document.getElementById('sfxSearchCommand').style.display = 'none'

    const genMusicSearchBar = document.getElementById("genMusicSearchBar");
    if (genMusicSearchBar && !genMusicSearchBar.disabled) {
      genMusicSearchBar.value = "";
      document.getElementById("genMusicInfoText").style.visibility = "";
      if (document.getElementById("modal_gen_music").children.length) {
        for (
          let i =
            document.getElementById("modal_gen_music").children.length - 1;
          i >= 0;
          i--
        ) {
          document
            .getElementById("prev_gen_musics")
            .insertBefore(
              document.getElementById("modal_gen_music").children[i],
              document.getElementById("prev_gen_musics").childNodes[0],
            );
        }
        handlePrevGenMusics([1]);
      } else {
        let modalGenMusicLinks =
          document.querySelectorAll("#modal_gen_music a");
        for (let i = 0; i < modalGenMusicLinks.length; i++) {
          modalGenMusicLinks[i].remove();
        }
      }
    }

    const genImageSearchBar = document.getElementById("genImageSearchBar");
    if (genImageSearchBar && !genImageSearchBar.disabled) {
      document.getElementById("genImageSearchBar").value = "";
      document.getElementById("ImageInfoText").style.visibility = "visible";
      if (document.getElementById("_gen_image").children.length) {
        getAllPrevGenImage(null, false, true);
        document.getElementById("_gen_image").innerHTML = "";
        handlePrevGenImage([1]);
      } else {
        var prevGenMusics = document.getElementById("prev_gen_musics");
        while (prevGenMusics.firstChild) {
          prevGenMusics.removeChild(prevGenMusics.firstChild);
        }
      }
    }

    const genSFXSearchBar = document.getElementById("genSFXSearchBar");
    if (genSFXSearchBar && !genSFXSearchBar.disabled) {
      document.getElementById("genSFXSearchBar").value = "";
      document.getElementById("genSFXInfoText").style.visibility = "visible";
      if (document.getElementById("modal_gen_sfx").children.length) {
        var modalGenSfx = document.getElementById("modal_gen_sfx");
        for (var i = modalGenSfx.children.length - 1; i >= 0; i--) {
          document
            .getElementById("prev_gen_sfx")
            .insertBefore(
              modalGenSfx.children[i],
              document.getElementById("prev_gen_sfx").firstChild,
            );
        }
        handlePrevGenSFX([1]);
      } else {
        var prevGenSfxAs = document.querySelectorAll("#modal_gen_sfx a");
        for (var i = 0; i < prevGenSfxAs.length; i++) {
          prevGenSfxAs[i].remove();
        }
      }
    }

    // genAIMenu();
  }

  // openGenAiContent()
  useEffect(() => {
    if (selectedOption == "genSFX") {
      isMounted.current = true;
      setElementId("genSFX");
      document.querySelector("body").style.overflow = "hidden";
      genSFXOptions();
    } else if (selectedOption == "genMusic") {
      isMounted.current = true;
      setElementId("genMusic");
      document.querySelector("body").style.overflow = "hidden";
      genMusicOptions();
    } else if (selectedOption == "genImage") {
      isMounted.current = true;
      setElementId("genImage");
      document.querySelector("body").style.overflow = "hidden";
      genImage();
    }
    return () => {
      // abortController.abort();
      isMounted.current = false; // Cleanup the ref on component unmount
    };
  }, [selectedOption]);

  function initiateGenLoader(aiContent) {
    topbar.show();
    (function step() {
      setTimeout(function () {
        if (topbar.progress("+.01") < 1) step();
      }, 32);
    })();
    if (aiContent === "music") {
      if (document.getElementById("genAiMusicContent")) {
        document.getElementById("genAiMusicContent").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-music-arrow-up")) {
        document.getElementById("gen-music-arrow-up").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-music-arrow-down")) {
        document.getElementById("gen-music-arrow-down").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-music-spinner")) {
        document.getElementById("gen-music-spinner").style.display = "block";
      }
    } else if (aiContent === "sfx") {
      if (document.getElementById("genAiSFXContent")) {
        document.getElementById("genAiSFXContent").style.visibility = "hidden";
      }
      if (document.getElementById("gen-sfx-arrow-up")) {
        document.getElementById("gen-sfx-arrow-up").style.visibility = "hidden";
      }
      if (document.getElementById("gen-sfx-arrow-down")) {
        document.getElementById("gen-sfx-arrow-down").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-sfx-spinner")) {
        document.getElementById("gen-sfx-spinner").style.display = "block";
      }
    } else if (aiContent === "image") {
      if (document.getElementById("genAiImageContent")) {
        document.getElementById("genAiImageContent").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-image-arrow-up")) {
        document.getElementById("gen-image-arrow-up").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-image-arrow-down")) {
        document.getElementById("gen-image-arrow-down").style.visibility =
          "hidden";
      }
      if (document.getElementById("gen-image-spinner")) {
        document.getElementById("gen-image-spinner").style.display = "block";
      }
    }
  }

  function destroyGenLoader(aiContent) {
    topbar.hide();

    if (!isMounted.current) return; // Early exit if component is not mounted

    if (aiContent === "music") {
      const musicContent = document.getElementById("genAiMusicContent");
      const musicSpinner = document.getElementById("gen-music-spinner");

      if (musicContent) {
        musicContent.style.visibility = "visible";
      }
      if (musicSpinner) {
        musicSpinner.style.display = "none";
      }
    } else if (aiContent === "sfx") {
      const sfxContent = document.getElementById("genAiSFXContent");
      const sfxSpinner = document.getElementById("gen-sfx-spinner");

      if (sfxContent) {
        sfxContent.style.visibility = "visible";
      }
      if (sfxSpinner) {
        sfxSpinner.style.display = "none";
      }
    } else if (aiContent === "image") {
      const imageContent = document.getElementById("genAiImageContent");
      const imageSpinner = document.getElementById("gen-image-spinner");

      if (imageContent) {
        imageContent.style.visibility = "visible";
      }
      if (imageSpinner) {
        imageSpinner.style.display = "none";
      }
    }
  }

  function sfxMusicOnFirstPage() {
    let sfxMusicArrowLeft = document.getElementById("sfx-music-arrow-left");
    sfxMusicArrowLeft.style.opacity = "0.5";
    sfxMusicArrowLeft.style.cursor = "not-allowed";
  }
  function initializeSfxMusicShow(isGenSFXMusic) {
    // foundMusic = false
    //showRiffsearchloader(isGenSFXMusic)
    if (isGenSFXMusic === "music") {
      document.getElementById("musicInvisibleSearchBar").click();
    } else if (isGenSFXMusic === "sfx") {
      document.getElementById("sfxInvisibleSearchBar").click();
    } else if (isGenSFXMusic === "image") {
      document.getElementById("imageInvisibleSearchBar").click();
    }
    return;
  }
  function showGenSFX() {
    const SFXLinkArray = [];

    // if (!document.getElementById("modal_gen_sfx_hidden").children.length) {
    //   return initializeSfxMusicShow("music");
    // }

    if (document.getElementById("modal_gen_sfx_hidden").children.length) {
      const sfxLinks = document.getElementById("modal_gen_sfx_hidden").children;

      for (let i = 0; i < sfxLinks.length; i++) {
        SFXLinkArray.push(sfxLinks[i]);
      }

      const sfxLinksContainer = document.getElementById("modal_gen_sfx");
      while (sfxLinksContainer.firstChild) {
        sfxLinksContainer.removeChild(sfxLinksContainer.firstChild);
      }

      const frag = document.createDocumentFragment();
      for (let i = 0; i < SFXLinkArray.length; i++) {
        frag.appendChild(SFXLinkArray[i]);
      }

      sfxLinksContainer.appendChild(frag);

      if (
        sfxLinksContainer.children.length === 1 &&
        document.getElementById("createSFXLoader")
      ) {
        // generatedSFXCount = 0;
        // setTimeout(() => {
        //   document.getElementById("createSFXLoader").remove();
        //   document.getElementById("create-gen-sfx-button").disabled = false;
        //   document.getElementById("genSFXSearchBar").disabled = false;
        //   document.getElementById("sfxSearchBar").disabled = false;
        // }, 500);
      }
    }
  }

  function showGenMusic() {
    const musicLinkArray = [];
    const modalGenMusicHidden = document.getElementById(
      "modal_gen_music_hidden",
    );

    if (!modalGenMusicHidden.children.length)
      return initializeSfxMusicShow("music");

    for (let i = 0; i < modalGenMusicHidden.children.length; i++) {
      musicLinkArray.push(modalGenMusicHidden.children[i]);
    }

    const modalGenMusic = document.getElementById("modal_gen_music");
    const aTags = modalGenMusic.getElementsByTagName("a");
    for (let i = 0; i < aTags.length; i++) {
      aTags[i].remove();
    }

    const frag = document.createDocumentFragment();
    for (let i = 0; i < musicLinkArray.length; i++) {
      frag.appendChild(musicLinkArray[i]);
    }

    modalGenMusic.appendChild(frag);

    if (
      modalGenMusic.children.length === 2 &&
      document.getElementById("createMusicLoader")
    ) {
      //     generatedMusicCount = 0;
      //     setTimeout(() => {
      //         document.getElementById("createMusicLoader").remove();
      //         document.getElementById("create-gen-music-button").disabled = false;
      //         document.getElementById("genMusicSearchBar").disabled = false;
      //         document.getElementById('sfxSearchBar').disabled = false;
      //     }, 500);
    }
  }

  let mouseOverCount = 0;
  let audio = null;

  function playSfxMusic(url) {
    if (!mouseOverCount) {
      const newAudio = new Audio(url);
      mouseOverCount = 1;
      audio = newAudio;
      newAudio
        .play()
        .then(() => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          // Show error UI or handle error appropriately.
        });
    }
  }

  function bindPlaySfx() {
    document
      .querySelectorAll('a[data-bs-original-title="sfx playing"]')
      .forEach(function (element) {
        let playSFXTimeOut;
        element.addEventListener("mouseover", function () {
          playSFXTimeOut = setTimeout(() => {
            playSfxMusic(this.id);
          }, 1000);
        });

        element.addEventListener("mouseleave", function () {
          clearTimeout(playSFXTimeOut);
          pauseSfxMusic();
        });
        element.addEventListener("click", function () {
          clearTimeout(playSFXTimeOut);
          pauseSfxMusic();
        });
      });
  }

  function pauseSfxMusic() {
    if (audio !== null) {
      audio.pause();
      mouseOverCount = 0;
      audio = null;
    }
  }
  const getFileName = useSelector((state) => state.genAiWebsocketData.value);
  const [showNotesErrorModal, setShowNotesErrorModal] = useState(false);
  useEffect(() => {
    if (getFileName.is_what == "sfx") {
      checkFunctionToRemoveLoader("sfx");
      const searchbar = document.getElementById("sfxSearchBar");
      const start = null;
      const end = null;
      const wordid = null;
      const invisibleSearchbar = document.getElementById(
        "sfxInvisibleSearchBar",
      );
      invisibleSearchbar.value =
        getFileName.fileName ||
        document.getElementById("genSFXSearchBar").value;
      // invisibleSearchbar.setAttribute('onClick', 'searchSfx(event, this.value, ' + start + ', ' + end + ', false, "' + wordid + '", true)')
      invisibleSearchbar.setAttribute("start", start);
      invisibleSearchbar.setAttribute("end", end);
      invisibleSearchbar.setAttribute("wordid", null);
      let word = getFileName.filename;

      getSFX(word, true).then((tracks) => {
        searchSfxSuccessHandler(
          tracks,
          word,
          0,
          0,
          false,
          null,
          false,
          true,
        ).catch((e) => {
          // console.error(e)
        });
      });
    } else if (getFileName.is_what == "music") {
      checkFunctionToRemoveLoader("music");
      const searchbar = document.getElementById("sfxSearchBar");
      const start = null;
      const end = null;
      const wordid = null;
      const invisibleSearchbar = document.getElementById(
        "musicInvisibleSearchBar",
      );
      invisibleSearchbar.value =
        getFileName.fileName ||
        document.getElementById("genMusicSearchBar").value;
      // invisibleSearchbar.setAttribute('onClick', 'searchSfx(event, this.value, ' + start + ', ' + end + ', false, "' + wordid + '", true)')
      invisibleSearchbar.setAttribute("start", start);
      invisibleSearchbar.setAttribute("end", end);
      invisibleSearchbar.setAttribute("wordid", null);
      let word = getFileName.filename;

      getMusic(word, true).then((tracks) => {
        searchMusicSuccessHandler(
          tracks,
          word,
          0,
          0,
          false,
          null,
          false,
          true,
        ).catch((e) => {
          // console.error(e)
        });
      });
    } else if (getFileName.is_what == "image") {
      checkFunctionToRemoveLoader("image");
      async function callImage(id) {
        let imageTracks = await getAllPrevGenImage(id, true);
        await searchImageSuccessHandler(imageTracks, false).catch((e) => {
          // console.error(e)
        });
      }
      callImage(getFileName.id);
      // let imageTracks = getAllPrevGenImage(getFileName.id,true)
    } else if (getFileName.keywords && getFileName.keywords != "") {
      showGeneratedNotes(getFileName);
    } else if (getFileName.is_what == "show-notes-error") {
      setShowNotesErrorModal(true);
    } else {
    }
  }, [getFileName]);

  function getMusic(word, audioCraft = false, sfxMusicOffset = 0, size = 5) {
    const state = store.getState();
    let resultStat = state.projectData.resultStat;
    const jobname = resultStat.jobname;
    const username = Koolioai.username;
    const searchApiName = audioCraft ? "/gen-ai" : "/search-music";
    let generatedMusicCount = state.genAiWebsocketData.numberOfgenAiProduced;
    return Koolioai.authToken().then((token) =>
      axios
        .get(_config.api + searchApiName, {
          params: {
            sentence: word,
            combineResult: true,
            offset: sfxMusicOffset,
            size,
            jobname,
            username,
            getPreviousGenAi: false,
            get_music: true,
            desiredSFXMusicCount: generatedMusicCount,
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const musicTracks = response.data[word] || response.data.data || [];
          // saveMusic(word, musicTracks)
          //     .catch(e => {
          //         // console.error(e)
          //     })
          return Promise.resolve(musicTracks);
        })
        .catch(() => Promise.resolve([])),
    );
  }

  function getSFX(word, audioCraft = false, sfxMusicOffset = 0, size = 5) {
    const state = store.getState();
    let resultStat = state.projectData.resultStat;
    const jobname = resultStat.jobname;
    const username = Koolioai.username;
    const searchApiName = audioCraft ? "/gen-ai" : "/search-sfx";
    return Koolioai.authToken().then((token) =>
      axios
        .get(_config.api + searchApiName, {
          params: {
            sentence: word,
            combineResult: true,
            offset: sfxMusicOffset,
            size,
            jobname,
            username,
            getPreviousGenAi: false,
            get_music: false,
            desiredSFXMusicCount: 1,
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const sfxTracks = response.data[word] || response.data.data || [];
          // saveSFX(word, sfxTracks).catch(e => {
          //     // console.error(e)
          // })
          return Promise.resolve(sfxTracks);
        })
        .catch(() => Promise.resolve([])),
    );
  }

  async function searchSfxSuccessHandler(
    tracks,
    word,
    start,
    end,
    backgroundLoad,
    wordid = null,
    prevGen = false,
    temp = false,
  ) {
    if (!isMounted.current) return;
    return new Promise((resolve) => {
      if (tracks.length || prevGen) {
        // first page
        // sfxMusicOffset <= 0 ? sfxMusicOnFirstPage() : document.getElementById('sfx-music-arrow-left').style.opacity = '1';
        // // last page
        // tracks.length < 5 ? sfxMusicOnLastPage() : document.getElementById('sfx-music-arrow-right').style.opacity = '1';

        if (!backgroundLoad) {
          return Promise.all(
            tracks.map((currTrack) =>
              Koolioai.getSignedPreview(
                currTrack.filename,
                currTrack.sfxlib_bucket,
              ).then((url) => {
                if (!isMounted.current) return null; // Prevent DOM manipulation
                const a = document.createElement("a");
                const name = document.createTextNode(
                  currTrack.description.toLowerCase(),
                );
                a.appendChild(name);
                a.href = "#";
                a.id = url.toString();
                a.title = "sfx playing";
                a.setAttribute("data-bs-custom-class", "custom-tooltip");
                new Tooltip(a, {
                  trigger: "hover",
                  boundary: "scrollParent",
                  animation: true,
                  delay: { show: 500, hide: 100 },
                });
                (function (track) {
                  a.onclick = () => {
                    addGenAiSfxMusic(
                      track.filename,
                      track.sfxlib_bucket,
                      track.name,
                    );
                  };
                })(currTrack);
                // a.setAttribute("onclick", ``);
                // a.setAttribute('onclick', `addSfx(event, ${start}, ${end}, "${currTrack.id}", "${currTrack.name}", "${currTrack.filename.replace('"', '&quot;').replace('"', '&quot;')}", "${wordid}", "${currTrack.description.toLowerCase()}", "${a.title}", ${currTrack.sfxlib_bucket? true : currTrack.sfxlib_bucket}, "${url}")`);
                return Promise.resolve(a);
              }),
            ),
          )
            .then((data) => {
              if (!isMounted.current) return;
              // if (foundMusic && !prevGen) document.querySelectorAll('.modal-content a').forEach(el => el.remove());
              const currentWord =
                document
                  .getElementById("sfxInvisibleSearchBar")
                  .value.trim()
                  .toLowerCase() ||
                document
                  .getElementById("genSFXSearchBar")
                  .value.trim()
                  .toLowerCase();
              data = data.filter(Boolean);
              if (
                word === currentWord ||
                word === currentWord.replace(/[^a-zA-Z0-9 ]/g, "") ||
                word === currentWord + "audiogen" ||
                word === currentWord + "audioldm" ||
                prevGen ||
                temp
              ) {
                if (data.length || prevGen) {
                  // document.querySelectorAll('.sfx-music-arrows').forEach(el => el.style.visibility = 'visible');
                  const frag = document.createDocumentFragment();
                  for (
                    let i = 0;
                    data.length === 8 ? i < data.length - 1 : i < data.length;
                    ++i
                  ) {
                    frag.appendChild(data[i]);
                  }
                  if (prevGen) {
                    document.getElementById("prev_gen_sfx").innerHTML = "";
                    document.getElementById("prev_gen_sfx").appendChild(frag);
                    handlePrevGenSFX(tracks);
                  } else {
                    document
                      .getElementById("modal_gen_sfx_hidden")
                      .appendChild(frag);
                    document.getElementById("modal_gen_sfx").appendChild(frag);
                    // // document.getElementById('modal_gen_sfx_hidden').style.display('block')
                    // const state = store.getState()
                    // store.dispatch(genAiActions.increaseGenAiCounter())
                    showGenSFX();
                  }
                  bindPlaySfx();
                }
              } else {
                const e = new KeyboardEvent("keydown", { keyCode: 13 });
              }
              if (!isMounted.current) return;
              destroyGenLoader("sfx");
            })
            .catch((error) => {
              // console.error("Error during fetching signed preview:", error);
              // if (isMounted.current) destroyGenLoader("sfx");
            });
        }
      }
      // else {
      //     const currentWordTyp = document.getElementById('sfxSearchBar').value;
      //     if (word !== currentWordTyp) {
      //         disableCreateMusicButton();
      //         showsfxsearchloader();
      //         document.querySelectorAll('.modal-content a').forEach(el => el.remove());
      //         const a = document.createElement('a');
      //         a.setAttribute('onclick', 'return false;');
      //         document.getElementById('modal_1').appendChild(a);
      //     } else {
      //         if (!backgroundLoad) {
      //             document.querySelectorAll('.sfx-music-arrows').forEach(el => el.style.visibility = 'hidden');
      //             document.querySelectorAll('.modal-content a').forEach(el => el.remove());
      //             const a = document.createElement('a');
      //             const name = document.createTextNode('no results, please create or try with a different keyword');
      //             a.appendChild(name);
      //             a.id = 'sfx-noresults';
      //             a.classList.add("noresults-font-style");
      //             a.title = 'sfx-noresults';
      //             a.setAttribute('onclick', 'return false;');
      //             document.getElementById('modal_1').appendChild(a);
      //             if (foundMusic) {
      //                 document.getElementById('modal_1_no_results').appendChild(a);
      //                 document.getElementById('modal_1_no_results').style.display = 'block';
      //                 hidesfxsearchloader();
      //                 enableCreateMusicButton();
      //             }
      //         }
      //     }
      // }
      resolve();
    });
  }

  const genSFXOptions = async () => {
    document.getElementById("sfx-gen-icon").style.opacity = "1";
    document.getElementById("music-gen-icon").style.opacity = "0.5";
    document.getElementById("gen-img-icon").style.opacity = "0.5";
    document.getElementById("show-notes-icon").style.opacity = "0.5";
    setSelectedOption("genSFX");
    initiateGenLoader("sfx"); // need to uncomment this
    try {
      let response = await getAllPrevGenSFX();
      if (isMounted.current) {
        // Only proceed if the component is mounted
        await searchSfxSuccessHandler(response, null, 0, 0, false, null, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (isMounted.current) {
        destroyGenLoader("sfx");
      }
    }
  };
  const [showGenaiLengthModal, setShowGenaiLengthModal] = useState({
    value: false,
    env: null,
  });
  function closeGenaiLengthModal() {
    setShowGenaiLengthModal({ value: false, env: null });
  }
  function setLength(value) {
    closeGenaiLengthModal();
    createGenAiSFXFunction(value);
  }
  function setLengthForGenAIMusic(value) {
    closeGenaiLengthModal();
    createGenAiMusicFunction(value);
  }
  async function createSFXcontainer(e) {
    const text = document.getElementById("genSFXSearchBar").value;
    if (text.trim() == "") {
      document.getElementById("sfxSearchCommand").style.display = "block";
      return;
    }
    setShowGenaiLengthModal({ value: true, env: "sfx" });
  }
  function createGenAiSFXFunction(length) {
    const text = document.getElementById("genSFXSearchBar").value;
    createYourAIGenSFXMusic(text, "create-gen-sfx-button", length);
  }
  function createGenAiMusicFunction(length) {
    const text = document.getElementById("genMusicSearchBar").value;
    createYourAIGenSFXMusic(text, "create-gen-music-button", length);
  }

  async function createMusicContainer() {
    const text = document.getElementById("genMusicSearchBar").value;
    if (text.trim() == "") {
      document.getElementById("musicSearchCommand").style.display = "block";
      return;
    }
    setShowGenaiLengthModal({ value: true, env: "musix" });
  }
  function createYourAIGenSFXMusic(word, id, selectedLength) {
    const permResponse = ACM.checkPermission("gen-ai");
    if (
      !permResponse.success ||
      ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"
    ) {
      return showMessage(
        permResponse.message,
        "Gen AI is not available in free plan",
        2000,
        "error",
        "workspace",
      );
    }
    // if(document.getElementById("gen-min-length").value.length != 0){
    //     closeHalfModal()

    if (id === "create-gen-music-button") {
      document.getElementById("musicSearchCommand").style.display = "none";
      if (document.getElementById("modal_gen_music").children.length) {
        for (
          let i =
            document.getElementById("modal_gen_music").children.length - 1;
          i >= 0;
          i--
        ) {
          document
            .getElementById("prev_gen_musics")
            .insertBefore(
              document.getElementById("modal_gen_music").children[i],
              document.getElementById("prev_gen_musics").childNodes[0],
            );
        }
        handlePrevGenMusics([1]);
      } else {
        var elements = document.querySelectorAll("#modal_gen_music a");
        for (var i = 0; i < elements.length; i++) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      }
      if (!word.length) {
        return (document.getElementById("musicSearchCommand").style.display =
          "block");
      }
      // document.getElementById("create-gen-music-button").disabled = true
      // document.getElementById("genMusicSearchBar").disabled = true
      // document.getElementById('sfxSearchBar').disabled = true
      word = word.trim();
      word = word.replace("_", " ");
      word = word.toLowerCase();
      // webSocketMessageForGenAIMusicReceived = false
      createMusic(word, selectedLength);
    } else if (id === "create-gen-sfx-button") {
      document.getElementById("sfxSearchCommand").style.display = "none";
      if (document.getElementById("modal_gen_sfx").children.length) {
        for (
          let i = document.getElementById("modal_gen_sfx").children.length - 1;
          i >= 0;
          i--
        ) {
          document
            .getElementById("prev_gen_sfx")
            .insertBefore(
              document.getElementById("modal_gen_sfx").children[i],
              document.getElementById("prev_gen_sfx").childNodes[0],
            );
        }
        handlePrevGenSFX([1]);
      } else {
        var elements = document.querySelectorAll("#modal_gen_sfx a");
        for (var i = 0; i < elements.length; i++) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      }
      if (!word.length) {
        return (document.getElementById("sfxSearchCommand").style.display =
          "block");
      }
      // document.getElementById("create-gen-sfx-button").disabled = true
      // document.getElementById("genSFXSearchBar").disabled = true
      // document.getElementById('sfxSearchBar').disabled = true
      word = word.trim();
      word = word.replace("_", " ");
      word = word.replace("-", " ");
      word = word.replace(/[^a-zA-Z0-9 ]/g, "");
      word = word.toLowerCase();
      // webSocketMessageForGenAISfxReceived = false
      createSFX(word, selectedLength);
    }
  }
  async function createMusic(
    textToGenerateMusic = "Hello",
    selectedLength = 4,
  ) {
    const permResponse = ACM.checkPermission("gen-ai");
    if (
      !permResponse.success ||
      ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"
    ) {
      return showMessage(
        permResponse.message,
        "Gen AI is not available in free plan",
        2000,
        "error",
        "workspace",
      );
    }
    //fetching jobname and username
    const state = store.getState();
    dispatch(genAiActions.clearWebsocketData()); // clearing websocket genai data before we create another genai request
    dispatch(genAiActions.setLengthOfSfxMusic({ numberOfgenAi: 2 }));

    //3403 starts here
    let percentage = 0;
    let estimateTimeToComplete = undefined;
    let percentageChangePerSecond = undefined;
    // for selected length than 7.68, riffusion model is major factor, so we will consider that while creating progress bar
    let desiredMusicCount;
    if (selectedLength < 7.68) {
      percentage = 0;
      desiredMusicCount = 2;
    } else {
      // now audioGen is the major factor, we will increase the percentage change based on the this calculations
      estimateTimeToComplete = 7.5 + selectedLength * 3.5;
      percentageChangePerSecond = 100 / estimateTimeToComplete;
      percentage = 7.5;
      desiredMusicCount = 1;
    }

    showStatText("Creating music...", false);

    const div = document.createElement("div");
    div.setAttribute("id", "createMusicLoader");
    div.classList.add("mb-5");
    document.getElementById("create-music-progress-box").appendChild(div);
    const increasePercentage = (percentage) => {
      if (document.getElementById("createMusicLoader")) {
        div.innerHTML = `<div class="position-relative mx-auto " id ="genAi-music" style="width: 50%; height:10vh; z-index: 0 ;">
            <img src="/image/loadingspinner.gif" style="width: 12%;position: relative;
    left: 39%;"id="genAi-music-spinner" alt="koolio.ai" /><br>
            <div style="font-weight:500;color:#c4c4c4;font-size: 1vw; background-color: transparent !important;margin-top:1.5vh;" class="position-absolute h-100 w-100">
            <p style="font-weight:500;color:#c4c4c4;font-size: 1vw;"id="genAi-music-loaderText">Please wait! This process may take sometime.</p>
            </div>
            </div>`;

        //if (percentage === 90) {
        //    document.getElementById("create-gen-music-button").disabled = false
        //    document.getElementById("genMusicSearchBar").disabled = false
        //    setTimeout(() => {

        //        document.getElementById("createMusicLoader").remove()
        //        let a = document.createElement('a')
        //        let name = document.createTextNode("demo")
        //        a.appendChild(name)
        //        a.href = '#'
        //        a.title = 'music'
        //        document.getElementById('modal_gen_music').appendChild(a)

        //    }, 1000)
        //}
        if (desiredMusicCount == 2) {
          // for selected length less than 7.68, the estimated commpletion time is  41 sec, so taking it into consideration, we have to increase our progress bar
          setTimeout(() => {
            if (percentage < 95) {
              percentage = percentage + 9;
              increasePercentage(percentage);
            }
          }, 4000);
        } else {
          // for selected length more than 7.68
          setTimeout(() => {
            if (percentage < 96) {
              percentage = Math.floor(
                percentage + 4 * percentageChangePerSecond,
              );
              increasePercentage(percentage);
            }
          }, 4000);
        }
      }
    };

    increasePercentage(percentage);
    let resultStat = state.projectData.resultStat;
    const jobname = resultStat.jobname;
    const username = Koolioai.username;
    const paramsMusicgen = {
      prompt: textToGenerateMusic,
      username,
      duration: parseFloat(selectedLength),
      jobname,
      model: "musicgen",
    };
    const paramsRiffusion = {
      prompt: textToGenerateMusic,
      username,
      duration: parseFloat(selectedLength),
      jobname,
      model: "riffusion",
    };

    let createMusics;

    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) => {
        if (desiredMusicCount === 2) {
          createMusics = [
            axios.post(_config.api + "/gen-ai", paramsMusicgen, {
              headers: {
                Authorization: `${tokens[0]}`,
                AccessToken: `${tokens[1]}`,
              },
            }),
            axios.post(_config.api + "/gen-ai", paramsRiffusion, {
              headers: {
                Authorization: `${tokens[0]}`,
                AccessToken: `${tokens[1]}`,
              },
            }),
          ];
        } else {
          createMusics = [
            axios.post(_config.api + "/gen-ai", paramsMusicgen, {
              headers: {
                Authorization: `${tokens[0]}`,
                AccessToken: `${tokens[1]}`,
              },
            }),
          ];
        }
        return Promise.all(createMusics)
          .then((response) => {
            showStatText("Creating music...", false);
            return Promise.resolve(response);
          })
          .catch((e) => {
            showStatText(
              "Sorry! could not generate the music. please try again later",
              false,
            );
            // return Promise.reject(e)
          });
      },
    );
  }

  async function createSFX(textToGenerateSFX = "Hello", selectedLength = 4) {
    const permResponse = ACM.checkPermission("gen-ai");
    if (
      !permResponse.success ||
      ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"
    ) {
      return showMessage(
        permResponse.message,
        "Gen AI is not available in free plan",
        2000,
        "error",
        "workspace",
      );
    }
    const state = store.getState();
    dispatch(genAiActions.clearWebsocketData());

    dispatch(genAiActions.setLengthOfSfxMusic({ numberOfgenAi: 1 }));

    // same process as createMusic
    let estimateTimeToComplete = undefined;
    let percentageChangePerSecond = undefined;
    // these are calculated time for different selected length, we will be displaying the progress bar based on this

    let GenAiSFXFetchingData = [
      {
        selectedLength: 7,
        time: 40,
      },
      {
        selectedLength: 8,
        time: 42,
      },
      {
        selectedLength: 9,
        time: 44,
      },
      {
        selectedLength: 10,
        time: 47,
      },
      {
        selectedLength: 15,
        time: 66,
      },
      {
        selectedLength: 20,
        time: 84,
      },
      {
        selectedLength: 25,
        time: 105,
      },
      {
        selectedLength: 30,
        time: 126,
      },
    ];

    //code commented to restrict AudioLDM temoporarily
    //if(selectedLength <= 10){
    //    desiredSFXCount = 2
    //    // we will try to estimate the time the progress bar will take.
    //    for( let i=0;i < GenAiSFXFetchingData.length;i++){
    //        if(GenAiSFXFetchingData[i].selectedLength >= selectedLength){
    //            estimateTimeToComplete = GenAiSFXFetchingData[i].time
    //            break
    //        }
    //    }
    //}
    //else{
    //    desiredSFXCount = 1
    //    estimateTimeToComplete = 7.5 + (selectedLength) * 3.5
    //}

    for (let i = 0; i < GenAiSFXFetchingData.length; i++) {
      if (GenAiSFXFetchingData[i].selectedLength >= selectedLength) {
        estimateTimeToComplete = GenAiSFXFetchingData[i].time;
        break;
      }
    }
    percentageChangePerSecond = 96 / estimateTimeToComplete;

    // showStatText('Creating sfx...', false)
    let percentage = 0;
    const div = document.createElement("div");
    div.setAttribute("id", "createSFXLoader");
    div.classList.add("mb-5");
    document.getElementById("create-sfx-progress-box").appendChild(div);

    const increasePercentage = (percentage) => {
      if (document.getElementById("createSFXLoader")) {
        div.innerHTML = `<div class="position-relative mx-auto " id ="genAi-sfx" style="width: 50%; height:10vh; z-index: 0 ;">
            <img src="/image/loadingspinner.gif" style="width: 12%;position: relative;
    left: 39%;"id="genAi-sfx-loader" alt="koolio.ai" /><br>
            <div style="font-weight:500;color:#c4c4c4;font-size: 1vw; background-color: transparent !important;margin-top:1.5vh;" class="position-absolute h-100 w-100">
            <p style="font-weight:500;color:#c4c4c4;font-size: 1vw;"id="genAi-sfx-loaderText">Please wait! This process may take sometime.</p>
            </div>
            </div>`;

        //if (percentage === 90) {
        //    document.getElementById("create-gen-sfx-button").disabled = false
        //    document.getElementById("genSFXSearchBar").disabled = false
        //    setTimeout(() => {

        //        document.getElementById("createSFXLoader").remove()
        //        let a = document.createElement('a')
        //        let name = document.createTextNode("demo")
        //        a.appendChild(name)
        //        a.href = '#'
        //        a.title = 'sfx'
        //        document.getElementById('modal_gen_sfx').appendChild(a)

        //    }, 1000)
        //}
        setTimeout(() => {
          if (percentage < 96) {
            percentage = Math.floor(percentage + 4 * percentageChangePerSecond);
            increasePercentage(percentage);
          }
        }, 4000);
      }
    };

    increasePercentage(percentage);

    let resultStat = state.projectData.resultStat;
    const jobname = resultStat.jobname;
    const username = Koolioai.username;
    const paramsAudiogen = {
      prompt: textToGenerateSFX,
      username: username,
      duration: parseFloat(selectedLength),
      jobname,
      model: "audiogen",
    };
    const paramsAudioldm = {
      prompt: textToGenerateSFX,
      username,
      duration: parseFloat(selectedLength),
      jobname,
      model: "audioldm",
    };

    let createSFX;
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) => {
        //code commented to restrict AudioLDM temoporarily
        //if(desiredSFXCount === 2){
        //    createSFX = [
        //        axios.post(_config.api + '/gen-ai', paramsAudiogen, {
        //            headers: {
        //                Authorization: `${tokens[0]}`,
        //                AccessToken: `${tokens[1]}`
        //            }
        //        }),
        //        axios.post(_config.api + '/gen-ai', paramsAudioldm, {
        //            headers: {
        //                Authorization: `${tokens[0]}`,
        //                AccessToken: `${tokens[1]}`

        //            }
        //        })
        //    ]
        //}
        //else{
        //    createSFX = [
        //        axios.post(_config.api + '/gen-ai', paramsAudioldm, {
        //            headers: {
        //                Authorization: `${tokens[0]}`,
        //                AccessToken: `${tokens[1]}`
        //            }
        //        })
        //    ]
        //}
        //code commented to restrict AudioLDM temoporarily
        createSFX = [
          axios.post(_config.api + "/gen-ai", paramsAudiogen, {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          }),
        ];
        return Promise.all(createSFX)
          .then((response) => {
            // showStatText('Creating sfx...', false)
            // console.log(response);
            return Promise.resolve(response);
          })
          .catch((e) => {
            // console.log("error coming");
            // return
            showStatText(
              "Sorry! could not generate the sfx. please try again later",
              false,
            );
            // return Promise.reject(e)
          });
      },
    );
    // return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    // .then(tokens => {
    //   return axios.post(_config.api + '/gen-ai', paramsAudiogen, {
    //     headers: {
    //       Authorization: `${tokens[0]}`,
    //       AccessToken: `${tokens[1]}`
    //     }
    //   });
    // })
    // .then(response => {
    //   console.log(response);
    //   return Promise.resolve(response);
    // })
    // .catch(e => {
    //   console.log("error:", e);
    //   // Handle error
    //   // return Promise.reject(e);
    // });
  }

  function bindPlayMusic() {
    let playMusicTimeOut;
    const musicPlayingLinks = document.querySelectorAll(
      'a[data-bs-original-title="music playing"]',
    );

    for (const link of musicPlayingLinks) {
      link.addEventListener("mouseover", () => {
        playMusicTimeOut = setTimeout(() => {
          playSfxMusic(link.id);
        }, 1000);
      });

      link.addEventListener("mouseleave", () => {
        clearTimeout(playMusicTimeOut);
        pauseSfxMusic();
      });
      link.addEventListener("click", () => {
        clearTimeout(playMusicTimeOut);
        pauseSfxMusic();
      });
    }
  }

  function handlePrevGenMusics(musicTracks) {
    if (!musicTracks.length) {
      document.getElementById("prevGenMusicContainer").style.display = "none";
      document.getElementById("genAiMusicContent").style.justifyContent =
        "center";
    } else {
      document.getElementById("prevGenMusicContainer").style.display = "";
      document.getElementById("genAiMusicContent").style.justifyContent = "";
      const prevGenBox = document.getElementById("prev_gen_musics");
      if (prevGenBox.scrollHeight > prevGenBox.offsetHeight) {
        document.getElementById("gen-music-arrow-up").style.visibility =
          "visible";
        document.getElementById("gen-music-arrow-down").style.visibility =
          "visible";
      } else {
        document.getElementById("gen-music-arrow-up").style.visibility =
          "hidden";
        document.getElementById("gen-music-arrow-down").style.visibility =
          "hidden";
      }
    }
    // destroyGenLoader("music");
  }

  function searchMusicSuccessHandler(
    tracks,
    word,
    start,
    end,
    backgroundLoad,
    wordid = null,
    prevGen = false,
    temp = false,
  ) {
    if (!isMounted.current) return;
    return new Promise((resolve) => {
      if (tracks.length || prevGen) {
        // first page
        // sfxMusicOffset <= 0 ? sfxMusicOnFirstPage() : document.getElementById('sfx-music-arrow-left').style.opacity = '1';
        // // last page
        // tracks.length < 5 ? sfxMusicOnLastPage() : document.getElementById('sfx-music-arrow-right').style.opacity = '1';

        if (!backgroundLoad) {
          Promise.all(
            tracks.map((currTrack) =>
              Koolioai.getSignedPreview(
                currTrack.filename,
                currTrack.sfxlib_bucket,
              ).then((url) => {
                if (!isMounted.current) return;
                const a = document.createElement("a");
                const name = document.createTextNode(
                  currTrack.description.toLowerCase(),
                );
                a.appendChild(name);
                a.href = "#";
                a.id = url;
                a.title = "music playing";
                a.setAttribute("data-bs-custom-class", "custom-tooltip");
                new Tooltip(a, {
                  trigger: "hover",
                  boundary: "scrollParent",
                  animation: true,
                  delay: { show: 500, hide: 100 },
                });
                (function (track) {
                  a.onclick = () => {
                    addGenAiSfxMusic(
                      track.filename,
                      track.sfxlib_bucket,
                      track.name,
                    );
                  };
                })(currTrack);
                // a.setAttribute('onclick', `addMusic(event, ${start}, ${end}, "${currTrack.id}", "${currTrack.name}", "${currTrack.filename.replace('"', '&quot;').replace('"', '&quot;')}", "${wordid}", "${currTrack.description.toLowerCase()}", "${a.title}", ${currTrack.sfxlib_bucket? true : currTrack.sfxlib_bucket}, "${url}")`);
                return Promise.resolve(a);
              }),
            ),
          )
            .then((data) => {
              // if (foundMusic && !prevGen) document.querySelectorAll('.modal-content a').forEach(el => el.remove());
              // const musicInvisibleSearchBar = document.getElementById('musicInvisibleSearchBar');
              // const genMusicSearchBar = document.getElementById('genMusicSearchBar');
              // const currentWord = (musicInvisibleSearchBar && musicInvisibleSearchBar.value.trim().toLowerCase()) || (genMusicSearchBar && genMusicSearchBar.value.trim().toLowerCase()) || "";
              if (!isMounted.current) return;
              const currentWord =
                document
                  .getElementById("musicInvisibleSearchBar")
                  .value.trim()
                  .toLowerCase() ||
                document
                  .getElementById("genMusicSearchBar")
                  .value.trim()
                  .toLowerCase();
              data = data.filter(Boolean);
              if (
                word === currentWord ||
                word === currentWord.replace(/[^a-zA-Z0-9 ]/g, "") ||
                word === currentWord + "musicgen" ||
                word === currentWord + "riffusion" ||
                prevGen ||
                temp
              ) {
                if (data.length || prevGen) {
                  document
                    .querySelectorAll(".sfx-music-arrows")
                    .forEach((el) => (el.style.visibility = "visible"));
                  const frag = document.createDocumentFragment();
                  for (
                    let i = 0;
                    data.length === 8 ? i < data.length - 1 : i < data.length;
                    ++i
                  ) {
                    frag.appendChild(data[i]);
                  }
                  if (prevGen) {
                    document.getElementById("prev_gen_musics").innerHTML = "";
                    document
                      .getElementById("prev_gen_musics")
                      .appendChild(frag);
                    handlePrevGenMusics(tracks);
                  } else {
                    document
                      .getElementById("modal_gen_music_hidden")
                      .appendChild(frag);
                    showGenMusic();
                  }
                  bindPlayMusic();
                }
              } else {
                const e = new Event("keydown");
                e.keyCode = 13;
                // searchSfx(e, currentWord, start, end, backgroundLoad, wordid);
              }
              // enableCreateMusicButton();
              // hidesfxsearchloader();
              if (!isMounted.current) return;
              destroyGenLoader("music");
            })
            .catch((error) => {
              // console.error("Error during fetching signed preview:", error);
              // if (isMounted.current) destroyGenLoader("sfx");
            });
        }
      }
      //  else {
      //     const currentWordTyp = document.getElementById('sfxSearchBar').value;
      //     if (word !== currentWordTyp) {
      //         disableCreateMusicButton();
      //         showsfxsearchloader();
      //         document.querySelectorAll('.modal-content a').forEach(el => el.remove());
      //         const a = document.createElement('a');
      //         a.setAttribute('onclick', 'return false;');
      //         document.getElementById('modal_1').appendChild(a);
      //     } else {
      //         if (!backgroundLoad) {
      //             document.querySelectorAll('.sfx-music-arrows').forEach(el => el.style.visibility = 'hidden');
      //             document.querySelectorAll('.modal-content a').forEach(el => el.remove());
      //             const a = document.createElement('a');
      //             const name = document.createTextNode('no results, please create or select from below music genres');
      //             a.appendChild(name);
      //             a.id = 'music-noresults';
      //             a.classList.add("noresults-font-style");
      //             a.title = 'music-noresults';
      //             a.setAttribute('onclick', 'return false;');
      //             if (foundMusic) {
      //                 document.getElementById('modal_1_no_results').appendChild(a);
      //                 document.getElementById('modal_1_no_results').style.display = 'block';
      //                 enableMusicGenres();
      //                 hidesfxsearchloader();
      //                 enableCreateMusicButton();
      //             }
      //         }
      //     }
      // }
      resolve();
    });
  }

  const genMusicOptions = async () => {
    document.getElementById("sfx-gen-icon").style.opacity = "0.5";
    document.getElementById("music-gen-icon").style.opacity = "1";
    document.getElementById("gen-img-icon").style.opacity = "0.5";
    document.getElementById("show-notes-icon").style.opacity = "0.5";
    setSelectedOption("genMusic");
    initiateGenLoader("music"); // need to uncomment this

    try {
      let response = await getAllPrevGenMusics();
      if (isMounted.current) {
        // Only proceed if the component is mounted
        await searchMusicSuccessHandler(
          response,
          null,
          0,
          0,
          false,
          null,
          true,
        );
      }
    } catch (error) {
      // console.error(error);
    } finally {
      if (isMounted.current) {
        destroyGenLoader("music");
      }
    }
  };

  const showNotesOptions = () => {
    setElementId("showNotes");
    document.getElementById("sfx-gen-icon").style.opacity = "0.5";
    document.getElementById("music-gen-icon").style.opacity = "0.5";
    document.getElementById("gen-img-icon").style.opacity = "0.5";
    document.getElementById("show-notes-icon").style.opacity = "1";
    setSelectedOption("showNotes");
    checkAINotes().then(() => {
      topbar.hide();
      document.getElementById("show-notes-project-button").disabled = false;
      document.getElementById("show-notes-spinner").style.display = "none";
    });
  };

  let genImageArray = {};

  const genImage = async () => {
    document.getElementById("sfx-gen-icon").style.opacity = "0.5";
    document.getElementById("music-gen-icon").style.opacity = "0.5";
    document.getElementById("gen-img-icon").style.opacity = "1";
    document.getElementById("show-notes-icon").style.opacity = "0.5";
    setSelectedOption("genImage");
    initiateGenLoader("image");
    try {
      let imageTracks = await getAllPrevGenImage();
      // console.log(imageTracks);

      if (isMounted.current) {
        // Only proceed if the component is mounted
        await searchImageSuccessHandler(imageTracks, true).catch((e) => {
          // console.error(e)
        });
      }
    } catch (error) {
    } finally {
      // if (isMounted.current) {
      //   console.log("loader ended");
      //   destroyGenLoader("image");
      // }
    }

    // destroyGenLoader('image')
  };

  let prevname = "";
  async function searchImageSuccessHandler(tracks, prevGen = false) {
    if (!isMounted.current) return;
    return Promise.all(
      tracks.map((currTrack) =>
        Koolioai.getSignedPreview(currTrack.filename, false).then(
          async (url, cntr) => {
            if (!isMounted.current) return;
            if (!prevGen) {
              const containerDiv = await createThumbnail(currTrack.name, url);
              return Promise.resolve(containerDiv);
            } else {
              if (!isMounted.current) return;
              const name = currTrack.description.toLowerCase().split("_")[0];
              const apndName = document.createTextNode(name);
              genImageArray[name] = genImageArray[name] || [];
              genImageArray[name].push(url);
              if (prevname != name) {
                const a = document.createElement("a");
                if (!isMounted.current) return;
                a.appendChild(apndName);
                a.style = "cursor: pointer;";
                a.id = url;
                a.title = "image";
                a.setAttribute("data-bs-custom-class", "custom-tooltip");
                new Tooltip(a, {
                  trigger: "hover",
                  boundary: "scrollParent",
                  animation: true,
                  delay: { show: 500, hide: 100 },
                });
                a.addEventListener("click", (event) => {
                  // console.log("callign addThunbnail");
                  addThumbnail(event, name);
                });
                prevname = name;
                return Promise.resolve(a);
              }
              prevname = name;
            }
          },
        ),
      ),
    ).then((data) => {
      if (data.length || prevGen) {
        const frag = document.createDocumentFragment();
        for (
          let i = 0;
          data.length === 8 ? i < data.length - 1 : i < data.length;
          ++i
        ) {
          if (data[i]) frag.appendChild(data[i]);
        }
        if (prevGen) {
          const prevGenImage = document.getElementById("prev_gen_image");
          const links = prevGenImage.getElementsByTagName("a");
          while (links.length) {
            links[0].remove();
          }
          document.getElementById("prev_gen_image").appendChild(frag);
          handlePrevGenImage(tracks);
        } else {
          document.getElementById("modal_gen_image_hidden").appendChild(frag);
          showGenImage();
        }
        if (!isMounted.current) return;
        destroyGenLoader("image");
      }
      // else{
      //     const a = document.createElement('a')
      //     const name = document.createTextNode('no results, please create or select from below music genres')
      //     a.appendChild(name)
      //     a.id = 'image-noresults'
      //     a.classList.add("noresults-font-style")
      //     a.title = 'image-noresults'
      //     a.setAttribute('onclick', 'return false;')
      //     document.getElementById('modal_1_no_results').appendChild(a)
      //     document.getElementById('modal_1_no_results').style.display = 'block'
      // }
    });
  }

  function handlePrevGenImage(ImageTracks) {
    if (!ImageTracks.length) {
      document.getElementById("prevGenImageContainer").style.display = "none";
      document.getElementById("genAiImageContent").style.justifyContent =
        "center";
    } else {
      document.getElementById("prevGenImageContainer").style.display = "";
      document.getElementById("genAiImageContent").style.justifyContent = "";
      const prevGenBox = document.getElementById("prev_gen_image");
      if (prevGenBox.scrollHeight > prevGenBox.offsetHeight) {
        document.getElementById("gen-image-arrow-up").style.visibility =
          "visible";
        document.getElementById("gen-image-arrow-down").style.visibility =
          "visible";
      } else {
        document.getElementById("gen-image-arrow-up").style.visibility =
          "hidden";
        document.getElementById("gen-image-arrow-down").style.visibility =
          "hidden";
      }
    }
    // destroyGenLoader("image");
  }
  let desiredImageCount = 6;
  function showGenImage() {
    const imageLinkArray = [];
    const modalGenImageHidden = document.getElementById(
      "modal_gen_image_hidden",
    );
    if (!modalGenImageHidden.children.length) {
      return initializeSfxMusicShow("image");
    }

    if (modalGenImageHidden.children.length) {
      for (let i = 0; i < modalGenImageHidden.children.length; i++) {
        imageLinkArray.push(modalGenImageHidden.children[i]);
      }

      const modalGenImage = document.getElementById("modal_gen_image");
      const aElements = modalGenImage.getElementsByTagName("a");
      while (aElements.length) {
        aElements[0].remove();
      }

      const frag = document.createDocumentFragment();
      for (let i = 0; i < imageLinkArray.length; i++) {
        frag.appendChild(imageLinkArray[i]);
      }

      modalGenImage.appendChild(frag);

      if (
        modalGenImage.children.length === desiredImageCount &&
        document.getElementById("createImageLoader")
      ) {
        // generatedImageCount = 0;
        setTimeout(() => {
          document.getElementById("createImageLoader").remove();
          document.getElementById("create-gen-image-button").disabled = false;
          document.getElementById("genImageSearchBar").disabled = false;
          // document.getElementById('sfxSearchBar').disabled = false
        }, 500);
      }
    }
  }

  async function addThumbnail(event, name) {
    document.getElementById("modal_gen_image").innerHTML = "";
    // if(fromMoreOptions && playlist.mode != 'edit') {
    //     event.preventDefault()
    //     return showMessage("Operation is diabled", 'Operation is not available in view mode', 2000, 'error', 'gen-options')
    // }
    if (genImageArray.hasOwnProperty(name)) {
      const urlsForName = genImageArray[name].slice(0, 6);

      for (const url of urlsForName) {
        const data = await createThumbnail(name, url);
        const frag = document.createDocumentFragment();
        if (data) {
          frag.appendChild(data);
          document.getElementById("modal_gen_image_hidden").appendChild(frag);
          showGenImage();
        }
      }
    } else {
      // console.log("file does exist");
      // return showMessage("File not exist", 'This file is no longer available', 3000, 'error', 'gen-options')
    }
  }

  async function createThumbnail(name, url) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("image-container");
    const thumbnailImg = document.createElement("img");
    thumbnailImg.src = url; // Replace with the actual path to your image
    thumbnailImg.alt = "Thumbnail";
    thumbnailImg.classList.add("thumbnail");
    if (!isMounted.current) return;
    containerDiv.appendChild(thumbnailImg);
    containerDiv.addEventListener("click", (event) => {
      // console.log("callign addImage");
      addImage(event, name, url);
    });
    // containerDiv.setAttribute('onclick', `addImage(event,"${name}","${url}")`)
    return containerDiv;
  }

  async function genImageGeneration(word) {
    // const permResponse = ACM.checkPermission('gen-ai')
    // if (!permResponse.success || ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"){
    //     if(fromMoreOptions) {
    //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'gen-options')
    //     }
    //     else {
    //         return showMessage(permResponse.message, 'Gen AI is not available in free plan', 2000, 'error', 'workspace')
    //     }
    // }
    if (document.getElementById("modal_gen_image").children.length) {
      let imageTracks = await getAllPrevGenImage(null, false, true);
      console.log(imageTracks);
      await searchImageSuccessHandler(imageTracks, true).catch((e) => {
        // console.error(e)
      });
      document.getElementById("modal_gen_image").innerHTML = "";
      handlePrevGenImage([1]);
    } else {
      // Get the modal element and its 'a' elements
      var modalGenImage = document.getElementById("modal_gen_image");
      var modalGenImageLinks = modalGenImage.getElementsByTagName("a");

      // Remove each 'a' element
      while (modalGenImageLinks.length > 0) {
        modalGenImage.removeChild(modalGenImageLinks[0]);
      }
    }
    if (!word.length) {
      return (document.getElementById("imageSearchCommand").style.display =
        "block");
    }
    // document.getElementById("create-gen-music-button").disabled = true
    // document.getElementById("genMusicSearchBar").disabled = true
    // document.getElementById('sfxSearchBar').disabled = true
    word = word.trim();
    word = word.replace("_", " ");
    word = word.replace("-", " ");
    word = word.replace(/[^a-zA-Z0-9 ]/g, "");
    word = word.toLowerCase();
    createImage(word);
  }

  async function createImage(word) {
    const permResponse = ACM.checkPermission("gen-ai");
    if (
      !permResponse.success ||
      ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"
    ) {
      return showMessage(
        permResponse.message,
        "Gen AI is not available in free plan",
        2000,
        "error",
        "workspace",
      );
    }
    showStatText("Creating image...", false);
    const state = store.getState();
    dispatch(genAiActions.clearWebsocketData());

    dispatch(genAiActions.setLengthOfSfxMusic({ numberOfgenAi: 6 }));
    let percentage = 0;
    const div = document.createElement("div");
    div.setAttribute("id", "createImageLoader");
    div.classList.add("mb-5");
    document.getElementById("create-image-progress-box").appendChild(div);
    document.getElementById("create-gen-image-button").disabled = true;
    document.getElementById("genImageSearchBar").disabled = true;
    const increasePercentage = (percentage) => {
      if (document.getElementById("createImageLoader")) {
        div.innerHTML = `<div class="position-relative mx-auto " id ="genAi-image" style="width: 50%; height:10vh; z-index: 0 ;">
            <img src="/image/loadingspinner.gif" style="width: 12%;position: relative;
    left: 39%;"id="genAi-image-spinner" alt="koolio.ai" /><br>
            <div style="font-weight:500;color:#c4c4c4;font-size: 1vw; background-color: transparent !important;margin-top:1.5vh;" class="position-absolute h-100 w-100">
            <p style="font-weight:500;color:#c4c4c4;font-size: 1vw;"id="genAi-image-loaderText">Please wait! This process may take sometime.</p>
            </div></div>`;
        setTimeout(() => {
          if (percentage < 90) {
            percentage = percentage + 10;
            increasePercentage(percentage);
          }
        }, 5500);
      }
    };
    let resultStat = state.projectData.resultStat;
    const jobname = resultStat.jobname;
    const username = Koolioai.username;

    increasePercentage(percentage);
    const paramsKandinsky = {
      prompt: word,
      username,
      jobname,
      model: "kandinsky",
    };
    const paramsStablediffusion = {
      prompt: word,
      username,
      jobname,
      model: "stablediffusion",
    };

    let createImages;

    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) => {
        createImages = [
          axios.post(_config.api + "/gen-ai", paramsKandinsky, {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          }),
          axios.post(_config.api + "/gen-ai", paramsStablediffusion, {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          }),
        ];
        return Promise.all(createImages)
          .then((response) => {
            showStatText("Creating Images...", false);
            return Promise.resolve(response);
          })
          .catch((e) => {
            // console.log("error in image");
            showStatText(
              "Sorry! could not generate the music. please try again later",
              false,
            );
            // return Promise.reject(e)
          });
      },
    );
  }

  const [showGenImageModal, setShowGenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  function closeGenAiImage() {
    setSelectedImage({});
    setShowGenImageModal(false);
  }
  async function addImage(event, docName, id) {
    // if(fromMoreOptions && playlist.mode != 'edit') {
    //     event.preventDefault()
    //     return showMessage("Operation is diabled", 'Operation is not available in view mode', 2000, 'error', 'gen-options')
    // }
    // $('#half-modals').html(imagemodal)
    // koolioModals()
    // $('#half-modals').css('visibility', 'visible')
    setSelectedImage({
      docName,
      id,
    });
    setShowGenImageModal(true);

    // document.getElementById("genimgtitle").childNodes[0].nodeValue = docName
    // document.getElementById("modalgenimage").innerHTML = `
    // <div class="image-container">
    //     <div class="image-wrapper">
    //         <img src="${id}" class="hoverable-image" alt="Image">
    //     </div>
    // </div>
    // `
  }

  const closeGenAiContent = () => {
    document.querySelector("body").style.overflow = "hidden";
    props.onClose();
  };

  const [shownotesModal, setShownotesModal] = useState(false);

  async function showNotesConfirmationModal() {
    const permResponse = ACM.checkPermission("generate-notes");
    if (
      !permResponse.success ||
      ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"
    )
      return showMessage(
        permResponse.message,
        "Show Notes is not available in free plan",
        2000,
        "error",
        "gen-options",
      );
    let transcribed = store.getState().projectData.resultStat.transcribed;
    if (!transcribed) {
      return showMessage(
        "Transcription unavailable",
        "Transcription is not available for this project",
        2000,
        "error",
        "gen-options",
      );
    }
    // const isTranscriptionInProgress = checkIfTranscriptionInProgressFromPlaylist()
    // if(isTranscriptionInProgress) return showMessage('Please wait', 'Transcription is in progress', 2000, 'error', 'gen-options')
    if (ACM.checkAllowedShowNotesCount() !== -1) {
      let currentCount = await getCurrentFeatureWiseCount(
        "allowed_number_of_show_notes",
      );
      let notesGenerated;
      if (sessionStorage.getItem("aiShowNotes")) {
        notesGenerated = JSON.parse(
          sessionStorage.getItem("aiShowNotes"),
        ).generated;
      } else {
        notesGenerated = false;
      }

      if (currentCount && !notesGenerated) {
        let featureWiseCountList = JSON.parse(
          localStorage.getItem("featureWiseCount"),
        );
        if (featureWiseCountList) {
          for (let i = 0; i < featureWiseCountList.length; i++) {
            if (
              featureWiseCountList[i].feature === "allowed_number_of_show_notes"
            ) {
              featureWiseCountList[i].count = 0;
              currentCount = 0;
            }
          }
          localStorage.setItem(
            "featureWiseCount",
            JSON.stringify(featureWiseCountList),
          );
        }
      }
      const allowed = ACM.checkAllowedShowNotesCount();
      if (allowed <= currentCount)
        return showMessage(
          "Limit reached",
          `Generation can be used once per project`,
          2000,
          "error",
          "gen-options",
        );
    }
    // const dataSynced = await syncOperationsInBackend()
    // if(!dataSynced){
    //     showMessage('Operations synchronizing...', 'Operations synchronization in progress. Please, try after some time', 2000, 'error', 'gen-options')
    // }
    // else{
    setShownotesModal(true);
    // }
  }
  function handleShowNotes() {
    showNotesConfirmationModal();
    // setShownotesModal(true);
  }

  function handleCloseShowNotesModal() {
    setShownotesModal(false);
  }
  function handleCloseShowNotesErrorModal() {
    setShowNotesErrorModal(false);
  }

  function handleShowNotesGenerate() {
    setShownotesModal(false);

    // $('#show-notes-project-button').addClass('btn-active')
    document
      .getElementById("show-notes-project-button")
      .classList.add("btn-active");
    document.getElementById("show-notes-project-button").disabled = true;

    document.getElementById("aiTitles").innerHTML = "";
    document.getElementById("aiTitlesLabel").style.display = "none";
    document.getElementById("aiKeywords").innerHTML = "";
    document.getElementById("aiKeywordsLabel").style.display = "none";
    document.getElementById("aiHighlights").innerHTML = "";
    document.getElementById("aihighlightsLabel").style.display = "none";
    document.getElementById("aiSocials").innerHTML = "";
    document.getElementById("aiSocialsLabel").style.display = "none";
    document.getElementById("show-notes-spinner").style.display = "block";
    showNotesGenerate(); //temporarily unavailable
    // const data = {
    //     generated_tags: {
    //         keywords: '["computer", "all day", "step out", "birds sing", "water nearby"]',
    //         summary: '["The individual has spent the entire day at the computer, likely working or studying. They express a longing to step outside and experience the natural world, specifically mentioning the desire to hear birds singing and the sound of nearby water. This could be a river, a lake, or even the ocean. The text suggests a yearning for a break from technology and a return to nature, highlighting the importance of balance between work and relaxation."]',
    //         titles: '["Escaping the Digital World: A Yearning for Nature", "All Day at the Computer: A Cry for the Outdoors", "From Tech to Tweets: A Desire to Hear the Birds Sing", "The Call of the Wild: Trading Screens for Streams", "Unplugging from the Matrix: A Longing for Natural Sounds"]',
    //         instagram: '"Spent all day at the computer? Time to step out and hear the birds sing! \\ud83d\\udc26\\ud83c\\udfb6 #NatureCalling #DigitalDetox"',
    //         twitter: '"Ever feel like trading the computer screen for a serene stream? \\ud83c\\udf0a\\ud83c\\udf3f You\'re not alone. #StepOutside #Unplug"',
    //         facebook: '"Who else has been at the computer all day and just wants to step out and hear the birds sing? Let\'s take a moment to appreciate the beauty of nature around us. \\ud83c\\udf33\\ud83d\\udc26"',
    //         youtube: '"Tired of staring at your computer screen all day? Join us as we explore the longing for the soothing sounds of nature. Don\'t forget to like, share, and subscribe for more content like this! #NatureLovers #DigitalDetox"',
    //         spotify: '"Listen to our latest podcast episode where we discuss the importance of stepping away from our screens and reconnecting with nature. \\ud83c\\udfa7\\ud83c\\udf32 #Unplug #NatureSounds"',
    //         social_media_postings: '[{"instagram": "\\"Spent all day at the computer? Time to step out and hear the birds sing! \\\\ud83d\\\\udc26\\\\ud83c\\\\udfb6 #NatureCalling #DigitalDetox\\""}, {"youtube": "\\"Tired of staring at your computer screen all day? Join us as we explore the longing for the soothing sounds of nature. Don\'t forget to like, share, and subscribe for more content like this! #NatureLovers #DigitalDetox\\""}, {"spotify": "\\"Listen to our latest podcast episode where we discuss the importance of stepping away from our screens and reconnecting with nature. \\\\ud83c\\\\udfa7\\\\ud83c\\\\udf32 #Unplug #NatureSounds\\""}, {"facebook": "\\"Who else has been at the computer all day and just wants to step out and hear the birds sing? Let\'s take a moment to appreciate the beauty of nature around us. \\\\ud83c\\\\udf33\\\\ud83d\\\\udc26\\""}, {"twitter": "\\"Ever feel like trading the computer screen for a serene stream? \\\\ud83c\\\\udf0a\\\\ud83c\\\\udf3f You\'re not alone. #StepOutside #Unplug\\""}]'
    //     }
    // };
    // showGeneratedNotes(data.generated_tags)
  }

  function showGeneratedNotes(aiNotes) {
    document.querySelector("body").style.overflow = "scroll";
    for (const tag in aiNotes) {
      if (tag === "titles") {
        document.getElementById("aiTitles").innerText = "";
        const div = document.createElement("div");
        JSON.parse(aiNotes.titles).map((post, i) => {
          const p = document.createElement("p");
          p.setAttribute("style", "display : block; !important");
          p.innerText = i + 1 + ". ";
          const id = `titles-${i}`;
          const span = document.createElement("span");
          span.id = id;
          // span.setAttribute("ondblclick", `editAInotesDblClick (event, "${id}")`)
          span.setAttribute("style", "display : inline; !important");
          span.innerText = post;
          p.appendChild(span);

          let editCopyOption = document.createElement("span");
          editCopyOption.style.display = "none";
          editCopyOption.setAttribute("id", `${id}-editCopy`);
          let editId = `${id}-edit`;
          let copyId = `${id}-copy`;
          editCopyOption.innerHTML = `
                    <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="/image/edit-orange.png" alt="" width="15px">
                    <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/image/copy-orange.png" alt="" width="15px">
                    `;

          p.addEventListener("mouseover", () => {
            editCopyOption.style.display = "inline";
            let editOption = document.getElementById(editId);
            editOption.addEventListener("click", (event) => {
              let a = [];
              a[0] = copyId.split("-")[0];
              a[1] = copyId.split("-")[1];
              let b = a.join("-");
              editAInotesDblClick(event, b);
              // console.log("edit button clicked");
              showMessage(
                "Edit mode enabled.",
                "",
                2000,
                "success",
                "workspace",
              );
            });

            let copyOption = document.getElementById(copyId);
            copyOption.addEventListener("click", () => {
              let a = [];
              a[0] = copyId.split("-")[0];
              a[1] = copyId.split("-")[1];
              let b = a.join("-");
              let c = document.getElementById(b).innerText;
              navigator.clipboard.writeText(c);
              showMessage("Text copied", "", 2000, "success", "workspace");
            });
          });

          p.addEventListener("mouseleave", () => {
            editCopyOption.style.display = "none";
          });

          p.appendChild(editCopyOption);
          div.appendChild(p);
          // adding copyEditOption at each segment
        });

        document.getElementById("aiTitles").appendChild(div);
        document.getElementById("aiTitlesLabel").style.display = "block";
      } else if (tag === "keywords") {
        if (!document.getElementById("aiKeywords")) {
          return;
        }
        document.getElementById("aiKeywords").innerText = "";
        const div = document.createElement("div");
        const span = document.createElement("span");
        span.id = "keywordsBox";
        // span.setAttribute("ondblclick", `editAInotesDblClick (event, "keywordsBox")`)
        let totalKeywords = JSON.parse(aiNotes.keywords);
        totalKeywords.map((post, i) => {
          if (i !== JSON.parse(aiNotes.keywords).length - 1)
            span.innerText += post + ", ";
          else span.innerText += post;
          div.appendChild(span);
        });
        //2885 starts for keywords
        let editCopyOption = document.createElement("span");
        editCopyOption.style.display = "none";
        let id = "keywordsBox";
        editCopyOption.setAttribute("id", `${id}-editCopy`);
        let editId = `${id}-edit`;
        let copyId = `${id}-copy`;
        editCopyOption.innerHTML = `
                <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="/image/edit-orange.png" alt="" width="15px">
                <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/image/copy-orange.png" alt="" width="15px">
                `;

        div.appendChild(editCopyOption);
        div.addEventListener("mouseover", () => {
          editCopyOption.style.display = "inline";
          let editOption = document.getElementById(editId);
          editOption.addEventListener("click", (event) => {
            editAInotesDblClick(event, "keywordsBox");
            // showMessage("Edit mode enabled","",2000,"success","workspace")
          });

          let copyOption = document.getElementById(copyId);
          copyOption.addEventListener("click", () => {
            let c = document.getElementById("keywordsBox").innerText;
            navigator.clipboard.writeText(c);
            // showMessage("Text copied","",2000,"success","workspace")
          });
        });

        div.addEventListener("mouseleave", () => {
          editCopyOption.style.display = "none";
        });

        document.getElementById("aiKeywords").appendChild(div);
        document.getElementById("aiKeywordsLabel").style.display = "block";
      } else if (tag === "summary") {
        document.getElementById("aiHighlights").innerText = "";
        const div = document.createElement("div");
        const aiSummary = JSON.parse(aiNotes.summary);
        if (aiSummary.length === 1) {
          aiSummary.map((post, i) => {
            const p = document.createElement("p");
            p.setAttribute("style", "display : block; !important");
            const id = `highlights-${i}`;
            const span = document.createElement("span");
            span.id = id;
            // span.setAttribute("ondblclick", `editAInotesDblClick (event, "${id}")`)
            span.setAttribute("style", "display : inline; !important");
            span.innerText = post;
            p.appendChild(span);

            //2885 starts for highlights
            let editCopyOption = document.createElement("span");
            editCopyOption.style.display = "none";
            editCopyOption.setAttribute("id", `${id}-editCopy`);
            let editId = `${id}-edit`;
            let copyId = `${id}-copy`;
            editCopyOption.innerHTML = `
                        <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="/image/edit-orange.png" alt="" width="15px">
                        <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/image/copy-orange.png" alt="" width="15px">
                        `;

            p.addEventListener("mouseover", () => {
              editCopyOption.style.display = "inline";
              let editOption = document.getElementById(editId);
              editOption.addEventListener("click", (event) => {
                let a = [];
                a[0] = copyId.split("-")[0];
                a[1] = copyId.split("-")[1];
                let b = a.join("-");
                editAInotesDblClick(event, b);
                // showMessage("Edit mode enabled.","",2000,"success","workspace")
              });

              let copyOption = document.getElementById(copyId);
              copyOption.addEventListener("click", () => {
                let a = [];
                a[0] = copyId.split("-")[0];
                a[1] = copyId.split("-")[1];
                let b = a.join("-");
                let c = document.getElementById(b).innerText;
                navigator.clipboard.writeText(c);
                // showMessage("Text copied","",2000,"success","workspace")
              });
            });

            p.addEventListener("mouseleave", () => {
              editCopyOption.style.display = "none";
            });
            p.appendChild(editCopyOption);
            div.appendChild(p);
          });
        } else {
          aiSummary.map((post, i) => {
            const p = document.createElement("p");
            p.setAttribute("style", "display : block; !important");
            p.innerText = i + 1 + ". ";
            const id = `highlights-${i}`;
            const span = document.createElement("span");
            span.id = id;
            // span.setAttribute("ondblclick", `editAInotesDblClick (event, "${id}")`)
            span.setAttribute("style", "display : inline; !important");
            span.innerText = post;
            p.appendChild(span);
            //2885 starts for highlights
            let editCopyOption = document.createElement("span");
            editCopyOption.style.display = "none";
            editCopyOption.setAttribute("id", `${id}-editCopy`);
            let editId = `${id}-edit`;
            let copyId = `${id}-copy`;
            editCopyOption.innerHTML = `
                        <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="/image/edit-orange.png" alt="" width="15px">
                        <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/image/copy-orange.png" alt="" width="15px">
                        `;

            p.addEventListener("mouseover", () => {
              editCopyOption.style.display = "inline";
              let editOption = document.getElementById(editId);
              editOption.addEventListener("click", (event) => {
                let a = [];
                a[0] = copyId.split("-")[0];
                a[1] = copyId.split("-")[1];
                let b = a.join("-");
                editAInotesDblClick(event, b);
                // showMessage("Edit mode enabled.","",2000,"success","workspace")
              });

              let copyOption = document.getElementById(copyId);
              copyOption.addEventListener("click", () => {
                let a = [];
                a[0] = copyId.split("-")[0];
                a[1] = copyId.split("-")[1];
                let b = a.join("-");
                let c = document.getElementById(b).innerText;
                navigator.clipboard.writeText(c);
                // showMessage("Text copied","",2000,"success","workspace")
              });
            });

            p.addEventListener("mouseleave", () => {
              editCopyOption.style.display = "none";
            });
            p.appendChild(editCopyOption);
            //2885 ends for highlights
            div.appendChild(p);
          });
        }

        document.getElementById("aiHighlights").appendChild(div);
        document.getElementById("aihighlightsLabel").style.display = "block";
      } else if (tag === "social_media_postings") {
        document.getElementById("aiSocials").innerText = "";
        const div = document.createElement("div");
        JSON.parse(aiNotes.social_media_postings).map((post, i) => {
          const p = document.createElement("p");
          if (typeof post === "string") {
            p.setAttribute("style", "display : block; !important");
            p.innerText = i + 1 + ". ";
            const id = `social_media_postings-${i}`;
            const span = document.createElement("span");
            span.id = id;
            // span.setAttribute("ondblclick", `editAInotesDblClick (event, "${id}")`)
            span.setAttribute("style", "display : inline; !important");
            span.innerText = post;
            p.appendChild(span);
            //2885 starts for social_media_postings
            let editCopyOption = document.createElement("span");
            editCopyOption.style.display = "none";
            editCopyOption.setAttribute("id", `${id}-editCopy`);
            let editId = `${id}-edit`;
            let copyId = `${id}-copy`;
            editCopyOption.innerHTML = `
                        <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="/image/edit-orange.png" alt="" width="15px">
                        <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/image/copy-orange.png" alt="" width="15px">
                        `;

            p.addEventListener("mouseover", () => {
              editCopyOption.style.display = "inline";
              let editOption = document.getElementById(editId);
              editOption.addEventListener("click", (event) => {
                let a = [];
                a[0] = copyId.split("-")[0];
                a[1] = copyId.split("-")[1];
                let b = a.join("-");
                editAInotesDblClick(event, b);
                // showMessage("Edit mode enabled.","",2000,"success","workspace")
              });

              let copyOption = document.getElementById(copyId);
              copyOption.addEventListener("click", () => {
                let a = [];
                a[0] = copyId.split("-")[0];
                a[1] = copyId.split("-")[1];
                let b = a.join("-");
                let c = document.getElementById(b).innerText;
                navigator.clipboard.writeText(c);
                // showMessage("Text copied","",2000,"success","workspace")
              });
            });

            p.addEventListener("mouseleave", () => {
              editCopyOption.style.display = "none";
            });
            p.appendChild(editCopyOption);
            //2885 ends for social_media_postings
            div.appendChild(p);
          } else {
            for (const key in post) {
              const p = document.createElement("p");
              p.setAttribute("style", "display : block; !important");
              p.innerText = i + 1 + `. ${key} - `;
              const id = `social_media_postings-${i}`;
              const span = document.createElement("span");
              span.id = id;
              // span.setAttribute("ondblclick", `editAInotesDblClick (event, "${id}")`)
              span.setAttribute("style", "display : inline; !important");
              span.innerText = `${post[key]}`;
              p.appendChild(span);
              //2885 starts for social_media_postings
              let editCopyOption = document.createElement("span");
              editCopyOption.style.display = "none";
              editCopyOption.setAttribute("id", `${id}-editCopy`);
              let editId = `${id}-edit`;
              let copyId = `${id}-copy`;
              editCopyOption.innerHTML = `
                            <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="/image/edit-orange.png" alt="" width="15px">
                            <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/image/copy-orange.png" alt="" width="15px">
                            `;

              p.addEventListener("mouseover", () => {
                editCopyOption.style.display = "inline";
                let editOption = document.getElementById(editId);
                editOption.addEventListener("click", (event) => {
                  let a = [];
                  a[0] = copyId.split("-")[0];
                  a[1] = copyId.split("-")[1];
                  let b = a.join("-");
                  editAInotesDblClick(event, b);
                  // showMessage("Edit mode enabled.","",2000,"success","workspace")
                });

                let copyOption = document.getElementById(copyId);
                copyOption.addEventListener("click", () => {
                  let a = [];
                  a[0] = copyId.split("-")[0];
                  a[1] = copyId.split("-")[1];
                  let b = a.join("-");
                  let c = document.getElementById(b).innerText;
                  navigator.clipboard.writeText(c);
                  showMessage("Text copied", "", 2000, "success", "workspace");
                });
              });

              p.addEventListener("mouseleave", () => {
                editCopyOption.style.display = "none";
              });
              p.appendChild(editCopyOption);
              //2885 ends for social_media_postings
              div.appendChild(p);
            }
          }
        });
        document.getElementById("aiSocials").appendChild(div);
        document.getElementById("aiSocialsLabel").style.display = "block";
      }
      //else if(tag === 'sound_bites'){
      //    document.getElementById("aiSoundbites").innerText=""
      //    const div = document.createElement("div")
      //    JSON.parse(aiNotes.sound_bites).map((post,i)=>{
      //        const p = document.createElement("p")
      //        p.setAttribute("style", "display : block; !important")
      //        p.innerText= i+1 +". "
      //        const id =`sound_bites-${i}`
      //        const span = document.createElement("span")
      //        span.id = id
      //        span.setAttribute("ondblclick", `editAInotesDblClick (event, "${id}")`)
      //        span.setAttribute("style", "display : inline; !important")
      //        span.innerText=post
      //        p.appendChild(span)
      //        //2885 starts for social_media_postings
      //        let editCopyOption=document.createElement('span')
      //        editCopyOption.style.display =  'none'
      //        editCopyOption.setAttribute('id', `${id}-editCopy` )
      //        let editId = `${id}-edit`
      //        let copyId = `${id}-copy`
      //        editCopyOption.innerHTML = `
      //        <img title="edit" class="img-fluid"   id=${editId} style="cursor: pointer; margin:  0 10px" src="static/img/edit-orange.png" alt="" width="15px">
      //        <img title="copy" class="img-fluid"  id=${copyId} style="cursor: pointer;" src="/static/img/copy-orange.png" alt="" width="15px">
      //        `

      //        p.addEventListener('mouseover',()=>{
      //            editCopyOption.style.display = 'inline'
      //            let editOption = document.getElementById(editId)
      //            editOption.addEventListener('click',(event)=>{
      //                let a = []
      //                a[0] = copyId.split('-')[0]
      //                a[1] = copyId.split('-')[1]
      //                let b = a.join('-')
      //                editAInotesDblClick (event, b)
      //                showMessage("Edit mode enabled.","",2000,"success","workspace")
      //            })

      //            let copyOption = document.getElementById(copyId)
      //            copyOption.addEventListener('click',()=>{
      //                let a = []
      //                a[0] = copyId.split('-')[0]
      //                a[1] = copyId.split('-')[1]
      //                let b = a.join('-')
      //                let c = document.getElementById(b).innerText
      //                navigator.clipboard.writeText(c)
      //                showMessage("Text copied","",2000,"success","workspace")
      //            })
      //        })

      //        p.addEventListener('mouseleave',()=>{
      //            editCopyOption.style.display = 'none'
      //        })
      //        p.appendChild(editCopyOption)
      //        //2885 ends for social_media_postings
      //        div.appendChild(p)
      //    })
      //    document.getElementById("aiSoundbites").appendChild(div)
      //    document.getElementById("aiSoundbitesLabel").style.display ="block"
      //}
    }
  }

  function editAInotesDblClick(event, id) {
    const $element = $(`#${id}`);
    const oldItemText = $element.text();

    // Set contenteditable to true and focus the element
    $element.attr("contenteditable", "true").focus();

    // Move the cursor to the end of the text segment - 2885 issue starts here
    const textNode = $element.get(0).childNodes[0];
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(textNode, oldItemText.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    //moving the cursor at the end of text segment - 2885 issue ends here

    // Handle Enter key press
    $element.keypress(function (e) {
      if (e.which === 13 || e.keyCode === 13) {
        e.target.blur();
        e.preventDefault();
      }
    });
    // element.removeEventListener('blur', handleBlur);
    // element.addEventListener('blur', handleBlur);

    $(`#${id}`)
      .off("blur")
      .on("blur", function () {
        let newItemText = this.textContent;
        if (!newItemText.trim().length) {
          document.getElementById(id).textContent = oldItemText;
          showStatText("Notes cannot be empty");
          return false;
        } else {
          this.textContent = this.textContent.trim();
        }
        this.contentEditable = false;
        const aiShowNotes = JSON.parse(sessionStorage.getItem("aiShowNotes"));
        if (this.id === "keywordsBox") {
          const oldKeywords = JSON.parse(aiShowNotes.keywords);
          const newKeywords = JSON.stringify(this.textContent.split(","));
          aiShowNotes.keywords = newKeywords;
          sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes));
          if (oldItemText !== this.textContent) {
            sendEditedAiNotesToBackend({ keywords: newKeywords });
          }
        } else if (id.split("-")[0] === "social_media_postings") {
          const [key, index] = id.split("-");
          const item = JSON.parse(aiShowNotes[key]);
          const oldItem = item[index];
          if (typeof item[index] === "string") {
            item[index] = this.textContent;
          } else {
            const newSocialMediaPost = {};
            const newSocialMediaPostKey = this.parentNode.innerText
              .split(". ")[1]
              .split(" -")[0];
            newSocialMediaPost[newSocialMediaPostKey] = this.textContent;
            item[index] = newSocialMediaPost;
          }
          aiShowNotes[key] = JSON.stringify(item);
          sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes));
          if (oldItemText !== this.textContent) {
            sendEditedAiNotesToBackend({ [key]: JSON.stringify(item) });
          }
        } else {
          const [key, index] = id.split("-");
          const item = JSON.parse(aiShowNotes[key]);
          const oldItem = item[index];
          item[index] = this.textContent;
          aiShowNotes[key] = JSON.stringify(item);
          sessionStorage.setItem("aiShowNotes", JSON.stringify(aiShowNotes));
          if (oldItemText !== this.textContent) {
            sendEditedAiNotesToBackend({ [key]: JSON.stringify(item) });
          }
        }
      });
  }

  // ShowNotes

  async function checkAINotes() {
    const permResponse = ACM.checkPermission("generate-notes");
    if (
      !permResponse.success ||
      ACM.planId === "f3ce00b8-d019-3dfe-8446-c64a843894e2"
    ) {
      return;
    }

    loadAINotesFromLS().catch(() => {
      document.getElementById("show-notes-spinner").style.display = "block";
      getAINotesFromDB()
        .then(() => {
          document.getElementById("show-notes-spinner").style.display = "none";
          // getAllFeatureCountForTrial();
          checkAINotes();
        })
        .catch(() => {
          document.getElementById("show-notes-spinner").style.display = "none";
        });
    });
  }

  async function loadAINotesFromLS() {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem("aiShowNotes") === null)
        reject(new Error("Key does not exist"));
      else {
        const aiShowNotes = JSON.parse(sessionStorage.getItem("aiShowNotes"));
        if (aiShowNotes.generated) {
          showGeneratedNotes(aiShowNotes);
        }
        resolve(aiShowNotes);
      }
    });
  }

  const addGenAiSfxMusic = async (filename, bucket, description) => {
    if (store.getState().viewEditMode.mode == "view") {
      showMessage(
        "Operation restricted!",
        "Cannot perform operation on View mode.",
        2000,
        "error",
        "workspace",
      );
      return;
    }
    const signedUrlCompressed = await Koolioai.getSignedCompressed(
      filename,
      bucket,
    );
    // console.log(signedUrlCompressed);
    let result = { signedUrlCompressed, description };
    pauseSfxMusic();
    store.dispatch(
      customGenaiSliceActions.updateGenAIsfxMusic({ value: result }),
    );
    return props.onMoreFunctionClose();
  };

  function checkFunctionToRemoveLoader(type) {
    if (type == "sfx") {
      const genAiCounterData = store.getState().genAiWebsocketData; // using it for removing the progress bar
      // console.log(genAiCounterData);
      // if we get websocket that loading is completed then we wont show the progress bar, we will be stopping the progress in between and show 100% COMPLETED
      if (
        genAiCounterData.numberOfgenAiProduced ===
          genAiCounterData.numberOfgenAi &&
        document.getElementById("createSFXLoader")
      ) {
        document.getElementById("createSFXLoader").innerHTML =
          `<div class="position-relative mx-auto " id ="genAi-music" style="width: 50%; height:10vh; z-index: 0 ;">
            <img src="/image/loadingspinner.gif" style="width: 12%;position: relative;
    left: 39%;"id="genAi-music-spinner" alt="koolio.ai" /><br>
            <div style="font-weight:500;color:#c4c4c4;font-size: 1vw; background-color: transparent !important;margin-top:1.5vh;" class="position-absolute h-100 w-100">
            <p style="font-weight:500;color:#c4c4c4;font-size: 1vw;position: relative;left:25%"id="genAi-music-loaderText">Completed!</p>
            </div>
            </div>`;
        setTimeout(() => {
          document.querySelectorAll("#createSFXLoader").forEach((element) => {
            element.remove();
          });
        }, 1000);

        return;
      }
    } else if (type === "music") {
      const genAiCounterData = store.getState().genAiWebsocketData;
      if (
        genAiCounterData.numberOfgenAiProduced ==
          genAiCounterData.numberOfgenAi &&
        document.getElementById("createMusicLoader")
      ) {
        document.getElementById("createMusicLoader").innerHTML =
          `<div class="position-relative mx-auto " id ="genAi-music" style="width: 50%; height:10vh; z-index: 0 ;">
            <img src="/image/loadingspinner.gif" style="width: 12%;position: relative;
    left: 39%;"id="genAi-music-spinner" alt="koolio.ai" /><br>
            <div style="font-weight:500;color:#c4c4c4;font-size: 1vw; background-color: transparent !important;margin-top:1.5vh;" class="position-absolute h-100 w-100">
            <p style="font-weight:500;color:#c4c4c4;font-size: 1vw;"id="genAi-music-loaderText">Completed!</p>
            </div>
            </div>`;
        setTimeout(() => {
          document.querySelectorAll("#createMusicLoader").forEach((element) => {
            element.remove();
          });
        }, 1000);

        return;
      }
    } else if (type == "image") {
      const genAiCounterData = store.getState().genAiWebsocketData;
      if (
        genAiCounterData.numberOfgenAiProduced == genAiCounterData.numberOfgenAi
      ) {
        document.getElementById("create-gen-image-button").disabled = false;
        document.getElementById("genImageSearchBar").disabled = false;
        document.querySelectorAll("#createImageLoader").forEach((element) => {
          element.remove();
        });
        return;
      }
    } else {
    }
  }

  return (
    <div>
      <div className="more-options-menu" id="more-options-menu"></div>
      <div
        className="more-options-sidebar bg-dark"
        style={{
          textAlign: "center",
          position: "fixed",
          top: 0,
          zIndex: 100,
          height: "100%",
        }}
      >
        <img
          className="more-opt-logo"
          src="/img/more-options/koolio.png"
          alt=""
        />
        <div className="options">
          <div className="genSFX menu-option" style={{ position: "relative" }}>
            <div
              id="sfx-gen-icon"
              className="tt"
              data-bs-placement="bottom"
              style={{ opacity: 1 }}
            >
              <img
                src="/img/more-options/gen_icon_3.png"
                className="menu-option-logo"
                alt=""
                title="Gen SFX"
                onClick={genSFXOptions}
              />
            </div>
          </div>
          <div
            className="genMusic menu-option"
            style={{ position: "relative" }}
          >
            <div
              id="music-gen-icon"
              className="tt"
              data-bs-placement="bottom"
              style={{ opacity: 0.5 }}
            >
              <img
                src="/img/more-options/music_gen_icon.png"
                className="menu-option-logo"
                alt=""
                title="Gen Music"
                onClick={genMusicOptions}
              />
            </div>
          </div>
          <div className="export menu-option" style={{ position: "relative" }}>
            <div
              id="gen-img-icon"
              className="gen-img-icon tt"
              data-bs-placement="bottom"
              style={{ opacity: 0.5 }}
            >
              <img
                src="/img/more-options/genimg.png"
                className="menu-option-logo"
                alt=""
                title="Gen Image"
                onClick={genImage}
              />
            </div>
          </div>
          <div
            className="analytics menu-option"
            style={{ position: "relative" }}
          >
            <div
              id="show-notes-icon"
              className="analytic-icon tt"
              data-bs-placement="bottom"
              style={{ opacity: 0.5 }}
            >
              <img
                src="/img/more-options/show-notes (1).png"
                id="showNotesLogo"
                className="menu-option-logo"
                alt=""
                title="Notes"
                onClick={showNotesOptions}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="secondhalf">
        {selectedOption === "genSFX" && (
          <div className="w-100" id="genSFX">
            <div id="genai-wavesurfer"></div>
            <div className="row options-header-cont">
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                SFX Generation{" "}
              </span>
              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-gen-ai-options-sfx"
                  src="/img/exit-workspace.svg"
                  onClick={closeGenAiContent}
                  alt=""
                />
              </span>
            </div>
            <div className="options-genai-body">
              <div className="more-options-header-sfx">
                Provide prompts to create and choose your own SFX using AI.
              </div>
              <div id="genAiSFXContent" className="genAiContent col-12">
                <div id="prevGenSFXContainer" className="col-4">
                  <p style={{ color: "rgb(226, 82, 43)" }}>
                    Previously Generated SFX
                  </p>
                  <img
                    className="gen-music-arrows"
                    id="gen-sfx-arrow-up"
                    src="/img/more-options/angle-up-solid.png"
                    style={{ opacity: 0.2 }}
                    alt="up"
                  />
                  <div id="prev_gen_sfx" className="prev_gen_sfx"></div>
                  <img
                    className="gen-music-arrows"
                    id="gen-sfx-arrow-down"
                    src="/img/more-options/angle-down-solid.png"
                    alt="down"
                  />
                </div>
                <div className="col-7" style={{ position: "relative" }}>
                  <div id="genSFXInputContainer">
                    <input
                      id="genSFXSearchBar"
                      onFocus={(e) => {
                        e.target.placeholder = "";
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          e.target.placeholder = "Type your prompt here...";
                        }
                      }}
                      autoComplete="off"
                      style={{
                        textIndent: 0,
                        marginLeft: 0,
                        width: "80%",
                        padding: "0% 2%",
                      }}
                      className="transcriptSearch sfx"
                      type="text"
                      placeholder="Type your prompt here..."
                    />
                    <div id="genSFXInfoText" className="gen-info-icon tt">
                      <i
                        className="fas fa-info-circle"
                        title="Describe the sound, scenario, and evolution. e.g., 'Whispering wind gradually becoming ominous in a spooky forest.'"
                        data-bs-placement="bottom"
                      ></i>
                    </div>
                  </div>
                  <input
                    id="sfxInvisibleSearchBar"
                    autoComplete="off"
                    style={{ textIndent: 0, marginLeft: 0, display: "none" }}
                    className="transcriptSearch sfx"
                    type="text"
                  />
                  <br />
                  <br />
                  <div
                    style={{
                      height: "3vh",
                      position: "absolute",
                      width: "100%",
                    }}
                    className="mt-2"
                  >
                    <div
                      id="sfxSearchCommand"
                      className="noresults-font-style"
                      style={{
                        display: "none",
                        position: "relative",
                        right: "10%",
                        color: "whitesmoke",
                        textAlign: "center",
                      }}
                    >
                      Please input a search text to create your SFX
                    </div>
                    <div
                      id="create-sfx-progress-box"
                      style={{ height: "4vh" }}
                    ></div>
                    <div
                      id="gen-sfx-loader"
                      style={{
                        position: "relative",
                        backgroundColor: "rgba(0,0,0,0)",
                        display: "none",
                        zIndex: 15,
                      }}
                    >
                      <img
                        src="static/img/blueviolet-loadingspinner.gif"
                        style={{
                          position: "absolute",
                          left: "48%",
                          top: "50%",
                          width: "3%",
                        }}
                        className="ajax-loader"
                      />
                    </div>
                    <div id="modal_gen_sfx" className="mt-2"></div>
                    <div
                      id="modal_gen_sfx_hidden"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    id="createGenSFX"
                    style={{ position: "absolute", top: "0%", right: "-5%" }}
                    className=""
                  >
                    <div
                      className="action-project d-flex justify-content-center"
                      id="sfx-gen-button-box"
                    >
                      <button
                        id="create-gen-sfx-button"
                        className="create-music-button gen-music-button"
                        onClick={createSFXcontainer}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="gen-sfx-spinner" style={{ display: "none" }}>
                <div
                  className="spinner-container"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.4vw",
                  }}
                >
                  <img
                    src="/image/koolio-icon-workspace.svg"
                    style={{
                      width: "5.856515373352855vw",
                    }}
                    className="ajax-loader"
                    alt="Loading Spinner"
                  />
                  <p
                    className="text-white"
                    style={{
                      fontSize: "1.171303074670571vw",
                    }}
                  >
                    Please Wait...
                  </p>
                </div>
              </div>
            </div>
            <div className="footer">
              <div
                id="gen_sfx_citation"
                style={{ display: "none", cursor: "pointer" }}
              >
                <div
                  className="gen-ai-text-slide text-right"
                  style={{
                    position: "relative",
                    right: "5%",
                    display: "none",
                  }}
                >
                  * References
                </div>
              </div>
              <span id="stat-text-modal"></span>
            </div>
          </div>
        )}

        {selectedOption === "genMusic" && (
          <div className="w-100" id="genMusic">
            <div className="row options-header-cont">
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Music Generation
              </span>

              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-gen-ai-options-music"
                  src="/img/exit-workspace.svg"
                  onClick={closeGenAiContent}
                  alt=""
                />
              </span>
            </div>

            <div className="options-genai-body">
              <div className="more-options-header-sfx">
                Provide prompts to create and choose your own music with AI.
              </div>

              <div id="genAiMusicContent" className="genAiContent col-12">
                <div id="prevGenMusicContainer" className="col-4">
                  <p style={{ color: "rgb(226, 82, 43)" }}>
                    Previously Generated Music
                  </p>
                  <img
                    className="gen-music-arrows"
                    id="gen-music-arrow-up"
                    src="/img/more-options/angle-up-solid.png"
                    style={{ opacity: 0.2 }}
                    alt="up"
                  />
                  <div id="prev_gen_musics"></div>
                  <img
                    className="gen-sfx-arrows"
                    id="gen-music-arrow-down"
                    src="/img/more-options/angle-down-solid.png"
                    alt="down"
                  />
                </div>
                <div className="col-7" style={{ position: "relative" }}>
                  <div id="genMusicInputContainer">
                    <input
                      id="genMusicSearchBar"
                      onFocus={(e) => {
                        e.target.placeholder = "";
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          e.target.placeholder = "Type your prompt here...";
                        }
                      }}
                      autoComplete="off"
                      style={{
                        textIndent: "0",
                        marginLeft: "0",
                        width: "80%",
                        padding: "0% 2%",
                      }}
                      className="transcriptSearch sfx"
                      type="text"
                      placeholder="Type your prompt here..."
                    />
                    <div id="genMusicInfoText" className="gen-info-icon tt">
                      <i
                        className="fas fa-info-circle"
                        title="Mention the genre, emotion, instruments, and rhythm. e.g., 'Optimistic jazz featuring a light-hearted trumpet and a bouncy rhythm, like Louis Armstrong.'"
                        data-bs-placement="bottom"
                      ></i>
                    </div>
                  </div>

                  <input
                    id="musicInvisibleSearchBar"
                    autoComplete="off"
                    style={{
                      textIndent: "0",
                      marginLeft: "0",
                      display: "none",
                    }}
                    className="transcriptSearch sfx"
                    type="text"
                  />

                  <br />
                  <div
                    style={{
                      height: "3vh",
                      position: "absolute",
                      width: "100%",
                    }}
                    className="mt-2"
                  >
                    <div
                      id="musicSearchCommand"
                      className="noresults-font-style"
                      style={{
                        display: "none",
                        marginTop: "5%",
                        position: "relative",
                        left: "8%",
                      }}
                    >
                      Please input a search text to create your music
                    </div>
                    <div
                      id="create-music-progress-box"
                      style={{ height: "4vh" }}
                    ></div>
                    <div
                      id="gen-music-loader"
                      style={{
                        position: "relative",
                        backgroundColor: "rgba(0,0,0,0)",
                        display: "none",
                        zIndex: 15,
                      }}
                    >
                      <img
                        src="static/img/blueviolet-loadingspinner.gif"
                        style={{
                          position: "absolute",
                          left: "48%",
                          top: "50%",
                          width: "3%",
                        }}
                        className="ajax-loader"
                      />
                    </div>
                    <div id="modal_gen_music" className="mt-5"></div>
                    <div
                      id="modal_gen_music_hidden"
                      style={{ display: "none" }}
                    ></div>
                  </div>

                  <div
                    id="createGenMusic"
                    style={{ position: "absolute", top: "0%", right: "-5%" }}
                    className=""
                  >
                    <div
                      className="action-project d-flex justify-content-center"
                      id="music-gen-button-box"
                    >
                      <button
                        id="create-gen-music-button"
                        className="create-music-button gen-music-button"
                        onClick={createMusicContainer}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="gen-music-spinner"
                style={{
                  display: "none",
                }}
              >
                <div
                  className="spinner-container"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.4vw",
                  }}
                >
                  <img
                    src="/image/koolio-icon-workspace.svg"
                    style={{
                      width: "5.856515373352855vw",
                    }}
                    className="ajax-loader"
                    alt="Loading Spinner"
                  />
                  <p
                    className="text-white"
                    style={{
                      fontSize: "1.171303074670571vw",
                    }}
                  >
                    Please Wait...
                  </p>
                </div>
              </div>
            </div>

            <div className="footer">
              <div
                id="gen_music_citation"
                style={{ display: "block", cursor: "pointer" }}
              >
                <div
                  className="gen-ai-text-slide text-right"
                  style={{ position: "relative", right: "5%" }}
                  onClick={() => {
                    /* add your function here */
                  }}
                >
                  * References
                </div>
              </div>
              <span id="stat-text-modal"></span>
            </div>
          </div>
        )}

        {selectedOption === "showNotes" && (
          <div className="w-100" id="aiAnalytics">
            <div className="row options-header-cont">
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Show Notes
              </span>
              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-more-options-analytics"
                  src="/img/exit-workspace.svg"
                  onClick={closeGenAiContent}
                  alt=""
                />
              </span>
            </div>

            <div
              className="options-genai-body"
              style={{ height: "80vh", overflow: "scroll" }}
            >
              <div className="more-options-header-sfx">
                This will create AI generated titles, keywords, highlights,
                sound bites and social media postings
                <p className="mt-4">based on your project transcript.</p>
              </div>

              <div
                className="action-project line-height-opt"
                id="generateButton"
              >
                <button
                  id="show-notes-project-button"
                  className="action-project-button"
                  onClick={handleShowNotes}
                >
                  Generate
                </button>
              </div>

              <div
                className="more-options-header d-flex align-items-start line-height-opt"
                style={{ marginTop: "18%" }}
              >
                <div
                  id="aiTitlesLabel"
                  className="project-filename"
                  style={{ width: "15%", display: "none" }}
                >
                  Titles:
                </div>
                <div
                  id="aiTitles"
                  className="submenu-opt-left-ai"
                  style={{ width: "65%" }}
                ></div>
              </div>

              <div
                className="more-options-header d-flex align-items-start line-height-opt"
                style={{ marginTop: "8%" }}
              >
                <div
                  id="aiKeywordsLabel"
                  className="project-filename"
                  style={{ width: "15%", display: "none" }}
                >
                  Keywords:
                </div>
                <div
                  id="aiKeywords"
                  className="submenu-opt-left-ai"
                  style={{ width: "65%" }}
                ></div>
              </div>

              <div
                className="more-options-header d-flex align-items-start line-height-opt"
                style={{ marginTop: "8%" }}
              >
                <div
                  id="aihighlightsLabel"
                  className="project-filename"
                  style={{ width: "15%", display: "none" }}
                >
                  Summary:
                </div>
                <div
                  id="aiHighlights"
                  className="submenu-opt-left-ai"
                  style={{ width: "65%" }}
                ></div>
              </div>

              <div
                className="more-options-header line-height-opt d-flex align-items-start line-height-opt-bottom-ai"
                style={{ marginTop: "8%" }}
              >
                <div
                  id="aiSocialsLabel"
                  className="project-filename"
                  style={{ width: "15%", display: "none" }}
                >
                  Social Media Postings:
                </div>
                <div
                  id="aiSocials"
                  className="submenu-opt-left-ai"
                  style={{ width: "65%" }}
                ></div>
              </div>

              <div
                id="show-notes-spinner"
                className="spinner-container"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.4vw",
                }}
              >
                <img
                  src="/image/koolio-icon-workspace.svg"
                  style={{
                    width: "5.856515373352855vw",
                  }}
                  className="ajax-loader"
                  alt="Loading Spinner"
                />
                <p
                  className="text-white"
                  style={{
                    fontSize: "1.171303074670571vw",
                  }}
                >
                  Please Wait...
                </p>
              </div>
            </div>
            {selectedOption == "showNotes" && shownotesModal && (
              <ShowNotesModal
                onClose={handleCloseShowNotesModal}
                onGenerate={handleShowNotesGenerate}
              ></ShowNotesModal>
            )}
            {selectedOption == "showNotes" && showNotesErrorModal && (
              <ShowNotesErrorModal onClose={handleCloseShowNotesErrorModal} />
            )}
          </div>
        )}

        {selectedOption === "genImage" && (
          <div className="w-100" id="genImage">
            <div className="row options-header-cont">
              <span
                className="col-11 more-options-menu-header"
                style={{ padding: 0 }}
              >
                Image Generation
              </span>
              <span className="col-1 close-more-options">
                <img
                  className="img-fluid"
                  title="close"
                  id="close-gen-ai-options-Image"
                  src="/img/exit-workspace.svg"
                  onClick={closeGenAiContent}
                  alt=""
                />
              </span>
            </div>

            <div className="options-genai-body">
              <div className="more-options-header-sfx">
                Provide prompts to create and choose your cover art using AI.
              </div>

              <div id="genAiImageContent" className="genAiContent col-12">
                <div id="prevGenImageContainer" className="col-4">
                  <p style={{ color: "rgb(226, 82, 43)" }}>
                    Previously Generated Image
                  </p>
                  <img
                    className="gen-music-arrows"
                    id="gen-image-arrow-up"
                    src="/img/more-options/angle-up-solid.png"
                    style={{ opacity: 0.2 }}
                    alt="up"
                  />
                  <div id="prev_gen_image"></div>
                  <img
                    className="gen-music-arrows"
                    id="gen-image-arrow-down"
                    src="/img/more-options/angle-down-solid.png"
                    alt="down"
                  />
                </div>
                <div className="col-7" style={{ position: "relative" }}>
                  <div id="genImageInputContainer">
                    <input
                      id="genImageSearchBar"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Type your prompt here...")
                      }
                      autoComplete="off"
                      style={{
                        textIndent: "0",
                        marginLeft: "0",
                        width: "80%",
                        padding: "0% 2%",
                      }}
                      className="transcriptSearch sfx"
                      type="text"
                      placeholder="Type your prompt here..."
                    />
                    <div id="genImageInfoText" className="gen-info-icon tt">
                      <i
                        className="fas fa-info-circle"
                        title="Mention the lighting, tone, scenario. Craft vivid and specific scenes for your images. Experiment with detailed descriptions, including colors, shapes, and emotions."
                        data-bs-placement="bottom"
                      ></i>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "4vh",
                      position: "absolute",
                      width: "100%",
                    }}
                  >
                    <div
                      id="imageSearchCommand"
                      className="noresults-font-style"
                      style={{
                        display: "none",
                        marginTop: "12%",
                        position: "relative",
                        left: "9%",
                      }}
                    >
                      Please input a search text to create your image
                    </div>
                    <div
                      id="create-image-progress-box"
                      style={{ height: "4vh" }}
                    ></div>
                    <div
                      id="gen-music-loader"
                      style={{
                        position: "relative",
                        backgroundColor: "rgba(0,0,0,0)",
                        display: "none",
                        zIndex: "15",
                      }}
                    >
                      <img
                        src="static/img/blueviolet-loadingspinner.gif"
                        style={{
                          position: "absolute",
                          left: "48%",
                          top: "50%",
                          width: "3%",
                        }}
                        class="ajax-loader"
                      />
                    </div>
                    <div id="modal_gen_image" className="mt-2 "></div>
                    <div
                      id="modal_gen_image_hidden"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    id="createGenImage"
                    style={{ position: "absolute", top: "0%", right: "-5%" }}
                    className=""
                  >
                    <div
                      className="action-project d-flex justify-content-center"
                      id="image-gen-button-box"
                    >
                      <button
                        id="create-gen-image-button"
                        className="create-music-button gen-music-button"
                        onClick={() =>
                          genImageGeneration(
                            document.getElementById("genImageSearchBar").value,
                          )
                        }
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="gen-image-spinner"
                style={{
                  display: "none",
                }}
              >
                <div
                  className="spinner-container"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.4vw",
                  }}
                >
                  <img
                    src="/image/koolio-icon-workspace.svg"
                    style={{
                      width: "5.856515373352855vw",
                    }}
                    className="ajax-loader"
                    alt="Loading Spinner"
                  />
                  <p
                    className="text-white"
                    style={{
                      fontSize: "1.171303074670571vw",
                    }}
                  >
                    Please Wait...
                  </p>
                </div>
              </div>
            </div>

            <div className="footer">
              <div
                id="gen_music_citation"
                style={{ display: "block", cursor: "pointer" }}
              >
                <div
                  className="gen-ai-text-slide text-right"
                  style={{ position: "relative", right: "5%" }}
                >
                  * References
                </div>
              </div>
              <span id="stat-text-modal"></span>
            </div>
            {selectedOption === "genImage" && showGenImageModal && (
              <GenAImodals
                closeGenAiImage={closeGenAiImage}
                selectedImage={selectedImage}
              />
            )}
          </div>
        )}
        {showGenaiLengthModal.value && (
          <GenaiLengthModal
            sfxcloseModal={closeGenaiLengthModal}
            env={showGenaiLengthModal.env === "sfx" ? "sfx" : "music"}
            setGenaiLengthFunction={
              showGenaiLengthModal.env === "sfx"
                ? setLength
                : setLengthForGenAIMusic
            }
          />
        )}
      </div>
    </div>
  );
};

export default GenAi;
