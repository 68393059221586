export const screenWidth = 1440;
export const screenHeight = 900;
let isTranscriptDrivenEdit = false;
let waveHeight = parseInt(window.innerHeight * 0.155);

let speakerIconHeight = (31 / screenWidth) * window.innerWidth;

let mutator = window.innerHeight > window.innerWidth ? "height" : "width";

const wp = (x) => {
  return (x / screenWidth) * window.innerWidth;
};

const hp = (y) => {
  return (y / screenHeight) * window.innerHeight;
};

let mutation = mutator === "height" ? hp : wp;

let count = 0;

authRes(); // Call authRes initially

window.addEventListener("resize", function () {
  authRes(); // Call authRes whenever the window is resized
});

export function authRes() {
  // landing animation
  let tag1 = document.querySelector(".tag1");
  if (tag1) {
    tag1.style.width = wp(230) + "px";
  }

  let tag2 = document.querySelector(".tag2");
  if (tag2) {
    tag2.style.width = wp(158) + "px";
  }

  let tag3 = document.querySelector(".tag3");
  if (tag3) {
    tag3.style.width = wp(220) + "px";
  }

  let landingTaglines = document.querySelector(".landing-taglines");
  if (landingTaglines) {
    landingTaglines.style.width = wp(668) + "px";
    landingTaglines.style.height = hp(96) + "px";
  }

  // let koolioLogo = document.querySelector(".koolio-logo");
  // if (koolioLogo) {
  //     let koolioLogoTop = (130 / screenHeight) * window.innerHeight;
  //     koolioLogo.style.top = koolioLogoTop + "px";
  //     koolioLogo.style.height = hp(53) + "px";
  // }

  // let koolioLogoImg = document.querySelector(".koolio-logo > img");
  // if (koolioLogoImg) {
  //     let koolioLogoWidth = (210 / screenWidth) * window.innerWidth;
  //     koolioLogoImg.style.width = koolioLogoWidth + "px";
  // }

  let landingUserSpan = document.querySelector(".landing-user > span");
  if (landingUserSpan) {
    let koolioUserFont = (16 / screenWidth) * window.innerWidth;
    landingUserSpan.style.fontSize = koolioUserFont + "px";
  }

  // Password view icon
  // let viewPasswordIcon = document.getElementById("view-password-cont");
  // if (viewPasswordIcon) {
  //     let viewPasswordIconWidth = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let viewPasswordIconMarginLeft = ((-25 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let viewPasswordImg = viewPasswordIcon.querySelector("img");
  //     if (viewPasswordImg) {
  //         viewPasswordImg.style.width = viewPasswordIconWidth + "px";
  //         viewPasswordImg.style.marginLeft = viewPasswordIconMarginLeft + "px";
  //     }
  // }

  // login signup
  // let inputL = document.querySelector(".text-l");
  // if (inputL) {
  //     let inputFontSizeL = ((15.5 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let inputPaddingBottom = ((6 / screenHeight) * 100 * window.innerHeight) / 100;
  //     inputL.style.fontSize = inputFontSizeL + "px";
  //     inputL.style.paddingBottom = inputPaddingBottom + "px";
  // }

  // let inputM = document.querySelector(".text-m");
  // if (inputM) {
  //     let inputFontSizeM = ((15 / screenWidth) * 100 * window.innerWidth) / 100;
  //     inputM.style.fontSize = inputFontSizeM + "px";
  // }

  // Terms modal
  // let termsCheckbox = document.getElementById("terms-checkbox");
  // if (termsCheckbox) {
  //     let termsCheckboxWidth = ((15.9 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let termsCheckboxMarginTop = ((-4.25 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let termsCheckboxMarginRight = ((4.25 / screenWidth) * 100 * window.innerWidth) / 100;
  //     termsCheckbox.style.width = termsCheckboxWidth + "px";
  //     termsCheckbox.style.marginTop = termsCheckboxMarginTop + "px";
  //     termsCheckbox.style.marginRight = termsCheckboxMarginRight + "px";
  // }

  // Terms modal header
  // let termsHeader = document.querySelector(".terms-header-text");
  // if (termsHeader) {
  //     let termsModalHeader = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let termsHeaderPadding = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  //     termsHeader.style.fontSize = termsModalHeader + "px";
  //     termsHeader.style.padding = termsHeaderPadding + "px";
  // }

  // let termsContent = document.querySelector(".terms-content");
  // if (termsContent) {
  //     let termsContentHeader = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let termsContentPadding = ((22 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let termsContentHeight = ((395 / screenWidth) * 100 * window.innerWidth) / 100;
  //     termsContent.style.height = termsContentHeight + "px";
  //     termsContent.querySelector(".content-header").style.fontSize = termsContentHeader + "px";
  //     termsContent.querySelector(".content-data").style.fontSize = termsContentPadding + "px";
  // }

  // // Terms modal options
  // let termsOptions = document.querySelector(".terms-modal-options");
  // if (termsOptions) {
  //     let termsOptionsPadding = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  //     termsOptions.style.padding = termsOptionsPadding + "px";
  // }

  // Terms decline button
  // let termsDecline = document.querySelector(".terms-decline");
  // if (termsDecline) {
  //     let termsDeclineRight = ((12 / screenWidth) * 100 * window.innerWidth) / 100;
  //     termsDecline.style.marginRight = termsDeclineRight + "px";
  // }

  // Signup options
  // let signupOpt = document.querySelector(".signup-opt");
  // if (signupOpt) {
  //     let forgotCancelButton = ((15 / screenHeight) * 100 * window.innerHeight) / 100;
  //     signupOpt.style.top = forgotCancelButton + "px";
  // }

  // Send OTP timer
  // let sendOtpTimer = document.querySelector(".sendOtpTimer");
  // if (sendOtpTimer) {
  //     let sendOtpTop = ((21.85 / screenHeight) * 100 * window.innerHeight) / 100;
  //     let sendOtpMarginLeft = ((10 / screenWidth) * 100 * window.innerWidth) / 100;
  //     let sendOtpFontSize = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  //     sendOtpTimer.style.top = sendOtpTop + "px";
  //     sendOtpTimer.style.marginLeft = sendOtpMarginLeft + "px";
  //     sendOtpTimer.style.fontSize = sendOtpFontSize + "px";
  // }
  // // Forgot password button padding
  // let forgotPasswordButton = document.querySelector(".forgot-password");
  // if (forgotPasswordButton) {
  //     let forgotButtonPadding = ((9 / screenHeight) * 100 * window.innerHeight) / 100;
  //     forgotPasswordButton.style.padding = forgotButtonPadding + "px";
  // }

  // // Line height for input elements
  // let lineHeightInput = document.querySelector(".line-height-input");
  // if (lineHeightInput) {
  //     let lineHeightInputValue = ((37.5 / screenHeight) * 100 * window.innerHeight) / 100;
  //     lineHeightInput.style.marginTop = lineHeightInputValue + "px";
  // }

  // let lineHeightInputSign = document.querySelector(".line-height-input-sign");
  // if (lineHeightInputSign) {
  //     let lineHeightInputSignValue = ((41.7 / screenHeight) * 100 * window.innerHeight) / 100;
  //     lineHeightInputSign.style.marginTop = lineHeightInputSignValue + "px";
  // }

  // let lineHeightInputAlt = document.querySelector(".line-height-input-alt");
  // if (lineHeightInputAlt) {
  //     let lineHeightInputAltValue = ((21.85 / screenHeight) * 100 * window.innerHeight) / 100;
  //     lineHeightInputAlt.style.top = lineHeightInputAltValue + "px";
  // }

  // let lineHeightInputAltNew = document.querySelector(".line-height-input-alt-new");
  // if (lineHeightInputAltNew) {
  //     let lineHeightInputAltNewValue = ((47.5 / screenHeight) * 100 * window.innerHeight) / 100;
  //     lineHeightInputAltNew.style.top = lineHeightInputAltNewValue + "px";
  // }

  // Line height for alternate input elements
  // let lineHeightInputAlt2 = document.querySelector(".line-height-input-alt2");
  // if (lineHeightInputAlt2) {
  //     let lineHeightInputAlt2Value = ((31.85 / screenHeight) * 100 * window.innerHeight) / 100;
  //     lineHeightInputAlt2.style.top = lineHeightInputAlt2Value + "px";
  // }

  // let lineHeightInput2 = document.querySelector(".line-height-input2");
  // if (lineHeightInput2) {
  //     let lineHeightInput2Value = ((58.5 / screenHeight) * 100 * window.innerHeight) / 100;
  //     lineHeightInput2.style.marginTop = lineHeightInput2Value + "px";
  // }

  // Terms modal options
  // let termsModalOptions = document.querySelector(".terms-modal-options");
  // if (termsModalOptions) {
  //     let termsModalOptionsPadding = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  //     termsModalOptions.style.padding = termsModalOptionsPadding + "px";
  // }

  // Calculate dimensions based on screen size
  // let termsModalHeight = ((600 / screenHeight) * 100 * window.innerHeight) / 100;
  // let termsModalWidth = ((600 / screenWidth) * 100 * window.innerWidth) / 100;
  // let termsContentPadding = ((22 / screenWidth) * 100 * window.innerWidth) / 100;
  // let termsContentHeight = ((395 / screenWidth) * 100 * window.innerWidth) / 100;

  // // Apply styles to DOM elements
  // let termsModal = document.querySelector(".terms-modal");
  // if (termsModal) {
  //     termsModal.style.height = termsModalHeight + "px";
  //     termsModal.style.width = termsModalWidth + "px";
  // }

  // let contentHeader = document.querySelector(".content-header");
  // if (contentHeader) {
  //     let termsContentHeader = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  //     contentHeader.style.fontSize = termsContentHeader + "px";
  // }

  // let contentData = document.querySelector(".content-data");
  // if (contentData) {
  //     let termsContentBody = ((14 / screenWidth) * 100 * window.innerWidth) / 100;
  //     contentData.style.fontSize = termsContentBody + "px";
  // }

  // let termsActionButton = document.querySelector(".terms-action-button");
  // if (termsActionButton) {
  //     termsActionButton.style.fontSize = ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px"; // Assuming the same font size as contentData
  // }

  // let termsContentData = document.querySelector(".terms-content-data");
  // if (termsContentData) {
  //     termsContentData.style.paddingLeft = termsContentPadding + "px";
  //     termsContentData.style.paddingRight = termsContentPadding + "px";
  //     termsContentData.style.paddingTop = termsContentPadding + "px";
  // }
}

function koolioProjectUploadRes(kooliojob) {
  const kooliospaceFontAlt = (14 * window.innerWidth) / screenWidth;
  const progressbarProjectHeight = (20 * window.innerWidth) / screenWidth;
  const progressbarProjectWidth = (300 * window.innerWidth) / screenWidth;
  const progressbarProjectTop = (30 * window.innerWidth) / screenWidth;
  const progressBarOuter = document.getElementById(
    kooliojob.jobname + "_progressBarOuter",
  );
  if (progressBarOuter) {
    progressBarOuter.style.width = progressbarProjectWidth + "px";
    progressBarOuter.style.height = progressbarProjectHeight + "px";
    progressBarOuter.style.marginTop = progressbarProjectTop + "px";
    progressBarOuter.style.fontSize = kooliospaceFontAlt + "px";
  }
}

function koolioProjectRes() {
  // res
  const kooliospaceFont = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  const kooliospaceFontAlt =
    ((14 / screenWidth) * 100 * window.innerWidth) / 100;
  const viewEditFontSize = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  const viewEditSpacing = ((100 / screenWidth) * 100 * window.innerWidth) / 100;
  const addProjectIcon = ((21 / screenWidth) * 100 * window.innerWidth) / 100;
  const addProjectIconSpacing =
    ((20 / screenHeight) * 100 * window.innerHeight) / 100;
  const uploadProjectHeight =
    ((388 / screenHeight) * 100 * window.innerHeight) / 100;
  const recordIconHeight =
    ((155 / screenHeight) * 100 * window.innerHeight) / 100;
  const uploadIconHeight =
    ((260 / screenHeight) * 100 * window.innerHeight) / 100;
  const recordIconWidth = ((70 / screenWidth) * 100 * window.innerWidth) / 100;
  const uploadIconWidth = ((53 / screenWidth) * 100 * window.innerWidth) / 100;
  const koolioProjectHeight =
    ((91 / screenHeight) * 100 * window.innerHeight) / 100;

  // const progressbarProjectHeight = ((((20 / screenWidth) * 100) * window.innerWidth) / 100);
  // const progressbarProjectWidth = ((((300 / screenWidth) * 100) * window.innerWidth) / 100);
  // const progressbarProjectTop = ((((30 / screenWidth) * 100) * window.innerWidth) / 100);
  // const progressBarOuter = document.getElementById(kooliojob.jobname + '_progressBarOuter')
  // if (progressBarOuter) {
  //     progressBarOuter.style.width = progressbarProjectWidth + 'px'
  //     progressBarOuter.style.height = progressbarProjectHeight + 'px'
  //     progressBarOuter.style.marginTop = progressbarProjectTop + 'px'
  //     progressBarOuter.style.fontSize = kooliospaceFontAlt + 'px'
  // }

  const koolioWorkspace = document.querySelector(".koolio-workspace");
  if (koolioWorkspace) {
    koolioWorkspace.style.height = koolioProjectHeight + "px";
  }

  const koolioProject = document.querySelector(".koolio-project");
  if (koolioProject) {
    koolioProject.style.fontSize = kooliospaceFont + "px";
    koolioProject.style.height = "100%";
  }

  const koolioProjectDuration = document.querySelector(
    ".koolio-project-duration",
  );
  if (koolioProjectDuration) {
    koolioProjectDuration.style.fontSize = kooliospaceFontAlt + "px";
  }

  const jobOptions = document.querySelector(".job-options");
  if (jobOptions) {
    jobOptions.style.fontSize = viewEditFontSize + "px";
    jobOptions.style.width = viewEditSpacing + "px";
  }

  // const addBtn = document.querySelector(".add-btn");
  // if (addBtn) {
  //   addBtn.style.width = addProjectIcon + "px";
  //   addBtn.style.marginBottom = addProjectIconSpacing + "px";
  // }

  const uploadProject = document.querySelector(".upload-project");
  if (uploadProject) {
    uploadProject.style.height = uploadProjectHeight + "px";
  }

  const record = document.querySelector(".record");
  if (record) {
    record.style.top = recordIconHeight + "px";
  }

  const upload = document.querySelector(".upload");
  if (upload) {
    upload.style.top = uploadIconHeight + "px";
  }

  const recordImg = document.querySelector(".record > img");
  if (recordImg) {
    recordImg.style.width = recordIconWidth + "px";
  }

  const uploadImg = document.querySelector(".upload > img");
  if (uploadImg) {
    uploadImg.style.width = uploadIconWidth + "px";
  }
}

export function koolioRes() {
  let koolioSpaceTextFont = (20 / screenWidth) * window.innerWidth;
  let kooliospaceFont = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  let kooliospaceFontAlt = ((14 / screenWidth) * 100 * window.innerWidth) / 100;
  let viewEditFontSize = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  let viewEditSpacing = ((100 / screenWidth) * 100 * window.innerWidth) / 100;
  let addProjectIcon = ((21 / screenWidth) * 100 * window.innerWidth) / 100;
  let addProjectIconSpacing =
    ((20 / screenHeight) * 100 * window.innerHeight) / 100;
  let uploadProjectHeight =
    ((388 / screenHeight) * 100 * window.innerHeight) / 100;
  let recordIconHeight =
    ((155 / screenHeight) * 100 * window.innerHeight) / 100;
  let recordingIcon = ((118 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordingDurationFontSize =
    ((26.5 / screenWidth) * 100 * window.innerWidth) / 100;
  // let recordingMessageFontSize = ((((20.5 / screenWidth) * 100) * window.innerWidth) / 100);
  let recordingDurationMargin =
    ((17 / screenWidth) * 100 * window.innerWidth) / 100;
  let uploadIconHeight =
    ((260 / screenHeight) * 100 * window.innerHeight) / 100;
  let recordIconWidth = ((70 / screenWidth) * 100 * window.innerWidth) / 100;
  let uploadIconWidth = ((53 / screenWidth) * 100 * window.innerWidth) / 100;
  let koolioProjectHeight =
    ((91 / screenHeight) * 100 * window.innerHeight) / 100;
  let projectTitleHeight = hp(377.7);
  let projectTitleTop = hp(40);
  const headerContainerHome = document.getElementById("site-header-home");
  const closeWorkspaceIconHome =
    ((20.67 / screenWidth) * 100 * window.innerWidth) / 100;
  const rightHome = document.getElementById("right-home");
  const networkStatusRight =
    ((24 / screenWidth) * 100 * window.innerWidth) / 100;
  const searchIconWidth = ((26 / screenWidth) * 100 * window.innerWidth) / 100;
  const searchIconMargin =
    ((190 / screenWidth) * 100 * window.innerWidth) / 100;
  const searchIconMarginTop =
    ((5 / screenHeight) * 100 * window.innerHeight) / 100;
  const searchBarWidth = ((190 / screenWidth) * 100 * window.innerWidth) / 100;
  const searchBarMargin = ((190 / screenWidth) * 100 * window.innerWidth) / 100;
  const searchBarMarginTop =
    ((5 / screenHeight) * 100 * window.innerHeight) / 100;
  const searchBarBorderRadius =
    ((20 / screenHeight) * 100 * window.innerHeight) / 100;
  const searchBarText = (16 / screenWidth) * window.innerWidth;
  const statTextFontSize = ((13 / screenWidth) * 100 * window.innerWidth) / 100;

  if (document.getElementById("user-profile-home")) {
    document.getElementById("user-profile-home").style.right =
      ((38 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const networkStatusElements = document.querySelectorAll(".networkStatus");
  networkStatusElements.forEach((element) => {
    element.style.right =
      ((24 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  });

  const searchIconElement = document.querySelector(".search_icon");
  if (searchIconElement) {
    searchIconElement.style.width =
      ((26 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    searchIconElement.style.marginRight =
      ((190 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    searchIconElement.style.marginTop =
      ((5 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  }

  const searchBarElement = document.querySelector(".search_in_koolio");
  if (searchBarElement) {
    searchBarElement.style.width =
      ((190 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    searchBarElement.style.borderRadius =
      ((20 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
    searchBarElement.style.marginRight =
      ((190 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    searchBarElement.style.marginTop =
      ((5 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
    searchBarElement.style.fontSize =
      (16 / screenWidth) * window.innerWidth + "px";
  }

  if (rightHome) {
    rightHome.style.right =
      ((25 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  if (document.getElementById("close-workspace-home")) {
    document
      .getElementById("close-workspace-home")
      .setAttribute("width", closeWorkspaceIconHome);
  }

  if (document.getElementById("close-modal-window")) {
    document
      .getElementById("close-modal-window")
      .setAttribute("width", closeWorkspaceIconHome);
  }

  if (headerContainerHome) {
    headerContainerHome.style.top =
      ((25 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  }

  // const koolioSpaceTextElements = document.querySelectorAll(".koolio-space-text");
  // koolioSpaceTextElements.forEach((element) => {
  //     element.style.fontSize = koolioSpaceTextFont + "px";
  // });

  const koolioWorkspaceElements =
    document.querySelectorAll(".koolio-workspace");
  koolioWorkspaceElements.forEach((element) => {
    element.style.height = koolioProjectHeight + "px";
    element.style.fontSize = kooliospaceFont + "px";
  });

  const koolioProjectDurationElements = document.querySelectorAll(
    ".koolio-project-duration",
  );
  koolioProjectDurationElements.forEach((element) => {
    element.style.fontSize = kooliospaceFontAlt + "px";
  });

  const projectTitleElements = document.querySelectorAll(".project-titles");
  projectTitleElements.forEach((element) => {
    element.style.height = projectTitleHeight + "px";
    // element.style.marginTop = projectTitleTop + "px";
  });

  const jobOptionsElements = document.querySelectorAll(".job-options");
  jobOptionsElements.forEach((element) => {
    element.style.fontSize = viewEditFontSize + "px";
    element.style.width = viewEditSpacing + "px";
  });

  const addBtnElements = document.querySelectorAll(".add-btn");
  addBtnElements.forEach((element) => {
    element.style.width = addProjectIcon + "px";
    element.style.marginBottom = addProjectIconSpacing + "px";
  });

  const uploadProjectElements = document.querySelectorAll(".upload-project");
  uploadProjectElements.forEach((element) => {
    element.style.height = uploadProjectHeight + "px";
  });

  const recordElements = document.querySelectorAll(".record");
  recordElements.forEach((element) => {
    element.style.top = recordIconHeight + "px";
  });

  const uploadElements = document.querySelectorAll(".upload");
  uploadElements.forEach((element) => {
    element.style.top = uploadIconHeight + "px";
  });

  const recordIconElements = document.querySelectorAll("#koolio-rec-icon");
  recordIconElements.forEach((element) => {
    element.style.width = recordingIcon + "px";
  });

  const recordDurationElements = document.querySelectorAll("#rec-duration");
  recordDurationElements.forEach((element) => {
    element.style.fontSize = recordingDurationFontSize + "px";
    element.style.marginTop = recordingDurationMargin + "px";
  });

  const uploadIconElements = document.querySelectorAll("#koolio-record-icon");
  uploadIconElements.forEach((element) => {
    element.style.width = recordingIcon + "px";
  });

  const recordDurationElementsAlt =
    document.querySelectorAll("#record-duration");
  recordDurationElementsAlt.forEach((element) => {
    element.style.fontSize = recordingDurationFontSize + "px";
    element.style.marginTop = recordingDurationMargin + "px";
  });

  const statTextElements = document.querySelectorAll(
    "#stat-text, #stat-text-modal",
  );
  statTextElements.forEach((element) => {
    element.style.fontSize = statTextFontSize + "px";
  });

  // Notification styles
  // const notificationIconFontSize = (27 / screenWidth) * window.innerWidth;
  // const notificationIconMargin = (1270 / screenWidth) * window.innerWidth;
  // const notificationMarginTop = (3 / screenHeight) * window.innerWidth;

  // const notificationIcons = document.querySelectorAll(".notification_icon");
  // if (notificationIcons) {
  //     notificationIcons.forEach((icon) => {
  //         // icon.style.fontSize = notificationIconFontSize + "px";
  //         // icon.style.left = notificationIconMargin + "px";
  //         icon.style.marginTop = notificationMarginTop + "px";
  //     });
  // }

  // // const notificationPendingWidth = (20 / screenWidth) * window.innerWidth;
  // // const notificationPendingPaddingTop = (3 / screenWidth) * window.innerWidth;
  // // const notificationPendingTextSize = (12 / screenWidth) * window.innerWidth;
  // // const notificationPendingMargin = (1260 / screenWidth) * window.innerWidth;
  // const notificationPendingMarginTop = (-10 / screenWidth) * window.innerWidth;

  // const pendingNotifications = document.querySelectorAll(".pending_notification");
  // if (pendingNotifications) {
  //     pendingNotifications.forEach((notification) => {
  //         // notification.style.width = notificationPendingWidth + "px";
  //         // notification.style.paddingTop = notificationPendingPaddingTop + "px";
  //         // notification.style.height = notificationPendingWidth + "px";
  //         // notification.style.fontSize = notificationPendingTextSize + "px";
  //         // notification.style.left = notificationPendingMargin + "px";
  //         notification.style.marginTop = notificationPendingMarginTop + "px";
  //     });
  // }

  // // const notificationListWidth = (240 / screenWidth) * window.innerWidth;
  // // const notificationListHeight = (300 / screenWidth) * window.innerWidth;
  // // const notificationListMarginRight = (5 / screenWidth) * window.innerWidth;
  // const notificationListMarginTop = (2 / screenWidth) * window.innerWidth;

  // const notificationLists = document.querySelectorAll(".notification-list");
  // if (notificationLists) {
  //     notificationLists.forEach((list) => {
  //         // list.style.width = notificationListWidth + "px";
  //         // list.style.height = notificationListHeight + "px";
  //         // list.style.right = notificationListMarginRight + "px";
  //         list.style.marginTop = notificationListMarginTop + "px";
  //     });
  // }

  // // const notificationWidth = (260 / screenWidth) * window.innerWidth;
  // // const notificationHeight = (320 / screenWidth) * window.innerWidth;
  // // const notificationMarginLeft = (1100 / screenWidth) * window.innerWidth;
  // const notificationTop = (40 / screenWidth) * window.innerWidth;
  // // const notificationBorder = (1 / screenWidth) * window.innerWidth;

  // const notifications = document.querySelectorAll(".notification");
  // if (notifications) {
  //     notifications.forEach((notification) => {
  //         // notification.style.width = notificationWidth + "px";
  //         // notification.style.height = notificationHeight + "px";
  //         // notification.style.left = notificationMarginLeft + "px";
  //         notification.style.top = notificationTop + "px";
  //         // notification.style.border = notificationBorder + "px solid blueviolet";
  //     });
  // }

  // // const notificationContentWidth = (220 / screenWidth) * window.innerWidth;
  // // const notificationContentMarginRight = (5 / screenWidth) * window.innerWidth;
  // const notificationContentMarginTop = (3 / screenWidth) * window.innerWidth;
  // // const notificationContentFont = (13 / screenWidth) * window.innerWidth;

  // const notificationContents = document.querySelectorAll(".notification-content");
  // if (notificationContents) {
  //     notificationContents.forEach((content) => {
  //         // content.style.width = notificationContentWidth + "px";
  //         // content.style.right = notificationContentMarginRight + "px";
  //         content.style.marginTop = notificationContentMarginTop + "px";
  //         // content.style.fontSize = notificationContentFont + "px";
  //     });
  // }

  //koolioProjectUploadProgress
  let progressbarProjectHeight = (20 / screenWidth) * window.innerWidth;
  let progressbarProjectWidth = (300 / screenWidth) * window.innerWidth;
  let progressbarProjectTop = (30 / screenWidth) * window.innerWidth;

  // try {
  //     if (uploadProgressStatus && uploadProgressStatus.uploadedProjectList) {
  //         uploadProgressStatus.uploadedProjectList.forEach((project) => {
  //             const progressBarOuter = document.getElementById(project.id + "_progressBarOuter");
  //             if (progressBarOuter) {
  //                 progressBarOuter.style.width = progressbarProjectWidth + "px";
  //                 progressBarOuter.style.height = progressbarProjectHeight + "px";
  //                 progressBarOuter.style.marginTop = progressbarProjectTop + "px";
  //                 progressBarOuter.style.fontSize = kooliospaceFontAlt + "px";
  //             }
  //         });
  //     }
  // } catch (e) {
  //     //uploadProgressStatus not Defined yet
  // }

  // overlay modals
  const overlayModalsPadding = (33 / screenWidth) * window.innerWidth + "px";
  const overlayModals = document.getElementById("overlay-modals");
  if (overlayModals) {
    overlayModals.style.padding = overlayModalsPadding;
  }

  const closeOverlayModalWidth = (15 / screenWidth) * window.innerWidth + "px";
  const closeOverlayModalRight = (35 / screenWidth) * window.innerWidth + "px";
  const closeOverlayModalTop = (28 / screenWidth) * window.innerWidth + "px";
  const closeOverlayModal = document.getElementById("close-overlay-modal");
  if (closeOverlayModal) {
    closeOverlayModal.style.width = closeOverlayModalWidth;
    closeOverlayModal.style.right = closeOverlayModalRight;
    closeOverlayModal.style.top = closeOverlayModalTop;
  }

  const overlayModalHeaderText = (24 / screenWidth) * window.innerWidth + "px";
  const overlayModalHeaderTop = (32 / screenWidth) * window.innerWidth + "px";
  const overlayModalHeader = document.querySelector(".overlay-modal-header");
  if (overlayModalHeader) {
    overlayModalHeader.style.fontSize = overlayModalHeaderText;
    overlayModalHeader.style.marginTop = overlayModalHeaderTop;
  }

  // projectTitle
  const projectTitle = document.getElementById("projectname");
  if (projectTitle) {
    projectTitle.style.fontSize = (24 / screenWidth) * window.innerWidth + "px";
  }

  // more options + logo + dynamic text
  // const koolioHeaderIcons = (192 / screenWidth) * window.innerWidth;
  // const koolioIconHeader = document.getElementById("koolio-icon-header");
  // if (koolioIconHeader) {
  //     koolioIconHeader.setAttribute("width", koolioHeaderIcons);
  // }

  // const moreOptionsIcon = (26 / screenWidth) * window.innerWidth;
  // const moreOptionIcon = document.getElementById("more-option-icon");
  // if (moreOptionIcon) {
  //     moreOptionIcon.setAttribute("width", moreOptionsIcon);
  // }
  // // New recording modal
  // const newRecordModalWidth = (740 / screenWidth) * window.innerWidth + "px";
  // const newRecordModalHeight = (320 / screenHeight) * window.innerHeight + "px";
  // const newRecordModalLeft = (355 / screenWidth) * window.innerWidth + "px";
  // const newRecordModalTop = (140 / screenHeight) * window.innerHeight + "px";
  // const newRecordModalPadding = (80 / screenHeight) * window.innerHeight + "px";
  // const newRecordModal = document.getElementById("new-record-modal");
  // if (newRecordModal) {
  //     newRecordModal.style.width = newRecordModalWidth;
  //     newRecordModal.style.height = newRecordModalHeight;
  //     newRecordModal.style.left = newRecordModalLeft;
  //     newRecordModal.style.top = newRecordModalTop;
  //     newRecordModal.style.padding = newRecordModalPadding;
  // }

  // const newRecordInputWidth = (595 / screenWidth) * window.innerWidth + "px";
  // const newRecordInputFontSize = (18 / screenWidth) * window.innerWidth + "px";
  // const newRecordInputPadding = (8 / screenWidth) * window.innerWidth + "px";
  // const newRecordInputPaddingAlt = (10 / screenWidth) * window.innerWidth + "px";
  // const recordTitleInput = document.getElementById("record-title-input");
  // if (recordTitleInput) {
  //     recordTitleInput.style.width = newRecordInputWidth;
  //     recordTitleInput.style.paddingLeft = newRecordInputPaddingAlt;
  //     recordTitleInput.style.paddingTop = newRecordInputPadding;
  //     recordTitleInput.style.paddingBottom = newRecordInputPadding;
  //     recordTitleInput.style.paddingRight = newRecordInputPaddingAlt;
  //     recordTitleInput.style.fontSize = newRecordInputFontSize;
  // }

  // const recordContentDataFontSize = (15 / screenWidth) * window.innerWidth + "px";
  // const recordContentDataMarginTop = (12 / screenHeight) * window.innerHeight + "px";
  // const recordContentDataMarginLeft = (9 / screenWidth) * window.innerWidth + "px";
  // const recordContentData = document.querySelectorAll(".record-content-data");
  // recordContentData.forEach((element) => {
  //     element.style.fontSize = recordContentDataFontSize;
  //     element.style.marginTop = recordContentDataMarginTop;
  //     element.style.marginLeft = recordContentDataMarginLeft;
  // });

  // const recordOptionMarginTop = (40 / screenHeight) * window.innerHeight + "px";
  // const recordOptionMarginRight = (18 / screenHeight) * window.innerHeight + "px";
  // const newRecordOption = document.querySelectorAll(".new-record-option");
  // newRecordOption.forEach((element) => {
  //     element.style.marginTop = recordOptionMarginTop;
  //     element.style.marginRight = recordOptionMarginRight;
  // });

  // const titleListFontSize = (14 / screenWidth) * window.innerWidth + "px";
  // const searchListItems = document.querySelectorAll(".searchList li");
  // searchListItems.forEach((item) => {
  //     item.style.fontSize = titleListFontSize;
  // });

  // const buzzPodcastIdErrorFontSize = (14 / screenWidth) * window.innerWidth + "px";
  // const buzzError = document.querySelectorAll(".buzzError");
  // buzzError.forEach((error) => {
  //     error.style.fontSize = buzzPodcastIdErrorFontSize;
  // });

  // // user-profile + close workspace
  // const userProfileIconWidth = (31 / screenWidth) * window.innerWidth + "px";
  // const statusIconWidth = (30 / screenWidth) * window.innerWidth + "px";
  // const statusIconHeight = (30 / screenHeight) * window.innerHeight + "px";
  // const togglerIconWidth = (38 / screenWidth) * window.innerWidth + "px";

  // const userProfile = document.getElementById("user-profile");
  // if (userProfile) {
  //     userProfile.style.width = userProfileIconWidth;
  // }

  // const userProfileCont = document.getElementById("user-profile-cont");
  // if (userProfileCont) {
  //     userProfileCont.style.right = (38 / screenWidth) * window.innerWidth + "px";
  // }

  // const transcriptEditBtn = document.getElementById("transcriptEditBtn");
  // if (transcriptEditBtn) {
  //     transcriptEditBtn.style.left = (50 / screenWidth) * window.innerWidth + "px";
  //     transcriptEditBtn.style.bottom = (2.9 / screenWidth) * window.innerWidth + "px";
  // }

  // const helpBtn = document.getElementById("help-btn");
  // if (helpBtn) {
  //     helpBtn.style.left = (80 / screenWidth) * window.innerWidth + "px";
  //     helpBtn.style.top = (6 / screenWidth) * window.innerWidth + "px";
  // }

  // const transcriptToggler = document.getElementById("transcriptToggler");
  // if (transcriptToggler) {
  //     transcriptToggler.style.width = togglerIconWidth;
  // }

  // const avatarMarginLeft = -1.6 * parseFloat(userProfileIconWidth);
  // const avatar = document.querySelectorAll(".avatar");
  // avatar.forEach((element) => {
  //     element.style.marginLeft = avatarMarginLeft + "px";
  // });

  // const avatarImg = document.querySelectorAll(".avatar img");
  // avatarImg.forEach((img) => {
  //     img.style.width = userProfileIconWidth;
  // });

  // const networkStatusIcons = document.querySelectorAll(".networkStatus");
  // networkStatusIcons.forEach((icon) => {
  //     icon.style.width = statusIconWidth;
  //     icon.style.height = statusIconHeight;
  // });

  // const closeWorkspaceIconWidth = (20.67 / screenWidth) * window.innerWidth + "px";
  // const closeWorkspaceIcon = document.getElementById("close-workspace");
  // if (closeWorkspaceIcon) {
  //     closeWorkspaceIcon.setAttribute("width", closeWorkspaceIconWidth);
  // }

  // // spacings
  // const siteHeader = document.getElementById("site-header");
  // if (siteHeader) {
  //     siteHeader.style.top = (25 / screenHeight) * window.innerHeight + "px";
  // }

  // const leftPanel = document.getElementById("left");
  // if (leftPanel) {
  //     leftPanel.style.left = (25 / screenWidth) * window.innerWidth + "px";
  // }

  // const rightPanel = document.getElementById("right");
  // if (rightPanel) {
  //     rightPanel.style.right = (25 / screenWidth) * window.innerWidth + "px";
  // }

  // // playlistArea
  // const playlistArea = document.getElementById("playlist-area");
  // if (playlistArea) {
  //     playlistArea.style.top = ((110 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recordPlaylistArea = document.getElementById("record-playlist-area");
  // if (recordPlaylistArea) {
  //     recordPlaylistArea.style.top = ((110 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recPlaylistArea = document.getElementById("rec-playlist");
  // if (recPlaylistArea) {
  //     recPlaylistArea.style.marginTop = ((130 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }

  // const speakerSliderIcon = isTranscriptDrivenEdit ? ((26 / screenWidth) * 100 * window.innerWidth) / 100 : ((31 / screenWidth) * 100 * window.innerWidth) / 100;
  // const speakerSlid = document.querySelectorAll(".spk-slid");
  // speakerSlid.forEach((element) => {
  //     element.style.width = speakerSliderIcon + "px";
  // });

  // const speakerChangeSpacing = ((15 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const speakerIcons = document.querySelectorAll(".speaker-icon-spacing");
  // speakerIcons.forEach((icon) => {
  //     icon.style.marginLeft = speakerChangeSpacing;
  //     icon.style.marginRight = speakerChangeSpacing;
  //     icon.style.cursor = "pointer";
  // });

  // let spkSliderWidth = isTranscriptDrivenEdit ? document.getElementById("track-controls").clientWidth * 0.6 + "px" : "60%";
  // let speakersSegmentHeight = isTranscriptDrivenEdit ? 0 : waveHeight / 2 - speakerIconHeight / 2;
  // const speakersSliders = document.getElementById("speakers-sliders");
  // if (speakersSliders) {
  //     speakersSliders.style.top = speakersSegmentHeight + "px";
  //     speakersSliders.style.width = spkSliderWidth;
  //     speakersSliders.style.margin = isTranscriptDrivenEdit ? "" : "auto";
  //     speakersSliders.style.marginTop = isTranscriptDrivenEdit ? "0.25%" : "";
  // }

  // const addTracksBtnWidth = ((11 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const addTracksBtn = document.getElementById("add-spk-sfx-tracks");
  // if (addTracksBtn) {
  //     addTracksBtn.setAttribute("width", addTracksBtnWidth);
  // }
  // const addTracksBtnTranscriptWidth = ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const addTracksBtnTranscript = document.getElementById("add-spk-sfx-tracks-transcript");
  // if (addTracksBtnTranscript) {
  //     addTracksBtnTranscript.setAttribute("width", addTracksBtnTranscriptWidth);
  // }

  // // globalPlaylist
  // const globalView = document.getElementById("global-view");
  // if (globalView) {
  //     globalView.style.top = ((55 / screenHeight) * 100 * window.innerHeight) / 100 + ((100 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recordGlobalView = document.getElementById("record-global-view");
  // if (recordGlobalView) {
  //     recordGlobalView.style.top = ((55 / screenHeight) * 100 * window.innerHeight) / 100 + ((100 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }

  // // controls
  // const controlModes = document.getElementById("controls");
  // if (controlModes) {
  //     controlModes.style.top = ((245 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recControlModes = document.getElementById("record-controls");
  // if (recControlModes) {
  //     recControlModes.style.top = ((245 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recordControlModes = document.getElementById("rec-controls");
  // if (recordControlModes) {
  //     recordControlModes.style.marginTop = ((150 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const subMenuSection = document.getElementById("sub-menus-section");
  // if (subMenuSection) {
  //     subMenuSection.style.top = ((245 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const controlIcons = document.getElementById("top-bar");
  // if (controlIcons) {
  //     controlIcons.style.top = ((50 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const historyPageControl = document.getElementById("historyPageControl");
  // if (historyPageControl) {
  //     historyPageControl.style.marginTop = ((60 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recordControlIcons = document.getElementById("record-top-bar");
  // if (recordControlIcons) {
  //     recordControlIcons.style.top = ((60 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }
  // const recControlIcons = document.getElementById("rec-top-bar");
  // if (recControlIcons) {
  //     recControlIcons.style.top = ((60 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }

  // // transcript
  // const btnTranscriptReplaceDiv = document.getElementById("btn-transcript-replace-div");
  // if (btnTranscriptReplaceDiv) {
  //     btnTranscriptReplaceDiv.addEventListener("mouseenter", () => {
  //         if (btnTranscriptReplaceDiv.getAttribute("class") == "disable") btnTranscriptReplaceDiv.setAttribute("src", "static/img/workspace/active-icons/replace.png");
  //     });
  //     btnTranscriptReplaceDiv.addEventListener("mouseleave", () => {
  //         if (btnTranscriptReplaceDiv.getAttribute("class") == "disable") btnTranscriptReplaceDiv.setAttribute("src", "static/img/workspace/controls/replace.png");
  //     });
  // }

  // if (document.getElementById("transcript-search-prev")) {
  //     document.getElementById("transcript-search-prev").addEventListener("mouseenter", () => {
  //         document.getElementById("transcript-search-prev").setAttribute("src", "static/img/transcript/active/transcriptUpArrow.png");
  //     });
  //     document.getElementById("transcript-search-prev").addEventListener("mouseleave", () => {
  //         document.getElementById("transcript-search-prev").setAttribute("src", "static/img/transcript/transcriptUpArrow.png");
  //     });
  // }

  // if (document.getElementById("transcript-search-next")) {
  //     document.getElementById("transcript-search-next").addEventListener("mouseenter", () => {
  //         document.getElementById("transcript-search-next").setAttribute("src", "static/img/transcript/active/transcriptDownArrow.png");
  //     });
  //     document.getElementById("transcript-search-next").addEventListener("mouseleave", () => {
  //         document.getElementById("transcript-search-next").setAttribute("src", "static/img/transcript/transcriptDownArrow.png");
  //     });
  // }

  // const multiselectTranscript = document.getElementById("multiselect-transcript");
  // if (multiselectTranscript) {
  //     multiselectTranscript.addEventListener("mouseenter", () => {
  //         if (multiselectTranscript.classList.contains("disable")) multiselectTranscript.setAttribute("src", "/image/workspace/active-icons/annotations.png");
  //     });
  //     multiselectTranscript.addEventListener("mouseleave", () => {
  //         if (multiselectTranscript.classList.contains("disable")) multiselectTranscript.setAttribute("src", "/image/workspace/controls/annotations.png");
  //     });
  // }

  // // transcriptHeader
  // const transcriptHeader = ((25 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // const transcriptHeaderElement = document.querySelector(".transcript-header");
  // if (transcriptHeaderElement) {
  //     transcriptHeaderElement.style.top = transcriptHeader;
  // }

  // // transcriptSearchFont
  // const transcriptSearchFont = document.getElementById("input-search-transcript");
  // if (transcriptSearchFont) {
  //     transcriptSearchFont.style.fontSize = ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // // transcriptBorderRadius
  // const transcriptBorderRadius = document.getElementById("input-search-transcript");
  // if (transcriptBorderRadius) {
  //     transcriptBorderRadius.style.borderRadius = ((39 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // // transcriptSearchWidth
  // const transcriptSearchWidth = document.getElementById("input-search-transcript");
  // if (transcriptSearchWidth) {
  //     transcriptSearchWidth.style.width = ((110 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // // transcriptReplaceFont
  // const transcriptReplaceFont = document.getElementById("input-replace-transcript");
  // if (transcriptReplaceFont) {
  //     transcriptReplaceFont.style.fontSize = ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // // transcriptReplaceBorderRadius
  // const transcriptReplaceBorderRadius = document.getElementById("input-replace-transcript");
  // if (transcriptReplaceBorderRadius) {
  //     transcriptReplaceBorderRadius.style.borderRadius = ((39 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // // transcriptReplaceWidth
  // const transcriptReplaceWidth = document.getElementById("input-replace-transcript");
  // if (transcriptReplaceWidth) {
  //     transcriptReplaceWidth.style.width = ((110 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // // transcriptSearchFontIcons
  // const transcriptSearchFontIcons = ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const transcriptSearchIconElements = document.querySelectorAll(".transcriptSearch-icon");
  // if (transcriptSearchIconElements) {
  //     transcriptSearchIconElements.forEach((element) => {
  //         element.style.fontSize = transcriptSearchFontIcons;
  //     });
  // }
  // const transcriptSearchImgElements = document.querySelectorAll(".transcriptSearch-img");
  // if (transcriptSearchImgElements) {
  //     transcriptSearchImgElements.forEach((element) => {
  //         element.style.width = transcriptSearchFontIcons;
  //     });
  // }

  // // transcriptSearchSpacing
  // const transcriptSearchSpacing = ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const transcriptSearchSpacingReplace = isTranscriptDrivenEdit ? ((32 / screenWidth) * 100 * window.innerWidth) / 100 + "px" : ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const searchOptionsElements = document.querySelectorAll(".searchOptions");
  // if (searchOptionsElements) {
  //     searchOptionsElements.forEach((element) => {
  //         element.style.left = transcriptSearchSpacing;
  //     });
  // }
  // const searchOptionsReplaceElements = document.querySelectorAll(".searchOptions-replace");
  // if (searchOptionsReplaceElements) {
  //     searchOptionsReplaceElements.forEach((element) => {
  //         element.style.left = transcriptSearchSpacingReplace;
  //     });
  // }

  // // transcriptSearchOptionSpacing
  // const transcriptSearchOptionSpacing = ((5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const transcriptDrivenSearchOptionSpacing = ((10 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const transcriptSearchOptionSpacingElements = document.querySelectorAll(".transcriptSearchOptionSpacing");
  // if (transcriptSearchOptionSpacingElements) {
  //     transcriptSearchOptionSpacingElements.forEach((element) => {
  //         element.style.marginRight = transcriptSearchOptionSpacing;
  //         element.style.marginLeft = transcriptSearchOptionSpacing;
  //     });
  // }
  // const transcriptSearchOptionSpacingAltElements = document.querySelectorAll(".transcriptSearchOptionSpacing-alt");
  // if (transcriptSearchOptionSpacingAltElements) {
  //     transcriptSearchOptionSpacingAltElements.forEach((element) => {
  //         element.style.marginRight = transcriptSearchOptionSpacing;
  //     });
  // }
  // const transcriptDrivenSearchOptionSpacingElements = document.querySelectorAll(".transcriptDrivenSearchOptionSpacing");
  // if (transcriptDrivenSearchOptionSpacingElements) {
  //     transcriptDrivenSearchOptionSpacingElements.forEach((element) => {
  //         element.style.marginRight = transcriptDrivenSearchOptionSpacing;
  //         element.style.marginLeft = transcriptDrivenSearchOptionSpacing;
  //     });
  // }
  // const transcriptDrivenSearchOptionSpacingAltElements = document.querySelectorAll(".transcriptDrivenSearchOptionSpacing-alt");
  // if (transcriptDrivenSearchOptionSpacingAltElements) {
  //     transcriptDrivenSearchOptionSpacingAltElements.forEach((element) => {
  //         element.style.marginRight = transcriptDrivenSearchOptionSpacing;
  //     });
  // }

  // // Other styles
  // // Other styles
  // const transcriptMultiSelectIcon = isTranscriptDrivenEdit ? ((20 / screenWidth) * 100 * window.innerWidth) / 100 : ((17 / screenWidth) * 100 * window.innerWidth) / 100;
  // const transcriptSearchNext = isTranscriptDrivenEdit ? ((20 / screenWidth) * 100 * window.innerWidth) / 100 : ((17 / screenWidth) * 100 * window.innerWidth) / 100;
  // const transcriptSearchPrev = isTranscriptDrivenEdit ? ((20 / screenWidth) * 100 * window.innerWidth) / 100 : ((17 / screenWidth) * 100 * window.innerWidth) / 100;
  // const transcriptReplace = ((-5 / screenWidth) * 100 * window.innerWidth) / 100;

  // if (document.getElementById("multiselect-transcript")) {
  //     document.getElementById("multiselect-transcript").style.width = transcriptMultiSelectIcon + "px";
  // }

  // const addSpkSfxTracksTranscriptModal = document.getElementById("add-spk-sfx-tracks-transcript-modal");
  // if (addSpkSfxTracksTranscriptModal) {
  //     addSpkSfxTracksTranscriptModal.style.width = transcriptMultiSelectIcon + "px";
  // }

  // const multiselectReplaceTranscript = document.getElementById("multiselect-replace-transcript");
  // if (multiselectReplaceTranscript) {
  //     multiselectReplaceTranscript.style.width = transcriptMultiSelectIcon + "px";
  // }

  // const transcriptSearchNextElement = document.getElementById("transcript-search-next");
  // if (transcriptSearchNextElement) {
  //     transcriptSearchNextElement.style.width = transcriptSearchNext + "px";
  // }

  // const transcriptSearchPrevElement = document.getElementById("transcript-search-prev");
  // if (transcriptSearchPrevElement) {
  //     transcriptSearchPrevElement.style.width = transcriptSearchPrev + "px";
  // }

  // if (document.getElementById("btn-transcript-replace-div")) {
  //     document.getElementById("btn-transcript-replace-div").style.width = transcriptSearchPrev + "px";
  //     document.getElementById("btn-transcript-replace-div").style.marginLeft = transcriptReplace + "px";
  // }

  // let transcriptCloseWidth = `${wp(20.87)}px`;
  // let closeTranscript = document.querySelector(".close-transcript");
  // if (closeTranscript) {
  //     closeTranscript.style.width = transcriptCloseWidth;
  //     const closeTranscriptRight = ((25 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     closeTranscript.style.right = closeTranscriptRight;
  //     const closeTranscripBottom = ((35 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     closeTranscript.style.marginBottom = closeTranscripBottom;
  // }

  // let transcriptMain = document.querySelector(".transcript-main");
  // if (transcriptMain) {
  //     const transcriptMainTop = isTranscriptDrivenEdit ? ((40 / screenHeight) * 100 * window.innerHeight) / 100 + "px" : ((80 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     const transcriptMainLeftRight = isTranscriptDrivenEdit ? ((1 / screenWidth) * 100 * window.innerWidth) / 100 + "px" : ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const transcriptMainTopBottom = ((8 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     const transcriptHeight = isTranscriptDrivenEdit ? ((320 / screenHeight) * 100 * window.innerHeight) / 100 + "px" : ((590 / screenHeight) * 100 * window.innerHeight) / 100 + "px";

  //     transcriptMain.style.top = transcriptMainTop;
  //     transcriptMain.style.height = transcriptHeight;
  //     transcriptMain.style.paddingRight = transcriptMainLeftRight;
  //     transcriptMain.style.paddingLeft = transcriptMainLeftRight;
  //     transcriptMain.style.paddingTop = transcriptMainTopBottom;
  //     transcriptMain.style.paddingBottom = transcriptMainTopBottom;
  // }

  // let fadesIndicatorImg = document.querySelector(".fadesIndicatorImg");
  // if (fadesIndicatorImg) {
  //     const fadesIndicatorImgWidth = ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const fadesIndicatorImgHeight = ((16.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     fadesIndicatorImg.style.width = fadesIndicatorImgWidth;
  //     fadesIndicatorImg.style.height = fadesIndicatorImgHeight;
  // }

  // let transcriptTime = document.querySelector(".transcriptTime");
  // if (transcriptTime) {
  //     const transcriptSpeakerBottom = ((20 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     const transcriptSpeakerleft = ((5.2 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     transcriptTime.style.marginBottom = transcriptSpeakerBottom;
  //     transcriptTime.style.marginLeft = transcriptSpeakerleft;
  // }
  // let transcriptTimeStep = document.querySelector(".transcript-time-step");
  // if (transcriptTimeStep) {
  //     const transcriptBottom = ((50 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     transcriptTimeStep.style.marginBottom = transcriptBottom;

  //     const transcriptFont = ((20 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     transcriptTimeStep.style.fontSize = transcriptFont;
  // }

  // let transcriptWord = document.querySelector(".transcriptWord");
  // let transcriptSfx = document.querySelector(".transcript-sfx");
  // if (transcriptWord && transcriptSfx) {
  //     const transcriptWordSpacing = ((10 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     const transcriptWordSpacingAlt = ((12 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     transcriptWord.style.paddingTop = transcriptWordSpacing;
  //     transcriptWord.style.paddingBottom = transcriptWordSpacing;
  //     transcriptWord.style.paddingRight = transcriptWordSpacingAlt;
  //     transcriptWord.style.paddingLeft = transcriptWordSpacingAlt;
  //     transcriptSfx.style.paddingTop = transcriptWordSpacing;
  //     transcriptSfx.style.paddingBottom = transcriptWordSpacing;
  //     transcriptSfx.style.paddingRight = transcriptWordSpacingAlt;
  //     transcriptSfx.style.paddingLeft = transcriptWordSpacingAlt;
  // }

  // let transcriptMenu = document.querySelector(".transcript-menu");
  // if (transcriptMenu) {
  //     const transcriptMenuTop = isTranscriptDrivenEdit ? ((25 / screenHeight) * 100 * window.innerHeight) / 100 + "px" : ((135.86206896551724 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     transcriptMenu.style.top = transcriptMenuTop;
  // }

  // let toggleSpk = document.getElementById("toggle-spk");
  // let toggleTSpk = document.getElementById("toggle-t-spk");
  // if (toggleSpk && toggleTSpk) {
  //     const showSpeakerIcon = isTranscriptDrivenEdit ? ((20 / screenWidth) * 100 * window.innerWidth) / 100 : ((19 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const showSpeakerIconHeight = isTranscriptDrivenEdit ? ((19 / screenWidth) * 100 * window.innerWidth) / 100 : "";
  //     toggleSpk.style.width = showSpeakerIcon;
  //     toggleSpk.style.height = showSpeakerIconHeight;
  //     toggleTSpk.style.width = showSpeakerIcon;
  //     toggleTSpk.style.height = showSpeakerIconHeight;
  // }

  // let exportTranscriptIcon = document.getElementById("exportTranscriptIcon");
  // if (exportTranscriptIcon) {
  //     const exportTranscriptIconWidth = isTranscriptDrivenEdit ? ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px" : ((21 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const exportTranscriptIconHeight = isTranscriptDrivenEdit ? ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px" : "";
  //     exportTranscriptIcon.style.width = exportTranscriptIconWidth;
  //     exportTranscriptIcon.style.height = exportTranscriptIconHeight;
  // }

  // let volumeTranscriptIcon = document.querySelector(".volume-alt-icon-transcript");
  // if (volumeTranscriptIcon) {
  //     const volumeTranscriptIconWidth = ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const volumeTranscriptIconHeight = ((19 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     volumeTranscriptIcon.style.width = volumeTranscriptIconWidth;
  //     volumeTranscriptIcon.style.height = volumeTranscriptIconHeight;
  //     volumeTranscriptIcon.style.marginTop = "0.42vh";
  // }

  // let printTranscriptIcon = document.getElementById("printTranscriptIcon");
  // if (printTranscriptIcon) {
  //     const printTranscriptIconWidth = isTranscriptDrivenEdit ? ((22 / screenWidth) * 100 * window.innerWidth) / 100 + "px" : ((27 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const printTranscriptIconHeight = isTranscriptDrivenEdit ? ((21 / screenWidth) * 100 * window.innerWidth) / 100 + "px" : ((27 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //     const printTranscriptMarginTop = isTranscriptDrivenEdit ? "0.42vh" : "";
  //     printTranscriptIcon.style.width = printTranscriptIconWidth;
  //     printTranscriptIcon.style.height = printTranscriptIconHeight;
  //     printTranscriptIcon.style.marginTop = printTranscriptMarginTop;
  // }

  // const moreOptionsSidebar = ((95 / screenWidth) * 100 * window.innerWidth) / 100;
  // let moreOptionsSidebarElement = document.querySelector(".more-options-sidebar");
  // if (moreOptionsSidebarElement) {
  //     moreOptionsSidebarElement.style.width = moreOptionsSidebar + "px";
  // }

  // let moreOptLogo, moreOptTop, moreOptionsLogos, historyLogoTop, shareLogoTop, exportLogoTop, genAiLogoTop, deleteLogoTop, genSFXLogoTop, genMusicLogoTop, genImageLogoTop, analyticLogoTop;

  // moreOptLogo = ((60 / screenWidth) * 100 * window.innerWidth) / 100;
  // let moreOptLogoElement = document.querySelector(".more-opt-logo");
  // if (moreOptLogoElement) {
  //     moreOptLogoElement.style.width = moreOptLogo + "px";
  // }

  // moreOptTop = ((25 / screenHeight) * 100 * window.innerHeight) / 100;
  // if (moreOptLogoElement) {
  //     moreOptLogoElement.style.top = moreOptTop + "px";
  // }

  // moreOptionsLogos = ((35 / screenWidth) * 100 * window.innerWidth) / 100;
  // let menuOptionLogoElements = document.querySelectorAll(".menu-option-logo");
  // menuOptionLogoElements.forEach((element) => {
  //     element.style.width = moreOptionsLogos + "px";
  // });

  // moreOptionsLogos = ((30 / screenWidth) * 100 * window.innerWidth) / 100;
  // let menuOptionLogo1Elements = document.querySelectorAll(".menu-option-logo1");
  // menuOptionLogo1Elements.forEach((element) => {
  //     element.style.width = moreOptionsLogos + "px";
  // });

  // historyLogoTop = ((160 / screenHeight) * 100 * window.innerHeight) / 100;
  // let historyElement = document.querySelector(".history");
  // if (historyElement) {
  //     historyElement.style.top = historyLogoTop + "px";
  // }

  // shareLogoTop = ((230 / screenHeight) * 100 * window.innerHeight) / 100;
  // let shareElement = document.querySelector(".share");
  // if (shareElement) {
  //     shareElement.style.top = shareLogoTop + "px";
  // }
  // exportLogoTop = ((300 / screenHeight) * 100 * window.innerHeight) / 100;
  // let exportElement = document.querySelector(".export");
  // if (exportElement) {
  //     exportElement.style.top = exportLogoTop + "px";
  // }

  // genAiLogoTop = ((370 / screenHeight) * 100 * window.innerHeight) / 100;
  // let genAiElement = document.querySelector(".generativeAi");
  // if (genAiElement) {
  //     genAiElement.style.top = genAiLogoTop + "px";
  // }

  // deleteLogoTop = ((440 / screenHeight) * 100 * window.innerHeight) / 100;
  // let deleteElement = document.querySelector(".delete");
  // if (deleteElement) {
  //     deleteElement.style.top = deleteLogoTop + "px";
  // }

  // genSFXLogoTop = ((160 / screenHeight) * 100 * window.innerHeight) / 100;
  // let genSFXElement = document.querySelector(".genSFX");
  // if (genSFXElement) {
  //     genSFXElement.style.top = genSFXLogoTop + "px";
  // }

  // genMusicLogoTop = ((230 / screenHeight) * 100 * window.innerHeight) / 100;
  // let genMusicElement = document.querySelector(".genMusic");
  // if (genMusicElement) {
  //     genMusicElement.style.top = genMusicLogoTop + "px";
  // }

  // genImageLogoTop = ((300 / screenHeight) * 100 * window.innerHeight) / 100;
  // let genImageElement = document.querySelector(".genImage");
  // if (genImageElement) {
  //     genImageElement.style.top = genImageLogoTop + "px";
  // }

  // analyticLogoTop = ((370 / screenHeight) * 100 * window.innerHeight) / 100;
  // let analyticElement = document.querySelector(".analytics");
  // if (analyticElement) {
  //     analyticElement.style.top = analyticLogoTop + "px";
  // }

  // let optionsHeaderCont, closeMoreOptions, moreOptionsHeaderLeft, moreOptionsHeaderFont, optionsBodyLeft, optionsBodyFont, formatSettingFont, lineHeightFormatSettings, normalizeCheckbox, normalizeCheckboxMarginTop, normalizeCheckboxMarginLeft, optionsSubMenusLeft, optionsSubMenusLeftAlt, dropdownTextIndent, dropdownItemsPadding, moreOptButtonWidth, moreOptButtonPadding, koolioButtonsPadding, koolioButtonsPaddingFontSize, koolioButtonsPaddingAlt, koolioButtonsMarginLeft;

  // optionsHeaderCont = ((25 / screenHeight) * 100 * window.innerHeight) / 100;
  // closeMoreOptions = ((21.67 / screenWidth) * 100 * window.innerWidth) / 100;

  // let optionsHeaderContElement = document.querySelector(".options-header-cont");
  // let closeMoreOptionsElements = document.querySelectorAll(".close-more-options");
  // if (optionsHeaderContElement) {
  //     optionsHeaderContElement.style.top = optionsHeaderCont + "px";
  // }
  // closeMoreOptionsElements.forEach((element) => {
  //     element.style.right = closeMoreOptions + "px";
  // });

  // let closeMoreOptionsExportElement = document.getElementById("close-more-options-export");
  // let closeMoreOptionsShareElement = document.getElementById("close-more-options-share");
  // let closeMoreOptionsDeleteElement = document.getElementById("close-more-options-delete");
  // let closeMoreOptionsHistoryElement = document.getElementById("close-more-options-history");
  // let closeMoreOptionsAnalyticsElement = document.getElementById("close-more-options-analytics");
  // let closeGenAiOptionsMusicElement = document.getElementById("close-gen-ai-options-music");
  // let closeGenAiOptionsSfxElement = document.getElementById("close-gen-ai-options-sfx");
  // if (closeMoreOptionsExportElement && closeMoreOptionsShareElement && closeMoreOptionsDeleteElement && closeMoreOptionsHistoryElement && closeMoreOptionsAnalyticsElement && closeGenAiOptionsMusicElement && closeGenAiOptionsSfxElement) {
  //     closeMoreOptionsExportElement.setAttribute("width", closeMoreOptions);
  //     closeMoreOptionsShareElement.setAttribute("width", closeMoreOptions);
  //     closeMoreOptionsDeleteElement.setAttribute("width", closeMoreOptions);
  //     closeMoreOptionsHistoryElement.setAttribute("width", closeMoreOptions);
  //     closeMoreOptionsAnalyticsElement.setAttribute("width", closeMoreOptions);
  //     closeGenAiOptionsMusicElement.setAttribute("width", closeMoreOptions);
  //     closeGenAiOptionsSfxElement.setAttribute("width", closeMoreOptions);
  // }

  // moreOptionsHeaderLeft = ((85 / screenWidth) * 100 * window.innerWidth) / 100;
  // moreOptionsHeaderFont = ((24 / screenWidth) * 100 * window.innerWidth) / 100;
  // let moreOptionsHeaderElements = document.querySelectorAll(".more-options-menu-header");
  // moreOptionsHeaderElements.forEach((element) => {
  //     element.style.fontSize = moreOptionsHeaderFont + "px";
  //     element.style.left = moreOptionsHeaderLeft + "px";
  // });

  // optionsBodyLeft = ((126 / screenWidth) * 100 * window.innerWidth) / 100;
  // optionsBodyFont = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  // formatSettingFont = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  // let optionsBodyElement = document.querySelector(".options-body");
  // if (optionsBodyElement) {
  //     optionsBodyElement.style.left = optionsBodyLeft + "px";
  //     optionsBodyElement.style.top = historyLogoTop + "px";
  //     optionsBodyElement.style.fontSize = optionsBodyFont + "px";
  // }
  // let formatSettingElements = document.querySelectorAll(".format-settings-title, .export-format, .project-filename, .project-output-filename, .more-options-header");
  // formatSettingElements.forEach((element) => {
  //     element.style.fontSize = formatSettingFont + "px";
  // });

  // lineHeightFormatSettings = ((61.96 / screenHeight) * 100 * window.innerHeight) / 100;
  // let lineHeightOptElements = document.querySelectorAll(".line-height-opt, .line-height-opt-bottom-ai, .line-height-opt-bottom");
  // lineHeightOptElements.forEach((element) => {
  //     element.style.marginTop = lineHeightFormatSettings + "px";
  //     element.style.paddingBottom = lineHeightFormatSettings + "px";
  //     element.style.marginBottom = lineHeightFormatSettings + "px";
  // });

  // normalizeCheckbox = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  // normalizeCheckboxMarginTop = ((6.25 / screenWidth) * 100 * window.innerWidth) / 100;
  // normalizeCheckboxMarginLeft = ((75 / screenWidth) * 100 * window.innerWidth) / 100;
  // let normalizeCheckboxElement = document.getElementById("normalize-checkbox-uo");
  // if (normalizeCheckboxElement) {
  //     normalizeCheckboxElement.style.width = normalizeCheckbox + "px";
  //     normalizeCheckboxElement.style.marginTop = normalizeCheckboxMarginTop + "px";
  //     normalizeCheckboxElement.style.marginLeft = normalizeCheckboxMarginLeft + "px";
  // }

  // optionsSubMenusLeft = ((230 / screenWidth) * 100 * window.innerWidth) / 100;
  // optionsSubMenusLeftAlt = ((140 / screenWidth) * 100 * window.innerWidth) / 100;
  // dropdownTextIndent = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  // dropdownItemsPadding = ((3 / screenWidth) * 100 * window.innerWidth) / 100;
  // let optionsSubMenusElements = document.querySelectorAll(".submenu-opt-left, .submenu-opt-left-ai, #dd-items, .dd-items-alt, .dd-selected, .dd-selected-alt, .dd-items li");
  // optionsSubMenusElements.forEach((element) => {
  //     element.style.marginLeft = optionsSubMenusLeft + "px";
  //     element.style.width = optionsSubMenusLeft + "px";
  //     element.style.textIndent = dropdownTextIndent + "px";
  // });
  // let dropdownItemsAltElement = document.querySelector(".dd-items-alt");
  // if (dropdownItemsAltElement) {
  //     dropdownItemsAltElement.style.marginLeft = "0";
  //     dropdownItemsAltElement.style.width = optionsSubMenusLeftAlt + "px";
  //     dropdownItemsAltElement.style.textIndent = dropdownTextIndent + "px";
  // }
  // let dropdownSelectedElements = document.querySelectorAll(".dd-selected, .dd-selected-alt");
  // dropdownSelectedElements.forEach((element) => {
  //     element.style.width = optionsSubMenusLeft + "px";
  //     element.style.textIndent = dropdownTextIndent + "px";
  //     element.style.paddingTop = dropdownItemsPadding + "px";
  //     element.style.paddingBottom = dropdownItemsPadding + "px";
  // });
  // let dropdownItemsElements = document.querySelectorAll(".dd-items li");
  // dropdownItemsElements.forEach((element) => {
  //     element.style.paddingTop = dropdownItemsPadding + "px";
  //     element.style.paddingBottom = dropdownItemsPadding + "px";
  // });

  // moreOptButtonWidth = ((160 / screenWidth) * 100 * window.innerWidth) / 100;
  // moreOptButtonPadding = ((5 / screenWidth) * 100 * window.innerWidth) / 100;
  // let actionProjectButtonElements = document.querySelectorAll(".action-project-button");
  // actionProjectButtonElements.forEach((element) => {
  //     element.style.width = moreOptButtonWidth + "px";
  //     element.style.padding = moreOptButtonPadding + "px";
  // });
  // let btnLqDownloadElement = document.getElementById("btn-lq-download");
  // if (btnLqDownloadElement) {
  //     btnLqDownloadElement.style.width = moreOptButtonWidth + 15 + "px";
  //     btnLqDownloadElement.style.padding = moreOptButtonPadding + "px";
  // }
  // let actionProjectButtonAiElements = document.querySelectorAll(".action-project-button-ai");
  // actionProjectButtonAiElements.forEach((element) => {
  //     element.style.width = moreOptButtonWidth / 1.5 + "px";
  //     element.style.padding = moreOptButtonPadding / 1.5 + "px";
  // });

  // koolioButtonsPadding = ((4 / screenWidth) * 100 * window.innerWidth) / 100;
  // koolioButtonsPaddingFontSize = ((16 / screenWidth) * 100 * window.innerWidth) / 100;
  // koolioButtonsPaddingAlt = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  // koolioButtonsMarginLeft = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  // let koolioButtonsElements = document.querySelectorAll(".koolio-buttons");
  // koolioButtonsElements.forEach((element) => {
  //     element.style.fontSize = koolioButtonsPaddingFontSize + "px";
  //     element.style.paddingTop = koolioButtonsPadding + "px";
  //     element.style.paddingLeft = koolioButtonsPaddingAlt + "px";
  //     element.style.paddingBottom = koolioButtonsPadding + "px";
  //     element.style.paddingRight = koolioButtonsPaddingAlt + "px";
  //     element.style.marginLeft = koolioButtonsMarginLeft + "px";
  // });

  // let lineHeightFormatSettingsAlt, shareButtonsWidth, shareButtonsLeft, exportButtonLeft, historyCreatorIcon, historyViewIconWidth, historyViewIconHeight, historyLoadingTop, historyLoadingLeft, historyLoadingFontSize, musicGenresButtonPadding, musicGenresButtonPaddingAlt, lineHeightMusicFormat, lineHeightMusicFormatAlt, sfxMusicArrowWidth, sfxMusicArrowHeight;

  // lineHeightFormatSettingsAlt = ((65 / screenHeight) * 100 * window.innerHeight) / 100;
  // shareButtonsWidth = ((160 / screenWidth) * 100 * window.innerWidth) / 100;
  // shareButtonsLeft = ((30 / screenWidth) * 100 * window.innerWidth) / 100;

  // // Apply styles to elements with class '.line-height-opt-alt'
  // let lineHeightFormatSettingsAltElements = document.querySelectorAll(".line-height-opt-alt");
  // if (lineHeightFormatSettingsAltElements.length > 0) {
  //     lineHeightFormatSettingsAltElements.forEach((element) => {
  //         element.style.marginTop = lineHeightFormatSettingsAlt + "px";
  //     });
  // }

  // // Apply styles to element with class '.share-email-list-msg'
  // let shareEmailListMsgElement = document.querySelector(".share-email-list-msg");
  // if (shareEmailListMsgElement) {
  //     shareEmailListMsgElement.style.textIndent = dropdownTextIndent + "px";
  //     shareEmailListMsgElement.style.paddingTop = dropdownItemsPadding + "px";
  //     shareEmailListMsgElement.style.paddingBottom = dropdownItemsPadding + "px";
  // }

  // // Apply styles to element with id 'share-modes-grandparent'
  // let shareModesGrandparentElement = document.getElementById("share-modes-grandparent");
  // if (shareModesGrandparentElement) {
  //     shareModesGrandparentElement.style.width = shareButtonsWidth + "px";
  // }

  // // Apply styles to elements with class '.margin-left-alt'
  // let marginLeftAltElements = document.querySelectorAll(".margin-left-alt");
  // if (marginLeftAltElements.length > 0) {
  //     marginLeftAltElements.forEach((element) => {
  //         element.style.marginLeft = shareButtonsLeft + "px";
  //     });
  // }

  // // Apply styles to element with class '.share-project-button'
  // let shareProjectButtonElement = document.querySelector(".share-project-button");
  // if (shareProjectButtonElement) {
  //     shareProjectButtonElement.style.width = shareButtonsWidth + "px";
  //     shareProjectButtonElement.style.paddingTop = dropdownItemsPadding + "px";
  //     shareProjectButtonElement.style.paddingBottom = dropdownItemsPadding + "px";
  // }

  // // Calculate export button left padding
  // exportButtonLeft = ((30 / screenWidth) * 100 * window.innerWidth) / 100;
  // // Apply styles to element with class '.high-quality-export'
  // let highQualityExportElement = document.querySelector(".high-quality-export");
  // if (highQualityExportElement) {
  //     highQualityExportElement.style.paddingLeft = exportButtonLeft + "px";
  // }

  // // Calculate and apply styles for history creator icon
  // historyCreatorIcon = ((32.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // let userProfileBorderElements = document.querySelectorAll(".user-profile-border");
  // if (userProfileBorderElements.length > 0) {
  //     userProfileBorderElements.forEach((element) => {
  //         element.style.width = historyCreatorIcon;
  //         element.style.height = historyCreatorIcon;
  //     });
  // }

  // // Calculate and apply styles for history view icon
  // historyViewIconWidth = ((19.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // historyViewIconHeight = ((21.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // let viewHistoryIconElement = document.querySelector(".view-history-icon");
  // if (viewHistoryIconElement) {
  //     viewHistoryIconElement.style.width = historyViewIconWidth;
  //     viewHistoryIconElement.style.height = historyViewIconHeight;
  // }

  // // Calculate and apply styles for history loading element
  // historyLoadingTop = ((295 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // historyLoadingLeft = ((460 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // historyLoadingFontSize = ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // let historyLoadingElement = document.getElementById("history-loading");
  // let shareHistoryLoadingElement = document.getElementById("share-history-loading");
  // if (historyLoadingElement && shareHistoryLoadingElement) {
  //     historyLoadingElement.style.top = historyLoadingTop;
  //     historyLoadingElement.style.left = historyLoadingLeft;
  //     historyLoadingElement.style.fontSize = historyLoadingFontSize;
  //     shareHistoryLoadingElement.style.top = historyLoadingTop;
  //     shareHistoryLoadingElement.style.left = historyLoadingLeft;
  //     shareHistoryLoadingElement.style.fontSize = historyLoadingFontSize;
  // }

  // // Calculate and apply styles for music genres buttons and line heights
  // musicGenresButtonPadding = ((5 / screenWidth) * 100 * window.innerWidth) / 100;
  // musicGenresButtonPaddingAlt = ((25 / screenWidth) * 100 * window.innerWidth) / 100;
  // lineHeightMusicFormat = ((10 / screenWidth) * 100 * window.innerWidth) / 100;
  // lineHeightMusicFormatAlt = ((15 / screenWidth) * 100 * window.innerWidth) / 100;
  // let musicActionProjectButtonElements = document.querySelectorAll(".music-action-project-button");
  // if (musicActionProjectButtonElements.length > 0) {
  //     musicActionProjectButtonElements.forEach((element) => {
  //         element.style.paddingTop = musicGenresButtonPadding + "px";
  //         element.style.paddingBottom = musicGenresButtonPadding + "px";
  //         element.style.paddingRight = musicGenresButtonPaddingAlt + "px";
  //         element.style.paddingLeft = musicGenresButtonPaddingAlt + "px";
  //     });
  // }
  // let createMusicButtonElements = document.querySelectorAll(".create-music-button");
  // if (createMusicButtonElements.length > 0) {
  //     createMusicButtonElements.forEach((element) => {
  //         element.style.paddingTop = musicGenresButtonPadding + "px";
  //         element.style.paddingBottom = musicGenresButtonPadding + "px";
  //         element.style.paddingRight = musicGenresButtonPaddingAlt + "px";
  //         element.style.paddingLeft = musicGenresButtonPaddingAlt + "px";
  //     });
  // }
  // let genMusicButtonElements = document.querySelectorAll(".gen-music-button");
  // if (genMusicButtonElements.length > 0) {
  //     genMusicButtonElements.forEach((element) => {
  //         element.style.paddingRight = musicGenresButtonPaddingAlt / 2 + "px";
  //         element.style.paddingLeft = musicGenresButtonPaddingAlt / 2 + "px";
  //         element.style.height = ((38 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  //     });
  // }
  // let lineHeightMusicGenresButtonElements = document.querySelectorAll(".line-height-music-genres > button");
  // if (lineHeightMusicGenresButtonElements.length > 0) {
  //     lineHeightMusicGenresButtonElements.forEach((element) => {
  //         element.style.marginLeft = lineHeightMusicFormat + "px";
  //         element.style.marginRight = lineHeightMusicFormat + "px";
  //         element.style.marginTop = lineHeightMusicFormatAlt + "px";
  //         element.style.marginBottom = lineHeightMusicFormatAlt + "px";
  //     });
  // }

  // // Calculate and apply styles for SFX music arrows
  // sfxMusicArrowWidth = ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // sfxMusicArrowHeight = ((32 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // let sfxMusicArrowsElements = document.querySelectorAll(".sfx-music-arrows");
  // if (sfxMusicArrowsElements.length > 0) {
  //     sfxMusicArrowsElements.forEach((element) => {
  //         element.style.width = sfxMusicArrowWidth;
  //         element.style.height = sfxMusicArrowHeight;
  //     });
  // }

  // // Check if either '#half-modals-content' or '#progress-modals-content' exist before calling 'koolioModals'
  // if (document.getElementById("half-modals-content") || document.getElementById("progress-modals-content")) {
  //     koolioModals();
  // }
}

export function koolioModals() {
  const progressModalsContent = document.querySelector(
    ".progress-modals-content",
  );
  if (progressModalsContent) {
    // Progress Model content
    let progressContentWidth =
      ((325 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let topMargin =
      ((-10 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let top = ((50 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let leftMargin =
      ((-140 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    progressModalsContent.style.width = progressContentWidth;

    const progressModals = document.querySelector(".progress-modals");
    if (progressModals) {
      progressModals.style.marginTop = topMargin;
      progressModals.style.top = top;
      progressModals.style.marginLeft = leftMargin;
    }

    let vantaWidth =
      ((1900 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const statusDisp = document.getElementById("status-disp");
    if (statusDisp) {
      statusDisp.style.width = vantaWidth;
    }

    let progressContentFontSize =
      ((22 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let progressDetailFontSize =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const progressContent = document.getElementById("progress-content");
    const progressDetail = document.getElementById("progress-detail");
    if (progressContent && progressDetail) {
      progressContent.style.fontSize = progressContentFontSize;
      progressDetail.style.fontSize = progressDetailFontSize;
    }

    let bottom = ((28 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let marginTopDetail =
      ((-40 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const progressContentElem = document.getElementById("progress-content");
    const progressDetailElem = document.getElementById("progress-detail");
    if (progressContentElem && progressDetailElem) {
      progressContentElem.style.bottom = bottom;
      progressDetailElem.style.marginTop = marginTopDetail;
    }

    let percentageFontSize =
      ((23 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const progressPercentage = document.getElementById("progress-percentage");
    if (progressPercentage) {
      progressPercentage.style.fontSize = percentageFontSize;
    }

    let progressHeight =
      ((2.8 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const progressStyle = document.getElementById("progress-style");
    if (progressStyle) {
      progressStyle.style.height = progressHeight;
    }
  }

  const halfModalsContent = document.querySelector("#half-modals-content");
  if (halfModalsContent) {
    // sfxLength
    // let sfxMaxTextLengthMarginLeft =
    //   ((508 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxMaxTextLengthMarginTop =
    //   ((1 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxMinTextLengthMarginLeft =
    //   ((143 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxMinTextLengthMarginTop =
    //   ((1 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxMinInputWidth = ((62 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxMinInputHeight =
    //   ((23 / screenWidth) * 100 * window.innerWidth) / 100;
    // const sfxMaxLength = document.querySelector(".sfx-max-length");
    // const sfxMinLength = document.querySelector(".sfx-min-length");
    // if (sfxMaxLength && sfxMinLength) {
    //   sfxMaxLength.style.marginLeft = sfxMaxTextLengthMarginLeft + "px";
    //   sfxMinLength.style.marginLeft = sfxMinTextLengthMarginLeft + "px";
    //   sfxMaxLength.style.marginTop = sfxMaxTextLengthMarginTop + "px";
    //   sfxMinLength.style.marginTop = sfxMinTextLengthMarginTop + "px";
    //   sfxMinLength.style.width = sfxMinInputWidth + "px";
    //   sfxMinLength.style.height = sfxMinInputHeight + "px";
    // }

    // let sfxSliderMarginLeft =
    //   ((218 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxSliderMarginTop =
    //   ((9 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxSliderWidth = ((280 / screenWidth) * 100 * window.innerWidth) / 100;
    // let sfxSliderHeight = ((7 / screenWidth) * 100 * window.innerWidth) / 100;
    // const slider = document.querySelector(".slider");
    // if (slider) {
    //   slider.style.marginLeft = sfxSliderMarginLeft + "px";
    //   slider.style.marginTop = sfxSliderMarginTop + "px";
    //   slider.style.width = sfxSliderWidth + "px";
    //   slider.style.height = sfxSliderHeight + "px";
    // }

    // checkbox
    let termsCheckboxWidth =
      ((15.9 / screenWidth) * 100 * window.innerWidth) / 100;
    let termsCheckboxMarginTop =
      ((-4.25 / screenWidth) * 100 * window.innerWidth) / 100;
    let termsCheckboxMarginRight =
      ((4.25 / screenWidth) * 100 * window.innerWidth) / 100;
    const diarizationCheckbox = document.getElementById("diarization-checkbox");
    const reannotCheckbox = document.getElementById("reannot-checkbox");
    if (diarizationCheckbox && reannotCheckbox) {
      diarizationCheckbox.style.width = termsCheckboxWidth + "px";
      diarizationCheckbox.style.marginTop = termsCheckboxMarginTop + "px";
      diarizationCheckbox.style.marginRight = termsCheckboxMarginRight + "px";
      reannotCheckbox.style.width = termsCheckboxWidth + "px";
      reannotCheckbox.style.marginTop = termsCheckboxMarginTop + "px";
      reannotCheckbox.style.marginRight = termsCheckboxMarginRight + "px";
    }

    // Half modals content
    let modalContentWidth =
      ((756 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentHeight =
      ((180 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentBorderRadius =
      ((12 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentPadding =
      ((30 / screenWidth) * 100 * window.innerWidth) / 100 + "px";

    // this condition is when screen reload when user clicks on a notification
    if (halfModalsContent.classList.contains("notification-welcome-modal")) {
      modalContentWidth = "30vw";
    }
    halfModalsContent.style.width = modalContentWidth;
    halfModalsContent.style.borderRadius = modalContentBorderRadius;
    halfModalsContent.style.padding = modalContentPadding;

    // content header
    let modalContentHeaderFontSize =
      ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const halfModalsHeader = document.querySelector(".half-modals-header");
    if (halfModalsHeader) {
      halfModalsHeader.style.fontSize = modalContentHeaderFontSize;
    }

    // speaker Name
    let modalContentSpeakerFontSize =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const halfModalsSpeaker = document.querySelectorAll(".half-modals-speaker");
    const halfModalBody = document.querySelectorAll(".half-modal-body");
    if (halfModalsSpeaker && halfModalBody) {
      halfModalsSpeaker.forEach((element) => {
        element.style.fontSize = modalContentSpeakerFontSize;
      });
      halfModalBody.forEach((element) => {
        element.style.fontSize = modalContentSpeakerFontSize;
        element.style.marginTop = modalContentmargin + "px";
      });
    }
    let modalContentmargin =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";

    const speakerInput = document.querySelector(".speaker-input");
    if (speakerInput) {
      let modalContentSpeakerRight =
        ((400 / screenWidth) * 100 * window.innerWidth) / 100;
      speakerInput.style.right = modalContentSpeakerRight + "px";
    }

    // sub header
    let modalContentSubHeaderFontSize =
      ((15 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentSubHeaderMarginTop =
      ((8 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    // const halfModalsSubHeader = document.querySelector(
    //   ".half-modals-sub-header"
    // );
    // if (halfModalsSubHeader) {
    //   halfModalsSubHeader.style.fontSize = modalContentSubHeaderFontSize;
    //   halfModalsSubHeader.style.marginTop = modalContentSubHeaderMarginTop;
    // }
    const transcribeText = document.getElementById("transcribe-text");
    if (transcribeText) {
      let modalContentProgressTextFontSize =
        ((11 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
      transcribeText.style.fontSize = modalContentProgressTextFontSize;
      let modalContentProgressTop =
        (transcribeText.parentNode.offsetHeight - transcribeText.offsetHeight) /
        2;
      transcribeText.style.top = modalContentProgressTop + "px";
    }

    // close button
    let modalContentCloseIcon =
      ((15 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const closeWorkspaceModalIcon = document.getElementById(
      "closeWorkspaceModalIcon",
    );
    if (closeWorkspaceModalIcon) {
      closeWorkspaceModalIcon.setAttribute("width", modalContentCloseIcon);
    }

    // action buttons
    let modalContentActionMarginTop =
      ((30 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsPadding =
      ((28 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsPaddingAlt =
      ((4 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsMarginRight =
      ((22 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsFontSize =
      ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const halfModalsAction = document.querySelectorAll(".half-modals-action");
    const halfModalsActionsButton = document.querySelectorAll(
      ".half-modals-actions > button",
    );
    const halfModalsActionButtonContainer = document.querySelectorAll(
      ".half-modals-action-button-container button",
    );
    const progressBar = document.getElementById("progress-bar");
    if (
      halfModalsAction &&
      halfModalsActionsButton &&
      halfModalsActionButtonContainer &&
      progressBar
    ) {
      halfModalsAction.forEach((element) => {
        element.style.marginTop = modalContentActionMarginTop;
        element.style.fontSize = modalContentActionsFontSize;
      });
      halfModalsActionsButton.forEach((element) => {
        element.style.paddingLeft = modalContentActionsPadding;
        element.style.paddingRight = modalContentActionsPadding;
        element.style.paddingTop = modalContentActionsPaddingAlt;
        element.style.paddingBottom = modalContentActionsPaddingAlt;
        element.style.marginRight = modalContentActionsMarginRight;
      });
      halfModalsActionButtonContainer.forEach((element) => {
        element.style.paddingLeft = modalContentActionsPadding;
        element.style.paddingRight = modalContentActionsPadding;
        element.style.paddingTop = modalContentActionsPaddingAlt;
        element.style.paddingBottom = modalContentActionsPaddingAlt;
        element.style.marginRight = modalContentActionsMarginRight;
      });
      progressBar.style.marginTop = modalContentActionMarginTop;
      progressBar.style.fontSize = modalContentActionsFontSize; // progress margin
    }

    let modalContentActionMarginTopInputs =
      ((50 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionMarginBottomInputs =
      ((30 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const halfModalsActionInputs = document.querySelector(
      ".half-modals-action-inputs",
    );
    if (halfModalsActionInputs) {
      halfModalsActionInputs.style.marginTop =
        modalContentActionMarginTopInputs;
      halfModalsActionInputs.style.marginBottom =
        modalContentActionMarginBottomInputs;
      halfModalsActionInputs.style.fontSize = modalContentActionsFontSize;
    }
    let modalContentActionsPaddingInput =
      ((40 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsPaddingAltInput =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsMarginRightInputs =
      ((12 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const halfModalsActionsInputsButton = document.querySelectorAll(
      ".half-modals-actions-inputs > button",
    );
    if (halfModalsActionsInputsButton) {
      halfModalsActionsInputsButton.forEach((element) => {
        element.style.paddingLeft = modalContentActionsPaddingInput;
        element.style.paddingRight = modalContentActionsPaddingInput;
        element.style.paddingTop = modalContentActionsPaddingAltInput;
        element.style.paddingBottom = modalContentActionsPaddingAltInput;
        element.style.margin = modalContentActionsMarginRightInputs;
      });
    }

    let modalContentActionMarginTopAlt =
      ((25 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionDownloadMarginTopAlt =
      ((36 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let modalContentActionsFontSizeAlt =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const exportTranscriptFilenameCont = document.querySelector(
      ".export-transcript-filename-cont",
    );
    const downloadTranscript = document.querySelector(".download-transcript");
    if (exportTranscriptFilenameCont && downloadTranscript) {
      exportTranscriptFilenameCont.style.marginTop =
        modalContentActionMarginTopAlt;
      exportTranscriptFilenameCont.style.fontSize =
        modalContentActionsFontSizeAlt;
      downloadTranscript.style.marginTop =
        modalContentActionDownloadMarginTopAlt;
    }

    let podcastIconSize =
      ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let podcastIconRight =
      ((6 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let podcastIconTop =
      ((-5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const podcastIcon = document.querySelector(".podcast-icon");
    if (podcastIcon) {
      podcastIcon.style.marginRight = podcastIconRight;
      podcastIcon.style.width = podcastIconSize;
      podcastIcon.style.marginTop = podcastIconTop;
    }

    let halfModalsInputMarginTop =
      ((32 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let halfModalsInputMarginBottom =
      ((47 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const halfModalsInputField = document.querySelector(
      ".half-modals-input-field",
    );
    if (halfModalsInputField) {
      halfModalsInputField.style.marginTop = halfModalsInputMarginTop;
      halfModalsInputField.style.marginBottom = halfModalsInputMarginBottom;
    }

    let halfModalsInputFieldFontSize =
      ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let halfModalsInputFieldPadding =
      ((5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    let halfModalsInputFieldMarginTop =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const apiInput = document.querySelector(".api-input");
    if (apiInput) {
      apiInput.style.marginTop = halfModalsInputFieldMarginTop;
      apiInput.style.fontSize = halfModalsInputFieldFontSize;
      apiInput.style.padding = halfModalsInputFieldPadding;
    }

    let showListMaxHeight =
      ((80 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
    const showList = document.getElementById("showList");
    if (showList) {
      showList.style.maxHeight = showListMaxHeight;
    }
    let showListFontSize =
      ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    const showListUl = document.querySelector("#showList ul");
    if (showListUl) {
      showListUl.style.fontSize = showListFontSize;
    }

    // Plan UL
    if (document.getElementsByClassName("pricing-modal")[0]) {
      const plansUL = document.getElementsByClassName("list-unstyled");
      if (
        document.getElementsByClassName("pricing-modal")[0].children[0]
          .children[0].children[0].children[1].children[0].offsetHeight <
        document.getElementsByClassName("pricing-modal")[0].children[0]
          .children[1].children[0].children[1].children[0].offsetHeight
      ) {
        document.getElementsByClassName(
          "pricing-modal",
        )[0].children[0].children[0].children[0].children[1].children[0].style.height =
          document.getElementsByClassName("pricing-modal")[0].children[0]
            .children[1].children[0].children[1].children[0].offsetHeight +
          "px";
      } else if (
        document.getElementsByClassName("pricing-modal")[0].children[0]
          .children[0].children[0].children[1].children[0].offsetHeight >
        document.getElementsByClassName("pricing-modal")[0].children[0]
          .children[1].children[0].children[1].children[0].offsetHeight
      ) {
        document.getElementsByClassName(
          "pricing-modal",
        )[0].children[0].children[1].children[0].children[1].children[0].style.height =
          document.getElementsByClassName("pricing-modal")[0].children[0]
            .children[0].children[0].children[1].children[0].offsetHeight +
          "px";
      }
    }
  }
}

export function playlistRes() {
  const speakerVolumeAlt = document.getElementById("spk-volume-alt-icon");
  if (speakerVolumeAlt) {
    speakerVolumeAlt.style.width = (25 / screenWidth) * 100 + "vw";
    speakerVolumeAlt.style.height = (27 / screenHeight) * 100 + "vh";
  }

  const sfxVolumeAlt = document.getElementById("sfx-volume-alt-icon");
  if (sfxVolumeAlt) {
    sfxVolumeAlt.style.width = (25 / screenWidth) * 100 + "vw";
    sfxVolumeAlt.style.height = (27 / screenHeight) * 100 + "vh";
  }

  const speakerVolText = document.getElementById("spk-volume-val");
  if (speakerVolText) {
    speakerVolText.style.fontSize = (12 / screenWidth) * 100 + "vw";
  }

  const sfxVolText = document.getElementById("sfx-volume-val");
  if (sfxVolText) {
    sfxVolText.style.fontSize = (12 / screenWidth) * 100 + "vw";
  }

  const spkMute = document.getElementById("spk-mute");
  if (spkMute) {
    spkMute.style.fontSize = (18 / screenWidth) * 100 + "vw";
  }

  const spkMuteTranscript = document.getElementById("spk-mute-transcript");
  if (spkMuteTranscript) {
    spkMuteTranscript.style.fontSize = (18 / screenWidth) * 100 + "vw";
  }

  const spkSoloTranscript = document.getElementById("spk-solo-transcript");
  if (spkSoloTranscript) {
    spkSoloTranscript.style.fontSize = (18 / screenWidth) * 100 + "vw";
  }

  const spkSolo = document.getElementById("spk-solo");
  if (spkSolo) {
    spkSolo.style.fontSize = (18 / screenWidth) * 100 + "vw";
  }

  const sfxMute = document.getElementById("sfx-mute");
  if (sfxMute) {
    sfxMute.style.fontSize = (18 / screenWidth) * 100 + "vw";
  }

  const sfxSolo = document.getElementById("sfx-solo");
  if (sfxSolo) {
    sfxSolo.style.fontSize = (18 / screenWidth) * 100 + "vw";
  }

  const volCtrlTrack = document.getElementById("vol-ctrl-track");
  if (volCtrlTrack) {
    volCtrlTrack.style.fontSize =
      ((11.6 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const volCtrlTrackCont = document.getElementById("vol-ctrl-track-cont");
  if (volCtrlTrackCont) {
    volCtrlTrackCont.style.height =
      ((50.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  // const zoomIn = document.getElementById("zoom-in");
  // if (zoomIn) {
  //   zoomIn.style.width = (12 / screenWidth) * 100 + "vw";
  //   zoomIn.style.height = (12 / screenHeight) * 100 + "vh";
  // }

  // const zoomOut = document.getElementById("zoom-out");
  // if (zoomOut) {
  //   zoomOut.style.width = (12 / screenWidth) * 100 + "vw";
  //   zoomOut.style.height = (12 / screenHeight) * 100 + "vh";
  // }

  // const zoomReset = document.getElementById("zoom-reset");
  // if (zoomReset) {
  //   zoomReset.style.width = (13.6 / screenWidth) * 100 + "vw";
  //   zoomReset.style.height = (13.6 / screenHeight) * 100 + "vh";
  // }

  const cursorTime = ((12 / screenWidth) * 100 * window.innerWidth) / 100;
  const cursorTimeElements = document.querySelectorAll(".cursor-time");
  if (cursorTimeElements) {
    cursorTimeElements.forEach((element) => {
      element.style.fontSize = cursorTime + "px";
    });
  }

  const globalPlaylistTime = document.getElementById("global-playlist-time");
  if (globalPlaylistTime) {
    globalPlaylistTime.style.fontSize =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const recordGlobalPlaylistTime = document.getElementById(
    "record-global-playlist-time",
  );
  if (recordGlobalPlaylistTime) {
    recordGlobalPlaylistTime.style.fontSize =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const globalPlaylistDuration = document.getElementById(
    "global-playlist-duration",
  );
  if (globalPlaylistDuration) {
    globalPlaylistDuration.style.fontSize =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const recordGlobalPlaylistDuration = document.getElementById(
    "record-global-playlist-duration",
  );
  if (recordGlobalPlaylistDuration) {
    recordGlobalPlaylistDuration.style.fontSize =
      ((16 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const recordGlobalView = document.querySelector(".record-global-view");
  if (recordGlobalView) {
    // recordGlobalView.style.height = globalWaveHeight + 'px';
  }

  // Volume controls
  const knobs = document.querySelectorAll(".knob, .knob-clean, .knob-svg");
  knobs.forEach((knob) => {
    knob.style.width = wp(45) + "px";
    knob.style.height = wp(51) + "px";
  });

  const knobOutline = document.querySelectorAll(".knob-outline");
  knobOutline.forEach((outline) => {
    outline.style.width = wp(45) + "px";
    outline.style.height = wp(54) + "px";
  });

  // Addon
  const addonSep = document.getElementById("addonSep");
  if (addonSep) {
    addonSep.style.width =
      ((1.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    addonSep.style.height =
      ((46 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  }

  const addonOptSfxText = document.getElementById("addon-opt-sfx-text");
  if (addonOptSfxText) {
    addonOptSfxText.style.marginLeft =
      ((82.56 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const addonOptMusicText = document.getElementById("addon-opt-music-text");
  if (addonOptMusicText) {
    addonOptMusicText.style.marginRight =
      ((82.56 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  // Controls
  // const undoBtn = document.getElementById("undoBtn");
  // if (undoBtn) {
  //   undoBtn.style.width =
  //     ((18.44 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  const recordUndoBtn = document.getElementById("record-undoBtn");
  if (recordUndoBtn) {
    recordUndoBtn.style.width =
      ((18.44 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  // const viewMode = document.getElementById("control-opt-preview");
  // if (viewMode) {
  //   viewMode.style.marginLeft =
  //     ((42.56 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //   viewMode.style.marginRight =
  //     ((19 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  const recordMode = document.getElementById("control-opt-record");
  if (recordMode) {
    recordMode.style.fontSize =
      ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const recMode = document.getElementById("control-opt-rec");
  if (recMode) {
    recMode.style.fontSize =
      ((18 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }
  // const editMode = document.getElementById("control-opt-edit");
  // if (editMode) {
  //   editMode.style.marginRight =
  //     ((42.56 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //   editMode.style.marginLeft =
  //     ((19 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  // const redoBtn = document.getElementById("redoBtn");
  // if (redoBtn) {
  //   redoBtn.style.width =
  //     ((18.44 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // }

  const recordRedoBtn = document.getElementById("record-redoBtn");
  if (recordRedoBtn) {
    recordRedoBtn.style.width =
      ((18.44 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  // const modeSep = document.getElementById("modeSep");
  // if (modeSep) {
  //   modeSep.style.width =
  //     ((1.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  //   modeSep.style.height =
  //     ((28 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
  // }

  let pushStartIcon,
    playIcon,
    pushEndIcon,
    loopIcon,
    flagIcon,
    cut,
    silence,
    copy,
    paste,
    insert,
    annot,
    chgSpeaker,
    fades,
    record,
    importTrack,
    magic;

  // pushStartIcon = ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const btnRewind = document.getElementById("btn-rewind");
  // if (btnRewind) {
  //   btnRewind.style.width = pushStartIcon;
  // }

  // playIcon = ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const btnPlayIcon = document.getElementById("btn-play-icon");
  // if (btnPlayIcon) {
  //   btnPlayIcon.style.width = playIcon;
  // }

  // pushEndIcon = ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const btnForward = document.getElementById("btn-forward");
  // if (btnForward) {
  //   btnForward.style.width = pushEndIcon;
  // }

  // loopIcon = ((24.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const btnLoopIcon = document.getElementById("btn-loop-icon");
  // if (btnLoopIcon) {
  //   btnLoopIcon.style.width = loopIcon;
  // }

  // flagIcon = ((19 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  // const btnFlag = document.getElementById("btn-flag");
  // if (btnFlag) {
  //   btnFlag.style.width = flagIcon;
  // }

  cut = document.getElementById("btn-cut-icon");
  if (cut) {
    cut.style.width =
      ((21 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  silence = document.getElementById("btn-silence");
  if (silence) {
    silence.style.width =
      ((21 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  copy = document.getElementById("btn-copy");
  if (copy) {
    copy.style.width =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  paste = document.getElementById("btn-insert-icon");
  if (paste) {
    paste.style.width =
      ((23 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  insert = document.getElementById("btn-insert-alt-icon");
  if (insert) {
    insert.style.width =
      ((20.3 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  annot = document.getElementById("btn-annot-icon");
  if (annot) {
    annot.style.width =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  chgSpeaker = document.getElementById("btn-spk-icon");
  if (chgSpeaker) {
    chgSpeaker.style.width =
      ((24 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  fades = document.getElementById("waveforms-selector");
  if (fades) {
    fades.style.width =
      ((23 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
    fades.style.height =
      ((21.5 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  record = document.getElementById("btn-record-icon");
  if (record) {
    record.style.width =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  importTrack = document.getElementById("btn-import-icon");
  if (importTrack) {
    importTrack.style.width =
      ((20 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  magic = document.getElementById("btn-magic-icon");
  if (magic) {
    magic.style.width =
      ((21 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  }

  const magicImages = document.querySelectorAll(".dd-magic img");
  magicImages.forEach((image) => {
    image.style.width =
      ((21 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  });

  const controlIconsSpacing =
    ((25 / screenWidth) * 100 * window.innerWidth) / 100;
  const iconSpacingElements = document.querySelectorAll(".icon-spacing");
  iconSpacingElements.forEach((element) => {
    element.style.marginLeft = controlIconsSpacing;
    element.style.marginRight = controlIconsSpacing;
    element.style.cursor = "pointer";
  });

  const magicListItems = document.querySelectorAll(".dd-magic li");
  magicListItems.forEach((item) => {
    item.style.marginBottom = 1.2 * controlIconsSpacing;
    item.style.cursor = "pointer";
  });

  const popoverMagicBody = document.querySelector(
    ".popover-magic .popover-body",
  );
  if (popoverMagicBody) {
    popoverMagicBody.style.paddingTop = controlIconsSpacing;
    popoverMagicBody.style.paddingBottom = 0;
  }

  // sub menu section

  let insertFontSize,
    insertBeforeAfterSpacing,
    insertBeforeAfterWidth,
    fadeSpacing,
    fadeSpacingCenter;

  insertFontSize =
    ((19.2 / screenWidth) * 100 * window.innerWidth) / 100 + "px";

  insertBeforeAfterSpacing =
    ((160 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  insertBeforeAfterWidth =
    ((32 / screenWidth) * 100 * window.innerWidth) / 100 + "px";

  const insertBeforeIcons = document.querySelectorAll(".insert-before-icon");
  if (insertBeforeIcons) {
    insertBeforeIcons.forEach((icon) => {
      icon.style.width = insertBeforeAfterWidth;
    });
  }

  const insertAfterIcons = document.querySelectorAll(".insert-after-icon");
  if (insertAfterIcons) {
    insertAfterIcons.forEach((icon) => {
      icon.style.width = insertBeforeAfterWidth;
    });
  }

  const insertIcons = document.querySelectorAll(".insert-icon");
  if (insertIcons) {
    insertIcons.forEach((icon) => {
      icon.style.fontSize = insertFontSize;
    });
  }

  fadeSpacing = ((210 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
  fadeSpacingCenter =
    ((70 / screenWidth) * 100 * window.innerWidth) / 100 + "px";

  const fadeExpoElements = document.querySelectorAll(".fade-expo");
  if (fadeExpoElements) {
    fadeExpoElements.forEach((element) => {
      element.style.marginRight = fadeSpacing;
      element.style.cursor = "pointer";
    });
  }

  const fadeCurveElements = document.querySelectorAll(".fade-curve");
  if (fadeCurveElements) {
    fadeCurveElements.forEach((element) => {
      element.style.marginLeft = fadeSpacing;
      element.style.cursor = "pointer";
    });
  }

  const fadeLineElements = document.querySelectorAll(".fade-line");
  if (fadeLineElements) {
    fadeLineElements.forEach((element) => {
      element.style.marginLeft = fadeSpacingCenter;
      element.style.cursor = "pointer";
    });
  }

  const fadeLogElements = document.querySelectorAll(".fade-log");
  if (fadeLogElements) {
    fadeLogElements.forEach((element) => {
      element.style.marginRight = fadeSpacingCenter;
      element.style.cursor = "pointer";
    });
  }

  const cmPasteBtn = document.getElementById("cm-paste-btn");
  if (cmPasteBtn) {
    cmPasteBtn.style.fontSize = hp(24);
  }

  const cmInsertBtn = document.getElementById("cm-insert-btn");
  if (cmInsertBtn) {
    cmInsertBtn.style.fontSize = hp(24);
  }

  // modals

  // sfx
  let modalHeight =
    ((346.55172413793105 / screenHeight) * 100 * window.innerHeight) / 100;
  let modalTop =
    ((139.6551724137931 / screenHeight) * 100 * window.innerHeight) / 100;
  let modalHeaderFont = ((26 / screenHeight) * 100 * window.innerHeight) / 100;
  let modalSearchSfxHeight =
    ((38 / screenHeight) * 100 * window.innerHeight) / 100;
  let modalFontSize = ((20 / screenHeight) * 100 * window.innerHeight) / 100;
  let closeModalRight = ((25.27 / screenWidth) * 100 * window.innerWidth) / 100;
  let closeModalTop = ((27.87 / screenHeight) * 100 * window.innerHeight) / 100;
  let closeModalWidth = ((20.67 / screenWidth) * 100 * window.innerWidth) / 100;
  let sfxSuggentionLeft = ((107 / screenWidth) * 100 * window.innerWidth) / 100;

  const modalContents = document.querySelectorAll(".modal-content");
  if (modalContents) {
    modalContents.forEach((content) => {
      content.style.height = modalHeight;
      content.style.top = modalTop;
      content.style.fontSize = modalFontSize;
    });
  }

  const modalHeaderTexts = document.querySelectorAll(".modal-header-text");
  if (modalHeaderTexts) {
    modalHeaderTexts.forEach((header) => {
      header.style.fontSize = modalHeaderFont;
      header.style.marginTop = modalHeaderFont;
      header.style.marginBottom = modalHeaderFont;
    });
  }

  const sfxSearchBars = document.querySelectorAll("#sfxSearchBar");
  if (sfxSearchBars) {
    sfxSearchBars.forEach((bar) => {
      bar.style.height = modalSearchSfxHeight;
    });
  }

  const genMusicSearchBars = document.querySelectorAll("#genMusicSearchBar");
  if (genMusicSearchBars) {
    genMusicSearchBars.forEach((bar) => {
      bar.style.height = modalSearchSfxHeight;
    });
  }

  const genImageSearchBars = document.querySelectorAll("#genImageSearchBar");
  if (genImageSearchBars) {
    genImageSearchBars.forEach((bar) => {
      bar.style.height = modalSearchSfxHeight;
    });
  }

  const genSFXSearchBars = document.querySelectorAll("#genSFXSearchBar");
  if (genSFXSearchBars) {
    genSFXSearchBars.forEach((bar) => {
      bar.style.height = modalSearchSfxHeight;
    });
  }

  const prevSearchedLists = document.querySelectorAll(".prev-searched-list");
  if (prevSearchedLists) {
    prevSearchedLists.forEach((list) => {
      list.style.marginLeft = sfxSuggentionLeft;
    });
  }

  const closeModalBtns = document.querySelectorAll(".close-modal");
  if (closeModalBtns) {
    closeModalBtns.forEach((btn) => {
      btn.style.right = closeModalRight;
      btn.style.top = closeModalTop;
      btn.style.width = closeModalWidth;
    });
  }

  // record modal
  let recordPlayIcon = ((19 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordPlayIconHeight =
    ((20 / screenHeight) * 100 * window.innerHeight) / 100;
  let recordIcon = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordDiscard = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordRetake = ((20 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordConfirmIcon = ((23 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordDiscardIcon =
    ((16.5 / screenWidth) * 100 * window.innerWidth) / 100;
  let recordRetakeIcon = ((18.5 / screenWidth) * 100 * window.innerWidth) / 100;

  const recPlay = document.getElementById("rec-play");
  if (recPlay) {
    recPlay.style.width = `${recordPlayIcon}px`;
  }

  const discardRecord = document.getElementById("discard-record");
  if (discardRecord) {
    discardRecord.style.width = `${recordDiscard}px`;
  }

  const retakeRecord = document.getElementById("retake-record");
  if (retakeRecord) {
    retakeRecord.style.width = `${recordRetake}px`;
  }

  const recRecord = document.getElementById("rec-record");
  if (recRecord) {
    recRecord.style.width = `${recordIcon}px`;
  }

  const recConfirm = document.getElementById("rec-confirm");
  if (recConfirm) {
    recConfirm.style.width = `${recordConfirmIcon}px`;
  }

  // const recPushStart = document.getElementById("rec-push-to-start");
  // const recPushEnd = document.getElementById("rec-push-to-end");
  // if (recordPushStart && recordPushEnd) {
  // recordPushStart.style.width = wp(20);
  // recordPushEnd.style.width = wp(20);
  // }

  const recControls = document.querySelectorAll(".rec-controls");
  if (recControls) {
    recControls.forEach((control) => {
      let recordControlsHeight = (25 / screenHeight) * window.screenHeight;
      control.style.height = `${recordControlsHeight}px`;
    });
  }

  // record modal (continued)
  const recordPlay = document.getElementById("record-play");
  if (recordPlay) {
    recordPlay.style.width = `${recordPlayIcon}px`;
  }

  const recordRecord = document.getElementById("record-record");
  if (recordRecord) {
    recordRecord.style.width = `${recordIcon}px`;
  }

  const recordConfirm = document.getElementById("record-confirm");
  if (recordConfirm) {
    recordConfirm.style.width = `${recordConfirmIcon}px`;
  }

  const recordPushStart = document.getElementById("record-push-to-start");
  const recordPushEnd = document.getElementById("record-push-to-end");
  if (recordPushStart && recordPushEnd) {
    recordPushStart.style.width = wp(20);
    recordPushEnd.style.width = wp(20);
  }

  const record_Discard = document.getElementById("record-discard");
  if (record_Discard) {
    record_Discard.style.width = `${recordDiscardIcon}px`;
  }

  const record_Retake = document.getElementById("record-retake");
  if (record_Retake) {
    record_Retake.style.width = `${recordRetakeIcon}px`;
  }

  // delete modal
  let actionButtonFontSize =
    ((18 / screenHeight) * 100 * window.innerHeight) / 100;
  let modalButtonWidth = ((160 / screenWidth) * 100 * window.innerWidth) / 100;
  let modalButtonPadding = ((5 / screenWidth) * 100 * window.innerWidth) / 100;

  const actionProjectButtons = document.querySelectorAll(
    ".action-project-button-modal",
  );
  if (actionProjectButtons) {
    actionProjectButtons.forEach((btn) => {
      btn.style.width = `${modalButtonWidth}px`;
      btn.style.padding = `${modalButtonPadding}px`;
      btn.style.fontSize = `${actionButtonFontSize}px`;
    });
  }

  const btnCancel = document.querySelector(".btn-cancel");
  if (btnCancel) {
    btnCancel.style.fontSize = `${actionButtonFontSize}px`;
  }

  const btnDelete = document.querySelector(".btn-delete");
  if (btnDelete) {
    btnDelete.style.fontSize = `${actionButtonFontSize}px`;
  }

  // user profile modal
  // let userProfileModalPadding = ((18 / screenWidth) * 100 * window.innerWidth) / 100;
  // let userProfileModalWidth = ((287 / screenWidth) * 100 * window.innerWidth) / 100;
  // let userProfileModalHeight = ((239 / screenHeight) * 100 * window.innerHeight) / 100;
  // let userProfileModalTop = ((78 / screenHeight) * 100 * window.innerHeight) / 100;
  // let userProfileModalRight = ((95 / screenHeight) * 100 * window.innerHeight) / 100;
  // let userProfileImage = ((62 / screenWidth) * 100 * window.innerWidth) / 100;
  // let userProfileDetailsTop = ((10.35 / screenHeight) * 100 * window.innerHeight) / 100;
  // let userProfileDetailsLeft = ((15.6 / screenWidth) * 100 * window.innerWidth) / 100;
  // let userProfileDetailsFont = ((19 / screenWidth) * 100 * window.innerWidth) / 100;
  // let userProfileDetailsFontHeight = ((30 / screenHeight) * 100 * window.innerHeight) / 100;
  // let userProfileDetailsFontAlt = ((14.5 / screenWidth) * 100 * window.innerWidth) / 100;
  // let userProfileDetailsFontDefault = ((15.8 / screenWidth) * 100 * window.innerWidth) / 100;

  // const userProfileModal = document.querySelector(".user-profile-modal");
  // if (userProfileModal) {
  //     userProfileModal.style.padding = `${userProfileModalPadding}px`;
  //     userProfileModal.style.width = `${userProfileModalWidth}px`;
  //     userProfileModal.style.height = `${userProfileModalHeight}px`;
  //     userProfileModal.style.top = `${userProfileModalTop}px`;
  //     userProfileModal.style.right = `${userProfileModalRight}px`;
  // }

  // const userProfileImageElem = document.getElementById("user-profile-image");
  // if (userProfileImageElem) {
  //     userProfileImageElem.style.width = `${userProfileImage}px`;
  // }

  // const userProfileDetails = document.querySelectorAll(".user-profile-details");
  // if (userProfileDetails) {
  //     userProfileDetails.forEach((detail) => {
  //         detail.style.paddingTop = `${userProfileDetailsTop}px`;
  //         detail.style.marginLeft = `${userProfileDetailsLeft}px`;
  //     });
  // }

  // const profileUsername = document.querySelector(".profile-username");
  // if (profileUsername) {
  //     profileUsername.style.fontSize = `${userProfileDetailsFontHeight}px`;
  // }

  // const profileEmail = document.querySelector(".profile-email");
  // if (profileEmail) {
  //     profileEmail.style.fontSize = `${userProfileDetailsFontAlt}px`;
  // }

  // const profileOption = document.querySelectorAll(".profile-option");
  // if (profileOption) {
  //     profileOption.forEach((option) => {
  //         option.style.fontSize = `${userProfileDetailsFontDefault}px`;
  //         option.style.marginLeft = `${userProfileDetailsLeft}px`;
  //         option.style.marginTop = `${userProfileDetailsTop}px`;
  //     });
  // }

  // const profileOptions = document.querySelector(".profile-options");
  // if (profileOptions) {
  //     profileOptions.style.marginTop = `${userProfileDetailsFontAlt}px`;
  // }

  // const userProfileSeparator = document.querySelectorAll(".user-profile-separator");
  // if (userProfileSeparator) {
  //     userProfileSeparator.forEach((separator) => {
  //         separator.style.marginTop = `${userProfileDetailsFontAlt}px`;
  //         separator.style.marginBottom = `${userProfileDetailsFontAlt}px`;
  //     });
  // }
}

export function tourGuideRes() {
  const fontSize = (18 * window.innerWidth) / screenWidth;
  const btnPaddingX = (20 * window.innerWidth) / screenWidth;
  const btnPaddingY = (10 * window.innerWidth) / screenWidth;
  const footerPaddingX = (10 * window.innerWidth) / screenWidth;
  const footerPaddingY = (20 * window.innerWidth) / screenWidth;
  const marginX = (20 * window.innerWidth) / screenWidth;
  const titleFontSize = (27 * window.innerWidth) / screenWidth;

  const shepherdButtons = document.querySelectorAll(".shepherd-button");
  if (shepherdButtons) {
    shepherdButtons.forEach((button) => {
      button.style.paddingTop = btnPaddingY + "px";
      button.style.paddingBottom = btnPaddingY + "px";
      button.style.paddingLeft = btnPaddingX + "px";
      button.style.paddingRight = btnPaddingX + "px";
      button.style.marginRight = marginX + "px";
    });
  }

  const shepherdElements = document.querySelectorAll(".shepherd-element");
  if (shepherdElements) {
    shepherdElements.forEach((element) => {
      element.style.fontSize = fontSize + "px";
    });
  }

  const shepherdFooter = document.querySelector(".shepherd-footer");
  if (shepherdFooter) {
    shepherdFooter.style.fontSize = fontSize + "px";
    shepherdFooter.style.marginBottom = "0";
    shepherdFooter.style.marginTop = "0";
    shepherdFooter.style.paddingTop = footerPaddingY + "px";
    shepherdFooter.style.paddingBottom = footerPaddingY + "px";
    shepherdFooter.style.paddingLeft = footerPaddingX + "px";
    shepherdFooter.style.paddingRight = footerPaddingX + "px";
  }

  const shepherdTitle = document.querySelector(".shepherd-title");
  if (shepherdTitle) {
    shepherdTitle.style.fontSize = titleFontSize + "px";
  }
}

export function toastRes() {
  let popUpMessageBorder = (7.5 / screenWidth) * window.innerWidth;
  let popUpMessageTop = (662.15 / screenHeight) * window.innerHeight;
  let popUpMessageLeft = (14.1 / screenWidth) * window.innerWidth;
  let popUpMessageHeight = (85 / screenWidth) * window.innerWidth;

  let columnProgressWidth = (75 / screenWidth) * window.innerWidth;
  let columnProgressMarginLeft = (5 / screenWidth) * window.innerWidth;
  let columnProgressMarginRight = (6 / screenWidth) * window.innerWidth;

  let circleSmallSize = (80 / screenWidth) * window.innerWidth;

  let columnMessageMinWidth = (282 / screenWidth) * window.innerWidth;
  let columnMessagePaddingRight = (28 / screenWidth) * window.innerWidth;

  let headerFontSize = (16 / screenWidth) * window.innerWidth;
  let subHeaderFontSize = (11.8 / screenWidth) * window.innerWidth;
  let subHeaderFontSizeTop = (3.8 / screenWidth) * window.innerWidth;

  let progressRadius = (21 / screenWidth) * window.innerWidth;

  let popIconWidth = (20.2 / screenWidth) * window.innerWidth;
  let popIconLeft = (29.7 / screenWidth) * window.innerWidth;
  let popIconTop = (30 / screenWidth) * window.innerWidth;

  let cx = (40 / screenWidth) * window.innerWidth;
  let cy = (40 / screenWidth) * window.innerWidth;

  let strokeWidth = (1.5 / screenWidth) * window.innerWidth;

  // Check for displayOn attribute and adjust positioning accordingly
  const popUpMessage = document.getElementById("pop-up-message");
  if (popUpMessage) {
    const displayOn = popUpMessage.getAttribute("displayOn");
    if (displayOn === "workspace") {
      popUpMessage.style.left = popUpMessageLeft + "px";
      popUpMessage.style.right = "unset";
      popUpMessageTop = (662.15 / screenHeight) * window.innerHeight;
    } else if (
      displayOn === "more-options" ||
      displayOn === "gen-options" ||
      displayOn === "workspace-transcript"
    ) {
      popUpMessage.style.right = popUpMessageLeft + "px";
      popUpMessage.style.left = "unset";
      popUpMessageTop = (680.15 / screenHeight) * window.innerHeight;
    }
  }

  // Apply styles to elements
  const popUpMessageElem = document.querySelector(".pop-up-message");
  if (popUpMessageElem) {
    popUpMessageElem.style.borderRadius = popUpMessageBorder + "px";
    popUpMessageElem.style.top = popUpMessageTop + "px";
    popUpMessageElem.style.height = popUpMessageHeight + "px";
  }

  const columnProgressElem = document.querySelector(".columnProgress");
  if (columnProgressElem) {
    columnProgressElem.style.width = columnProgressWidth + "px";
    columnProgressElem.style.height = columnProgressWidth + "px";
    columnProgressElem.style.marginLeft = columnProgressMarginLeft + "px";
    columnProgressElem.style.marginRight = columnProgressMarginRight + "px";
  }

  const columnMessageElem = document.querySelector(".columnMessage");
  if (columnMessageElem) {
    columnMessageElem.style.minWidth = columnMessageMinWidth + "px";
    columnMessageElem.style.paddingRight = columnMessagePaddingRight + "px";
  }

  const popUpMessageTitleElem = document.querySelector(".pop-up-message-title");
  if (popUpMessageTitleElem) {
    popUpMessageTitleElem.style.fontSize = headerFontSize + "px";
  }

  const popUpMessageSubTitleElem = document.querySelector(
    ".pop-up-message-sub-title",
  );
  if (popUpMessageSubTitleElem) {
    popUpMessageSubTitleElem.style.fontSize = subHeaderFontSize + "px";
    popUpMessageSubTitleElem.style.marginTop = subHeaderFontSizeTop + "px";
  }

  const circleSmallElem = document.querySelector(".circle-small");
  if (circleSmallElem) {
    circleSmallElem.style.height = circleSmallSize + "px";
    circleSmallElem.style.width = circleSmallSize + "px";
  }

  const circleSmallSvgElem = document.querySelector(".circle-small > svg");
  if (circleSmallSvgElem) {
    circleSmallSvgElem.style.height = circleSmallSize + "px";
    circleSmallSvgElem.style.width = circleSmallSize + "px";
  }

  const bgProgressElem = document.getElementById("bg-progress");
  const activeProgressElem = document.getElementById("active-progress");
  if (bgProgressElem && activeProgressElem) {
    bgProgressElem.setAttribute("r", progressRadius);
    activeProgressElem.setAttribute("r", progressRadius);
    bgProgressElem.setAttribute("cx", cx);
    activeProgressElem.setAttribute("cx", cx);
    bgProgressElem.setAttribute("cy", cy);
    activeProgressElem.setAttribute("cy", cy);
    bgProgressElem.setAttribute("stroke-width", strokeWidth);
    activeProgressElem.setAttribute("stroke-width", strokeWidth);
  }

  const popIconElem = document.getElementById("pop-icon");
  if (popIconElem) {
    popIconElem.style.width = popIconWidth + "px";
    popIconElem.style.left = popIconLeft + "px";
    popIconElem.style.top = popIconTop + "px";
  }
}

function forceHideMessage() {
  const popUpMessage = document.getElementById("pop-up-message");
  if (popUpMessage) {
    popUpMessage.style.display = "none";
  }
}

// window.addEventListener('resize', function () {
//     // waveformResize();
//     koolioRes();
//     playlistRes();
//     tourGuideRes();
//     koolioModals();
//     toastRes();
//     // if (isvantaactive) {
//         // destroyVanta();
//         // createVanta();
//     // }
// });
