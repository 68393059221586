import { createSlice } from "@reduxjs/toolkit";

const initialvaue = {
  wavsurferObj: {},
};
const wavesurferobjslice = createSlice({
  name: "wavesurferObj",
  initialState: initialvaue,
  reducers: {
    updateWavesurferObj(state, action) {
      state.wavsurferObj = action.payload.wavesurferObj;
    },
    clearWavesurferObj(state) {
      state.wavsurferObj = {};
    },
  },
});
export const wavesurferObjActions = wavesurferobjslice.actions;
export default wavesurferobjslice.reducer;
