/* eslint-env browser */

/* global AWS
 */

import { create_UUID, startApp } from "./utils";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { getNotifications } from "../services/NotificationServices";
import { getKooliospace } from "../services/KooliospaceServices";
import {
  showNewPassword,
  showError,
  resetNewPassword,
  forgotPasswordFailure,
  forgotPasswordSuccess,
} from "../components/pages/loginPage/loginUtils";
import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import topbar from "topbar";
import store from "../redux/Store";
import { setSignInUserAction } from "../redux/slice/SignInUserSlice";
export const Koolioai = window.Koolioai || {};
export const _config = {
  uam: "accounts.koolio.ai",
  api: "api.koolio.ai",
  response_type: "code",
  grant_type: "authorization_code",
  scope: "aws.cognito.signin.user.admin+email+openid+phone",
  wss: {
    development:
      "wss://lhi2b07nj1.execute-api.us-east-2.amazonaws.com/development",
    qa: "wss://v8z703cc0a.execute-api.us-east-2.amazonaws.com/qa",
    app: "wss://ejts43qo3b.execute-api.us-east-2.amazonaws.com/production",
  },
};

// eslint-disable-next-line no-var
let queryString = window.location.search;
// eslint-disable-next-line no-var
let urlParams = new URLSearchParams(queryString);
let jobname = urlParams.get("jobname");
let location = window.location;
export let ENV;

// eslint-disable-next-line camelcase

(function scopeWrapper() {
  const protocol = "https:";
  const root = protocol + "//" + "www.koolio.ai";
  ENV =
    location.host.includes("localhost") || location.host.includes("127.0.0.1")
      ? "development"
      : location.host.split(".")[0] === "production"
        ? "app"
        : location.host.split(".")[0];
  const UPools = {
    development: "us-east-2_e2fYbYD6n",
    qa: "us-east-2_aFVhRYQZS",
    app: "us-east-2_2hFTmQBac",
  };
  const UClients = {
    development: "2fmctevbp6rfn2eopptfgg0mum",
    qa: "71fpecuq3db2v79j6ck2b9fd42",
    app: "2uck12qofspo5sahaflbtsqouk",
  };
  const Identity = {
    development: "us-east-2:24f45f72-bb4b-4a31-919f-ab227e84ba99",
    qa: "us-east-2:88be8777-5064-459b-855b-b41a3c17ed01",
    app: "us-east-2:5f12cb39-0dcc-41c9-9516-29dae3629554",
  };
  const region = "us-east-2";
  const poolData = {
    UserPoolId: UPools[ENV],
    ClientId: UClients[ENV],
  };
  const Buckets = {
    Previews: "previews.sfxlib.koolio.ai",
    GenPreviews: "previews.sfxlib.koolio.ai/genai",
    Compressed: "compressed.sfxlib.koolio.ai",
    GenCompressed: "compressed.sfxlib.koolio.ai/genai",
    Upload: "app.koolio.ai",
    JobStatus: "jobstatus.app.koolio.ai",
    Clipboard: "clipboard.app.koolio.ai",
    Publish: "static.koolio.ai",
  };
  const IdentityEnpoint =
    "cognito-idp." + region + ".amazonaws.com/" + poolData.UserPoolId;

  if (!(poolData.UserPoolId && poolData.ClientId)) window.location = root;

  if (typeof AWS !== "undefined") {
    AWS.config.region = region;
  }

  if (ENV !== "app") {
    _config.uam = protocol + "//" + ENV + "." + _config.uam;
    _config.api = protocol + "//" + ENV + "." + _config.api;
    // _config.wss = wss_protocol + '//' + ENV + '.' + _config.wss
  } else {
    _config.uam = protocol + "//" + _config.uam;
    _config.api = protocol + "//" + _config.api;
    // _config.wss = wss_protocol + '//' + _config.wss
  }
  function authenticateUser() {
    Koolioai.isAuth()
      .then((isAuth) => {
        if (isAuth) {
          if (window.location.href.includes("subscription")) {
            localStorage.setItem("subscribeTo", window.location.href);
            window.location.href = window.location.href.split("?")[0];
          }
          Koolioai.accessToken().then((accessToken) => {
            const cognitoidentityserviceprovider =
              new AWS.CognitoIdentityServiceProvider();
            const params = {
              AccessToken: accessToken,
            };
            cognitoidentityserviceprovider.getUser(
              params,
              function (err, data) {
                if (!err) {
                  if (window.location.pathname === "/") {
                    let signInUser = data.Username;
                    store.dispatch(setSignInUserAction.setUser(signInUser));
                    continueSession();
                    return;
                  }
                  for (let i = 0; i < data.UserAttributes.length; i++) {
                    if (data.UserAttributes[i].Name === "email") {
                      Koolioai.email = data.UserAttributes[i].Value;
                      break;
                    }
                  }
                  Koolioai.username = data.Username;
                  // $(document).ready(function () {
                  getKooliospace().then(() => {
                    //             chooseUpload()
                    //             imageUpload()
                    //             bindUploadAudio()
                    //             console.log("startApp");
                    startApp();
                    // console.log("testing", Koolioai.getDisplayName());
                    localStorage.setItem(
                      "username",
                      JSON.stringify(Koolioai.getDisplayName()),
                    );
                    localStorage.setItem(
                      "AuthUserName",
                      JSON.stringify(Koolioai.username),
                    );

                    //             bindModalClose()
                    //             bindMacScrollOff()
                    //             bindImportTrack()
                    //             viewControls(false)

                    let subscribeTo = localStorage.getItem("subscribeTo");
                    // if(subscribeTo){
                    //     const subscribeToPlan =async(id, promoCode='')=>{
                    // await showPlanSubscriptionModal(true, promoCode)
                    // if(ACM.planName === "Free") document.querySelector(`[data-plan-id="${id}"]`).click()
                    //                     else {
                    //                         closeHalfModal()
                    //                         document.getElementById('blur-modals').style.visibility='hidden'
                    //                         document.getElementById('blur-landing-modals').style.visibility='hidden'
                    //                     }
                    //                 }
                    //                 const website_url = subscribeTo.split("subscription=").join(',').split("&promo_code=").join(',').split(',')
                    //                 const id = website_url[1]
                    //                 const promoCode = website_url[2]
                    //                 subscribeToPlan(id.split("&loaded")[0], promoCode)
                    //                 localStorage.removeItem("subscribeTo")
                    //             }
                    //             else{
                    //                 const indexedDBcleanedAt = localStorage.getItem("indexedDBcleanedAt")
                    //                 if(!indexedDBcleanedAt){
                    //                     localStorage.setItem("indexedDBcleanedAt", new Date())
                    //                 }
                    //                 else{
                    //                     let differenceInTimeStamp = new Date().getTime() -  new Date(indexedDBcleanedAt).getTime()
                    //                     let differenceInDays = Math.round(differenceInTimeStamp / (1000 * 3600 * 24))
                    //                     if(differenceInDays >= 7){
                    //                         deleteOldIndexDBdata (20)
                    //                     }
                    //                 }
                    //                 //document.getElementById('blur-modals').style.visibility='hidden'
                    //                 //document.getElementById('blur-landing-modals').style.visibility='hidden'
                    //             }
                    //             //2988 starts when user login into kooliospace
                    //             setTimeout(()=>{
                    //                 //fetch all notifications and then filter out update release notifications which contains release id
                    //                 getNotifications()
                    //                     .then(notifications=>{
                    //                         const filteredNotifications = notifications.notifications.filter(notification => {
                    //                             return (notification.notification_action.includes("release_id"))
                    //                         })
                    //                         // if we have multiple update notifications then we are going to show the first notifications only
                    //                         if(filteredNotifications.length > 0){
                    //                             if(!filteredNotifications[0].is_viewed){
                    //                                 //we will be sending an extra param as true and that will prevent the notificationAlreadyOpenedModal from displaying
                    //                                 viewNotification(event,filteredNotifications[0].notification_action, true)
                    //                             }
                    //                         }
                    //                     })
                    //             },5000)
                    //             //2988 ends here
                    //         }).then(()=>{
                    //             getPlanCancellationStatus()
                    //         })
                  });
                }
              },
            );
          });
        } else {
          localStorage.clear();
          if (window.location.href.includes("subscription")) {
            localStorage.setItem("subscribeTo", window.location.href);
          }
          // window.location.href = "profile.html";
        }
      })
      .catch((e) => {
        // console.log("testing", Koolioai.getDisplayName());
        localStorage.setItem(
          "username",
          JSON.stringify(Koolioai.getDisplayName()),
        );
        localStorage.setItem("AuthUserName", JSON.stringify(Koolioai.username));
        // console.error(e)
      });
  }

  function initState() {
    // cognito-identity.amazonaws.com
    Koolioai.signUp = function signUp(
      username,
      password,
      attributes,
      callback,
    ) {
      let attributeList = attributes.map(
        (attribute) =>
          new AmazonCognitoIdentity.CognitoUserAttribute(attribute),
      );
      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      userPool.signUp(username, password, attributeList, null, callback);
    };

    Koolioai.getSignedPreview = function (key, aiSFXMusic) {
      return new Promise(function (resolve, reject) {
        new AWS.S3().getSignedUrl(
          "getObject",
          {
            Bucket: aiSFXMusic ? Buckets.GenPreviews : Buckets.Previews,
            Key: aiSFXMusic ? key.split(".wav")[0] + ".mp3" : key,
            Expires: 86400,
          },
          function (err, data) {
            if (err) reject(err);
            else {
              resolve(data);
            }
          },
        );
      });
    };
    // INVITE ONLY
    //Koolioai.inviteOnly = function inviteOnly (email, reason) {
    //    topbar.show();
    //    (function step () {
    //        setTimeout(function () {
    //            if (topbar.progress('+.01') < 1) step()
    //        }, 30)
    //    })()
    //    axios.post(_config.api + '/request-invite', { email, reason })
    //        .then((response) => {
    //            if (response.status === 200) {
    //                if (response.data.statusCode === 400 && response.data.body.includes('EmailAlreadyExistException')) {
    //                    showError('Email already exists.', 'request-access')
    //                } else {
    //                    showError('Thank you! Please check your email for instructions.', 'request-access')
    //                }
    //                $('.request-access-button').removeClass('btn-active')
    //                document.getElementById('request-access-form').elements[1].disabled = false
    //                document.getElementById('request-access-form').reset()
    //                document.getElementById('request-access-reason').innerText = ''
    //            }
    //            topbar.hide()
    //        })
    //}

    Koolioai.signIn = function signIn(authenticationData, success, failure) {
      let authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      let userData = {
        Username: authenticationData.Username,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          // console.log(result);
          initState();
          success(result);
        },
        onFailure: failure,
      });
      // Koolioai.getDisplayName();
    };

    Koolioai.signUp = function signUp(
      username,
      password,
      attributes,
      callback,
    ) {
      let attributeList = attributes.map(
        (attribute) =>
          new AmazonCognitoIdentity.CognitoUserAttribute(attribute),
      );
      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      userPool.signUp(username, password, attributeList, null, callback);
    };

    // Here I work for profile user credentials

    Koolioai.signInViaToken = function signInViaAuthCode(tokens) {
      function parseJwt(token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
        );

        return JSON.parse(jsonPayload);
      }
      const userName = parseJwt(tokens.id_token)["cognito:username"];
      // const userName = parseJwt(tokens.id_token)["cognito:username"]
      //console.log(tokens);
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const userData = {
        Username: userName,
        Pool: userPool,
      };
      //console.log(userData);
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      //console.log("cognitoUser", cognitoUser);

      // Koolioai.username = tokens.userInfo.name;
      // Koolioai.email = tokens.userInfo.email;
      // localStorage.setItem("token", tokens.access_token);
      // sessionStorage.setItem("token", tokens.access_token);
      const sessionData = {
        AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
          AccessToken: tokens.access_token,
        }),
        RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({
          RefreshToken: tokens.refresh_token,
        }),
        IdToken: new AmazonCognitoIdentity.CognitoIdToken({
          IdToken: tokens.id_token,
        }),
      };

      const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);
      cognitoUser.setSignInUserSession(session);
      return [cognitoUser, userName];
    };

    Koolioai.trigger = function trigger() {
      initState();
      authenticateUser();
    };

    // Koolioai.signInViaToken = function signInViaAuthCode(tokens) {
    //   // const userName = parseJwt(tokens.id_token)["cognito:username"];
    //   const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    //   const userData = {
    //     Username: tokens,
    //     Pool: userPool,
    //   };

    //   const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    //   const sessionData = {
    //     AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
    //       AccessToken: tokens.access_token,
    //     }),
    //     RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({
    //       RefreshToken: tokens.refresh_token,
    //     }),
    //     IdToken: new AmazonCognitoIdentity.CognitoIdToken({
    //       IdToken: tokens.id_token,
    //     }),
    //   };

    //   const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);
    //   cognitoUser.setSignInUserSession(session);
    // };

    Koolioai.resendVerification = function (username, success, failure) {
      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      let userData = {
        Username: username,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          return failure(err);
        }
        success(result);
      });
    };

    Koolioai.forgotPassword = function forgotPassword(
      username,
      success,
      failure,
    ) {
      let callback = function (err, result) {
        if (err) {
          switch (err.code) {
            case "UsernameExistsException": {
              const userPool = new AmazonCognitoIdentity.CognitoUserPool(
                poolData,
              );
              const userData = {
                Username: username,
                Pool: userPool,
              };
              // console.log("userData", userData)
              const cognitoUser = new AmazonCognitoIdentity.CognitoUser(
                userData,
              );
              cognitoUser.forgotPassword({
                onSuccess: success,
                onFailure: failure,
                inputVerificationCode() {
                  topbar.hide();
                  document
                    .getElementById("forgot-button")
                    .removeAttribute("disabled");
                  //  $('.forgot-button').removeClass('btn-active')
                  document
                    .getElementById("forgot-button")
                    .classList.remove("btn-active");
                  showNewPassword();
                },
              });
              return;
            }
            case "UserLambdaValidationException": {
              showError("koolio account doesn't exist", "login");
              topbar.hide();
              document
                .getElementById("forgot-button")
                .removeAttribute("disabled");
              //$('.forgot-button').removeClass('btn-active')
              document
                .getElementById("forgot-button")
                .classList.remove("btn-active");

              return;
            }
            case "InvalidParameterException": {
              showError(
                "Invalid username! Please enter a valid username",
                "login",
              );
              topbar.hide();
              document
                .getElementById("forgot-username-button")
                .removeAttribute("disabled");
              // $('#forgot-username-button').removeClass('btn-active')
              document
                .getElementById("forgot-username-button")
                .classList.remove("btn-active");
              document
                .getElementById("forgot-button")
                .removeAttribute("disabled");
              //$('.forgot-button').removeClass('btn-active')
              document
                .getElementById("forgot-button")
                .classList.remove("btn-active");
              return;
            }
            default: {
              showError("Oops something went wrong, try again", "login");
              topbar.hide();
              document
                .getElementById("forgot-button")
                .removeAttribute("disabled");
              // $('.forgot-button').removeClass('btn-active')
              document
                .getElementById("forgot-button")
                .classList.remove("btn-active");
            }
          }
        }
        // if (!result.userConfirmed) {
        //   // console.log('success', result)
        //   //    topbar.hide()
        //   document.getElementById("forgot-button").removeAttribute("disabled");
        //   //    $('.forgot-button').removeClass('btn-active')
        //   document
        //     .getElementById("forgot-button")
        //     .classList.remove("btn-active");
        //   failure();
        // }
      };
      Koolioai.signUp(
        username,
        "Verification1@koolio.ai",
        [{ Name: "email", Value: "username.verification@koolio.ai" }],
        callback,
      );
    };

    Koolioai.forgotUsername = function forgotUsername(email) {
      //console.log(email);
      let callback = function (err, result) {
        if (err) {
          // console.warn("error occured", err)
          switch (err.code) {
            case "UsernameExistsException": {
              topbar.hide();
              document
                .getElementById("forgot-username-button")
                .removeAttribute("disabled");
              // $("#forgot-username-button").removeClass("btn-active");
              document
                .getElementById("forgot-username-button")
                .classList.remove("btn-active");

              let seconds = 7;
              //redirectingSeconds =
              const intervalid = setInterval(function () {
                document.getElementById("login-message").innerHTML =
                  "Username has been sent to the corresponding email. Redirecting to login page " +
                  seconds +
                  " seconds";
                seconds--;
                if (seconds < 0) {
                  clearInterval(intervalid);
                  resetNewPassword();
                }
              }, 1000);
              return;
            }
            case "UserLambdaValidationException": {
              showError("koolio account doesn't exist", "login");
              topbar.hide();
              document
                .getElementById("forgot-username-button")
                .removeAttribute("disabled");
              // $("#forgot-username-button").removeClass("btn-active");
              document
                .getElementById("forgot-username-button")
                .classList.remove("btn-active");
              return;
            }
            case "InvalidParameterException": {
              showError(
                "Invalid email! Please enter a valid email address",
                "login",
              );
              topbar.hide();
              document
                .getElementById("forgot-username-button")
                .removeAttribute("disabled");

              // $("#forgot-username-button").removeClass("btn-active");
              document
                .getElementById("forgot-username-button")
                .classList.remove("btn-active");
              return;
            }
            default: {
              showError("Oops something went wrong, try again", "login");
              topbar.hide();
              document
                .getElementById("forgot-username-button")
                .removeAttribute("disabled");
              // $("#forgot-username-button").removeClass("btn-active");
              document
                .getElementById("forgot-username-button")
                .classList.remove("btn-active");
            }
          }
        }
      };
      Koolioai.signUp(
        "forgot.username",
        "Verification1@koolio.ai",
        [{ Name: "email", Value: email }],
        callback,
      );
    };

    Koolioai.confirmForgetPassword = function confirmForgetPassword(
      username = null,
    ) {
      username =
        username || document.getElementById("username-forgot-password").value;
      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      let userData = {
        Username: username,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      let verificationCode = document.getElementById(
        "verification-code-forgot-password",
      ).value;
      let newPassword = document.getElementById(
        "new-pass-forgot-password",
      ).value;

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(_error) {
          forgotPasswordFailure();
          //  Koolioai.confirmForgetPassword(username);
        },
        onSuccess(result) {
          forgotPasswordSuccess();
        },
      });
    };
    Koolioai.isAuth = function isAuth() {
      return new Promise((resolve) => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
          cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
              resolve(false);
            } else if (session.isValid()) {
              // Koolioai.lastrefreshed = new Date();
              AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: Identity[ENV],
                Logins: {
                  [IdentityEnpoint]: session.getIdToken().getJwtToken(),
                },
              });
              Koolioai.getSignedPreview = function (key, aiSFXMusic) {
                if (aiSFXMusic) {
                  if (key.split(".wav").length > 1) {
                    key = key.split(".wav")[0] + ".mp3";
                  } else if (key.split(".mp3").length > 1) {
                    key = key.split(".mp3")[0] + ".mp3";
                  }
                }
                return new Promise(function (resolve, reject) {
                  new AWS.S3().getSignedUrl(
                    "getObject",
                    {
                      Bucket: aiSFXMusic
                        ? Buckets.GenPreviews
                        : Buckets.Previews,
                      Key: key,
                      Expires: 86400,
                    },
                    function (err, data) {
                      if (err) reject(err);
                      else {
                        resolve(data);
                      }
                    },
                  );
                });
              };
              Koolioai.getSignedCompressed = function (key, aiSFXMusic) {
                if (aiSFXMusic) {
                  if (key.split(".wav").length > 1) {
                    key = key.split(".wav")[0] + ".mp3";
                  } else if (key.split(".mp3").length > 1) {
                    key = key.split(".mp3")[0] + ".mp3";
                  }
                }
                return new Promise(function (resolve, reject) {
                  new AWS.S3().getSignedUrl(
                    "getObject",
                    {
                      Bucket: aiSFXMusic
                        ? Buckets.GenCompressed
                        : Buckets.Compressed,
                      Key: key,
                    },
                    function (err, data) {
                      if (err) reject(err);
                      else resolve(data);
                    },
                  );
                });
              };

              Koolioai.deleteFile = function (fileName) {
                const params = {
                  Bucket: Buckets.Publish + "/headlinerAPI",
                  Key: fileName,
                };
                return new AWS.S3().deleteObject(params, function (err, data) {
                  if (err) {
                    // console.log(err, err.stack) // an error occurred
                  } else {
                    // console.log(data) // successful response
                  }
                });
              };

              Koolioai.uploadObject = function (
                body,
                key,
                uploadedProject = false,
              ) {
                /* fix for #2791 where we are calculating the actual progress progress, start*/
                if (uploadedProject) {
                  return new AWS.S3()
                    .upload(
                      {
                        Bucket: Buckets.Upload,
                        Key: "uploads/" + key,
                        Body: body,
                      },
                      function (err, data) {
                        if (err) {
                          // console.log('There was an error uploading your file: ', err);
                          return false;
                        }
                        // console.log('Successfully uploaded file.', data);
                        return true;
                      },
                    )
                    .on("httpUploadProgress", function (progress) {
                      //! what does means .on
                      if (uploadedProject.recordings) {
                        const progressBar = document.getElementById(
                          uploadedProject.id + "_innerProgress",
                        );
                        const progressBarText = document.getElementById(
                          uploadedProject.id + "_innerProgressText",
                        );

                        uploadedProject.progress = 0;

                        let progressPerTrack = Math.floor(
                          100 / uploadedProject.tracks.length,
                        );
                        uploadedProject.tracks.map((track) => {
                          if (track.isUploaded) {
                            uploadedProject.progress += progressPerTrack;
                          }
                        });

                        let trackProgressPercentage = Math.round(
                          (progress.loaded / progress.total) * 100,
                        );

                        if (trackProgressPercentage === 100) {
                          uploadedProject.tracks.find((track) => {
                            if (
                              track.id === key.split(".")[0] &&
                              !track.isUploaded
                            ) {
                              track.isUploaded = true;
                              uploadedProject.progress =
                                uploadedProject.progress + progressPerTrack;
                              progressBar.style.width =
                                uploadedProject.progress + "%";
                              progressBarText.textContent =
                                uploadedProject.progress;
                            }
                          });
                        }
                        let progressPercentage = uploadedProject.progress;
                        uploadedProject.tracks.find((track) => {
                          if (
                            track.id ===
                              progress.key
                                .split("uploads/")[1]
                                .split(".wav")[0] &&
                            !track.isUploaded
                          ) {
                            progressBar.style.width =
                              progressPercentage +
                              Math.round(
                                trackProgressPercentage /
                                  uploadedProject.tracks.length,
                              ) +
                              "%";
                            progressBarText.textContent =
                              progressPercentage +
                              Math.round(
                                trackProgressPercentage /
                                  uploadedProject.tracks.length,
                              );
                            uploadedProject.progress =
                              progressPercentage +
                              Math.round(
                                trackProgressPercentage /
                                  uploadedProject.tracks.length,
                              );
                          }
                        });
                        if (
                          uploadedProject.isRecordCompleted &&
                          progressPercentage === 100
                        ) {
                          let infoText = document.querySelector(
                            "[id='" +
                              uploadedProject.id +
                              "_progressBarOuter'].progress p",
                          ).lastChild;
                          progressBar.style.width = 0 + "%";
                          progressBarText.textContent = "";
                          infoText.textContent = "Analyzing";
                          uploadedProject.status = "Uploaded";
                        }
                      } else {
                        if (uploadedProject != "completed") {
                          const progressBar = document.getElementById(
                            uploadedProject.id + "_innerProgress",
                          );
                          const progressBarText = document.getElementById(
                            uploadedProject.id + "_innerProgressText",
                          );

                          let progressPercentage = Math.round(
                            (progress.loaded / progress.total) * 100,
                          );
                          progressBar.style.width = progressPercentage + "%";
                          progressBarText.textContent = progressPercentage;
                          uploadedProject.progress = progressPercentage;
                          if (progressPercentage === 100) {
                            // clearInterval(interval);
                            //2915 issue fix start, changing the text content from 100 percent upload to 0% transcription, for smooth transition
                            let infoText = document.querySelector(
                              "[id='" +
                                uploadedProject.id +
                                "_progressBarOuter'].progress p",
                            ).lastChild;
                            progressBar.style.width = 0 + "%";
                            progressBarText.textContent = "";
                            infoText.textContent = "Analyzing";
                            //2915 issue fix, end
                            uploadedProject.status = "Uploaded";
                          }
                        }
                      }
                    })
                    .promise();
                } else {
                  /* fix for #2791 completed */
                  return new AWS.S3()
                    .upload({
                      Bucket: Buckets.Upload,
                      Key: "uploads/" + key,
                      Body: body,
                    })
                    .promise();
                }
              };

              Koolioai.uploadResultStat = function (body, key) {
                return new AWS.S3()
                  .upload({
                    Bucket: Buckets.JobStatus,
                    Key: key,
                    Body: body,
                  })
                  .promise();
              };
              Koolioai.getJobStatusObject = function (key) {
                return new Promise(function (resolve, reject) {
                  new AWS.S3().getSignedUrl(
                    "getObject",
                    {
                      Bucket: Buckets.JobStatus,
                      Key: "000417f7-d86a-4042-aa17-81f68e9a6576/452dfa13-5dc6-433d-867d-91680196450b.json",
                    },
                    function (err, url) {
                      if (err) reject(err);
                      else resolve(url);
                    },
                  );
                });
              };
              Koolioai.getSignedObject = function (key) {
                return new Promise(function (resolve, reject) {
                  new AWS.S3().getSignedUrl(
                    "getObject",
                    { Bucket: Buckets.Upload, Key: key },
                    function (err, url) {
                      if (err) reject(err);
                      else resolve(url);
                    },
                  );
                });
              };
              Koolioai.uploadClipboard = function (body, key) {
                return new AWS.S3()
                  .upload({
                    Bucket: Buckets.Clipboard,
                    Key: key,
                    Body: body,
                  })
                  .promise();
              };
              Koolioai.getSignedClipboard = function (key) {
                return new Promise(function (resolve, reject) {
                  new AWS.S3().getSignedUrl(
                    "getObject",
                    { Bucket: Buckets.Clipboard, Key: key },
                    function (err, url) {
                      if (err) reject(err);
                      else resolve(url);
                    },
                  );
                });
              };
              Koolioai.listUsers = function () {
                return new Promise(function (resolve, reject) {
                  new AWS.CognitoIdentityServiceProvider().listUsers(
                    {
                      UserPoolId: poolData.UserPoolId,
                      AttributesToGet: ["email"],
                      Filter: 'cognito:user_status = "CONFIRMED"',
                    },
                    function (err, users) {
                      if (err) reject(err);
                      else
                        resolve(
                          users.Users.filter(
                            (u) => u.Username !== Koolioai.username,
                          ),
                        );
                    },
                  );
                });
              };
              resolve(session.isValid());
            } else {
              resolve(session.isValid());
            }
          });
        } else {
          resolve(false);
        }
      });
    };

    Koolioai.signOut = function () {
      // console.log('function click')
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        currentUser.signOut();
        localStorage.removeItem("activeJobs");
        initState();
        window.location.href = "/";
        // TODO: Reload or any other post-logout action
      }
    };

    Koolioai.authToken = function authToken() {
      return new Promise(function fetchCurrentAuthToken(resolve, reject) {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
          cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
              reject(err);
            } else if (!session.isValid()) {
              reject(new Error("Unauthorized"));
            } else {
              resolve(session.getIdToken().getJwtToken());
            }
          });
        } else {
          reject(new Error("Unauthorized"));
        }
      });
    };

    Koolioai.accessToken = function () {
      return new Promise(function fetchCurrentAuthToken(resolve, reject) {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();
        // console.log(cognitoUser);
        if (cognitoUser) {
          // let username = storeUserName(cognitoUser.username);
          cognitoUser.getSession(function sessionCallback(err, session) {
            // console.log(session);
            if (err) {
              // console.log(err);
              reject(err);
            } else if (!session.isValid()) {
              // console.log(session);
              reject(new Error("Unauthorized"));
            } else {
              resolve(session.getAccessToken().getJwtToken());
            }
          });
        } else {
          reject(new Error("Unauthorized"));
        }
      });
    };

    Koolioai.handleError = function handleError(err) {
      return new Promise((resolve) => {
        if (
          (err.response && err.response.data && err.response.data.Error) ||
          err.Error
        ) {
          let errorStatus = err.Error ? err.Error : err.response.data.Error;
          let errorMessage = err.Message
            ? err.Message
            : err.response.data.Message;

          //clearWorkspace()
          document.getElementById("error-status").innerHTML = errorStatus; //err.response.data.Error
          document.getElementById("error-message").innerHTML = errorMessage; //err.response.data.Message
          document.getElementById("annotmodal").style.display = "block";
          document.getElementById("modal_9").style.display = "block";
        } else if (
          (err.message && err.message === "Unauthorized") ||
          (err.status && err.status === 401) ||
          (err.response && err.response.status && err.response.status === 401)
        ) {
          // authenticateUser()
          // TODO: refresh token flow
          //window.location.href = '/'
        } else {
          // console.error(err)
        }
        resolve();
      });
    };

    Koolioai.createJob = function createJob(params) {
      // new structure
      // document.getElementById('site-header').style.display = 'none'
      // document.getElementById('projectname').innerHTML = ''
      return {
        //jobname : params.jobname || create_UUID(),
        project_title:
          params.filename.split(".").slice(0, -1).join(".") || params.filename,
        duration: params.duration,
        status: "Uploading",
        username: params.username,
        created_at: new Date().toString(),
        updated_at: new Date(),
        format: params.format || params.filename.split(".").pop(),
        modes: ["view", "edit"],
        is_owner: true,
        diarization_require: params.diarization,
      };
    };

    Koolioai.isLive = false;

    Koolioai.goLive = function goLive(onOpen, onError, onClose, onMessage) {
      return Koolioai.accessToken().then((accessToken) => {
        Koolioai.wss = new WebSocket(
          _config.wss[ENV] + "?accesstoken=" + accessToken,
        );
        //console.log(Koolioai.wss);
        Koolioai.wss.onopen = onOpen;
        Koolioai.wss.onerror = onError;
        Koolioai.wss.onclose = onClose;
        Koolioai.wss.onmessage = onMessage;
        return Promise.resolve();
      });
    };

    Koolioai.goOffline = function goOffline() {
      if (Koolioai.wss) Koolioai.wss.close();
    };

    Koolioai.getDisplayName = function getDisplayName() {
      if (Koolioai.username) {
        if (Koolioai.username.startsWith("google")) {
          return Koolioai.email.split("@")[0];
        } else
          return (
            Koolioai.username.charAt(0).toUpperCase() +
            Koolioai.username.slice(1).toLowerCase()
          );
      }
    };
  }
  /*
   * Cognito User Pool functions
   */
  initState();
  authenticateUser();
})();

export const logout = async () => {
  // console.log('logout')
  // sendLog({
  // activity: 'click',
  // target: 'logout',
  // timeStamp: new Date().toISOString(),
  // jobname,
  // status: 'success',
  // statusMsg: 'success'
  // })
  localStorage.removeItem("kooliospace");
  localStorage.removeItem("subscription_status");
  localStorage.removeItem("username");
  localStorage.removeItem("AuthUserName");
  localStorage.removeItem("planName");
  sessionStorage.removeItem("aiShowNotes");
  sessionStorage.removeItem("prevstate");
  sessionStorage.removeItem("locationObjCopy");
  document.cookie.split(";").forEach(function (c) {
    document.cookie =
      c.trim().split("=")[0] +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  });

  Koolioai.signOut();

  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
  }
};

//Keeping it for future to revoke google token during logout

// export const googleSignOut = async () => {
//   const idToken = sessionStorage.getItem("googleIdToken");
//   sessionStorage.removeItem("googleIdToken");

//   // Call this function during logout
//   if (idToken) {
//     await revokeToken(idToken);
//   }
// };
// const revokeToken = async (token) => {
//   try {
//     const response = await axios.post(
//       "https://oauth2.googleapis.com/revoke",
//       new URLSearchParams({ token }).toString(),
//       {
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//       },
//     );

//     if (response.status === 200) {
//       console.log("Token revoked successfully");
//     } else {
//       console.error(
//         "Failed to revoke token",
//         response.status,
//         response.statusText,
//       );
//     }
//   } catch (error) {
//     console.error("Error revoking token:", error);
//   }
// };

function continueSession() {
  window.location.href = "/kooliospace";
}

//Poll refresh tokens

export async function pollRefreshToken() {
  const isAuth = await Koolioai.isAuth();
  if (isAuth) {
    setTimeout(pollRefreshToken, 15 * 60 * 1000);
    return;
  }
  return Koolioai.handleError(new Error("Unauthorized"));
}
