import { createSlice } from "@reduxjs/toolkit";
const initialProjectData = {
  operationCountHandler: undefined,
  totalOperation: 0,
};

const OperationCountHandlerSlice = createSlice({
  name: "operationCount",
  initialState: initialProjectData,
  reducers: {
    updateOperationsHandler(state, action) {
      state.operationCountHandler = action.payload.operationCountHandler;
    },
    updateTotalOperation(state, action) {
      state.operationCountHandler = action.payload.totalOperation;
    },
    clearOperationCountHandlerAndTotalOperation(state) {
      state.resultStat = undefined;
    },
  },
});

export const OperationsCountHandlerSliceActions =
  OperationCountHandlerSlice.actions;
export default OperationCountHandlerSlice.reducer;
