import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import {view-password} from "../../../image/workspace/active-icons/view-password.png";
import {
  forgotPassword,
  forgotUsername,
  newPassword,
  resendOTP,
  resetForgotPassword,
  resetNewPassword,
  showForgotPassword,
  showForgotUsername,
  signInToCognitoUsingGoogle,
  checkUncheck,
  signInViaAuthCode,
  authorizeWithCognito,
  authUrl,
  redirectURL,
  poolData,
  subscribePlan,
  showLoader,
  loginAuth,
  showError,
  location,
  mediaQueriess,
  signupAuth,
} from "./loginUtils";
import "../../../css/dark/loginpage.css";
import "../../../css/dark/LandingPage.css";
import logo from "../../../image/landing-page/Logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import TermsandConditionModal from "../../modals/TermsandConditionModal";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin, googleLogout, GoogleLogin } from "@react-oauth/google";
import { Koolioai, _config } from "../../../utils/cognitoAuth";
import axios from "axios";
import {
  handleWindowLoadForKoolioTagline,
  handleWindowLoadForLogin,
  showStatText,
} from "../../../utils/utils";
import BlurModal from "../../modals/BlurModal";
import { showplaylistloader } from "../kooliospace/kooliospaceUtils";
import { useDispatch } from "react-redux";
import {
  setSignInUserAction,
  setUser,
} from "../../../redux/slice/SignInUserSlice";
import { showMessage } from "../../../utils/koolio-workspace";
import { authRes } from "../../../utils/Res";
import anime from "animejs";

// const authUrl = "https://development.accounts.koolio.ai";
// const poolData = {
//   UserPoolId: "us-east-2_e2fYbYD6n",
//   ClientId: "2fmctevbp6rfn2eopptfgg0mum",
// };
// const redirectURL = "http://localhost:3000";

const LoginPage = () => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.href.includes("?code=")) {
      let logo = document.getElementById("koolio-ai-logo");
      if (logo) logo.parentNode.removeChild(logo);
      const url = new URL(window.location.href);
      const searchParams = url.searchParams;
      const code = searchParams.get("code");
      const error = searchParams.get("error_description");

      function signInToCognito(code, error) {
        return signInToCognitoUsingGoogle(code, error);
      }
      signInToCognito(code, error);
    } else {
      handleWindowLoadForKoolioTagline(true);
    }
  }, []);

  async function signInToCognitoUsingGoogle(code, error, Navigate) {
    if (code) {
      const body = new URLSearchParams({
        grant_type: "authorization_code",
        client_id: poolData.ClientId,
        code: code,
        redirect_uri: window.location.origin + "/",
      }).toString();

      await axios
        .post(_config.uam + "/oauth2/token", body, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          // console.log(response);
          let [...usernameCredentials] = Koolioai.signInViaToken(response.data);
          let signInUser = usernameCredentials[1];
          dispatch(setSignInUserAction.setUser(signInUser));
        })
        .catch((error) => {});
      // reload page
      window.location.href = window.location.origin + "/kooliospace";
    }
    if (error) {
      error = error.toLowerCase().trim();
      setTimeout(() => {
        switch (error) {
          case "user is not confirmed.":
            showError(
              "Kindly verify your email address.<br>Check your mail for verification link.",
              "login",
            );
            break;
          default:
            showError("Oops something went wrong, try again", "login");
            break;
        }
      }, 500);
    }
  }

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const sessionId = searchParams.get("session_id");
      const status = searchParams.get("status");
      // console.log(sessionId, status);

      if (status === "cancelled") {
        showMessage("Payment cancelled. Please try again.");
        navigate("/kooliospace");
        return;
      }

      if (!sessionId) return;
      else {
        showplaylistloader();
        setTimeout(() => {
          navigate("/kooliospace");
        }, 2000);
      }

      const params = {
        session_id: sessionId,
        payment_status: status,
        username: Koolioai.username,
      };
      // console.log(params);

      try {
        const tokens = await Promise.all([
          Koolioai.authToken(),
          Koolioai.accessToken(),
        ]);

        const response = await axios.post(
          _config.api + "/subscription-status-confirmation",
          params,
          {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          },
        );

        const subscriptionStatus = response.data;
        // console.log(subscriptionStatus);

        if (subscriptionStatus.status === "succeeded") {
          // console.log("success");
          showStatText("Payment succeeded!");
          subscribePlan({}, true);
        } else {
          // Handle other statuses or errors
          // console.log("Payment not succeeded");
        }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  async function signInToGoogleViaHostedUI(googleToken) {
    const url = new URL(_config.uam + "/oauth2/authorize");
    // console.log(url);
    url.searchParams.set("redirect_uri", redirectURL + "/");
    url.searchParams.set("response_type", "code");
    url.searchParams.set("client_id", poolData.ClientId);
    url.searchParams.set("state", crypto.randomUUID());
    //url.searchParams.set('scope', 'openid email phone profile')
    url.searchParams.set("identity_provider", "Google");
    url.searchParams.set("prompt", "select_account"); // cognito doesn't forward this param to Google

    const strWindowFeatures =
      "toolbar=no, menubar=no, popup=true, width=500, height=550, top=50, left=250";
    window.location.href = url.href; //URL changed for handling cross origin issue in popup

    //previous code commented to handle cross origing issue
    //const popup = window.open(url.href, 'popup', strWindowFeatures)
    //const checkPopup = setInterval(() => {
    //    try {
    //        //const code = window.location.search.split("?code=")[1].split("&state")[0]
    //        //signInToCognitoUsingGoogle(code, error)
    //        //if (popup.window.location.href.startsWith(window.location.origin)) {
    //        //    popup.close()
    //        //    const url = new URL(popup.window.location.href)
    //        //    const searchParams = url.searchParams
    //        //    const code = searchParams.get('code')
    //        //    const error = searchParams.get('error_description')
    //        //    window.focus()
    //        //    signInToCognitoUsingGoogle(code, error)
    //        //}
    //        //if (!popup || !popup.closed)
    //        //    return
    //        clearInterval(checkPopup)
    //    }
    //    catch {
    //        //nothing to do here
    //    }
    //}, 900)
    //previous code commented to handle cross origing issue
  }

  function handleSignup(e) {
    e.preventDefault();

    Promise.resolve(signupAuth(e))
      .then((ConfirmationMessageSignUP) => {
        if (ConfirmationMessageSignUP) {
          setIsLoginForm(!isLoginForm);
          setTimeout(() => {
            showError(ConfirmationMessageSignUP, "login");
          }, 100);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleLogin(e) {
    e.preventDefault();
    // console.log("hii", _config, Koolioai);
    const form = document.getElementById("login-form");

    if (form.checkValidity()) {
      if (username.match(/\s/g)) {
        showError("No spaces are allowed in the username", "login");
      } else {
        loginAuth(e)
          .then((LoginInfo) => {
            const signInUser = LoginInfo.user.Username;
            // console.log(signInUser);
            if (LoginInfo.success) {
              dispatch(setSignInUserAction.setUser(signInUser));
              window.location.href = window.location.origin + "/kooliospace";
            }
          })
          .catch((LoginInfo) => {
            // console.log(LoginInfo);
          });
      }
    } else {
      // showError("Username or Password cannot be empty", "login");
      form.reportValidity();
    }
  }

  function handleUsername(event) {
    event.preventDefault();
    setUsername(event.target.value);
  }
  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm);
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const recaptchaRef = useRef();

  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();
  };

  const handleTermsAndCondition = () => {
    setShowTermsModal(!showTermsModal); // Toggle visibility
  };

  return (
    <>
      {console.log(isLoginForm)}
      <div id="landing-screen" className="container">
        {/* Logo  Partt*/}

        <div id="koolio-login-logo" className="koolio-landing row d-none">
          <div className="d-flex flex-column flex-sm-row">
            <img
              src="../../../image/landing-page/koolio.ai.png"
              id="koolio-login-logo"
              className="col-12 col-sm-7"
              alt="koolio.ai"
            />
            <div className="col-sm-5"></div>
          </div>
        </div>
        <div
          id="landing-taglines-profile"
          class="landing-taglines d-flex justify-content-center"
        ></div>
        {/* Login Part */}

        <div className="row koolio-space " id="koolio-space">
          {isLoginForm ? (
            <div className="" id="auth-login">
              <div className="auth-screen d-flex flex-column flex-sm-row">
                <div
                  id="form"
                  className="col-12 col-sm-7 justify-content-start align-items-center"
                >
                  <button
                    className="btn-padding google-button line-height-input text-large"
                    onClick={signInToGoogleViaHostedUI}
                    // onClick={() => {
                    //   setClickedGoogleBtn(true);
                    //   signInToGoogleViaHostedUI();
                    // }}
                  >
                    <div className="d-flex align-items-center " id="buttonDiv">
                      <div className="col-3 text-start ">
                        <img
                          src="./img/login/google-logo.png"
                          className=""
                          // style={{ width: "40%", marginLeft: "19%" }}
                          alt=""
                        />
                      </div>
                      <div
                        className="col-6 text-center
                    "
                      >
                        Log in With Google
                      </div>
                      <div className="col-3"></div>
                    </div>
                  </button>

                  <div className="d-flex justify-content-center line-height-input">
                    <div className="d-flex justify-content-center align-items-center ggl-or mt-2">
                      <hr
                        style={{
                          width: "28%",
                          color: "#c4c4c4",
                          opacity: "0.5 ",
                        }}
                      />
                      <div
                        className="mx-1"
                        id="ggl-or-msg"
                        style={{ color: "#c4c4c4", fontSize: "1vw" }}
                      >
                        Or
                      </div>
                      <hr
                        style={{
                          width: "28%",
                          color: "#c4c4c4",
                          opacity: "0.5 ",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    id="login-message"
                    className="line-height-input err-message "
                    style={{ color: "#8A2BE2" }}
                  ></div>
                  <div className="line-height-input"></div>

                  <form
                    id="login-form"
                    className="auth-input"
                    autocomplete="off"
                    // onsubmit={validateCaptcha}
                    style={{ margin: "auto" }}
                  >
                    <input
                      type="text"
                      className="inputStyle text-large"
                      placeholder="User name or Email"
                      onChange={handleUsername}
                      required
                    />{" "}
                    <br />
                    <div>
                      <input
                        id="lfpwd"
                        className="line-height-input inputStyle text-large"
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Password"
                        required
                      />
                      <span
                        id="view-password-cont"
                        className="password-hidden"
                        onClick={togglePasswordVisibility}
                      >
                        {isPasswordVisible ? (
                          <img src="../../../image/workspace/active-icons/view-password.png" />
                        ) : (
                          <img src="../../../image/workspace/controls/view-password.png" />
                        )}
                      </span>
                    </div>
                    <a
                      style={{ display: "block" }}
                      className="line-height-input forgot-password text-medium"
                      id="forgot-password"
                      onClick={showForgotPassword}
                    >
                      Forgot username or password?
                    </a>
                    {/* <!-- recaptcha --> */}
                    <form>
                      <button
                        id="logInButton"
                        className="line-height-input btn-padding auth-button text-large login-button"
                        type="submit"
                        onClick={handleLogin}
                      >
                        Log in
                      </button>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfaJLYlAAAAAHlr17xw3x_L4r3rc5GH3-07jEYp"
                      />
                    </form>
                    {/* <!-- recaptcha -->
                            <!--<br />--> */}
                    <div className="signup-msg line-height-input">
                      {/* <!-- INVITE ONLY --> */}
                      <span className="signup-opt text-medium">
                        Don't have an account?
                      </span>{" "}
                      <span
                        className="sign-up text-medium"
                        onClick={toggleForm}
                      >
                        Sign up
                      </span>
                    </div>
                  </form>

                  {/* forgot-password and username part */}
                  <form
                    id="forgot-pwd-form"
                    className="auth-input"
                    autocomplete="off"
                    onSubmit={forgotPassword}
                    style={{ display: "none" }}
                  >
                    <input
                      className="inputStyle text-large"
                      type="text"
                      id="username-forgot-password"
                      placeholder="User name"
                      required
                    />{" "}
                    <br />
                    <a
                      style={{ display: "block" }}
                      className="forgot-password text-medium line-height-input"
                      id="forgot-password"
                      onClick={showForgotUsername}
                    >
                      Forgot username?
                    </a>
                    <button
                      className="line-height-input auth-button forgot-button text-large"
                      id="forgot-button"
                    >
                      Submit
                    </button>{" "}
                    <br />
                    <a
                      id="cance-password"
                      className="signup-opt text-medium line-height-input"
                      onClick={resetForgotPassword}
                      style={{
                        display: "block",
                        textDecoration: "none",
                        marginTop: "2%",
                      }}
                    >
                      Cancel
                    </a>
                  </form>
                  <form
                    id="forgot-username-form"
                    className="auth-input"
                    autocomplete="off"
                    onSubmit={forgotUsername}
                    style={{ display: "none" }}
                  >
                    <input
                      type="text"
                      className="inputStyle line-height-input text-large"
                      id="username-forgot"
                      placeholder="Email"
                      required
                    />
                    <div className="line-height-input"></div>
                    <button
                      className="line-height-input auth-button text-large"
                      style={{ marginTop: "2%" }}
                      id="forgot-username-button"
                    >
                      Submit
                    </button>{" "}
                    <br />
                    {/* <!--<br>--> */}
                    <a
                      id="cance-password"
                      className="signup-opt line-height-input text-medium"
                      onClick={resetForgotPassword}
                      style={{
                        display: "block",
                        textDecoration: "none",
                        marginTop: "2%",
                      }}
                    >
                      Cancel
                    </a>
                  </form>
                  <form
                    id="forgot-new-pwd-form"
                    className="auth-input"
                    autocomplete="off"
                    onSubmit={newPassword}
                    style={{ display: " none" }}
                  >
                    <input
                      className="line-height-input inputStyle text-large"
                      type="text"
                      id="verification-code-forgot-password"
                      placeholder="Verification code"
                      required
                    />{" "}
                    <br />
                    <input
                      className="line-height-input inputStyle text-large"
                      type={isPasswordVisible ? "text" : "password"}
                      id="new-pass-forgot-password"
                      placeholder="New password"
                      required
                    />{" "}
                    <span
                      id="view-password-cont"
                      className="password-hidden"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <img src="../../../image/workspace/active-icons/view-password.png" />
                      ) : (
                        <img src="../../../image/workspace/controls/view-password.png" />
                      )}
                    </span>
                    <br />
                    <div>
                      <a
                        className="resendOtp-button line-height-input-alt text-medium"
                        id="forgetPasswordResendOtp"
                        onClick={resendOTP}
                        disabled
                      >
                        Resend Otp?
                      </a>
                      <span className="sendOtpTimer" id="sendOtpTimer"></span>
                    </div>
                    <button
                      className="line-height-input auth-button forgot-button text-large"
                      id="submit-verification"
                      style={{ position: "relative;" }}
                    >
                      Submit
                    </button>{" "}
                    <br />
                    <a
                      id="cancel-verification"
                      className="signup-opt text-medium line-height-input"
                      onClick={resetNewPassword}
                      style={{
                        position: "relative",
                        textDecoration: "none",
                        marginTop: "2%",
                      }}
                    >
                      Cancel
                    </a>
                  </form>
                </div>
                <div className="col-5 mt-5 mt-sm-0">
                  <img
                    src="./img/login/signup_page_mic_image_5.png"
                    alt="Mic-Image"
                    className="image"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="" id="auth-signup">
              <div className="auth-screen d-flex flex-column flex-sm-row">
                <div className="col-12 col-sm-7 justify-content-center align-items-center">
                  <div
                    id="signup-message"
                    className="line-height-input-sign err-message"
                  ></div>
                  <div className="line-height-input"></div>

                  <form
                    id="signup-form"
                    className="auth-input"
                    autocomplete="off"
                    style={{ width: "fit-content", margin: "auto" }}
                    onSubmit={handleSignup}
                  >
                    <input
                      id="userName"
                      className="inputStyle text-large"
                      type="text"
                      placeholder="User name"
                      value={username}
                      onChange={handleUsername}
                      required
                    />{" "}
                    <br />
                    <input
                      id="userEmail"
                      className="inputStyle line-height-input-sign text-large"
                      type="email"
                      placeholder="Email"
                      value={userEmail}
                      onChange={handleEmailChange}
                      required
                    />
                    <br />
                    <input
                      id="sipwd"
                      className="inputStyle line-height-input-sign text-large"
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <span
                      id="view-password-cont-si"
                      className="password-hidden"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <img src="../../../image/workspace/active-icons/view-password.png" />
                      ) : (
                        <img src="../../../image/workspace/controls/view-password.png" />
                      )}
                    </span>
                    <br />
                    <input
                      id="sicpwd"
                      className="inputStyle text-large line-height-input-sign"
                      // type="password"
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                    <span
                      id="view-password-cont-sic"
                      // className="password-hidden"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <img src="../../../image/workspace/active-icons/view-password.png" />
                      ) : (
                        <img src="../../../image/workspace/controls/view-password.png" />
                      )}
                    </span>
                    <div className="signup-msg line-height-input-sign ">
                      <label
                        className="terms-check text-medium"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <img
                          src="../../../image/checkbox-unchecked.png"
                          className="unchecked"
                          id="terms-checkbox"
                          alt=""
                          onClick={checkUncheck}
                        />
                        <span className="signup-opt line-height-input ">
                          I accept the{" "}
                        </span>
                        <span
                          className="sign-up"
                          onClick={handleTermsAndCondition}
                        >
                          terms and conditions
                        </span>
                      </label>
                    </div>
                    {showTermsModal && <TermsandConditionModal />}
                    <button
                      className="line-height-input-sign btn-padding auth-button disable-btn text-large"
                      id="koolio-sign-up"
                    >
                      Sign up
                    </button>
                    {/* )} */}
                    {/* <!-- recaptcha --> */}
                    <form onSubmit={onSubmitWithReCAPTCHA}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfaJLYlAAAAAHlr17xw3x_L4r3rc5GH3-07jEYp"
                      />
                    </form>
                    {/* <!-- recaptcha --> */}
                    <div className="signup-msg line-height-input-sign">
                      <span className="signup-opt text-medium line-height-input-sign">
                        Already have an account?
                      </span>{" "}
                      <span
                        className="sign-up text-medium"
                        onClick={toggleForm}
                      >
                        Log in
                      </span>
                    </div>
                  </form>
                </div>
                <div className="col-5 mt-5 mt-sm-0">
                  <img
                    src="./img/login/signup_page_mic_image_5.png"
                    alt=""
                    className="image"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        id="playlist-loader"
        className="profileModal-wrapper"
        style={{
          // marginTop: "58px",
          // position: "absolute",
          // backgroundColor: "rgba(0,0,0,0)",
          display: "none",
          // zIndex: "15",
        }}
      >
        <img
          src="/image/koolio-icon-workspace.svg"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-32px",
            marginTop: "-32px",
            width: "80px",
          }}
          className="ajax-loader"
          alt="Loading Spinner"
        />
        <p
          className="text-white text-center"
          style={{
            position: "absolute",
            left: "47%",
            top: "65%",
            marginLeft: "-32px",
            marginTop: "-32px",
          }}
        >
          Subcribing to pro plan.....
        </p>
      </div>
      {/* <div
        id="playlist-loader"
        style={{
          display: "none",
          position: "absolute",
          top: "100%",
          left: "60%",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        <img
          src="./image/koolio-icon-workspace.svg"
          style={{ width: "calc(4vw + 4vh)" }}
          className="ajax-loader"
        />
        <p style={{ display: "block", color: "#c4c4c4" }}>Please wait...</p>
      </div> */}
    </>
  );
};

export default LoginPage;
