import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  props: {},
};

const propsSlice = createSlice({
  name: "propsSlice",
  initialState: initialVal,
  reducers: {
    updateProps(state, action) {
      state.props = action.payload.props;
      // console.log(state)
    },
    clearProps(state) {
      //for clearing record file from store after the file is added in kooliospace
      // console.log(state.file)
      state.props = {};
    },
  },
});

export const propsActions = propsSlice.actions;
export default propsSlice.reducer;
