import React, { useEffect } from "react";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { createPortal } from "react-dom";
import { koolioModals } from "../../../../utils/Res";
import store from "../../../../redux/Store";
import { screenReloadSliceActions } from "../../../../redux/slice/ScreenReloadSlice";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";

const NotificationModalView = (props) => {
  let screenReloads = false;

  useEffect(() => {
    koolioModals();
    handleNotificationContent();
  }, []);

  function scrollContent(direction) {
    const content = document.getElementById("featureUpdate");
    const step = 20; // Set your desired scroll step
    if (direction === "up") {
      content.scrollTop -= step;
      document.getElementById("scrollDown").style.visibility = "visible";
      if (content.scrollTop <= 0) {
        document.getElementById("scrollUp").style.visibility = "hidden";
      }
    } else if (direction === "down") {
      content.scrollTop += step;
      document.getElementById("scrollUp").style.visibility = "visible";
      if (
        content.scrollTop + content.offsetHeight >=
        content.scrollHeight - 1
      ) {
        document.getElementById("scrollDown").style.visibility = "hidden";
      }
    }
  }

  window.addEventListener("resize", function (event) {
    // do stuff here
    let a = document.getElementById("featureUpdate");
    if (a) {
      if (a.scrollHeight == a.clientHeight) {
        document.getElementById("scrollDown").style.visibility = "hidden";
        document.getElementById("scrollUp").style.visibility = "hidden";
      } else {
        document.getElementById("scrollDown").style.visibility = "visible";
      }
    }
  });

  function isFirstCharacterEmoji(str) {
    if (str[0] == "/" && str[1] == "h") {
      return true;
    }
    return false;
  }

  function handleNotificationContent() {
    let data = props.data;
    try {
      store.dispatch(
        screenReloadSliceActions.updateScreenReload({ value: true }),
      );
      document.getElementById("annotation-modal-header").textContent =
        data.notification_text;
      //showing the feature updates
      let notificationUpdatesFromBackend = data.notification_data;
      let specificUpdates = notificationUpdatesFromBackend.split("/n");
      let list = document.getElementById("featureUpdate");
      for (let i = 0; i < specificUpdates.length; ++i) {
        let li = document.createElement("li");
        //checking for the emoji on every string so that we can highlight that list
        let isFirstCharacterEmojiOrNot = isFirstCharacterEmoji(
          specificUpdates[i],
        );
        if (isFirstCharacterEmojiOrNot) {
          let slicedString = specificUpdates[i].slice(2);
          li.innerText = slicedString;
          li.style.listStyleType = "none";
          li.style.margin = "5px 0";
          li.style.color = "#E2522B";
        } else {
          li.innerText = specificUpdates[i];
        }

        list.appendChild(li);
      }
      if (
        document.getElementById("featureUpdate").scrollHeight ==
        document.getElementById("featureUpdate").clientHeight
      ) {
        document.getElementById("scrollDown").style.visibility = "hidden";
        document.getElementById("scrollUp").style.visibility = "hidden";
      }
    } catch (err) {
      // console.log(err)
    }
  }

  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="half-modals-content" id="half-modals-content">
          <div
            className="half-modals-header"
            style={{ flexDirection: "column" }}
          >
            <p style={{ margin: 0 }} id="annotation-modal-header">
              Notification Data
            </p>
            <img
              title="close"
              className="img-fluid"
              onClick={() => window.location.reload()}
              id="closeWorkspaceModalIcon"
              style={{ visibility: "hidden" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>
          <div id="notificationUpdates" className="half-modals-header">
            <div
              id="scrollUp"
              style={{
                display: "flex",
                justifyContent: "center",
                visibility: "hidden",
              }}
            >
              <img
                className="img-fluid"
                onClick={() => scrollContent("up")}
                src="/image/angle-up-solid.png"
                alt=""
                width="13px"
              />
            </div>
            <p
              style={{ color: "blueviolet", display: "none", margin: "10px 0" }}
            >
              Feature Updates:
            </p>
            <ul
              id="featureUpdate"
              style={{ maxHeight: "70vh", overflow: "hidden" }}
            ></ul>
            <div
              id="scrollDown"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                className="img-fluid"
                onClick={() => scrollContent("down")}
                src="/image/angle-down-solid.png"
                alt=""
                width="13px"
              />
            </div>
          </div>
          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                onClick={() => window.location.reload()}
                className="half-modals-action-button"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};

export default NotificationModalView;

// if the message is already viewed

export const NotificationModalAlreadyViewed = (props) => {
  const closeHalfModal = () => {
    // Define closeHalfModal function implementation here
    return props.onClose();
  };

  useEffect(() => {
    koolioModals();
  }, []);

  return createPortal(
    <>
      <div className="half-modals-content" id="half-modals-content">
        <div className="half-modals-header">
          <p style={{ margin: 0 }} id="annotation-modal-header">
            You are already enjoying the latest updates!
          </p>
          <img
            title="close"
            className="img-fluid"
            onClick={() => {}}
            id="closeWorkspaceModalIcon"
            style={{ visibility: "hidden" }}
            src="/image/exit-workspace.svg"
            alt=""
            width="13px"
          />
        </div>
        <div className="half-modals-action">
          <div className="half-modals-actions text-center">
            <button
              type="button"
              onClick={closeHalfModal}
              className="half-modals-action-button"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};

export const NotificationWelcomeModal = (props) => {
  const closeHalfModal = () => {
    // Define closeHalfModal function implementation here
    return props.onClose();
  };

  useEffect(() => {
    koolioModals();
  }, []);

  return createPortal(
    <>
      <div
        className="half-modals-content notification-welcome-modal"
        id="half-modals-content"
      >
        <div className="half-modals-header">
          <p style={{ margin: 0 }} id="annotation-modal-header">
            Enjoy the latest updates!
          </p>
          <img
            title="close"
            className="img-fluid"
            onClick={() => {}}
            id="closeWorkspaceModalIcon"
            style={{ visibility: "hidden" }}
            src="static/img/exit-workspace.svg"
            alt=""
            width="13px"
          />
        </div>
        <div className="half-modals-action">
          <div className="half-modals-actions text-center">
            <button
              type="button"
              onClick={closeHalfModal}
              className="half-modals-action-button"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};
