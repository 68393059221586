import { createSlice } from "@reduxjs/toolkit";
const initialUser = {
  userName: sessionStorage.getItem("user") || "",
  //   email: "",
};
const SignInUserSlice = createSlice({
  name: "user",
  initialState: initialUser,
  reducers: {
    setUser(state, action) {
      //   console.log(action.payload);
      sessionStorage.setItem("user", action.payload);
      state.userName = action.payload;
      //   console.log(state.userName);
    },
  },
});

export default SignInUserSlice.reducer;
export const setSignInUserAction = SignInUserSlice.actions;
