import { createSlice } from "@reduxjs/toolkit";
const initialLoaderVal = {
  showLoader: false,
};

const playlistLoaderSlice = createSlice({
  name: "showLoader",
  initialState: initialLoaderVal,
  reducers: {
    updateLoaderValue(state, action) {
      state.showLoader = action.payload.value;
    },
  },
});

export const playlistLoaderSliceActions = playlistLoaderSlice.actions;
export default playlistLoaderSlice.reducer;
