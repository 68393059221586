import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  adjustAnnot: false,
};

const AdjustAnnotationSlice = createSlice({
  name: "adjustAnnot",
  initialState: initialValue,
  reducers: {
    updateAdjustAnnotation(state, action) {
      state.adjustAnnot = action.payload.adjustAnnot;
    },
  },
});

export const AdjustAnnotationActions = AdjustAnnotationSlice.actions;
export default AdjustAnnotationSlice.reducer;
