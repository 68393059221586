import { exportHighQualityAudio } from "../utils";

export async function publishAudioToPodbean(
  episodeTitle,
  episodeContent,
  fileToUpload,
  options,
) {
  await uploadAudioToShowInPodbean(
    episodeTitle,
    episodeContent,
    fileToUpload,
    options,
  );
}

export async function uploadAudioToShowInPodbean(
  episodeTitle,
  episodeContent,
  fileToUpload,
  options,
) {
  const publishInfo = {
    episodeContent,
    episodeTitle,
    apiProvider: "podbean",
  };
  return exportHighQualityAudio(options, false, true, publishInfo)
    .then((res) => Promise.resolve())
    .catch((error) => {
      // Handle the error here
      // console.error("An error occurred:", error);
      // You can choose to rethrow the error or handle it gracefully
      // throw error; // Rethrowing the error
    });
}
