import { createSlice } from "@reduxjs/toolkit";

const initialOperationList = {
  operationList: [],
  isOperationsTransActive: false,
  isfrontendApplyActive: false,
};

const operationListSlice = createSlice({
  name: "operationListData",
  initialState: initialOperationList,
  reducers: {
    operationReducerData(state, action) {
      if (typeof action.payload === "boolean") {
        state.isOperationsTransActive = action.payload;
      } else {
        state.operationList = action.payload;
      }
    },
    clearOperationReducerData(state) {
      state.operationList = [];
    },
  },
});
export const operationListSliceActions = operationListSlice.actions;
export default operationListSlice.reducer;
