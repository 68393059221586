// this slice is to trigger pop up of specific public method, eg: if buzzsprout is clicked then next form should open

import { createSlice } from "@reduxjs/toolkit";

const initialPopUp = {
  apiProvider: "",
};

const initiatePopUpForEpisodeSlice = createSlice({
  name: "initiatePopUpForEpisodeSlice",
  initialState: initialPopUp,
  reducers: {
    updateApiProvider(state, action) {
      state.apiProvider = action.payload.apiProvider;
    },
    cleatApiProvider(state) {
      state.apiProvider = "";
    },
  },
});

export const initiatePopUpForEpisodeSliceActions =
  initiatePopUpForEpisodeSlice.actions;
export default initiatePopUpForEpisodeSlice.reducer;
