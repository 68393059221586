import React from "react";
import { showStatText } from "../../../../utils/utils";
import store from "../../../../redux/Store";

const ToggleButton = ({ isChecked, setIsChecked }) => {
  return (
    <div>
      <label className="switch">
        <input
          type="checkbox"
          id="togBtn"
          checked={isChecked}
          onChange={() => {
            if (store.getState().projectData.resultStat.transcribed) {
              setIsChecked(!isChecked);
            } else
              showStatText("transcription is not available for this project");
          }}
        />
        <div className="toggle round" id="toggleBtn">
          <span
            title="audio driven mode"
            data-bs-custom-class="custom-tooltip"
            data-bs-placement="left"
            className="on"
          >
            A
          </span>
          <span
            title="transcript driven mode"
            data-bs-custom-class="custom-tooltip"
            data-bs-placement="right"
            className="off"
          >
            T
          </span>
        </div>
      </label>
    </div>
  );
};

export default ToggleButton;
