// This slice is used to trigger saveState() which is used to perform undo redo

import { createSlice } from "@reduxjs/toolkit";

const initialVal = {
  currentResultStat: "",
  type: "",
};

const UndoRedoSlice = createSlice({
  name: "undoRedoSlice",
  initialState: initialVal,
  reducers: {
    sendResultstatAndType(state, action) {
      state.currentResultStat = action.payload.resultStat;
      state.type = action.payload.type;
    },
    clearData(state) {
      state.currentResultStat = "";
      state.type = "";
    },
  },
});

export const UndoRedoSliceActions = UndoRedoSlice.actions;
export default UndoRedoSlice.reducer;
