import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";
import { showStatText } from "../utils/utils";

export function getNotifications() {
  const params = {
    username: Koolioai.username,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.get(_config.api + "/api-notifications", {
        params,
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      }),
    )
    .then(
      (response) =>
        new Promise((resolve) => {
          let notifications = response.data;
          resolve(notifications);
        }),
    )
    .catch(
      (_err) =>
        new Promise((resolve) => {
          resolve(null);
        }),
    );
}

export async function fetchMessageInfo(env, param) {
  return axios
    .get(env + "/api-release-notifications", {
      params: {
        release_id: param.release_id,
        username: Koolioai.username,
      },
    })
    .then((res) => {
      console.log(res);
      return res;
    });
}
